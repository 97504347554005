// import { GoogleMap, Marker, infoWindow, useJsApiLoader } from 'google-map-react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow} from '@react-google-maps/api';
import { useEffect, useRef } from 'react'
import "./map.css";

const mapStylesDetails = {
    width: "100%",
    height: "50%",
};

const GoogleMapContainer = ({coords}) => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyB0QTd2qpVsJefoIwGs82dAzIKxxPmP1eA'
    })

    const mapRef = useRef(null)

    const onLoad = (map) => {
        mapRef.current = map
    }

    const onUnMount = () => {
        mapRef.current = null
    }

    useEffect(()=>{
        if(coords && mapRef.current){
            mapRef.current.panTo({lat: coords.latitude, lng: coords.longitude })
        }
    }, [coords, mapRef])


    return (
        <div className="map-container mt-24">
            {isLoaded ? <GoogleMap
                mapContainerStyle={mapStylesDetails}
                onLoad={onLoad}
                onUnMount={onUnMount}
                zoom={12}
                center={{
                    lat: coords && coords.latitude ? coords.latitude : 0,
                    lng: coords && coords.longitude ? coords.longitude : 0
                }}
                option={{
                    disableDefaultUI: true,
                    zoomControl: true
                }}
            />: 'Caricamento...'}
        </div>
    )
}

export default GoogleMapContainer
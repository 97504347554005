import BackgroundTitle from "../components/BackgroundTitle";
import TopBarIndex from "../components/TopBarIndex";
import img1 from "../assets/img/img-slider-home.jpg";
import React, { useState } from "react";
import ItemEvento from "./ItemEvento";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import InputBilo from "../components/InputBilo";
import InputCustom from "../components/InputCustom";
import { MenuItem } from "@mui/material";

const Eventi = () => {

    const [departmentID, setDepartmentID] = useState(null)

    const fetcher = (url) => api.post(url, {
        department_id: !departmentID || departmentID === 'tutte' ? null : departmentID
    }).then((res) => {
        return res
    });
    const { data: events } = useSWR([`/events/list`, departmentID], fetcher);

    const fetcher2 = (url) => api.get(url).then((res) => {
        return res
    });
    const { data: departments } = useSWR([`/all-departments`], fetcher2);

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle title={"Eventi"} img={img1} position={"center"} />
            <div
                className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}
                style={{ fontFamily: "bilo" }}
            >
                <div className={"flex flex-row items-center gap-1"}>
                    <div
                        className={"cursor-pointer hover:opacity-50"}
                    >
                        Home {">"}
                    </div>
                    <div className={"font-bold"}> Eventi</div>
                </div>
                <div
                    className={"my-6 text-4xl text-center w-full text-[#DF5B23]"}
                    style={{ fontFamily: "gimlet-text" }}
                >
                    Ultimi eventi da Trekking Italia
                </div>
                <div className="w-full flex justify-end">
                    <div className={'w-full max-w-[250px]'}>
                        <InputCustom
                            select
                            title={"Sezione"}
                            value={departmentID ? departmentID : 'tutte'}
                            onChange={(e) => setDepartmentID(e.target.value)}>
                            {
                                departments && departments.data && departments.data.data ? departments.data.data.map((department, index) => {
                                    return (
                                        department.id === 1 ?

                                            <MenuItem value={'tutte'} key={index}>
                                                Tutte le sezioni
                                            </MenuItem>
                                            :
                                            <MenuItem value={department.id} key={index}>
                                                {department.name}
                                            </MenuItem>
                                    )
                                }) : ''
                            }
                        </InputCustom>
                    </div>

                </div>
                <div
                    className={
                        "p-2 grid 2xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-6 mt-6 justify-evenl justify-items-center content-center items-center"
                    }
                >
                    {events && events.data && events.data.data && events.data.data.data.length > 0 ? events.data.data.data.map((event, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ItemEvento res={event} />
                            </React.Fragment>
                        );
                    }) : <InputBilo className={'text-2xl'} title={'Nessun Evento'} />}
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default Eventi;

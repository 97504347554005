import React, { useState } from "react";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { api } from "../conf/apiConf";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Alert, Box, Button, IconButton, Modal, Typography } from "@mui/material";
import TopBarIndex from "../components/TopBarIndex";

const validationSchema = yup.object({
  email: yup.string().required("Campo obbligatorio"),
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 4
};

const ResetPass = () => {
  const navigate = useNavigate();
  const [errorForm, setErrorForm] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      api.setHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      api.post(`/forgot-password`, values).then((res) => {
        if (res.ok) {
          setOpenSuccess(true)
        } else {
          setErrorForm(res.data.errors);
        }
      });
    },
  });

  return (
    <>
      <TopBarIndex />
      <form onSubmit={formik.handleSubmit}>
        <div class=" my-36">
          <div class="flex flex-col  items-center justify-center px-6 py-8 mx-auto lg:py-0">
            <div class="w-full  bg-[#f0e9e3] rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
              <div class="p-6 gap-4 flex flex-row items-center">
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIcon />
                </IconButton>
                <div class="font-bold text-[#4a4137] text-2xl">
                  Inserisci la tua mail
                </div>
              </div>
              <div className="px-6 py-4">
                <TextField
                  label={"Email"}
                  name={"email"}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              {errorForm &&
                Object.values(errorForm).map((res, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Alert className={"mb-2 mx-6"} severity="error">
                        {res}
                      </Alert>
                    </React.Fragment>
                  );
                })}
              <div className={"w-full flex flex-row gap-6 my-6 justify-center"}>
                <button
                  type={"submit"}
                  className={
                    "uppercase cursor-pointer hover:opacity-50 text-center text-white text-lg w-1/2 p-2 bg-[#df5b23]"
                  }
                  style={{ fontFamily: "gimlet-text" }}>
                  RESET PASSWORD
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Modal
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ m: 10, textAlign: 'center' }}>
            E' stata inviata una mail con il collegamento per impostare una nuova password
          </Typography>
          <div
            className={`w-full mt-6 flex flex-row items-center gap-4 justify-center`}>
            <Button
              variant={"contained"}
              className={
                "shadow-md uppercase w-full max-w-[300px] !text-2xl italic !font-normal"
              }
              onClick={() => {
                window.scroll(0, 0)
                navigate("/login")
                setOpenSuccess(false)
              }}>
              Chiudi
            </Button>
          </div>
        </Box>
      </Modal>

      <FooterTop />
      <FooterDown />
    </>
  );
};

export default ResetPass;

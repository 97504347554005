import React from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from '../Sezioni/FooterTop';
import FooterDown from '../Sezioni/FooterDown';
import InputBilo from '../components/InputBilo';
import InputGimlet from '../components/InputGimlet';
import { Link } from 'react-router-dom';

function Manutenzione({ text = '', to = '/' }) {
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Coperture assicurative"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%] flex justify-center flex-col items-center">
                    <InputBilo className={'text-2xl'} title={text} />
                    <Link
                        className={"my-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}
                        to={to}
                    >
                        Torna alla Home
                    </Link>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    )
}

export default Manutenzione
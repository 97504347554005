import React, { useEffect, useState } from "react";
import logo from "../assets/img/logo-footer.png";
import fb from "../assets/img/icona-footer-social-FB.png";
import insta from "../assets/img/icona-footer-social-IG.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import { Link, useNavigate } from "react-router-dom";
const FooterDown = () => {

    const [department, setDepartment] = useState(null)
    const navigate = useNavigate()
    const fetcher1 = (url) =>
        api.get(url).then((res) => {
            return res;
        });
    const { data } = useSWR([`/all-departments`], fetcher1);

    useEffect(() => {
        if (data && data.data && data.data.data) {
            data.data.data.map(department => {
                if (department.name.includes('Nazionale')) {
                    setDepartment(department)
                }
                return 0
            })
        } else {
            setDepartment(null)
        }
    }, [data])

    return (
        <div className={"bg-[#796557]"}>
            <div
                className={
                    "flex lg:flex-row flex-col items-center justify-between px-12"
                }
            >
                <div
                    className={
                        "flex lg:flex-row flex-col lg:items-center items-start gap-4 lg:pl-12 lg:pb-24"
                    }
                >
                    <div
                        className={
                            "flex flex-col border-l border-white mt-12 text-white pl-4"
                        }
                    >
                        <div>
                            <img src={logo} alt={"img"} className={"object-contain mb-6 "} />
                        </div>
                        <div className={"font-bold"}>SEDE LEGALE</div>
                        <div>Via Lazzaro Palazzi 3</div>
                        <div>20124 - MILANO</div>
                        <div>P.IVA 09434450152</div>
                    </div>
                    <div
                        className={
                            "flex flex-col border-l lg:border-x border-white lg:mt-12 mt-4 text-white px-4"
                        }
                    >
                        <div className={"font-bold"}>SITE MAP</div>
                        <ol>
                            <li>
                                <Link to={'/associazione'} className={"hover:cursor-pointer flex flex-row items-center gap-2"}>
                                    <FiberManualRecordIcon sx={{ width: 10 }} />
                                    Associazione
                                </Link>
                            </li>

                            <li>
                                <Link to={'/trek-italia'} className={"hover:cursor-pointer flex flex-row items-center gap-2"}>
                                    <FiberManualRecordIcon sx={{ width: 10 }} />
                                    I nostri trek
                                </Link>
                            </li>

                            <li>
                                <Link to={'/trek-magazine'} className={"hover:cursor-pointer flex flex-row items-center gap-2"}>
                                    <FiberManualRecordIcon sx={{ width: 10 }} />
                                    Magazine
                                </Link>
                            </li>

                            <li>
                                <Link to={'/news'} className={"hover:cursor-pointer flex flex-row items-center gap-2"}>
                                    <FiberManualRecordIcon sx={{ width: 10 }} />
                                    News
                                </Link>
                            </li>

                            <li>
                                <Link to={'/eventi'} className={"hover:cursor-pointer flex flex-row items-center gap-2"}>
                                    <FiberManualRecordIcon sx={{ width: 10 }} />
                                    Eventi
                                </Link>
                            </li>

                        </ol>
                        {/* <div onClick={()=>{navigate('/register')}} className={"hover:cursor-pointer flex flex-row items-center gap-2"}>
                            <FiberManualRecordIcon sx={{ width: 10 }} />
                            Diventa socio
                        </div> */}
                    </div>
                    <div
                        className={
                            "flex flex-col lg:mt-12 mt-4 text-white border-l lg:border-0 border-white pl-4"
                        }
                    >
                        <div className={"font-bold"}>LEGAL</div>
                        <ol>
                            <li>
                                <Link to={'/privacy-policy'} className={"hover:cursor-pointer flex flex-row items-center gap-2"}>
                                    <FiberManualRecordIcon sx={{ width: 10 }} />
                                    Privacy policy
                                </Link>
                            </li>
                            <li>
                                <Link to={'/cookie-policy'} className={"hover:cursor-pointer flex flex-row items-center gap-2"}>
                                    <FiberManualRecordIcon sx={{ width: 10 }} />
                                    Cookie policy
                                </Link>
                            </li>
                            <li>
                                <Link to={'/statuto-e-regolamenti'} className={"hover:cursor-pointer flex flex-row items-center gap-2"}>
                                    <FiberManualRecordIcon sx={{ width: 10 }} />
                                    Statuto
                                </Link>
                            </li>
                        </ol>
                    </div>
                </div>
                {department &&
                    ((department.facebook !== null && department.facebook !== undefined) ||
                        (department.youtube !== null && department.youtube !== undefined) ||
                        (department.instagram !== null && department.instagram !== undefined)) ?
                    <div
                        className={
                            "rounded-3xl border mt-12 lg:mt-0 border-white p-2 gap-2 mb-4 lg:mb-0 flex flex-row items-center"
                        }
                    >
                        <div className={"text-xl text-white"}>Seguici sui social:</div>
                        <>
                            {
                                department.facebook !== null && department.facebook !== undefined ?
                                    <div onClick={() => { window.open(department.facebook) }} className={"p-3 rounded-full bg-[#f0e9e3]"}>
                                        <FacebookIcon sx={{ color: "#796557", fontSize: 30 }} />
                                    </div>
                                    : ''
                            }
                            {
                                department.instagram !== null && department.instagram !== undefined ?
                                    <div onClick={() => { window.open(department.instagram) }} className={"p-3 rounded-full bg-[#f0e9e3]"}>
                                        <InstagramIcon sx={{ color: "#796557", fontSize: 30 }} />
                                    </div>
                                    : ''
                            }
                            {
                                department.youtube !== null && department.youtube !== undefined ?
                                    <div onClick={() => { window.open(department.youtube) }} className={"p-3 rounded-full bg-[#f0e9e3]"}>
                                        <YouTubeIcon sx={{ color: "#796557", fontSize: 30 }} />
                                    </div>
                                    : ''
                            }
                        </>

                    </div>
                    : <></>}
            </div>
            <div
                className={
                    "px-6 py-4 lg:text-lg text-md bg-[#34291f] text-white text-center"
                }
            >
                Trekking Italia è un’associazione senza scopo di lucro che ha lo scopo
                di promuovere il trekking in tutte le sue manifestazioni. Tutte le
                attività di Trekking Italia sono riservate esclusivamente ai soci.
            </div>
        </div>
    );
};

export default FooterDown;

import React, { useState } from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/news.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import ItemNews from "../components/ItemNews";
import ItemArchivioNews from "../components/ItemArchivioNews";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import ErrorsComponent from "../components/ErrorsComponent";
import LoadingComponent from "../components/LoadingComponent";
import "../News/scrollbar.css";
import InputGimlet from "../components/InputGimlet";
import { Modal } from "@mui/material";
import NesModaleAchivio from "./NesModaleAchivio";

const News = () => {
    const [openArchivio, setOpenArchivio] = useState(false)
    const navigate = useNavigate();
    const { token } = useSelector((rootState) => rootState.count);
    api.setHeaders({
        Authorization: "Bearer " + token,
    });
    const fetcher = (url) =>
        api
            .post(url, {
                active: 1,
                order_by_last: 1,
                // limit: 12,
            })
            .then((res) => res);
    const { data } = useSWR([`/news`], fetcher);

    //   const fetcher2 = (url) =>
    //     api
    //       .post(url, {
    //         active: 1,
    //         order_by_last: 1,
    //         limit: 3,
    //       })
    //       .then((res) => res);
    //   const { data: archivio } = useSWR([`/archive-news`], fetcher2);

    if ((data && !data.ok))
        return <ErrorsComponent />;
    if (!data) return <LoadingComponent />;
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle title={"News"} img={img1} position={"center"} />
            <div
                className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}
                style={{ fontFamily: "bilo" }}
            >
                <div className={"flex flex-row items-center gap-1"}>
                    <div
                        className={"cursor-pointer hover:opacity-50"}
                        onClick={() => navigate(-1)}
                    >
                        Home {">"}
                    </div>
                    <div className={"font-bold"}> News</div>
                </div>
                <div
                    className={"my-6 text-4xl text-center w-full text-[#DF5B23]"}
                    style={{ fontFamily: "gimlet-text" }}
                >
                    Ultime news da Trekking Italia
                </div>
                <div
                    className={
                        "xl:mx-56 p-2 xl:grid xl:grid-cols-2 2xl:grid-cols-3 lg:grid lg:grid-cols-2 flex flex-col gap-6 justify-items-center mt-6"
                    }
                    style={{
                        maxHeight: "45rem",
                        overflow: "auto",
                    }}
                >
                    {data.data.data.map((res, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ItemNews res={res} />
                            </React.Fragment>
                        );
                    })}
                </div>
                <div
                    className={"my-6 mt-12 text-4xl text-center w-full text-[#DF5B23]"}
                    style={{ fontFamily: "gimlet-text" }}
                >
                    Archivio
                </div>
                <div
                    className={
                        "xl:mx-56 p-2 xl:grid xl:grid-cols-2 2xl:grid-cols-3 lg:grid lg:grid-cols-2 flex flex-col gap-6 justify-items-center mt-6"
                    }
                >
                    {[... new Array((new Date).getFullYear() - 2023)].map((res, index) => {
                        return (
                            <ItemArchivioNews key={index} res={res} index={index} setOpenArchivio={setOpenArchivio} />
                        );
                    })}
                </div>
            </div>
            <NesModaleAchivio setOpenArchivio={setOpenArchivio} openArchivio={openArchivio}/>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default News;

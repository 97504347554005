import React, { useState } from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import FooterTop from '../Sezioni/FooterTop'
import FooterDown from '../Sezioni/FooterDown'
import img1 from "../assets/img/accompagnatori.jpg";
import InputGimlet from '../components/InputGimlet'
import env from '../conf/variables'
import { Typography } from '@mui/material'
import { api } from '../conf/apiConf'
import useSWR from 'swr'
import axios from 'axios'

function BilancioSociale() {

    const [today, setToday] = useState(new Date())

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Bilancio sociale"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
                <div
                    className={"flex flex-row items-center gap-1"}
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    <div>Associazione  {">"}</div>
                    <div>Trekking Italia  {">"}</div>
                    <div className={"font-bold"}>Bilancio sociale</div>
                </div>
                <div>

                    <div className={"flex flex-col "}>
                        <InputGimlet
                            title={'Bilancio sociale'}
                            fontWeight={500}
                            fontStyle="italic"
                            className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                        />
                        {
                            [...new Array(Number(today.getFullYear()) - 2021)].map((year, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Typography className={'!mt-10 !text-2xl'} component={"h1"}>{2021 + index}</Typography>
                                        <iframe
                                            title="Informativa Privacy"
                                            className="w-full"
                                            height="800"
                                            src={`${env.url_api_without_api}document/bilancio/bilancio_sociale/${2021 + index}.pdf`}
                                            allowFullScreen
                                        ></iframe>
                                    </React.Fragment>
                                )
                            }).sort((a, b)=> b?.key - a?.key )
                        }
                    </div>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    )
}

export default BilancioSociale
import React from "react";
import img1 from "../assets/img/maschera-come-attrezzarsi-home.png";
import img2 from "../assets/img/maschera-dove-andiamo-home.png";
import { Link, useNavigate } from "react-router-dom";

const TrekkingConsiglia = () => {

    const navigate = useNavigate()

    return (
        <div className={"bg-[#f0e9e3] pb-80 flex flex-col"}>
            <h1
                style={{ fontFamily: "gimlet-text" }}
                className={"text-[#df5b23] text-4xl mb-12 text-center mt-6"}
            >
                Trekking Italia
            </h1>
            <div className={"w-full flex flex-row my-36 justify-center items-start"}>
                <div
                    className={
                        "bg-white flex flex-row items-center  w-1/2 relative rounded-r-xl p-8"
                    }
                >
                    <img
                        alt={"trek"}
                        src={img1}
                        className={"object-contain w-full absolute -left-80 z-20"}
                        style={{ width: "50rem", height: "35rem" }}
                    />
                    <div className={"pl-56 flex flex-col items-end gap-8 w-full"}>
                        <h2 className={"text-[#df5b23] text-xl"}>DOVE ANDIAMO?</h2>
                        <p
                            style={{ fontFamily: "gimlet-text" }}
                            className={"text-2xl w-72 text-end"}
                        >
                            Le destinazioni più amate per camminare in compagnia
                        </p>
                        <Link to={'/trek-italia'}
                            className={
                                "uppercase cursor-pointer hover:opacity-50 text-center text-lg w-1/3 p-2 bg-[#f0e9e3]"
                            }
                            style={{ fontFamily: "gimlet-text" }}
                        >
                            SCOPRILE
                        </Link>
                    </div>
                </div>
            </div>
            <div className={"w-full flex flex-row my-36 justify-center items-start"}>
                <div
                    className={
                        "bg-white flex flex-row items-center  w-1/2 relative rounded-l-xl p-8"
                    }
                >
                    <div className={"pr-56 flex flex-col items-start gap-8 w-full"}>
                        <h2 className={"text-[#df5b23] text-xl"}>DOVE ANDIAMO?</h2>
                        <p
                            style={{ fontFamily: "gimlet-text" }}
                            className={"text-2xl w-80 text-start"}
                        >
                            Preparati per i tuoi trekking di uno o più giorni con i nostri
                            volontari
                        </p>
                        <button
                            role="button"
                            className={
                                "uppercase cursor-pointer hover:opacity-50 text-center text-lg w-1/3 p-2 bg-[#f0e9e3]"
                            }
                            style={{ fontFamily: "gimlet-text" }}
                        >
                            LEGGI DI PIU'
                        </button>
                    </div>
                    <img
                        alt={"Paesaggio"}
                        src={img2}
                        className={"object-contain w-full absolute -right-80 z-20"}
                        style={{ width: "50rem", height: "35rem" }}
                    />
                </div>
            </div>
        </div>
    );
};

export default TrekkingConsiglia;

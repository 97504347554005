import BackgroundTitle from "../components/BackgroundTitle";
import TopBarIndex from "../components/TopBarIndex";
import img1 from "../assets/img/img-slider-home.jpg";
import InputBilo from "../components/InputBilo";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import { useEffect, useState } from "react";
import InputGimlet from "../components/InputGimlet";
import renderHTML from "react-render-html";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import GoogleMapContainer from "../Trek/GoogleMapContainer";
import MapContainer from "../Trek/MapContainer";
import InputCustom from "../components/InputCustom";
import { Alert, Dialog, DialogContent, DialogTitle, MenuItem } from "@mui/material";
import Login from "../Auth/Login";
import { useSelector } from "react-redux";
import DialogCustom from "../components/DialogCustom";
import { useFormik } from "formik";
import env from "../conf/variables";
import parse from 'html-react-parser';
const SingleEvent = () => {
    const [event, setEvent] = useState(null)
    const [monthName, setMonthName] = useState('')
    const [imgUrl, setImgUrl] = useState('')
    const [imgUrlIndex, setImgUrlIndex] = useState(0)
    const [openIscriviti, setOpenIscriviti] = useState(false)
    const [openPrenotati, setOpenPrenotati] = useState(false)
    const [idMultiIscrizioni, setIdMultiIscrizioni] = useState([])
    const [usersFound, setUsersFound] = useState([])
    const [openPaymentMethod, setOpenPaymentMethod] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate()
    const count = useSelector(state => state.count)
    const [searchUser, setSearchUser] = useState({
        nome: '',
        cognome: '',
        email: '',
        codice_fiscale: ''
    })
    const [error, setError] = useState(
        {
            open: false,
            error: ''
        }
    )
    const [success, setSuccess] = useState(
        {
            open: false,
            message: ''
        }
    )
    const [openFormSearchUser, setOpenFormSearchUser] = useState(false)
    const [userSelected, setUserSelected] = useState(null)
    const location = useLocation()

    api.setHeaders({
        Authorization: "Bearer " + count.token,
    });

    const fetcher = (url) => api.get(url).then((res) => {
        setEvent(res.data.data)
        return res
    });
    const { data: eventObj } = useSWR([`/events/show/${id}`], fetcher);


    function getNameMonth(monthIndex) {
        let name = 'Gen'
        switch (monthIndex) {
            case 0:
                name = 'Gen';
                break;
            case 1:
                name = 'Feb';
                break;
            case 2:
                name = 'Mar';
                break;
            case 3:
                name = 'Apr';
                break;
            case 4:
                name = 'Mag';
                break;
            case 5:
                name = 'Giu';
                break;
            case 6:
                name = 'Lug';
                break;
            case 7:
                name = 'Ago';
                break;
            case 8:
                name = 'Set';
                break;
            case 9:
                name = 'Ott';
                break;
            case 10:
                name = 'Nov';
                break;
            case 11:
                name = 'Dec';
                break;

            default:
                break;
        }

        setMonthName(name)
    }

    function handleClickedImg(e, index) {
        setImgUrl(event && event.other_image ? event.other_image[index].image : '')
        setImgUrlIndex(index)
    }

    function handleNavigationGalleryButton(e, to) {
        let newIndex = imgUrlIndex + to
        if (newIndex < 0) {
            newIndex = 0
        }
        if (newIndex > event && event.other_image !== null ? event.other_image.length : 0) {
            newIndex = event && event.other_image ? (event.other_image.length - 1) : 0
        }
        setImgUrl(event && event.other_image && event.other_image !== null ? event.other_image[newIndex].image : '')
        setImgUrlIndex(newIndex)
    }

    useEffect(() => {
        getNameMonth(new Date(event ? event.date_event : '').getMonth())
        setImgUrl(event && event.other_image ? event.other_image[0].image : '')
        setImgUrlIndex(0)
    }, [event])

    function handleClickPagamento(payment_method = '') {
        api.post('subscriptions/add-subscription-without-payment', {
            user_id: count.profile.id,
            id: event ? event.event_subscription_id : '',
            type: 'event',
            userIDs: idMultiIscrizioni.map(user => user.id),
            balance: event,
            payment_method: payment_method
        }).then(res => {
            if (res.data.errors) {
                setError({
                    open: true,
                    error: res.data.errors.user[0]
                })
            } else {
                setSuccess({
                    open: true,
                    message: 'La informiamo che ha effettuato correttamente l\'iscrizione'
                })
            }

            return res
        })
        setOpenIscriviti(false);
    }

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle title={event ? event.title : ''} img={img1} position={"center"} />
            <div
                className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}
                style={{ fontFamily: "bilo" }}
            >
                <div className={"flex flex-row items-center gap-1"}>
                    <div
                        className={"cursor-pointer hover:opacity-50"}
                    >
                        Home {">"}
                    </div>
                    <div>Eventi {">"}</div>
                    <div className={"font-bold"}>{event ? event.title : ''}</div>
                </div>
                <div className="flex my-5">
                    <div className="bg-[#F1E9E4] aspect-square w-[4rem]">
                        <InputGimlet title={event ? new Date(event.date_event).getDate() : ''} fontStyle="italic" fontWeight={700} className={'text-3xl text-center'} />
                        <InputGimlet title={monthName} fontStyle="italic" fontWeight={500} className={'text-2xl text-center'} />
                    </div>
                    <div className="ml-2">
                        <InputGimlet title={'Sezione'} fontStyle="italic" fontWeight={500} className={'text-2xl text-[#DF5B23]'} />
                        <InputGimlet title={event ? event.dname : ''} fontStyle="italic" fontWeight={700} className={'text-3xl text-[#DF5B23]'} />
                    </div>
                </div>
                <InputGimlet title={event ? event.title : ''} fontStyle="italic" fontWeight={700} className={'text-3xl text-[#DF5B23]'} />
                <InputGimlet title={event ? event.subtitle : ''} fontStyle="italic" fontWeight={500} className={'text-2xl text-[#DF5B23]'} />
                {event && event.hours_start ?
                    <div className="mt-5">
                        <InputBilo
                            className={'text-center text-2xl inline'}
                            title={'ORARIO DI INIZIO: '}
                            fontWeight={700}
                        />
                        <InputBilo
                            className={'text-center text-2xl inline'}
                            title={event['hours_start'].split('T')[1].split(':')[0] + ':' + event['hours_start'].split('T')[1].split(':')[1]}

                        />
                    </div>
                    : ''}
                {event && event.hours_end ?
                    <div className="my-5">
                        <InputBilo
                            className={'text-center text-2xl inline'}
                            title={'ORARIO DI FINE: '}
                            fontWeight={700}
                        />
                        <InputBilo
                            className={'text-center text-2xl inline'}
                            title={event['hours_end'].split('T')[1].split(':')[0] + ':' + event['hours_end'].split('T')[1].split(':')[1]}
                        />
                    </div>
                    : ''}
                <InputBilo
                    className={'text-xl whitespace-pre-wrap'}
                    title={parse(event && event.description ? event.description : '')}
                />

                <div className="my-5">
                    <InputBilo
                        className={'text-xl inline'}
                        title={'Scarica la locandina dell’evento '}
                    />
                    <div
                        onClick={() => {
                            if (event && event.event_poster_path) {
                                window.open(event.event_poster_path, '_blank')
                            }
                        }}
                        className={'text-xl text-[#DF5B23] underline inline hover:opacity-80 hover:cursor-pointer font-bold'}
                    >
                        Qui
                    </div>
                </div>

                {
                    event && event.other_image ?
                        <>
                            <div className="h-[630px] w-full rounded-xl relative">
                                <div onClick={(e) => { handleNavigationGalleryButton(e, -1) }} className="absolute top-1/2 -translate-y-1/2 left-[10px]">{'<'}-</div>
                                <img className="rounded-xl h-full w-full object-cover" src={imgUrl} alt="" />
                                <div onClick={(e) => { handleNavigationGalleryButton(e, 1) }} className="absolute top-1/2 -translate-y-1/2 right-[10px]">-{'>'}</div>
                            </div>
                            <div className="flex justify-center gap-2 items-center">
                                {event.other_image.map((img, index) => {
                                    return <div onClick={(e) => { handleClickedImg(e, index) }} className="rounded-lg aspect-square w-[4rem]">
                                        <img className="rounded-xl h-full w-full object-cover" src={img.image} alt="" />
                                    </div>
                                })}
                            </div>
                        </>
                        : ''
                }

                {
                    event && event.special_guests ?
                        <>
                            <InputGimlet
                                title={'Ospiti di rilievo'}
                                fontStyle="italic"
                                className={'text-2xl text-[#DF5B23]'}
                            />
                            {
                                event.special_guests.map((guest, index) => {
                                    return <div key={index}>
                                        <InputBilo
                                            title={guest.name + ' ' + guest.surname}
                                            fontWeight={700}
                                            className={'text-lg inline'}
                                        />
                                        <InputBilo
                                            title={guest.special_guest_description}
                                            fontWeight={700}
                                            className={'text-lg inline'}
                                        />
                                    </div>
                                })
                            }
                        </>
                        : ''
                }

                {
                    event && event.referenti_esterni ?
                        <>
                            <InputGimlet
                                title={'Referenti esterni'}
                                fontStyle="italic"
                                className={'text-2xl text-[#DF5B23]'}
                            />
                            {
                                event.referenti_esterni.map((referente, index) => {
                                    return <div key={index}>
                                        <InputBilo
                                            title={referente.name + ' ' + referente.surname}
                                            fontWeight={700}
                                            className={'text-lg inline'}
                                        />
                                        <InputBilo
                                            title={referente.referente_esterno_description}
                                            fontWeight={700}
                                            className={'text-lg inline'}
                                        />
                                    </div>
                                })
                            }
                        </>
                        : ''
                }

                {
                    event && event.referenti_interni ?
                        <>
                            <InputGimlet
                                title={'Referenti interni'}
                                fontStyle="italic"
                                className={'text-2xl text-[#DF5B23]'}
                            />
                            {
                                event.referenti_interni.map((referente, index) => {
                                    return <div key={index}>
                                        <InputBilo
                                            title={referente.name + ' ' + referente.surname}
                                            fontWeight={700}
                                            className={'text-lg inline'}
                                        />
                                        <InputBilo
                                            title={referente.referente_interno_description}
                                            fontWeight={700}
                                            className={'text-lg inline'}
                                        />
                                    </div>
                                })
                            }
                        </>
                        : ''
                }

                <div className="flex flex-col lg:flex-row justify-between align my-5">
                    <div className={'w-full'}>
                        <div className="p-2 bg-[#e7ded5] pt-6">
                            <div className="flex flex-row items-center gap-2">
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    }}
                                    className={"inline-flex text-3xl text-[#796557]"}>
                                    QUOTA DI PARTECIPAZIONE{" "}
                                </div>
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    }}
                                    className={"text-3xl  text-[#DF5B23]"}>
                                    €
                                    {event ? event.quote_request : ''}
                                </div>
                            </div>
                            <div className={"underline text-[#796557] text-2xl"}>
                                *leggi le note sulla composizione delle quota
                            </div>
                        </div>
                        <div className="p-2 pb-6 bg-[#f2ede8]">

                            <div className={"flex flex-row items-center"}>
                                <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                    }}
                                    className={"ml-2 text-2xl  text-[#796557]"}>
                                    Tipo d'iscrizione:{" "}
                                    <div
                                        style={{
                                            fontFamily: "bilo",
                                            fontWeight: "bold",
                                            fontStyle: "normal",
                                        }}
                                        className={"inline-flex"}>
                                        {event ? event.type_enrollment : ''}
                                    </div>
                                </div>
                            </div>
                            <div className={"flex flex-row items-center"}>
                                <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                    }}
                                    className={"ml-2 text-2xl  text-[#796557]"}>
                                    Modalità d'iscrizione:{" "}
                                    <div
                                        style={{
                                            fontFamily: "bilo",
                                            fontWeight: "bold",
                                            fontStyle: "normal",
                                        }}
                                        className={"inline-flex"}>
                                        {event ? event.registration_methods.slice(0, 1).toUpperCase() + event.registration_methods.slice(1) : ''}
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    fontFamily: "bilo",
                                    fontWeight: "bold",
                                    fontStyle: "normal",
                                }}
                                className={"text-2xl text-[#DF5B23] mt-12"}>
                                {event && event.type_enrollment !== 'non_necessaria' ? event.available_seats > 0 ? 'Posti disponibili: ' + event.available_seats : "Abbiamo raggiunto il numero massimo di iscritti" : ''}
                            </div>
                        </div>
                        <div
                            style={{
                                fontFamily: "bilo",
                                fontWeight: "bold",
                                fontStyle: "normal",
                            }}
                            className={"text-2xl text-[#796557] pl-2 bg-[#f2ede8]"}>
                            Per ulteriori informazioni contatta la segreteria di Trekking
                            Italia
                        </div>
                        <div
                            style={{
                                fontFamily: "bilo",
                                fontWeight: 500,
                                fontStyle: "normal",
                            }}
                            className={"text-2xl text-[#796557] p-2 pb-6 bg-[#f2ede8]"}>
                            {event ? event.email_department : ''}
                        </div>
                        {
                            event && event.users_subscribers && (count?.profile?.id in event.users_subscribers) ?
                                <div className="pl-2 pb-2 w-full flex items-center flex-col md:flex-row bg-[#f2ede8] gap-2">
                                    <h1 className="py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit ">
                                        {
                                            event.users_subscribers[count?.profile?.id] === 'subscriber' || event.users_subscribers[count?.profile?.id] === 'complete' ?
                                                'Sei già iscritto'
                                                : (
                                                    event.users_subscribers[count?.profile?.id] === 'reservation' ?
                                                        'Iscrizione in corso'
                                                        :
                                                        'Sei in lista d\'attesa'
                                                )
                                        }
                                    </h1>
                                </div>
                                :
                                <div className="pl-2 pb-2 w-full flex items-center flex-col md:flex-row bg-[#f2ede8] gap-2">
                                    {
                                        event && event.add_subscriber ?
                                            <button
                                                onClick={() => { setOpenIscriviti(true) }}
                                                className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                style={{
                                                    fontFamily: "gimlet-text",
                                                    fontWeight: 500,
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                ISCRIVITI
                                            </button>
                                            : ''
                                    }
                                    {
                                        event && event.add_reservation ?
                                            <button
                                                onClick={() => { setOpenPrenotati(true) }}
                                                className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                style={{
                                                    fontFamily: "gimlet-text",
                                                    fontWeight: 500,
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                PRENOTATI
                                            </button>
                                            : ''
                                    }
                                    {
                                        event && event.add_waiting_list ?
                                            <button
                                                className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                style={{
                                                    fontFamily: "gimlet-text",
                                                    fontWeight: 500,
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                LISTA D'ATTESA
                                            </button>
                                            : ''
                                    }
                                </div>
                        }

                    </div>
                    <div className="mt-4 lg:mt-0 h-[50vh] lg:h-[20vh] w-full lg:w-[75%]">
                        {
                            event ? <MapContainer details={{
                                latitude_start: event.latitude_start,
                                longitude_start: event.longitude_start,
                            }} /> : ''
                        }
                    </div>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
            {
                count && count.profile ?
                    <>
                        {/* ISCRIVITI */}
                        <Dialog
                            open={openIscriviti}
                            onClose={() => {
                                setOpenIscriviti(false);
                            }}
                            maxWidth={"lg"}
                            fullWidth={true}>
                            <>
                                <DialogTitle>
                                    Iscrizione
                                </DialogTitle>
                                <DialogContent>
                                    <div className="grid grid-cols-2 gap-4 border-2 pr-[95px]">
                                        <div className="flex">
                                            <InputBilo
                                                title={`Nome: ${count.profile.name}`}
                                                className={'text-lg'} />
                                        </div>
                                        <div className="flex">
                                            <InputBilo
                                                title={`Cognome: ${count.profile.surname}`}
                                                className={'text-lg'} />
                                        </div>
                                        <div className="flex">
                                            <InputBilo
                                                title={`email: ${count.profile.email}`}
                                                className={'text-lg'} />
                                        </div>
                                        <div className="flex">
                                            <InputBilo
                                                title={`Codice Fiscale: ${count.profile.fiscal_code}`}
                                                className={'text-lg'} />
                                        </div>
                                    </div>
                                    {
                                        idMultiIscrizioni && idMultiIscrizioni.map((user, index) => {
                                            return (
                                                <div className="flex gap-2 items-center">
                                                    <div key={index} className="w-full mt-2 grid grid-cols-2 gap-4 border-2">
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`Nome: ${user.nome}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`Cognome: ${user.surname}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`email: ${user.email}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`Codice Fiscale: ${user.codice_fiscale}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                    </div>
                                                    <button
                                                        onClick={() => {
                                                            setIdMultiIscrizioni(prevState => {
                                                                return prevState.filter((value, indexPrev) => {
                                                                    return indexPrev !== index
                                                                })
                                                            })
                                                        }}
                                                        className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                        style={{
                                                            fontFamily: "gimlet-text",
                                                            fontWeight: 500,
                                                            fontStyle: "italic",
                                                        }}>
                                                        X
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="mt-5">
                                        <button
                                            onClick={() => { setOpenFormSearchUser(true) }}
                                            className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                            style={{
                                                fontFamily: "gimlet-text",
                                                fontWeight: 500,
                                                fontStyle: "italic",
                                            }}>
                                            + Aggiungi Utente
                                        </button>
                                    </div>
                                    {openFormSearchUser &&
                                        <>
                                            <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 w-full items-end gap-4 mt-5">
                                                <InputCustom
                                                    name={"nome"}
                                                    value={searchUser.nome}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            nome: e.target.value
                                                        }
                                                    })}
                                                    title={"Nome*"}
                                                    fullWidth
                                                />
                                                <InputCustom
                                                    name={"cognome"}
                                                    value={searchUser.cognome}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            cognome: e.target.value
                                                        }
                                                    })}
                                                    title={"cognome*"}
                                                    fullWidth
                                                />
                                                <InputCustom
                                                    name={"email"}
                                                    value={searchUser.email}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            email: e.target.value
                                                        }
                                                    })}
                                                    title={"Email"}
                                                    fullWidth
                                                />
                                                <InputCustom
                                                    name={"fiscal_code"}
                                                    value={searchUser.codice_fiscale}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            codice_fiscale: e.target.value
                                                        }
                                                    })}
                                                    title={"Codice Fiscale"}
                                                    fullWidth
                                                />
                                                <button
                                                    onClick={() => {
                                                        setUsersFound([])
                                                        api.post('search-user-for-subscription', searchUser).then((res) => {
                                                            setUsersFound(res.data.data.map((user) => {
                                                                return {
                                                                    nome: user.name,
                                                                    surname: user.surname,
                                                                    email: user.email,
                                                                    codice_fiscale: user.fiscal_code,
                                                                    id: user.id
                                                                }
                                                            }))
                                                            return res
                                                        });
                                                    }}
                                                    type={"button"}
                                                    className={"h-[50px] py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                    style={{
                                                        fontFamily: "gimlet-text",
                                                        fontWeight: 500,
                                                        fontStyle: "italic",
                                                    }}>
                                                    CERCA
                                                </button>
                                            </div>
                                            {
                                                usersFound.length &&
                                                <div className="flex gap-2">
                                                    <InputCustom
                                                        id="select_user"
                                                        select
                                                        fullWidth
                                                        defaultValue='-'
                                                        name={"id_user"}
                                                        sx={{
                                                            '& .MuiSelect-select': {
                                                                display: 'flex',
                                                                gap: 2
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setUserSelected(e.target.value)
                                                        }}
                                                    >
                                                        <MenuItem disabled={true} key={'-'} value={'-'} className="w-full p-2 gap-2 flex justiify-center">
                                                            <InputBilo
                                                                className={'whitespace-pre'}
                                                                title={'Clicca per selezionare un utente'}
                                                            />
                                                        </MenuItem>
                                                        {usersFound.map((user, index) => {
                                                            return (
                                                                <MenuItem key={index} value={user} className="w-full p-2 gap-2 flex justiify-center">
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.nome}
                                                                    />
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.surname}
                                                                    />
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.email}
                                                                    />
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.codice_fiscale}
                                                                    />
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </InputCustom>
                                                    <button
                                                        onClick={() => {
                                                            setIdMultiIscrizioni(prevState => {
                                                                if (prevState.some((value) => { return value.id === userSelected.id })) {
                                                                    return [...prevState]
                                                                } else {
                                                                    return [
                                                                        ...prevState,
                                                                        userSelected
                                                                    ]
                                                                }
                                                            })
                                                            setOpenFormSearchUser(false)
                                                        }}
                                                        type={"button"}
                                                        className={"h-[50px] py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                        style={{
                                                            fontFamily: "gimlet-text",
                                                            fontWeight: 500,
                                                            fontStyle: "italic",
                                                        }}>
                                                        Salva
                                                    </button>
                                                </div>
                                            }
                                        </>

                                    }

                                    {
                                        event ? (
                                            event.quote_request != 0 ?
                                                <button
                                                    className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                    style={{
                                                        fontFamily: "gimlet-text",
                                                        fontWeight: 500,
                                                        fontStyle: "italic",
                                                    }}
                                                    onClick={() => { setOpenIscriviti(false); setOpenPaymentMethod(true) }}>
                                                    Iscrivi
                                                </button>
                                                :
                                                <button
                                                    className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                    style={{
                                                        fontFamily: "gimlet-text",
                                                        fontWeight: 500,
                                                        fontStyle: "italic",
                                                    }}
                                                    onClick={() => { handleClickPagamento() }}>
                                                    Iscrivi
                                                </button>
                                        )
                                            : ''
                                    }
                                </DialogContent>
                            </>

                        </Dialog >
                        {/* SCEGLI METODO DI PAGAMENTO ISCRIZIONE*/}
                        <DialogCustom
                            open={openPaymentMethod}
                            title={'Scegli'}
                            content={
                                <>
                                    <InputBilo className={'text-2xl'} title={`Importo: €${event ? `${(Number(event.quote_request) + (Number(event.quote_request) * idMultiIscrizioni.length)).toFixed(2)}` : ''} `} />
                                    <InputBilo className={'text-xl'} title={env.borsellinoFunction ? "Preferisci pagare con il borsellino elettronico o con Carta?" : 'Con cosa vuoi pagare?'} />
                                </>
                            }
                            handleClose={() => {
                                api.post(`subscriptions/prepareWalletSubscription`, {
                                    user_id: count.profile.id,
                                    id: event ? event.event_subscription_id : '',
                                    type: 'event',
                                    balance: event ? `${(Number(event.quote_request) + (Number(event.quote_request) * idMultiIscrizioni.length)).toFixed(2)}` : '',
                                    userIDs: idMultiIscrizioni.map(user => user.id)
                                }).then((res) => {
                                    localStorage.setItem('lastpage', location.pathname)
                                    if (res && res.data && res.data.data) {
                                        window.open(res.data.data)
                                    } else {
                                        setError({
                                            open: true,
                                            error: res && res.data && res.data.errors ? res.data.errors[Object.keys(res.data.errors)[0]][0] : ''
                                        })
                                    }
                                    return res
                                });
                                setOpenPaymentMethod(false)
                            }}
                            closeModal={() => {
                                setOpenPaymentMethod(false)
                            }}
                            handleClose2={() => {
                                api.post(`subscriptions/requestWithoutWallet`, {
                                    user_id: count.profile.id,
                                    id: event ? event.event_subscription_id : '',
                                    type: 'event',
                                    balance: event ? `${(Number(event.quote_request) + (Number(event.quote_request) * idMultiIscrizioni.length)).toFixed(2)}` : '',
                                    userIDs: idMultiIscrizioni.map(user => user.id)
                                }).then((res) => {
                                    localStorage.setItem('lastpage', location.pathname)
                                    if (res && res.data && res.data.data) {
                                        window.open(res.data.data)
                                    } else {
                                        setError({
                                            open: true,
                                            error: res && res.data && res.data.errors ? res.data.errors[Object.keys(res.data.errors)[0]][0] : ''
                                        })
                                    }
                                    return res
                                });

                                setOpenPaymentMethod(false)
                            }}
                            button={env.borsellinoFunction ? "Borsellino elettronico" : null}
                            button2={'Carta'}
                        />
                        {/* ERROR ISCRIVITI */}
                        <DialogCustom
                            open={error.open}
                            title={'Errore'}
                            content={
                                <>
                                    <InputBilo className={'text-xl'} title={error.error} />
                                </>
                            }
                            button={'Chiudi'}
                            handleClose={() => {
                                (setError(prevState => {
                                    return {
                                        open: false,
                                        error: ''
                                    }
                                }))
                            }}
                            closeModal={() => {
                                (setError(prevState => {
                                    return {
                                        open: false,
                                        error: ''
                                    }
                                }))
                            }}
                        />

                        {/* PRENOTATI */}
                        <DialogCustom
                            open={openPrenotati}
                            title={'Prenotati'}
                            content={
                                <>
                                    <InputBilo className={'text-xl'} title={
                                        'Sicuro di volerti iscrivere a questo evento?'
                                    } />
                                </>
                            }
                            button={'No'}
                            button2={'Si'}
                            handleClose={() => {
                                setOpenPrenotati(false)
                            }}
                            closeModal={() => {
                                setOpenPrenotati(false)
                            }}
                            handleClose2={() => {
                                api.post('subscriptions/add-reservation', {
                                    user_id: count.profile.id,
                                    id: event ? event.event_subscription_id : '',
                                    type: 'event',
                                    balance: event ? `${(Number(event.quote_request) + (Number(event.quote_request) * idMultiIscrizioni.length)).toFixed(2)}` : '',
                                }).then(res => {
                                    if (res.data.errors) {
                                        setError({
                                            open: true,
                                            error: res.data.errors.user[0]
                                        })
                                    } else {
                                        setSuccess({
                                            open: true,
                                            message: 'La informiamo che la sua prenotazione è andata a buon fine'
                                        })
                                    }

                                    return res
                                })


                                setOpenPrenotati(false)
                            }}
                        />
                        {/* Success avvenuta prenotazione/riservazione */}
                        <Dialog
                            open={success.open}
                            onClose={() => {
                                setSuccess({
                                    open: false,
                                    message: ''
                                });
                            }}
                            maxWidth={"lg"}
                            fullWidth={true}>
                            <DialogTitle>
                                Prenotazione effettuata
                            </DialogTitle>
                            <DialogContent>
                                <InputBilo
                                    className={'text-2xl'}
                                    title={success.message}
                                />
                                <button
                                    onClick={() => {
                                        setSuccess({
                                            open: false,
                                            message: ''
                                        })
                                    }}
                                    className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                    style={{
                                        fontFamily: "gimlet-text",
                                        fontWeight: 500,
                                        fontStyle: "italic",
                                    }}>
                                    Chiudi
                                </button>
                            </DialogContent>
                        </Dialog>
                    </>
                    :
                    <Dialog
                        open={openIscriviti || openPrenotati}
                        onClose={() => {
                            setOpenIscriviti(false);
                            setOpenPrenotati(false);
                        }}
                        maxWidth={"lg"}
                        fullWidth={true}>
                        <>
                            <DialogTitle>
                                Per visualizzare questo contenuto devi effettuare l’accesso
                            </DialogTitle>
                            <DialogContent>
                                <Login loginTo={`/eventi/${id}`} />
                            </DialogContent>
                        </>

                    </Dialog>
            }
        </>
    );
};

export default SingleEvent;


import React from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import FooterTop from '../Sezioni/FooterTop'
import FooterDown from '../Sezioni/FooterDown'
import img1 from "../assets/img/accompagnatori.jpg";
import InputGimlet from '../components/InputGimlet'
import InputBilo from '../components/InputBilo'
import { Link } from 'react-router-dom'
import env from '../conf/variables'

function ChiSiamo() {
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Chi siamo"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
                <div
                    className={"flex flex-row items-center gap-1"}
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    <div>Associazione  {">"}</div>
                    <div>Trekking Italia  {">"}</div>
                    <div className={"font-bold"}> Chi siamo</div>
                </div>
                <InputGimlet
                    id='main'
                    title={'chi siamo'}
                    fontWeight={500}
                    fontStyle="italic"
                    className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                />
                <div className="mb-5 text-xl">
                    <InputBilo
                        title={'Trekking Italia è una Associazione di Promozione Sociale (DL 117/2017)'}
                        fontWeight={700}
                    />
                    <br />
                    <InputBilo
                        title={'Dal 1985 la nostra missione è quella di impegnarci ad avvicinare le persone alla natura, conoscendola, rispettandola e difendendola, al fine di riscoprire e rivalutare quelle capacità percettive e relazionali che oggi più che mai devono essere valorizzate.'}
                    />
                    <br />
                    <InputBilo
                        title={'Trekking Italia valorizza il cammino come attività per il benessere psicofisico e come luogo di incontro in tutte le sue espressioni, con l’obiettivo di favorire le relazioni sociali, la scoperta dei luoghi, l’esplorazione, la conoscenza e il rispetto dell’ambiente naturale, di tutte le culture e delle rispettive tradizioni.'}
                        fontWeight={700}
                    />
                    <br />
                    <InputBilo
                        title={'Trekking Italia non è un’agenzia viaggi '}
                        className={'inline'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={'e la gran parte delle attività si basa sul contributo dei Soci volontari, che accompagnano i trek e gestiscono le Sezioni Regionali e l\'Associazione a livello nazionale.'}
                        className={'inline'}
                    />
                    <br />
                    <br />
                    <InputBilo
                        title={'Alla scoperta del territorio viaggiando a piedi'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={'Ripercorrere antichi sentieri ed itinerari, che un tempo erano importanti vie di comunicazione e di commercio fra diversi paesi e popoli, come la Via Francigena o il Cammino di Santiago, rappresenta un ottimo stimolo per accrescere ed approfondire le proprie conoscenze.'}
                    />
                    <InputBilo
                        title={'Con i nostri Soci è possibile riscoprire, viaggiando a piedi, i diversi sentieri e percorsi con occhi nuovi.'}
                    />
                    <InputBilo
                        title={'Questa è esattamente la formula che caratterizza i nostri gruppi di trekking, riuscendo a creare un bagaglio di emozioni e di esperienze uniche, incoraggiando la curiosità ed il desiderio di conoscere e di incontrarsi.'}
                    />
                    <br />
                    <InputBilo
                        title={'Il cammino come luogo dell’incontro'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={'La nostra è un’avventura che ci permette di muoverci'}
                        className={'inline'}
                    />
                    <InputBilo
                        title={' tra gli aspetti naturali e culturali del territorio: un lento viaggio volto all\'incremento della conoscenza, alla creazione di stili di vita nel rispetto delle risorse naturali e delle culture.'}
                        className={'inline'}
                    />
                    <br />
                    <br />
                    <InputBilo
                        title={'Camminare con Trekking Italia'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={'Grandi Trek, Trek week-end e Trek giornalieri '}
                        fontWeight={700}
                        className={'inline'}
                    />
                    <InputBilo
                        title={'sono le tre principali attività che l\'Associazione propone.'}
                        className={'inline'}
                    />
                    <InputBilo
                        title={'Tutti i nostri trek si svolgono in gruppo perché ciò favorisce la circolazione delle idee, delle esperienze e stimola l’aiuto reciproco.'}
                    />
                    <InputBilo
                        title={'Non solo, in gruppo nascono anche nuovi rapporti di amicizia e legami di solidarietà, utili per ricostruire una nuova e moderna cittadinanza.'}
                    />
                    <br />
                    <InputBilo
                        title={'Altre informazioni sull\'Associazione sono nel Bilancio Sociale a questo '}
                        fontWeight={700}
                        className={'inline'}
                    />
                    <Link to={`${env.url_api_without_api}document/bilancio/bilancio_sociale/2022.pdf`} className='font-bold'>link</Link>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    )
}

export default ChiSiamo
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import InputGimlet from "./InputGimlet";

const ItemNews = ({ res }) => {
    const navigate = useNavigate();
    return (
        <div
            className={
                "relative rounded-xl p-4 flex-flex-col bg-[#f0e9e3] xl:w-80 lg:w-full h-[24rem] lg:m-8 lg:m-4 max-h-full"
            }
        >
            <div className={"flex flex-row items-center gap-4 mb-2"}>
                <div
                    className={"flex flex-col bg-[#796557] rounded-xl p-2 text-center "}
                >
                    <div
                        style={{ fontFamily: "gimlet-text" }}
                        className={"text-[#f0e9e3] text-2xl"}
                    >
                        {res.day}
                    </div>
                    <div
                        style={{ fontFamily: "gimlet-text" }}
                        className={"text-[#f0e9e3] text-2xl uppercase"}
                    >
                        {res.month}
                    </div>
                </div>
                <div className={"flex flex-col text-[#796557]"}>
                    <div className={"text-lg"}>Sezione</div>
                    <div className={"font-bold text-xl uppercase"}>
                        {res.name_department}
                    </div>
                </div>
            </div>
            <InputGimlet wrapperTag="h1" className={" text-xl "}
                title={res.title}
            />
            {res.subtitle ?
                <InputGimlet wrapperTag="h2" className={" text-md mb-4"}
                    title={res.subtitle}
                />
                : ''
            }

            <InputGimlet wrapperTag="p" className={"whitespace-pre-wrap text-xs mb-4"}
                title={res?.preview_description?.length > 250 ? `${res?.preview_description?.slice(0, 250)}...` : res?.preview_description}
            />
            <Link
                aria-label={`Leggi di più la news ${res.title}`}
                to={"/news/" + res.id}
                style={{ fontFamily: "gimlet-text" }}
                className={"text-xl absolute bottom-0 lg:bottom-4 hover:opacity-50 cursor-pointer"}
            >
                LEGGI DI PIÙ
            </Link>
        </div>
    );
};

export default ItemNews;

import React from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import InputBilo from "../components/InputBilo";
import { Link } from "react-router-dom";
import env from "../conf/variables";

const Faq = () => {

    let ambiti = [
        {
            title: "Per una società aperta alla solidarietà e alla cittadinanza attiva",
            punti: [
                "• Sensibilizzare ai valori dell'associazionismo. ",
                "• Coinvolgere la società sensibile all'apporto volontario nelle sedi. ",
                "• Valorizzare i progetti che incentivano i rapporti solidaristici e di rete con realtà simili e limitrofe. ",
                "• Conoscere e incentivare progetti ed economie con minori possibilità attraverso mediatori culturali. ",
                "• Privilegiare il viaggio di gruppo come metodo di condivisione dell'esperienza. ",
                "• Preparare i volontari al lavoro in Associazione con una formazione mirata."
            ]
        },
        {
            title: "Per lo sviluppo della cultura del viaggio a piedi",
            punti: [
                "• Proposte di trekking ispirate alla valorizzazione delle tematiche intrinseche al territorio, alla sua economia, cultura, arte e storia. ",
                "• Documentazione informativa sulle caratteristiche geomorfologiche, naturalistiche, storico-artistiche del territorio che si va ad incontrare. ",
                "• Progetti per la conoscenza e l'approfondimento di determinati aspetti naturalistici e sociali. ",
                "• Esperienze di scoperta e frequentazione di percorsi storici e abbandonati, recupero delle tradizioni e della cultura materiale. ",
                "• Serate di divulgazione e incontro, pubblicazioni di racconti di viaggio e documentazione."
            ]
        },
        {
            title: "Per lo sviluppo della cultura del viaggio a piedi",
            punti: [
                "• Camminare implica l'uso di energia e favorisce il benessere corporeo. ",
                "• Si stimola la percezione sensitiva. ",
                "• Si va alla scoperta di sé, ci si mette alla prova, si verificano e si potenziano le proprie risorse fisiche e di autostima. ",
                "• Si ha voglia di comunicare e di condividere emozioni, sensazioni, esperienze. ",
                "• Ci si relaziona in gruppo instaurando rapporti di condivisione e solidarietà."
            ]
        }
    ]

    // let intro = [
    //     {
    //         bold: "Trekking Italia - Associazione Amici del Trekking e della Natura,",
    //         text: " è una libera Associazione senza scopo di lucro che si fonda sui valori di solidarietà, pace, democrazia, tutela e valorizzazione del patrimonio naturale e culturale, sull'educazione a muoversi nella natura e a raggiungere uno stile di vita semplice e coerente con questi valori."
    //     },
    //     {
    //         bold: "La solidarietà è un valore di base ",
    //         text: "che l’Associazione cerca di declinare in tutte le circostanze possibili per educare ad uno stile di vita equo, solidale e responsabile, nella quotidianità e anche nel viaggiare e nel muoversi nell’ambiente. Tutto questo ci ha portato nel corso degli anni a sostenere, nei vari modi legati al nostro camminare, realtà di diversi continenti e più recentemente in Italia, nei luoghi devastati dai terremoti o nelle terre infestate dalle mafie."
    //     },
    //     {
    //         bold: "Le azioni che l'Associazione promuove fanno riferimento a questi princìpi ",
    //         text: "che ne ispirano i programmi e le proposte di attività di viaggio e di trekking. Questo non esaurisce il campo di azione: i cambiamenti che promuoviamo richiedono un lavoro più profondo e radicale, che tocca atteggiamenti e comportamenti."
    //     },
    //     {
    //         bold: "Perciò abbiamo articolato princìpi e finalità in ambiti operativi ",
    //         text: "che ci permettono di meglio programmare e verificarne la ricaduta nell'organizzazione e nella risposta dei soci."
    //     }
    // ]

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"I valori"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Associazione &gt; </div>
                        <div>Trekking Italia &gt; </div>
                        <div className={"font-bold"}>Valori</div>
                    </div>
                    {/* Content */}
                    <div
                        className={"my-6 mb-[2rem] text-4xl text-[#DF5B23]"}
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}
                    >
                        I nostri valori
                    </div>
                    {/* {intro.map(sezione => {
                        return (
                            <div className={"mb-[3rem]"}>
                                <div className="text-2xl" style={{display: "inline", fontFamily: "Bilo", fontWeight: 500, color: "#796557", }}>{sezione.bold}</div>
                                <div className="text-2xl" style={{display: "inline", fontFamily: "Bilo", color: "#796557", }}>{sezione.text}</div>
                            </div>

                        )
                    })} */}
                    {/* <div className="flex justify-center w-full">
                        <div className={"mb-[3rem] "}>
                            <div className="text-2xl" style={{ display: "inline", fontFamily: "Bilo", fontWeight: 600, color: "#796557", }}>
                                Trekking Italia - Associazione Amici del Trekking e della Natura, è una <div className=" inline">libera</div> Associazione di promozione sociale, senza scopo di lucro, che
                                si fonda sui valori di solidarietà, pace, democrazia, tutela e
                                valorizzazione del patrimonio naturale e culturale, sull'educazione a
                                muoversi nella natura e a raggiungere uno stile di vita semplice e
                                coerente con questi valori.
                            </div>
                            <br /><br />
                            <div className="text-2xl" style={{ display: "inline", fontFamily: "Bilo", color: "#796557", }}>
                                <div className="text-2xl" style={{ display: "inline", fontFamily: "Bilo", fontWeight: 600, color: "#796557", }}>La solidarietà è un valore <div className="text-[#DF5B23] inline">fondante</div></div> che l’Associazione cerca di declinare
                                in tutte le circostanze possibili, <div className="text-[#DF5B23] inline">con l’obiettivo</div> di educare ad uno stile di vita
                                equo, solidale e responsabile, sia nella quotidianità sia <div className="text-[#DF5B23] inline">nel modo di </div>
                                viaggiare e nel modo di muoversi nell’<div className="text-2xl" style={{ display: "inline", fontFamily: "Bilo", fontWeight: 500, color: "#796557", }}>ambiente</div> <div className="text-[#DF5B23] inline">circostante</div>.
                            </div>
                            <br /><br />
                            <div className="text-2xl" style={{ display: "inline", fontFamily: "Bilo", color: "#796557", }}>
                                Tutto questo ci ha portato nel corso degli anni a sostenere, nei diversi modi
                                legati al nostro camminare, tante realtà di continenti diversi e, più
                                recentemente anche in Italia, <div className="text-[#DF5B23] inline">quei</div> luoghi devastati dai terremoti e <div className="text-[#DF5B23] inline">quei territori </div>
                                infestati dalle mafie.
                            </div>
                            <br /><br />
                            <div className="text-2xl" style={{ display: "inline", fontFamily: "Bilo", fontWeight: 600, color: "#796557", }}>
                                Le azioni che l'Associazione promuove fanno riferimento a questi
                                princìpi,{" "}
                            </div>
                            <div className="text-2xl" style={{ display: "inline", fontFamily: "Bilo", color: "#DF5B23", }}>
                                dai quali viene tratta ispirazione per i programmi dei viaggi e le
                                proposte di trekking.
                            </div>
                            <br />
                            <div className="text-2xl" style={{ display: "inline", fontFamily: "Bilo", color: "#796557", }}>
                                Questo non esaurisce il campo di azione: i cambiamenti che promuoviamo
                                richiedono un lavoro più profondo e radicale, che tocca atteggiamenti e
                                comportamenti.
                            </div>
                            <div className="text-2xl" style={{ display: "inline", fontFamily: "Bilo", fontWeight: 600, color: "#796557", }}>
                                Perciò  abbiamo articolato princìpi e finalità <div className=" inline">in ambiti operativi {' '}</div>
                            </div>
                            <div className="text-2xl" style={{ display: "inline", fontFamily: "Bilo", color: "#796557", }}>
                                anche sul piano operativo, che ci permette di programmare <div className="text-[#DF5B23] inline">al meglio </div>
                                e verificarne la <div className="text-[#DF5B23] inline">reale  </div>
                                ricaduta nell'organizzazione e nella risposta dei Soci.
                            </div>
                        </div>
                    </div> */}
                    <InputBilo wrapperTag="h2" className='text-2xl !text-[#255b4c]' title='Rispettiamo la natura e tuteliamo l’ambiente'/>
                    <InputBilo wrapperTag="p" className={'text-lg'} title='Lasciamo sui sentieri solo le orme dei nostri scarponi e portiamo a casa solo emozioni ed immagini.'/>
                    <br />
                    <InputBilo wrapperTag="h2" className='text-2xl !text-[#255b4c]' title='Rispettiamo e siamo solidali con i territori che attraversiamo'/>
                    <InputBilo wrapperTag="p" className={'text-lg'} title='Viaggiamo e camminiamo con consapevolezza per le condizioni dei popoli e delle culture che incontriamo che hanno subito calamità naturali o che vivono in condizioni economiche fragili o di conflitto, realizzando trekking con uno spirito equo e solidale.'/>
                    
                    <br />
                    <InputBilo wrapperTag="h2" className='text-2xl !text-[#255b4c]' title='Promuoviamo l’accoglienza'/>
                    <InputBilo wrapperTag="p" className={'text-lg'} title='Le diversità permettono confronti, scambi e arricchimenti reciproci, migliorano la qualità e il valore del cammino.'/>
                    <br />
                    <InputBilo wrapperTag="h2" className='text-2xl !text-[#255b4c]' title='Ricerchiamo connessioni e collaborazioni'/>
                    <InputBilo wrapperTag="p" className={'text-lg'} title='Miriamo a creare contatti positivi nei territori che attraversiamo, collaboriamo con istituzioni e organizzazioni che condividono il valore del camminare.'/>
                
                    <br />
                    <InputBilo wrapperTag="h2" className='text-2xl !text-[#255b4c]' title='Condividiamo esperienze'/>
                    <InputBilo wrapperTag="p" className={'text-lg'} title='Il trekking si fonda sulla condivisione di esperienze tra socie e soci, del bagaglio di conoscenze e saperi per realizzare e accrescere il patrimonio comune che sta alla base del cammino.'/>
                    
                    <br />
                    <InputBilo wrapperTag="h2" className='text-2xl !text-[#255b4c]' title='Valorizziamo la cultura dell’associazionismo di promozione sociale'/>
                    <InputBilo wrapperTag="p" className={'text-lg'} title='Favoriamo il coinvolgimento e la responsabilità delle socie e dei soci nell’animare la vita associativa e le iniziative che realizziamo.'/>
                    <br />
                    <p className="text-xl">Clicca<Link className="italic font-bold" to={`${env.url_api_without_api}/document/carta_dei_valori.pdf`} target="_blank"> quì</Link> per vedere la carta dei valori di Trekking Italia</p>
                </div>

                
                {/* <div className="w-[100%]">
                    {ambiti.map((ambito, index) => {
                        return (
                            <div key={index} className={`pl-[7.5%] pr-[7.5%] mb-10 ${index % 2 === 0 ? "bg-[#F0E9E3] pt-10 pb-10" : ""}`}>
                                <div className={`text-3xl text-[#DF5B23] ${index % 2 === 0 ? "bg-white" : "bg-[#F0E9E3]"} pr-3 pb-[2.5px] pl-3 rounded-lg`} style={{
                                    width: "fit-content", fontFamily: "gimlet-text",
                                    fontWeight: 500,
                                    fontStyle: "italic",
                                }}>Ambito {String.fromCharCode(index + 65)}</div>
                                <div className="text-2xl text-[#DF5B23]" style={{
                                    fontFamily: "gimlet-text",
                                    fontWeight: 500,
                                    fontStyle: "italic",
                                }}>{ambito.title}</div>
                                {ambito.punti.map((punto, index) => {
                                    return (
                                        <div key={index} className="text-xl text-[#796557]" style={{ fontFamily: "Bilo" }}>{punto}</div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div> */}
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default Faq;
import React from "react";
import img from "../assets/img/numeri-sfondo-home.png";
import Divider from "@mui/material/Divider";
import zaino from "../assets/img/icona-trekking-diverso-home.png";
import passi from "../assets/img/icona-passi-insieme-home.png";
import associati from "../assets/img/icona-associati-home.png";
import amici from "../assets/img/icona-amici-volontari-home.png";
import line from "../assets/img/Linea_Trekking.png";

const Numbers = () => {
  return (
    <div
      className={"mt-8 p-8 w-full text-center"}
      style={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "34rem",
      }}
    >
      <h1 className={"text-xl font-bold  text-[#df5b23]"}>
        TREKKING ITALIA IN NUMERI
      </h1>
      <h2
        style={{
          fontFamily: "gimlet-text",
          fontWeight: 500,
          fontStyle: "italic",
        }}
        className={"text-3xl text-xl mt-4 text-center text-[#34291f]"}
      >
        Fino ad oggi abbiamo fatto...
      </h2>
      <div
        className={"flex flex-row px-20 items-center justify-center  mt-24"}
        style={{ gap: "120px" }}
      >
        <div className={"flex flex-col gap-4 items-center relative"}>
          <img
            alt={"Zaino"}
            src={zaino}
            className={"object-contain w-36 h-36 z-20"}
          />
          <img
            alt={"linea"}
            src={line}
            className={"object-contain absolute w-36 h-36 "}
            style={{ right: "-134px" }}
          />

          <div
            style={{
              fontFamily: "gimlet-text",
              fontWeight: 500,
              fontStyle: "italic",
            }}
            className={"text-[#df5b23] text-4xl font-bold"}
          >
            + 1.000
          </div>
          <div
            style={{
              fontFamily: "gimlet-text",
              fontWeight: 500,
              fontStyle: "italic",
            }}
            className={"text-2xl text-[#34291f]"}
          >
            trekking diversi
          </div>
        </div>
        <div className={"flex flex-col gap-4 items-center relative"}>
          <img
            alt={"Scarpa"}
            src={passi}
            className={"object-contain w-36 h-36 z-20"}
          />
          <img
            alt={"linea"}
            src={line}
            className={"object-contain absolute w-36 h-36 "}
            style={{ right: "-126px" }}
          />

          <div
            style={{
              fontFamily: "gimlet-text",
              fontWeight: 500,
              fontStyle: "italic",
            }}
            className={"text-[#df5b23] text-4xl font-bold"}
          >
            + 350.000
          </div>
          <div
            style={{
              fontFamily: "gimlet-text",
              fontWeight: 500,
              fontStyle: "italic",
            }}
            className={"text-2xl text-[#34291f]"}
          >
            passi insieme
          </div>
        </div>
        <div className={"flex flex-col gap-4 items-center relative "}>
          <img
            alt={"Persona"}
            src={associati}
            className={"object-contain w-36 h-36 z-20 "}
          />
          <img
            alt={"linea"}
            src={line}
            className={"object-contain absolute z-10 w-36 h-36 "}
            style={{ right: "-126px" }}
          />

          <div
            style={{
              fontFamily: "gimlet-text",
              fontWeight: 500,
              fontStyle: "italic",
            }}
            className={"text-[#df5b23] text-4xl font-bold"}
          >
            + 7.000
          </div>
          <div
            style={{
              fontFamily: "gimlet-text",
              fontWeight: 500,
              fontStyle: "italic",
            }}
            className={"text-2xl text-[#34291f]"}
          >
            associati
          </div>
        </div>
        <div className={"flex flex-col gap-4 items-center "}>
          <img
            alt={"mano e cuore"}
            src={amici}
            className={"object-contain w-36 h-36 z-20 "}
          />

          <div
            style={{
              fontFamily: "gimlet-text",
              fontWeight: 500,
              fontStyle: "italic",
            }}
            className={"text-[#df5b23] text-4xl font-bold"}
          >
            + 300
          </div>
          <div
            style={{
              fontFamily: "gimlet-text",
              fontWeight: 500,
              fontStyle: "italic",
            }}
            className={"text-2xl text-[#34291f]"}
          >
            amici volontari
          </div>
        </div>
      </div>
    </div>
  );
};

export default Numbers;

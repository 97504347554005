import React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const SnackbarComponent = ({ open, setOpen, variant, message }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={2000}
      onClose={() => setOpen(false)}>
      <Alert
        variant={"filled"}
        onClose={() => setOpen(false)}
        severity={variant ? variant : "success"}
        sx={{ width: "100%" }}>
        {message ? message : "Operazione completata con successo!"}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;

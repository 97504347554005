import React from "react";
import Topbar from "../components/Topbar";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TopBarIndex from "../components/TopBarIndex";

const ThankyouPage = () => {
  // const navigate = useNavigate();
  return (
    <>
      <TopBarIndex />
      <div
        className={
          "flex flex-col mx-56 text-center my-36 rounded-xl p-8 shadow-2xl"
        }
      >
        <h1 class="text-xl font-bold mb-8 leading-tight tracking-tight text-[#4a4137] md:text-2xl">
          Benvenuto
        </h1>
        <h1 class="text-xl font-bold mb-8 leading-tight tracking-tight text-[#4a4137] md:text-2xl">
          Grazie per esserti registrato!
        </h1>
        <h1 class="text-xl font-bold mb-8 leading-tight tracking-tight text-[#4a4137] md:text-2xl">
          Controlla la mail per attivare il tuo account
        </h1>
      </div>
      {/* <div className={"flex flex-row items-center justify-center gap-4"}>
        <Button onClick={() => navigate("/")} variant={"contained"}>
          Cambia password
        </Button>
        <Button variant={"contained"}>Vai al sito</Button>
      </div>*/}
      <FooterTop />
      <FooterDown />
    </>
  );
};

export default ThankyouPage;

import React from "react";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { useNavigate } from "react-router-dom";

const ItemCatalog = ({ title, img, res, badge, colSpan, type }) => {
  const navigate = useNavigate();
  return (
    <div className={`flex flex-col ${colSpan}`}>
      <div
        className={"rounded-t-3xl h-96 relative"}
        style={{
          backgroundImage: `url(${img})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          className={`text-center  text-3xl text-white ${
            badge ? "mt-28 lg:mt-6" : "mt-6"
          }`}
        >
          Catalogo
        </div>
        <div className={"text-center uppercase mt-2 text-3xl text-white"}>
          {title}
        </div>
        {badge && (
          <div className={"absolute left-0 top-0 p-6"}>
            <div className={"bg-orange-500 p-6 rounded-full relative"}>
              <AutoStoriesIcon className={"text-white"} />
              <div
                className={
                  "text-white absolute bg-orange-500 px-4 rounded-r-3xl"
                }
                style={{ top: "36%", left: "95%" }}
              >
                NEW
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={
          "flex flex-row justify-center w-full bg-[#f1e9e4] rounded-b-3xl"
        }
      >
        {type === "trek" ? (
          <button
            onClick={() => navigate("/trek-magazine/" + res.id)}
            className={
              "p-4 uppercase border-2 cursor-pointer text-center border-[#796557] text-2xl w-56 hover:opacity-50 text-[#796557] m-6"
            }
            style={{ fontFamily: "gimlet-text" }}
          >
            SFOGLIA
          </button>
        ) : (
          <a
            href={res.catalog_file_path}
            target="_blank"
            className={
              "p-4 uppercase border-2 cursor-pointer text-center border-[#796557] text-2xl w-56 hover:opacity-50 text-[#796557] m-6"
            }
            style={{ fontFamily: "gimlet-text" }}
          >
            SFOGLIA
          </a>
        )}
      </div>
    </div>
  );
};

export default ItemCatalog;

import { api } from "../conf/apiConf"
import env from "../conf/variables"

export function getAuthToken(){
    const token = localStorage.getItem('token')
    return token
}

export function tokenLoaer(){
    return getAuthToken()
}

export function changeUrlBased(webhook = true){
    if(webhook){
        api.setBaseURL('https://webhook.site/3a3dace1-f396-4c8d-8d3b-7b6aad8042b1')
    }else{ //https://staging-bff.trekkingitalia.teaweb.it/api/v1
        api.setBaseURL(env.url_api)
    }
    
}
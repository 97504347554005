import React from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import InputGimlet from "../components/InputGimlet";
import InputBilo from "../components/InputBilo";

const Formazione = () => {

    let page = {
        title: "Formazione",
        paragraph: [
            "Trekking Italia progetta ed organizza consapevolmente tutti i propri trek, con la massima attenzione per la sicurezza di chi accompagna e di chi partecipa. Più di duecento socie e soci accompagnano diversi i gruppi di persone nei percorsi di trekking e diffondono i valori dell’Associazione.",
            "Sono volontari non retribuiti, che seguono un percorso di formazione specifico e a loro dedicato, svolto da professionisti di varie discipline (attrezzatura, cartografia, sicurezza in montagna, interpretazione ambientale, meteorologia, allenamento e alimentazione) e da specialisti per la gestione del primo soccorso.",
            "I Soci Accompagnatori conoscono l’ambiente e i sentieri, sanno individuare e consigliare il trek più adatto al livello di allenamento, chiariscono i diversi gradi di difficoltà dello stesso, agiscono con prontezza nel risolvere eventuali difficoltà dei partecipanti. Consideriamo la formazione uno dei più importanti servizi che l’Associazione offre a tutti i soci, a cui sono dedicati corsi di escursionismo, di botanica e diverse altre iniziative, proposte sia dalle sezioni sia a livello nazionale."
        ]
    }

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Formazione"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Associazione &gt; </div>
                        <div>Progetti &gt; </div>
                        <div className={"font-bold"}>Formazione</div>
                    </div>
                    {/* Content */}
                    <InputGimlet
                        title={'formazione'}
                        fontWeight={500}
                        fontStyle="italic"
                        className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                    />
                    <div className="mb-5 text-xl">
                        <InputBilo
                            title={'Un\'Associazione di promozione sociale come Trekking Italia deve '}
                            className={'inline'}
                        />
                        <InputBilo
                            title={'impegnarsi ogni giorno'}
                            className={'inline'}
                        />
                        <InputBilo
                            title={' e curare costantemente gli strumenti operativi per realizzare al meglio la propria vocazione.'}
                            className={'inline'}
                        />
                        <br />
                        <br />
                        <InputBilo
                            title={'Accompagnare le varie tipologie di trek che proponiamo richiede competenze diversificate e capacità relazionali che non sono tutte naturalmente presenti nei soci accompagnatori e nelle socie accompagnatrici ma devono essere oggetto di continua formazione.'}
                            className={''}
                        />
                        <br />
                        <InputBilo
                            title={'I soci accompagnatori sono volontari che mettono a disposizione di altri soci passione, entusiasmo e conoscenze personali e questo è senz’altro un valore che promuoviamo, ma per guidare con sicurezza in un ambiente e prendersi cura al meglio dei bisogni di tutti è necessario affinare, approfondire e potenziare le doti '}
                            className={'inline'}
                        />
                        <InputBilo
                            title={'innate'}
                            className={'inline'}
                        /><p className="inline">.</p>
                        <br />
                        <br />
                        <InputBilo
                            title={' Con l’obiettivo di proporre soci accompagnatori preparati ed affidabili, capaci di testimoniare e '}
                            className={'inline'}
                        />
                        <InputBilo
                            title={'realizzare al meglio la missione e i valori dell’Associazione'}
                            className={'inline'}
                            fontWeight={700}
                        />
                        <InputBilo
                            title={', Trekking Italia si è dotata di un '}
                            className={'inline'}
                        />
                        <InputBilo
                            title={'Piano Formativo Nazionale '}
                            className={'inline'}
                            fontWeight={700}
                        />
                        <InputBilo
                            title={'che prevede sia una '}
                            className={'inline'}
                        />
                        <InputBilo
                            title={'formazione di base preliminare per chi si offre come nuovo socio accompagnatore'}
                            className={'inline'}
                            fontWeight={700}
                        />
                        <InputBilo
                            title={', sia regolari '}
                            className={'inline'}
                        />
                        <InputBilo
                            title={'percorsi successivi di formazione permanente '}
                            className={'inline'}
                            fontWeight={700}
                        />
                        <InputBilo
                            title={'con particolare riguardo a Responsabilità e Sicurezza, Topografia e Orientamento, Interpretazione ambientale, Dinamiche relazionali. Tali corsi, a cadenza periodica vengono promossi sia con iniziative di carattere nazionale sia su iniziativa delle diverse Sezioni regionali.'}
                            className={'inline'}
                        />
                        <br />
                        <br />
                        <InputBilo
                            title={'L’Associazione inoltre, con cadenza annuale, '}
                            className={'inline'}
                        />
                        <InputBilo
                            title={'propone a tutti i soci '}
                            className={'inline'}
                            fontWeight={700}
                        />
                        <InputBilo
                            title={'che lo desiderino '}
                            className={'inline'}
                        />
                        <InputBilo
                            title={'corsi base di escursionismo per potenziare le personali abilità escursionistiche '}
                            className={'inline'}
                            fontWeight={700}
                        />
                        <InputBilo
                            title={'e permettere ad ognuno di affrontare l’attività di trekking con maggiore sicurezza e conoscenza dei vari aspetti legati al cammino.'}
                            className={'inline'}
                        />
                        <br />
                        <br />
                        <InputBilo
                            title={'La formazione dei soci e dei soci accompagnatori contribuiscono a costruire una '}
                            className={'inline'}
                        />
                        <InputBilo
                            title={'comunità in cammino. '}
                            className={'inline'}
                            fontWeight={700}
                        />
                    </div>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default Formazione;
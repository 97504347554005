import React from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import FooterTop from '../Sezioni/FooterTop'
import FooterDown from '../Sezioni/FooterDown'
import img1 from "../assets/img/accompagnatori.jpg";
import InputGimlet from '../components/InputGimlet'
import env from '../conf/variables'
import { Typography } from '@mui/material'

function StatutoRegolamenti() {
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Statuto e regolamenti"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
                <div
                    className={"flex flex-row items-center gap-1"}
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    <div>Associazione  {">"}</div>
                    <div>Trekking Italia  {">"}</div>
                    <div className={"font-bold"}>Statuto e regolamenti</div>
                </div>
                <div>

                    <div className={"flex flex-col "}>
                        <InputGimlet
                            title={'Statuto'}
                            fontWeight={500}
                            fontStyle="italic"
                            className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                        />
                        <iframe
                            title="Informativa Privacy"
                            className="w-full"
                            height="800"
                            src={`${env.url_api_without_api}document/statuto.pdf`}
                            allowFullScreen
                        ></iframe>
                        <InputGimlet
                            title={'Regolamenti'}
                            fontWeight={500}
                            fontStyle="italic"
                            className={"mt-14 uppercase text-4xl text-[#DF5B23]"}
                        />
                        <Typography className={'!mt-10 !text-2xl'} component={"h1"}>REGOLAMENTO DI ATTUAZIONE DELLO STATUTO:</Typography>
                        <iframe
                            title="Informativa Privacy"
                            className="w-full"
                            height="800"
                            src={`${env.url_api_without_api}document/regolamento_statuto.pdf`}
                            allowFullScreen
                        ></iframe>
                        <Typography className={'!mt-10 !text-2xl'} component={"h1"}>REGOLAMENTO SUL FUNZIONAMENTO DELLE ASSEMBLEE:</Typography>
                        <iframe
                            title="Informativa Privacy"
                            className="w-full"
                            height="800"
                            src={`${env.url_api_without_api}document/regolamento_assemblee.pdf`}
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    )
}

export default StatutoRegolamenti
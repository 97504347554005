import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingComponent = () => {
  return (
    <div
      className={
        "w-full h-screen flex-grow text-center flex flex-col justify-center items-center"
      }
    >
      <CircularProgress />
    </div>
  );
};

export default LoadingComponent;

import React from "react";
import InputBilo from "./InputBilo";

const RowHeaders = (props) => {
  return (
    <div className={`flex flex-row items-center gap-1 ${props.className}`}>
      <div>
        <InputBilo
          title={props.campo + ":"}
          className={"text-[#796557] text-2xl"}
          fontWeight={500}
        />
      </div>
      <div>
        <InputBilo
          title={props.data}
          className={"text-[#796557] text-2xl"}
          fontWeight={700}
        />
      </div>
    </div>
  );
};

export default RowHeaders;

import React, { useEffect } from "react";
import montagne1 from "../assets/img/testata-aggiornata-def.png";

const BackgroundTitle = ({ title, subtitle, img, position = "bottom", classNameTitle = "", show = 'true' }) => {

useEffect(()=>{
    if(show){
        document.getElementById('main_title').innerText = title + ' - Trekking Italia'
    }
}, [])

  return (
    <div className={`h-full lg:h-[calc(100vh-300px)] relative`}>
      <img
        alt={title}
        src={img}
        className={`object-cover h-full w-full lg:h-[calc(100vh-300px)] brightness-50 object-${position}`}
      />
      <div
        className={"absolute top-1/3 font-bold text-white text-center w-full"}>
        <div className={"mb-10 w-full mx-auto"}>
          <h1
            className={classNameTitle === '' ? "text-3xl lg:text-6xl" : classNameTitle}
            style={{
              fontFamily: "gimlet-text",
              fontWeight: 500,
              fontStyle: "italic",
            }}>
            {title}
          </h1>
          {subtitle && (
            <div
              className={"text-xl lg:text-3xl"}
              style={{
                fontFamily: "bilo",
                fontWeight: 500,
                fontStyle: "normal",
              }}>
              {subtitle}
            </div>
          )}
        </div>
      </div>
      <img
        src={montagne1}
        alt={"img"}
        className={
          "object-contain absolute z-30 w-full lg:-bottom-11 -bottom-3"
        }
      />
    </div>
  );
};

export default BackgroundTitle;

import BackgroundTitle from "../components/BackgroundTitle";
import TopBarIndex from "../components/TopBarIndex";
import FooterDown from "./FooterDown";
import FooterTop from "./FooterTop";
import img1 from "../assets/img/accompagnatori.jpg";
import { api } from "../conf/apiConf";
import useSWR, { mutate } from "swr";
import { Link, useParams } from "react-router-dom";
import InputBilo from "../components/InputBilo";
import InputGimlet from "../components/InputGimlet";
import { width } from "@mui/system";
import Trek from "../components/Trek";
import trekimg from "../assets/img/img-trek-1.png";
import trekimg2 from "../assets/img/img-trek-2.png";
import React, { useEffect, useRef, useState } from "react";
import LoadingComponent from "../components/LoadingComponent";
import ItemNews from "../components/ItemNews";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import YouTubeIcon from '@mui/icons-material/YouTube';
import noImage from "../assets/img/no_image.jpg";
import ItemOrganigramma from "../components/ItemOrganigramma";
import DividerCustom from "../components/DividerCustom";
import moment from "moment";
import PaginationCustom from "../components/PaginationCustom";

const InitialStateOrganigramma = {
    coordinatore: {
        name: '',
        surname: '',
        title: 'Coordinatore',
    },
    consiglioDirettivoSezionale: [],
    delegatiAssembleaNazionale: [],
}

const dataTreks = [
    {
        nome: "lombardia",
        coordinatore: "Liliana Balduin",
        indirizzo: "Via Ettore Ponti, 13 - 20143 Milano",
        telefono: "+39 02 837 2838",
        mail: "milano@trekkingitalia.org",
        info: `
        Mercoledì e giovedì dalle 10:00 alle 15:00.
        Al telefono: martedì/giovedì/venerdì dalle 10:30 alle 12:30.
        Per informazioni manda una mail: milano@trekkingitalia.org,
        
        IBAN: Banca Intesa San Paolo IT 39 X 03069 09606 100000011868
        Bic BCITITMM - intestato a Trekking Italia
        
        La nostra sede è aperta al pubblico solo al mercoledì e al giovedì dalle 10:00 alle 15:00. Negli altri giorni gli operatori
        lavorano in smart working. Al telefono siamo reperibili il martedì/giovedì/venerdì dalle 10:30 alle 12:30.
    `,
    },
];
;

function Sezioni() {

    const trekInPartenzaRef = useRef(null)
    const [page, setPage] = useState(1)
    const [loadingTreks, setLoadingTreks] = useState(true);
    const [loadingNews, setLoadingNews] = useState(true);
    const [organigramma, setOrganigramma] = useState([])
    const [organigramma2, setOrganigramma2] = useState({
        coordinatore: {
            name: '',
            surname: '',
            title: 'Coordinatore',
        },
        consiglioDirettivoSezionale: [],
        delegatiAssembleaNazionale: [],
    })
    const { sezione } = useParams();


    // const fetcher = (url) => api.get(url).then((res) => res);
    // const { data: data2 } = useSWR([`/sezione/${sezione}`], fetcher);
    const fetcher1 = (url) =>
        api.get(url).then((res) => {
            let sez = {};
            res.data.data?.map((department) => {
                if (department.name.includes(sezione)) {
                    sez = department;
                    api.get(`/departments/positions-managerial/${sez.id}`).then((res) => {
                        setOrganigramma(res.data.data)
                        return res
                    })
                }
                return 0;
            });
            return sez;
        });
    const { data: department } = useSWR([`/all-departments`, sezione], fetcher1);

    const fetcher2 = (url) =>
        api
            .post(url, {
                date: new Date(),
                dateEnd: null,
                maps: false,
                type_trek: null,
                destination: null,
                difficulty: null,
                regions: null,
                typology: [],
                departments: [department?.id],
                label: null,
                position: null,
                paginate: 12
            })
            .then((res) => {
                setLoadingTreks(false);
                return res;
            });
    const { data: trek } = useSWR([`/search-trek?page=${page}`, sezione, department, page], fetcher2);

    const fetcher3 = (url) =>
        api
            .post(url, {
                department_id: department.id,
                active: 1,
                order_by_last: 1,
                limit: 12
            })
            .then((res) => {
                setLoadingNews(false);
                return res;
            });
    const { data: news } = useSWR([`/news`, sezione, department], fetcher3);

    useEffect(() => {
        if (organigramma) {
            let organigrammaTemp = { ...InitialStateOrganigramma }
            organigramma?.map((user, index) => {
                if (user.id_incarico === 'Coordinatore di Sezione') {
                    organigrammaTemp.coordinatore.name = user.nome
                    organigrammaTemp.coordinatore.surname = user.surname
                    organigrammaTemp.coordinatore.title = 'Coordinatore'
                }
                if (user.id_incarico === 'Consigliere Consiglio Direttivo Sezionale' ||
                    user.id_incarico === 'Tesoriere di Sezione' ||
                    user.id_incarico === 'Segretario di Sezione') {
                    organigrammaTemp.consiglioDirettivoSezionale = [...organigrammaTemp.consiglioDirettivoSezionale, {
                        name: user.nome,
                        surname: user.surname,
                        work: user.id_incarico === 'Consigliere Consiglio Direttivo Sezionale' ? '' : user.id_incarico,
                    }]
                }
                if (user.id_incarico === 'Delegato Assemblea Nazionale' || user.id_incarico === 'Delegato Assemblea Nazionale (supplente)') {
                    organigrammaTemp.delegatiAssembleaNazionale = [...organigrammaTemp.delegatiAssembleaNazionale, {
                        name: user.nome,
                        surname: user.surname,
                        work: user.id_incarico === 'Delegato Assemblea Nazionale' ? '' : (user.id_incarico === 'Delegato Assemblea Nazionale (supplente)' ? 'supplente' : user.id_incarico),
                        supplente: user.id_incarico.includes('supplente')
                    }]
                }

            })
            setOrganigramma2(organigrammaTemp)
        }
    }, [organigramma])

    if (!department) {
        return <LoadingComponent />;
    }

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={sezione}
                img={img1}
                position={"center"}
            />
            <div
                className={
                    " bg-white relative z-40 pt-20 flex flex-col justify-center items-center"
                }>
                <div className="w-[85%] max-w-[1500px]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}>
                        <div>Sezione &gt; </div>
                        <div className={"font-bold"}>
                            {sezione.charAt(0).toUpperCase() + sezione.slice(1).toLowerCase()}
                        </div>
                    </div>
                    {/* Content */}
                    <div
                        id="main"
                        className={"my-6 mb-[2rem] text-4xl text-[#DF5B23]"}
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}>
                        {sezione.toUpperCase()}
                    </div>
                    <div
                        className="mb-5 text-xl flex flex-col sm:flex-row justify-start items-center"
                        style={{ fontFamily: "Bilo" }}>
                        <div className="rounded-full h-56 max-w-[459px] min-w-[224px] bg-[#C4B3A5] relative">
                            <img
                                src={department && department['imageCoord'] ? department['imageCoord'] : noImage}
                                alt=""
                                className="object-cover rounded-full h-56 w-56 p-3"
                            />
                        </div>
                        <div className="m-10">
                            <InputGimlet
                                title={department && department["sessoCoord"] ? (department["sessoCoord"] === 'femmina' ? "Coordinatrice" : "Coordinatore") : ""}
                                className={""}
                                fontWeight={500}
                                fontStyle="italic"
                            />
                            <InputBilo
                                className="text-3xl whitespace-nowrap"
                                fontWeight={600}
                                title={department && department["nameCoord"] ? department["nameCoord"] : ""}
                            />
                        </div>
                        <div className="w-full flex sm:flex-col justify-center items-end gap-4 lg:pr-10">
                            {department ?
                                <>
                                    {
                                        department.facebook ?
                                            <Link to={department.facebook} className={"p-3 rounded-full bg-[#f0e9e3]"}>
                                                <FacebookIcon sx={{ color: "#796557", fontSize: 30 }} />
                                            </Link>
                                            : ''
                                    }
                                    {
                                        department.instagram ?
                                            <Link to={department.instagram} className={"p-3 rounded-full bg-[#f0e9e3]"}>
                                                <InstagramIcon sx={{ color: "#796557", fontSize: 30 }} />
                                            </Link>
                                            : ''
                                    }
                                    {
                                        department.youtube ?
                                            <Link to={department.youtube} className={"p-3 rounded-full bg-[#f0e9e3]"}>
                                                <YouTubeIcon sx={{ color: "#796557", fontSize: 30 }} />
                                            </Link>
                                            : ''
                                    }
                                </>
                                : ''
                            }
                        </div>
                    </div>
                    <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                        <div className="border-y-4 border-[#F0E9E3] p-4 flex flex-col justify.center items-center">
                            <div>
                                <InputGimlet
                                    title={"INDIRIZZO:"}
                                    className={"text-2xl"}
                                    fontWeight={500}
                                    fontStyle="italic"
                                />
                                <InputBilo
                                    title={department ? department["address"] : ""}
                                    className={"text-xl"}
                                    fontWeight={700}
                                />
                            </div>
                        </div>
                        <div className="border-b-4 md:border-l-4 md:border-y-4 border-[#F0E9E3] p-4 flex flex-col justify.center items-center">
                            <div>
                                <InputGimlet
                                    title={"TELEFONO:"}
                                    className={"text-2xl"}
                                    fontWeight={500}
                                    fontStyle="italic"
                                />
                                {
                                    department.phonenumbers?.map((phone, index) => {
                                        return (
                                            <div key={index} className={"flex items-end"}>
                                                <InputBilo
                                                    title={phone.label + ': '}
                                                    className={"text-xl"}
                                                    fontWeight={700}
                                                />
                                                <a
                                                    href={`tel:${phone.phone_number}`}
                                                    style={{
                                                        fontFamily: "bilo",
                                                        fontWeight: 700,
                                                        fontStyle: "normal",
                                                    }}
                                                    className={"text-xl w-full"}>
                                                    {phone.phone_number}
                                                </a>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="border-b-4 lg:border-y-4 lg:border-l-4 border-[#F0E9E3] p-4 flex flex-col justify.center items-center">
                            <div>
                                <InputGimlet
                                    title={"MAIL:"}
                                    className={"text-2xl"}
                                    fontWeight={500}
                                    fontStyle="italic"
                                />
                                <a
                                    href={`mailto:${department ? department["email_info"] : ""}`}
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: 700,
                                        fontStyle: "normal",
                                    }}
                                    className={"text-xl"}>
                                    {department ? department["email_info"] : ""}
                                </a>
                            </div>
                        </div>
                        <div className="border-b-4 md:border-l-4 lg:border-l-0 xl:border-l-4 xl:border-y-4  border-[#F0E9E3] p-4 flex flex-col justify.center items-center">
                            <div>
                                <InputGimlet
                                    title={"FAX:"}
                                    className={"text-2xl"}
                                    fontWeight={500}
                                    fontStyle="italic"
                                />
                                {
                                    department.faxs?.map((fax, index) => {
                                        return (
                                            <div key={index} className="flex items-end">
                                                <InputBilo

                                                    title={fax.label + ': '}
                                                    className={"text-xl"}
                                                    fontWeight={700}
                                                />
                                                <a
                                                    href={`fax:${fax.fax}`}
                                                    style={{
                                                        fontFamily: "bilo",
                                                        fontWeight: 700,
                                                        fontStyle: "normal",
                                                    }}
                                                    className={"text-xl"}>
                                                    {fax.fax}
                                                </a>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="my-10">
                        <div className="mb-4">
                            <InputGimlet
                                title={"ORARI:"}
                                className={"text-2xl"}
                                fontWeight={500}
                                fontStyle="italic"
                            />
                            <InputBilo
                                title={department ? department["office_hours"] : ""}
                                className={"text-xl whitespace-pre-wrap"}
                                fontWeight={500}
                            />
                        </div>
                        <div className="my-4">
                            <InputGimlet
                                title={"IBAN:"}
                                className={"text-2xl"}
                                fontWeight={500}
                                fontStyle="italic"
                            />
                            {
                                department && department['credits'] ? department['credits'].filter(credit => credit?.iban && (credit?.iban?.trim() !== ''))?.map((credit, index) => {
                                    return (
                                        <div className="flex " key={index}>
                                            <InputBilo
                                                title={credit.name + ': '}
                                                className={"text-xl whitespace-pre-wrap"}
                                                fontWeight={500}
                                            />
                                            <InputBilo
                                                title={credit.iban}
                                                className={"text-xl whitespace-pre-wrap"}
                                                fontWeight={500}
                                            />
                                        </div>


                                    )
                                }) : ''
                            }
                        </div>
                        <InputBilo title={""} className={"text-xl"} fontWeight={500} />
                    </div>
                </div>
                <div ref={trekInPartenzaRef} id="trek_in_partenza" className="bg-[#EBE5DF] w-full flex flex-col justify-center">
                    <div className="text-center p-8">
                        <InputGimlet
                            title={"Trek in partenza"}
                            className={"text-[#df5b23] text-3xl"}
                            fontWeight={500}
                            fontStyle="italic"
                        />
                    </div>
                    <div className="w-full flex justify-center">
                        <div className="max-w-[1500px]">
                            {trek &&
                                trek.data &&
                                trek.data.data &&
                                trek.data.data.data &&
                                trek.data.data.data.length > 0 &&
                                !loadingTreks ? (
                                <>
                                    <div className={`grid xl:grid-cols-${trek.data.data.data.length > 4 ? 4 : trek.data.data.data.length} lg:grid-cols-${trek.data.data.data.length > 3 ? 3 : trek.data.data.data.length} md:grid-cols-${trek.data.data.data.length > 2 ? 2 : trek.data.data.data.length} :grid-cols-1 mt-12 gap-12 mx-10`}>
                                        {trek.data.data.data?.map((res, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <Trek
                                                        maps={false}
                                                        res={res}
                                                        img={index % 2 === 1 ? trekimg : trekimg2}
                                                        index={index}
                                                    />
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                    <div
                                        className={
                                            "flex flex-row w-full gap-4 justify-center items-center"
                                        }>
                                        <PaginationCustom page={page} onChange={
                                            (e) => {
                                                setPage(
                                                    Number(e.target.textContent) ?
                                                        Number(e.target.textContent)
                                                        : (
                                                            e.target.dataset.testid === "NavigateNextIcon" ?
                                                                (prevPage) => { return (prevPage + 1) }
                                                                : (prevPage) => { return (prevPage - 1) }
                                                        )
                                                )
                                                window.location.assign(`/sezioni/${sezione}#${trekInPartenzaRef?.current?.id}`)
                                            }
                                        } count={trek.data.data['last_page'] > 2 ? 2 : trek.data.data['last_page']} />
                                    </div>
                                </>


                            ) : (
                                ""
                            )}
                            {trek &&
                                trek.data &&
                                trek.data.data &&
                                trek.data.data.data &&
                                trek.data.data.data.length === 0 &&
                                !loadingTreks ? (
                                <div className="text-center pb-10">Nessun Trek</div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className="flex justify-center gap-4 m-8 ">
                        <Link
                            className="p-2 uppercase text-xl text-center font-bold bg-[#df5b23] text-white mt-2 cursor-pointer hover:opacity-50 max-w-[150px]"
                            to={`/trek-italia?date=${moment().format('yyyy-MM-DD')}&dateEnd=null&maps=false&type_trek=null&destination=null&difficulty=%5B%5D&regions=null&typology=%5B%5D&departments=[${department.id}]&title=&label=null&position=Italia`}>
                            Vedi tutti in Italia
                        </Link>
                        <Link
                            className="p-2 uppercase text-xl text-center font-bold bg-[#df5b23] text-white mt-2 cursor-pointer hover:opacity-50 max-w-[150px]"
                            to={`/trek-estero?date=${moment().format('yyyy-MM-DD')}&dateEnd=null&maps=false&type_trek=null&destination=null&difficulty=%5B%5D&regions=null&typology=%5B%5D&departments=[${department.id}]&title=&label=null&position=Estero`}>
                            Vedi tutti all'estero
                        </Link>
                    </div>
                    {loadingTreks ? <LoadingComponent /> : ""}
                </div>
                <div className="py-20">
                    <div className="text-center">
                        <InputGimlet
                            title={"Ultime news dalla sezione"}
                            className={"text-[#df5b23] text-3xl"}
                            fontWeight={500}
                            fontStyle="italic"
                        />
                    </div>
                    <div>
                        <div
                            className={
                                "xl:mx-56 p-2 xl:grid xl:grid-cols-2 2xl:grid-cols-3 lg:grid lg:grid-cols-2 flex flex-col gap-6 justify-items-center mt-6"
                            }
                            style={{
                                maxHeight: "45rem",
                                overflow: "auto",
                            }}>
                            {news && news.data && news.data.data && !loadingNews
                                ? news.data.data?.map((res, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ItemNews res={res} />
                                        </React.Fragment>
                                    );
                                })
                                : ""}
                        </div>
                    </div>
                    {news &&
                        news.data &&
                        news.data.data &&
                        news.data.data.length === 0 &&
                        !loadingNews ? (
                        <div className="text-center">Nessuna News</div>
                    ) : (
                        ""
                    )}
                    {loadingNews ? <LoadingComponent /> : ""}
                </div>
                <div className="py-20 w-full">
                    <div className="text-center">
                        <InputGimlet
                            title={"Consiglio Direttivo"}
                            className={"text-[#df5b23] text-3xl mb-10"}
                            fontWeight={500}
                            fontStyle="italic"
                        />
                    </div>
                    {/* organigramma2 MOBILE */}
                    <div
                        className={
                            "block sm:flex md:flex lg:flex xl:flex 2xl:hidden flex flex-col gap-6"
                        }
                    >
                        <InputBilo
                            title={`Coordinatore: ${organigramma2.coordinatore.name} ${organigramma2.coordinatore.surname}`}
                            fontWeight={700}
                            className={"text-[#796557] text-2xl"}
                        />
                        <div
                            style={{ fontFamily: "gimlet-text" }}
                            className={"mt-12 mb-6 text-2xl text-[#DF5B23]"}
                        >
                            Consiglieri
                        </div>
                        <div className={"flex flex-col gap-0"}>
                            {
                                organigramma2.consiglioDirettivoSezionale?.map((user, index) => {
                                    return <div key={index}>
                                        <InputBilo
                                            title={`${user.name} ${user.surname}`}
                                            className={`text-[#796557] text-2xl ${user.work !== '' ? 'inline' : ''}`}
                                        />
                                        {
                                            (user.work !== '') &&
                                            <InputBilo
                                                key={index}
                                                title={` (${user.work})`}
                                                className={'text-[#796557] inline text-2xl'}
                                            />
                                        }
                                    </div>
                                })
                            }

                        </div>
                        <div className={"text-[#796557] text-xl font-semibold mt-8"}>
                            Delegati Assemblea Nazionale
                        </div>
                        <div className="flex flex-col gap-0">
                            {
                                organigramma2.delegatiAssembleaNazionale?.map((user, index) => {
                                    return <div key={index}>
                                        <InputBilo
                                            title={`${user.name} ${user.surname}`}
                                            className={`text-[#796557] text-2xl ${user.work !== '' ? 'inline' : ''}`}
                                        />
                                        {
                                            (user.work !== '') &&
                                            <InputBilo
                                                key={index}
                                                title={` ${user.work ? `(${user.work})` : ''}`}
                                                className={'text-[#796557] inline text-2xl'}
                                            />
                                        }
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    {/* organigramma2 DESKTOP */}
                    <div
                        className={
                            "hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:flex  relative w-full flex flex-col items-center justify-center"
                        }
                    >
                        <ItemOrganigramma
                            star={true}
                            title={organigramma2.coordinatore.title}
                            name={organigramma2.coordinatore.name}
                            surname={organigramma2.coordinatore.surname}
                        />
                        {/* <DividerCustom />
                        <DividerCustom /> */}
                        <br />
                        <div
                            style={{ fontFamily: "gimlet-text" }}
                            className={
                                "p-4 text-[#DF5B23] text-2xl bg-[#F0E9E3] w-full rounded-3xl text-center "
                            }
                        >
                            Consiglieri
                        </div>
                        {/* <div className={"flex flex-row gap-56"}>
                            {organigramma2.consiglioDirettivoSezionale.slice(0, 7)?.map((item, index) => {
                                return <DividerCustom key={index} />
                            })}
                        </div> */}
                        <br />

                        <>
                            {
                                [...new Array(Math.ceil((organigramma2.consiglioDirettivoSezionale.length / 6.5)))]?.map((row, index) => {
                                    if (index % 2 === 0) {
                                        return <div key={index} className={`${index === 0 ? '' : 'mt-4'} grid grid-cols-${organigramma2.consiglioDirettivoSezionale.slice(0, 7).length} gap-12`}>
                                            {organigramma2.consiglioDirettivoSezionale.slice(0 + (index * 6.5), 7 + (index * 6.5))?.map((item, index) => {
                                                return (
                                                    <ItemOrganigramma
                                                        key={index}
                                                        index={index}
                                                        star={false}
                                                        name={item.name}
                                                        surname={item.surname}
                                                        work={item.work === '' ? false : item.work}
                                                    />
                                                )
                                            })}
                                        </div>
                                    } else {
                                        return <div key={index} className={`mt-4 grid grid-cols-${organigramma2.consiglioDirettivoSezionale.slice(0, 6).length} gap-12`}>
                                            {organigramma2.consiglioDirettivoSezionale.slice(0.5 + (index * 6.5), 6.5 + (index * 6.5))?.map((item, index) => {
                                                return (
                                                    <ItemOrganigramma
                                                        key={index}
                                                        index={index}
                                                        star={false}
                                                        name={item.name}
                                                        surname={item.surname}
                                                        work={item.work === '' ? false : item.work}
                                                    />
                                                )
                                            })}
                                        </div>
                                    }
                                })
                            }
                        </>

                        <div
                            style={{ fontFamily: "gimlet-text" }}
                            className={
                                "p-4 text-[#DF5B23] mt-12 text-2xl bg-[#F0E9E3] w-full rounded-3xl text-center relative"
                            }
                        >
                            Delegati Assemblea Nazionale
                        </div>
                        <br />
                        {/* <div className={"flex flex-row gap-56"}>
                            {organigramma2.delegatiAssembleaNazionale.slice(0, 7)?.map((item, index) => {
                                return <DividerCustom key={index} />
                            })}
                        </div> */}
                        {
                            [...new Array(Math.ceil((organigramma2.delegatiAssembleaNazionale.length / 6.5)))]?.map((row, index) => {
                                if (index % 2 === 0) {
                                    return <div key={index} className={`${index === 0 ? '' : 'mt-4'} grid grid-cols-${organigramma2.delegatiAssembleaNazionale.slice(0, 7).length} gap-12`}>
                                        {organigramma2.delegatiAssembleaNazionale.slice(0 + (index * 6.5), 7 + (index * 6.5))?.map((item, index) => {
                                            return (
                                                <ItemOrganigramma
                                                    key={index}
                                                    index={index}
                                                    star={false}
                                                    name={item.name}
                                                    surname={item.surname}
                                                    work={item.work === '' ? false : item.work}
                                                    supplente={item.supplente}
                                                />
                                            )
                                        })}
                                    </div>
                                } else {
                                    return <div key={index} className={`mt-4 grid grid-cols-${organigramma2.delegatiAssembleaNazionale.slice(0, 6).length} gap-12`}>
                                        {organigramma2.delegatiAssembleaNazionale.slice(0.5 + (index * 6.5), 6.5 + (index * 6.5))?.map((item, index) => {
                                            return (
                                                <ItemOrganigramma
                                                    key={index}
                                                    index={index}
                                                    star={false}
                                                    name={item.name}
                                                    surname={item.surname}
                                                    work={item.work === '' ? false : item.work}
                                                    supplente={item.supplente}
                                                />
                                            )
                                        })}
                                    </div>
                                }
                            })
                        }
                    </div>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
}

export default Sezioni;

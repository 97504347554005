import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import InputBilo from "../InputBilo";
import InputGimlet from "../InputGimlet";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";
import "./custom-swiper.css";

import { Scrollbar, Mousewheel, FreeMode } from "swiper";
import { Dialog } from "@mui/material";

function Template2({text, gallery}) {
  const [openGallery, setOpenGallery] = useState(null);

  const swiperRef = useRef(null);

  const mobile = window.matchMedia("(max-width: 768px)").matches;
  const tablet = window.matchMedia("(max-width: 1170px)").matches;
  const smallScreen = window.matchMedia("(max-width: 1535px)").matches;

  return (
    <>
      <InputBilo className="whitespace-pre-wrap text-2xl mb-20" title={text} />

      <InputGimlet
        className={"my-6 mb-[2rem] text-4xl text-[#DF5B23]"}
        title={"GALLERY"}
        fontWeight={500}
      />
      <Swiper
        ref={swiperRef}
        mousewheel={true}
        freeMode={true}
        spaceBetween={20}
        slidesPerView={mobile ? 2 : tablet ? 4 : smallScreen ? 6 : 8}
        scrollbar={{
          horizontalClass: "swiper-scrollbar-horizontal",
          draggable: true,
          dragSize: 40,
          snapOnRelease: false,
        }}
        direction="vertical"
        modules={[Scrollbar, Mousewheel, FreeMode]}
        className="mySwiper template2"
        style={{ maxHeight: "850px" }}
        // onSlideChange={(e)=>{console.log(e.params.slidesPerView)}}
      >
        {gallery.map((foto, index) => {
          return (
            <SwiperSlide
              onClick={() => {
                setOpenGallery(index);
              }}
              className="!h-[392px] !w-[320px] bg-[#00cc00] bg-[#00cc00] !rounded-[35px]"
              key={index}>
              <div className="w-full h-full bg-[#00cc00] !rounded-[35px]">
                <img
                  className=" w-full h-full object-cover !rounded-[35px]"
                  src={foto}
                  alt=""
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <Dialog
        open={openGallery === null ? false : true}
        onClose={() => {
          setOpenGallery(null);
        }}
        maxWidth={"lg"}
        fullWidth={true}
        sx={{ backgroundColor: "transparent" }}>
        <div className="w-full h-[850px] flex justify-around items-center bg-[trasparent]">
          <KeyboardArrowLeftIcon
            sx={{ fontSize: "90px" }}
            onClick={() => {
              setOpenGallery((prevIndex) =>
                prevIndex === 0 ? gallery.length - 1 : prevIndex - 1
              );
            }}
          />
          <div>
            <img
              className="object-cover h-full md:w-[700px] lg:w-[1000px] xl:w-[1200px] 2xl:w-[1500px]"
              src={gallery[openGallery]}
              alt=""
            />
          </div>

          <KeyboardArrowRightIcon
            sx={{ fontSize: "90px" }}
            onClick={() => {
              setOpenGallery((prevIndex) =>
                prevIndex === gallery.length - 1 ? 0 : prevIndex + 1
              );
            }}
          />
        </div>
      </Dialog>
    </>
  );
}

export default Template2;

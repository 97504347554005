import BackgroundTitle from "../components/BackgroundTitle";
import TopBarIndex from "../components/TopBarIndex";
import FooterDown from "../Sezioni/FooterDown";
import FooterTop from "../Sezioni/FooterTop";
import img1 from "../assets/img/img-slider-home.jpg";
import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { api } from "../conf/apiConf";
import TrekList from "../Sezioni/TrekList";
import useSWR from "swr";
import ItemNews from "../components/ItemNews";
import ItemCatalog from "../components/ItemCatalog";
import ItemEvento from "../Eventi/ItemEvento";
import InputBilo from "../components/InputBilo";
const Search = () => {
    const [search, setSearch] = useState("");
    const [tab, setTab] = useState(0);
    const [treks, setTreks] = useState([]);
    const [cataloghi, setCataloghi] = useState([]);
    const [corsi, setCorsi] = useState([]);
    const [eventi, setEventi] = useState([]);
    const [magazine, setMagazine] = useState([]);
    const [news, setNews] = useState([]);
    const [page, setPage] = useState(1)
    const [drawerIsOpen, setDrawerIsOpen] = useState(false)


    const fetcher5 = (url) =>
        api
            .post(url, {
                title: localStorage.getItem("search").slice(0, -1).slice(1),
            })
            .then((res) => {
                setTreks(res.data.data.trek);
                setCataloghi(res.data.data.cataloghi);
                setCorsi(res.data.data.corsi);
                setEventi(res.data.data.eventi);
                setMagazine(res.data.data.magazine);
                setNews(res.data.data.news);
                return res;
            });
    const { data: searched } = useSWR([`/search-all`, search], fetcher5);

    useEffect(() => {
        setSearch(localStorage.getItem("search").slice(0, -1).slice(1));
        setTab(0);
        handleChangeTab({}, 0);
    }, [localStorage.getItem("search")]);

    if (search !== localStorage.getItem("search").slice(0, -1).slice(1)) {
        setSearch(localStorage.getItem("search").slice(0, -1).slice(1))
    }

    if (document && document.getElementById("search-input")) {
        document.getElementById("search-input").onkeyup = (e) => {
            if (e.key === "Enter") {
                setSearch(localStorage.getItem("search"));
                handleChangeTab({}, tab);
            }
        };
    }
    useEffect(() => {
        if (document && document.getElementById("search-input-mobile")) {
            document.getElementById("search-input-mobile").onkeyup = (e) => {
                if (e.key === "Enter") {
                    setSearch(localStorage.getItem("search"));
                    handleChangeTab({}, tab);
                }
            };
        }
    }, [drawerIsOpen])


    function handleChangeTab(e, newValue) {
        setTab(newValue);
    }

    return (
        <>
            <TopBarIndex setDrawerIsOpen={setDrawerIsOpen} />
            <BackgroundTitle title={"Search"} img={img1} position={"center"} />
            <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
                <div
                    className={"flex flex-row items-center gap-1"}
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}>
                    <div className={"font-bold text-2xl"}>Hai cercato: {search}</div>
                </div>
            </div>
            <Box display="flex" justifyContent="center" width="100%" backgroundColor="#ffffff">
                <Tabs
                    sx={{ backgroundColor: "white" }}
                    value={tab}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    centered>
                    <Tab label="Trek" />
                    <Tab label="Magazine" />
                    <Tab label="Cataloghi" />
                    <Tab label="News" />
                    <Tab label="Eventi" />
                </Tabs>
            </Box>

            <div className="">
                {tab === 0 && (
                    <>
                        <TrekList data={treks} direct={true} page={page} setPage={setPage} />
                    </>
                )}
                {tab === 1 && (
                    <>
                        <div className={`${magazine && magazine.length > 0 ? "mt-20 flex flex-col lg:grid lg:grid-cols-3 gap-4" : ''}`}>
                            {magazine && magazine.length > 0
                                ? magazine.map((res, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ItemCatalog
                                                type={"trek"}
                                                img={res.preview_image_path}
                                                title={res.title}
                                                badge={index === 0}
                                                res={res}
                                                colSpan={index === 0 && "col-span-2"}
                                            />
                                        </React.Fragment>
                                    );
                                })
                                : <InputBilo className={'text-2xl text-center mt-2'} title={'Nessun Magazine'} />}
                        </div>
                    </>
                )}
                {tab === 2 && (
                    <>
                        <div className={`${cataloghi && cataloghi.length > 0 ? "mt-20 flex flex-col lg:grid lg:grid-cols-3 gap-4" : ''}`}>
                            {cataloghi && cataloghi.length > 0
                                ? cataloghi.map((res, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ItemCatalog
                                                img={res.preview_image_path}
                                                title={res.title}
                                                badge={index === 0}
                                                res={res}
                                                colSpan={index === 0 && "col-span-2"}
                                            />
                                        </React.Fragment>
                                    );
                                })
                                : <InputBilo className={'text-2xl text-center mt-2'} title={'Nessun Catalogo'} />}
                        </div>
                    </>
                )}
                {tab === 3 && (
                    <>
                        <div
                            className={
                                `${news && news.length > 0 ? "xl:mx-56 p-2 xl:grid xl:grid-cols-2 2xl:grid-cols-3 lg:grid lg:grid-cols-2 flex flex-col gap-6 justify-items-center mt-6" : ''}`
                            }
                            style={{
                                maxHeight: "45rem",
                                overflow: "auto",
                            }}>
                            {news && news.length > 0
                                ? news.map((res, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ItemNews res={res} />
                                        </React.Fragment>
                                    );
                                })
                                : <InputBilo className={'text-2xl text-center mt-2'} title={'Nessuna News'} />}
                        </div>
                    </>
                )}
                {
                    tab === 4 && (
                        <div
                            className={
                                `${eventi && eventi.length > 0 ? 'xl:mx-56 p-2 xl:grid xl:grid-cols-2 2xl:grid-cols-3 lg:grid lg:grid-cols-2 flex flex-col gap-6 justify-items-center mt-6' : ''}`
                            }
                        >
                            {eventi && eventi.length > 0 ? eventi.map((event, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <ItemEvento res={event} />
                                    </React.Fragment>
                                );
                            }) : <InputBilo className={'text-2xl text-center mt-2'} title={'Nessun Evento'} />}
                        </div>
                    )
                }
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default Search;

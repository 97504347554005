import React from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";

const Associazione = () => {

    let intro = "Dal 1985 ci impegniamo ad avvicinare, conoscere, rispettare e difendere la natura, rivalutando quelle capacità di percezione e di relazione dell'uomo che le abitudini di vita moderne hanno assopito."

    let sezioni = [
        {
            title: "Alla scoperta del mondo viaggiando a piedi",
            paragraph: ["Molto in natura si può imparare ripercorrendo antichi sentieri che una volta avevano uno scopo ben diverso da quello attuale: vie importanti di comunicazione e di scambi economici fra paesi e popoli diversi. La loro riscoperta è possibile viaggiando a piedi, percorrendo con i nostri soci i sentieri del mondo. Questa è la formula che caratterizza i nostri gruppi dal 1985 e che riesce a creare un bagaglio di emozioni, incoraggiare la curiosità e il desiderio di conoscere e di incontrarsi."]
        },
        {
            title: "Il cammino come luogo dell’incontro",
            paragraph: ["La nostra avventura è un girovagare tra gli aspetti naturali e culturali del territorio, e le contraddizioni che il nostro stile di vita produce. Questo lento viaggio è volto all'incremento della conoscenza, alla creazione di stili di vita alternativi all'attuale, nel rispetto delle risorse della Terra e delle culture del mondo. E' fondamentale che i nostri soci si dimostrino aperti all'incontro, si dispongano serenamente allo scambio di idee e sensazioni che l'andare a piedi promuove, sviluppino capacità di affrontare costruttivamente i disagi che si possono presentare in condizioni avverse o in paesi lontani, molto diversi dal nostro."]
        }

    ]

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Associazione"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Associazione &gt; </div>
                        <div>Trekking Italia &gt; </div>
                        <div className={"font-bold"}>Associazione</div>
                    </div>
                    {/* Content */}
                    <div
                        className={"my-6 mb-[2rem] text-4xl text-[#DF5B23]"}
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}
                    >
                        TREKKING ITALIA È UN’ASSOCIAZIONE DI PROMOZIONE SOCIALE
                        <div className="mb-5 text-2xl" style={{ display: "inline" }}>(DL 117/2017)</div>
                    </div>
                    <div className="mb-5 text-xl" style={{ fontFamily: "Bilo" }}>{intro}</div>
                    {sezioni.map((sezione) => {
                        return (
                            <>
                                <div className="text-4xl text-[#DF5B23]" style={{
                                    fontFamily: "gimlet-text",
                                    fontWeight: 500,
                                    fontStyle: "italic",
                                }}>{sezione.title}</div>
                                <div className="mb-5 text-xl" style={{ fontFamily: "Bilo" }}>{sezione.paragraph}</div>
                            </>

                        )
                    })}
                    <div className="text-4xl text-[#DF5B23]" style={{
                        fontFamily: "gimlet-text",
                        fontWeight: 500,
                        fontStyle: "italic",
                    }}>Camminare con Trekking Italia</div>
                    <div className="mb-5 text-xl" style={{ fontFamily: "Bilo", display: "inline"}}>Trekking Italia ha come obiettivo quello di accrescere nei soci le competenze individuali nell’escursionismo e nella la capacità di autorganizzarsi un viaggio a piedi: </div> 
                    <div className="mb-5 text-xl" style={{ fontFamily: "Bilo", fontWeight: 500, display: "inline"}}>non è un'agenzia viaggi </div> 
                    <div className="mb-5 text-xl" style={{ fontFamily: "Bilo", display: "inline"}}>e chi partecipa deve sapere che gran parte dell'organizzazione poggia sul contributo responsabile di soci volontari che accompagnano i trek e gestiscono le Sezioni regionali. </div> 
                    <div className="mb-5 text-xl" style={{ fontFamily: "Bilo", fontWeight: 500, display: "inline"}}>Grandi trek, Trekweek-end e Trek giornalieri </div>
                    <div className="mb-5 text-xl" style={{ fontFamily: "Bilo", display: "inline"}}>sono le attività caratteristiche che l'Associazione propone. Tutti i nostri trek si svolgono in gruppo perché ciò favorisce la circolazione delle idee, delle esperienze e il reciproco aiuto: in gruppo si crea amicizia e solidarietà utili alla ricostruzione/costruzione di una nuova cittadinanza.</div>
                    <br /><br />
                    <div className="mb-5 text-xl" style={{ fontFamily: "Bilo", display: "block"}}>Oltre ai trek, ogni Sezione propone eventi specifici: serate culturali, gruppi di lettura, presentazione e preparazione di trek, corsi di formazione (escursionismo, botanica), iniziative sociali organizzate in sede o in ambiente.</div>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default Associazione;
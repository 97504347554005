import React from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { Link } from "react-router-dom";
import env from "../conf/variables";

const Faq = () => {

    let faqs = [
        {
            domanda: "Perché privilegiare il pagamento on line del rinnovo dell'iscrizione, della nuova iscrizione e anche l'iscrizione e il pagamento dei trek giornalieri con borsellino elettronico?",
            risposta: <div>
                <p>Trekking Italia vuole ridurre il lavoro amministrativo e i suoi costi, per dedicare ancora maggiore attenzione alla qualità dei trek e delle altre attività associative.</p>
            </div>
        },
        {
            domanda: "Come iscriversi ad un trek?",
            risposta: <div>
                <p>Per partecipare ad un trek è necessario essere associati a Trekking Italia. Se si è Soci di Trekking Italia, è possibile iscriversi ai trek in programma in tre diverse modalità:</p>
                <ul style={{ listStyle: 'disc', paddingLeft: 40 }}>
                    <li>iscrivendosi e pagando direttamente on line con carte bancarie o con l'utilizzo del borsellino elettronico (il socio deve aver autorizzato la creazione del borsellino elettronico, il borsellino deve essere capiente, il socio in qualsiasi momento può richiedere la chiusura del borsellino);</li>
                    <li>contattando direttamente la Segreteria della Sezione regionale;</li>
                    <li>per quanto riguarda i Grandi Trek anche inviando una email alla Sezione regionale che organizza il trek allegando la ricevuta del relativo bonifico.</li>
                </ul>
            </div>
        },
        {
            domanda: "Come diventare socio di Trekking Italia?",
            risposta: <div>
                <p>Per aderire a Trekking Italia è necessario versare la quota di iscrizione e compilare, in ogni sua parte, il <strong>modulo di iscrizione</strong> e i <strong>moduli relativi al trattamento dei dati personali ai sensi del </strong><strong >GDPR</strong><strong> UE 2016/67 (Privacy).</strong></p>
                <p>Scaricare i documenti Consenso – Informativa – Scheda adesione a Trekking Italia <a href="" >link</a></p>
                <br />
                <p>L’iscrizione a Trekking Italia può avvenire secondo tre differenti modalità:</p>
                <ul style={{ listStyle: 'disc', paddingLeft: 40 }}>
                    <li>on line (<Link to={'/register'} >link</Link>): in questo caso il modulo di iscrizione e i moduli relativi al trattamento dati sono compresi nel processo elettronico di iscrizione e vanno sottoscritti inserendo la firma in formato jpg; in caso di difficoltà ad inserire la suddetta firma l'aspirante socio può allegare copia del documento di identità in formato jpg.</li>
                    <li>direttamente presso la sede della Sezione regionale di riferimento;</li>
                    <li>accreditando la quota di iscrizione sull’IBAN della Sezione regionale di riferimento e inviando una email alla stessa con copia del bonifico e i moduli sopra indicati interamente compilati e firmati.</li>
                </ul>
            </div>
        },
        {
            domanda: "Come rinnovare l'iscrizione a Trekking Italia?",
            risposta: <div>
                <ul style={{ listStyle: 'disc', paddingLeft: 40 }}>
                    <li>Il socio registrato può pagare la quota di rinnovo on line con carta di credito o debito;</li>
                    <li>Il socio può inviare una mail alla Sezione di appartenenza con copia del bonifico;</li>
                    <li>Il socio può pagare la quota in occasione della prima partecipazione ad un trek in accordo con la propria Sezione.</li>
                </ul>
                <br />
                <p>L’adesione a Trekking Italia e la corresponsione della quota associativa annuale permettono la </p> <p style={{ textDecoration: 'underline' }}>partecipazione alle attività organizzate da tutte le Sezioni di Trekking Italia.</p>
            </div>
        },
        {
            domanda: "A quanto ammonta il costo della quota associativa annuale per Soci Ordinari e Sostenitori?",
            risposta: <div>
                <p>Il costo della quota associativa annuale è di € 20 per i Soci Ordinari.</p>
                <p>La quota minima per i Soci Sostenitori è di € 40.</p>
                <p>La quota associativa è valida a partire dal 1° dicembre dell'anno in corso fino al 31 dicembre dell’anno successivo e <strong>comprende la copertura assicurativa Responsabilità Civile e Infortuni.</strong>  <Link to="/coperture-assicurative" >link</Link></p>
            </div>
        },
        {
            domanda: "Come si effettua la registrazione sul sito?",
            risposta: <div>
                <p>Per registrarti al sito, <Link to={'/register'} onClick={() => { window.scroll(0, 0) }}><strong>clicca quì</strong></Link>, inserisci i tuoi dati, la tua email e crea una password, accettando i termini e le condizioni.</p>
            </div>
        },
        {
            domanda: "Ho perso la mia password, posso recuperarla?",
            risposta: <div>
                <p>Certo che sì, nessun problema. <Link to={'/login'} onClick={() => { window.scroll(0, 0) }}><strong>clicca quì</strong></Link> e sulla voce "password dimenticata", riceverai subito un'email con una nuova password di accesso.</p>
            </div>
        },
        {
            domanda: "Quali sono le attività promosse da Trekking Italia?",
            risposta: <div>
                <p>L’Associazione organizza tre diverse tipologie di trek:</p>
                <ul style={{ listStyle: 'disc', paddingLeft: 40 }}>
                    <li><strong>Giornaliero</strong>: l’escursione ha inizio e si esaurisce nella stessa giornata;</li>
                    <li><strong>Weekend</strong>: escursioni di 2 o 3 giorni, in cui sono previsti 1 o 2 pernottamenti;</li>
                    <li><strong>Grandi</strong> trek: escursioni della durata dai 4 giorni in su.</li>
                </ul>
                <br />
                <p>Oltre ai trek, ogni Sezione promuove eventi specifici come serate culturali, presentazione e preparazione di trek, corsi di formazione, iniziative sociali, organizzati in sede o in ambiente.</p>
            </div>
        },
        {
            domanda: "Come rimanere aggiornati sulla programmazione dei trek?",
            risposta: <div>
                <p>La programmazione dei Trek Giornalieri, Weekend e Grandi Trek viene resa nota attraverso:</p>
                <ul style={{ listStyle: 'disc', paddingLeft: 40 }}>
                    <li>il sito www.trekkingitalia.org;</li>
                    <li>le newsletter settimanali delle Sezioni regionali;</li>
                    <li>i social curati dalle Sezioni regionali;</li>
                    <li>Le proposte dei Grandi Trek vengono pubblicizzate in modo univoco da tutte le Sezioni di Trekking Italia e pubblicate su Cataloghi Nazionali (<strong>Catalogo di Capodanno-Epifania</strong> e <strong>Catalogo Nazionale GT</strong>) reperibili sul sito internet.</li>
                </ul>
            </div>
        },
        {
            domanda: "Come è organizzato il trasporto per raggiungere il luogo del trek?",
            risposta: <div>
                <p>Ogni partecipante deve provvedere direttamente all’acquisto dei titoli di viaggio di andata e ritorno per raggiungere il luogo dove si effettuerà il trek, oppure, in caso di utilizzo di mezzi ad uso esclusivo del gruppo, parteciperà al costo del trasporto.</p>
                <p>Alcuni trek prevedono il noleggio di veicoli (pulmini, fuoristrada) guidati dai partecipanti stessi. L’Associazione non è responsabile di eventuali ritardi o annullamenti da parte delle compagnie aeree.</p>
                <br />
                <p>Si raccomanda di non acquistare alcun biglietto di viaggio personale (treno, aereo, altro) per raggiungere il luogo del ritrovo partecipanti al trek, a meno che dalla Sede organizzatrice non sia stata confermata la partenza del trek stesso e siano state date specifiche indicazioni in merito.</p>
                <p>L'Associazione non è responsabile di variazioni non comunicate del piano voli e dei ritardi, scioperi, contrattempi durante il viaggio causati dalla compagnia aerea o altro vettore.</p>
                <br />
                <p>Trekking Italia non si ritiene responsabile per i problemi relativi a biglietti aerei o ferroviari, acquistati autonomamente per raggiungere il luogo di partenza del viaggio, derivanti da improvvisi cambi di orario o del luogo di partenza per cause di forza maggiore o necessità operative. </p>
                <p>Nel caso si rendesse necessario un prolungamento del viaggio per i fattori suddetti o altri imprevisti non dipendenti dall'Associazione, le spese addizionali saranno a carico del partecipante.</p>
                <br />
                <p>Si ricorda che le norme vigenti non consentono il trasporto in cabina di elementi ritenuti pericolosi (coltelli multiuso, forbici, bastoncini telescopici, liquidi contenuti in recipienti con capacità superiori ai 100 ml, ecc…) che saranno da inserire nel bagaglio personale, posizionato nella stiva dell'aereo. </p>
                <p>È importante fare attenzione a tutte le comunicazioni in merito fornite dalla compagnia aerea o presso lo scalo aeroportuale prima di recarsi al check-in.</p>
            </div>
        },
        {
            domanda: "Come sono organizzati i trek che prevedono il pernottamento?",
            risposta: <div>
                <p>Se non precisato diversamente, i pernottamenti sono da intendersi presso alberghi, ostelli, rifugi o gîte d'étape, con sistemazioni variabili dalla camera doppia alla camerata con più letti; quando possibile e su richiesta la sistemazione è in camera singola. </p>
                <p>In alcuni pernottamenti bisogna essere dotati di sacco a pelo oppure sacco lenzuolo e di asciugamani personali. </p>
                <p>Per determinati trek, il pernottamento è previsto in tenda.</p>
            </div>
        },
        {
            domanda: "I pasti sono inclusi?",
            risposta: <div>
                <p>Solitamente il trattamento è di mezza pensione con pasti presso la struttura di pernottamento oppure, a volte, al di fuori della stessa. </p>
                <p>Alcuni trek prevedono il solo trattamento di Bed & Breakfast; altri ancora non comprendono il trattamento di mezza pensione per motivi logistici e, di conseguenza, ogni partecipante provvederà direttamente al pagamento dei suoi pasti.</p>
            </div>
        },
        {
            domanda: "Qual è la classificazione del grado di difficoltà dei trek?",
            risposta: <div>
                Trekking Italia ritiene che sia innanzitutto il partecipante a dover fare un’autovalutazione delle proprie capacità tenendo conto del grado di difficoltà del trek: vedi classificazione <Link to={`${env.url_api_without_api}/document/ognuno_il_suo_trek.pdf`} target="_blank">link</Link>
                <p>Nel caso in cui il trek proposto fosse ritenuto troppo impegnativo per uno dei soci l’accompagnatore può sconsigliarne la partecipazione.  </p>
            </div>
        },
        {
            domanda: "È ammessa la partecipazione dei cani ai trek giornalieri?",
            risposta: <div>
                <p>La partecipazione dei cani ai trek giornalieri è limitata ad un singolo animale previo accordo con l’Accompagnatore. Qualsiasi danno o infortunio provocato dalla presenza del cane sarà coperto dall’assicurazione del Socio proprietario e non da quella di Trekking Italia che esclude tale possibilità. La partecipazione dei cani non è ammessa nei trek di più giorni.</p>
            </div>
        },
        {
            domanda: "È possibile recedere dall’iscrizione di un Grande Trek? Cosa comporta?",
            risposta: <div>
                <p>In caso di disdetta non tempestiva, il Socio perde una parte o l’intera quota come di seguito specificato.</p>
                <ul style={{ listStyle: 'disc', paddingLeft: 40 }}>
                    <li>Per disdetta tempestiva s’intende la richiesta di annullare la propria partecipazione al trek più di 20 giorni prima della conferma del trek.</li>
                    <li>La disdetta è da considerarsi non tempestiva se il socio recede a trek confermato o 20 giorni prima della partenza. In questo caso perde l'acconto (normalmente pari al 25%) già versato.</li>
                    <li>In caso di recesso fino a 8 giorni prima della partenza il socio perde il 50% del prezzo del trek.</li>
                    <li>Al socio che recede da 8 giorni fino a 3 giorni prima della partenza sarà addebitato il 75% del prezzo, il 100% dopo tali termini.</li>
                </ul>
                <p>Il giorno della partenza è sempre escluso dal conteggio; il giorno dell'annullamento è sempre compreso.</p>
                <br />
                <p>Indipendentemente dai termini sopra indicati, nel caso la rinuncia venisse comunicata dopo il pagamento da parte di Trekking Italia di servizi e trasporti non più rimborsabili, i costi degli stessi verranno integralmente addebitati, mentre rimangono valide le percentuali di cui sopra per la restante quota di partecipazione.</p>
                <br />
                <p>Nonostante quanto previsto in caso di disdetta o di rinuncia non tempestiva da parte di un socio iscritto ad un’escursione promossa dall’Associazione, quest’ultima cercherà di favorire il socio, ove possibile, restituendogli quanto eventualmente non verrà richiesto dai fornitori per i servizi non effettuati e comunque verranno trattenute le spese amministrative.</p>
            </div>
        },
        {
            domanda: "Cosa succede in caso di disdetta di un trek per cause che non dipendono da Trekking Italia?",
            risposta: <div>
                <p>Nel caso in cui un trek venga annullato per cause che non dipendono da Trekking Italia (maltempo, allerte meteo, calamità naturali, eventi sociopolitici, emergenze sanitarie, ecc…) oppure nel caso di annullamento e/o ritardo dei mezzi di trasporto prenotati, l’Associazione non è tenuta a rimborsare tali costi sostenuti dai Soci, né eventuali costi aggiuntivi.</p>
                <p>Inoltre, l’Associazione non potrà rimborsare i costi sostenuti relativamente all’organizzazione del trek.</p>
                <p>Trekking Italia non ha polizze assicurative a copertura dell'annullamento di un trek.</p>
            </div>
        },
        // {
        //     title: "Associarsi a trek italia",
        //     domande: [
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?",
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?",
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?"
        //     ]
        // },
        // {
        //     title: "Rinnovo tessere",
        //     domande: [
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?",
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?",
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?"
        //     ]
        // },
        // {
        //     title: "Iscrizione",
        //     domande: [
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?",
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?",
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?"
        //     ]
        // },
        // {
        //     title: "Partecipazione ai trek",
        //     domande: [
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?",
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?",
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?"
        //     ]
        // },
        // {
        //     title: "Navigaizione sito",
        //     domande: [
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?",
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?",
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?"
        //     ]
        // },
        // {
        //     title: "Info utili",
        //     domande: [
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?",
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?",
        //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ?"
        //     ]
        // }
    ]

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Faq"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Associazione &gt; </div>
                        <div className={"font-bold"}>FAQ</div>
                    </div>
                    {/* Content */}
                    <div
                        className={"my-6 mb-[3.5rem] text-4xl text-[#DF5B23]"}
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}
                    >
                        FAQ
                    </div>
                </div>
                <div>
                    {faqs.map((faq, index) => {
                        return (
                            <div key={index} className={`pl-[7.5%] pr-[7.5%] mb-10 ${index % 2 === 0 ? "" : "bg-[#F0E9E3] pt-10 pb-10"}`}>
                                <div className="text-3xl text-[#DF5B23]" style={{
                                    fontFamily: "gimlet-text",
                                    fontWeight: 500,
                                    fontStyle: "italic",
                                }}>{faq.domanda}</div>

                                <div className="mt-4">
                                    <div className="text-xl" style={{ fontFamily: "Bilo" }}>{faq.risposta}</div>
                                </div>

                            </div>
                        )
                    })}
                </div>

            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default Faq;
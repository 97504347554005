import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import DialogCustom from "../components/DialogCustom";
import { connect } from "react-redux";
import { IconButton, InputAdornment } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TopBarIndex from "../components/TopBarIndex";
import { api } from "../conf/apiConf";
import { changeUrlBased } from "./auth";
import CryptoJS from "crypto-js";
import env from "../conf/variables";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const validationSchema = yup.object({
    username: yup.string().required("Campo obbligatorio"),
    password: yup
        .string()
        .min(5, "La password deve contenere almeno almeno 8 caratteri")
        .required("Password obbligatoria"),
});

const Login = ({ dispatch, loginTo = '/' }) => {

    const [showPassword, setShowPassword] = React.useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [fromAssocia, setFromAssocia] = useState(localStorage.getItem('from_associa'))

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            //   if (socio === false) {
            api.post(`/login`, values).then((res) => {
                if (!res.ok || res.data.error === 0) {
                    setOpen(true);
                } else {
                    const encryptedPassword = CryptoJS.AES.encrypt(values.password, env.secret_key).toString()
                    dispatch.count.setPassword(encodeURIComponent(encryptedPassword));
                    dispatch.count.setToken(res.data.data.access_token);
                    dispatch.count.setProfile(res.data.data.user); //real data
                    if (fromAssocia === 'true' && res.data.data.user['user_partner'] === '0') {
                        navigate('/register/socio')
                    } else {
                        navigate(loginTo);
                    }

                }
            });
            //   } else {
            //     api.post(`/login`, values).then((res) => {
            //       console.log(res);
            //       if (!res.ok || res.data.error === 0) {
            //         setOpen(true);
            //       } else {
            //         dispatch.count.setToken(res.data.data.access_token);
            //         dispatch.count.setProfile(res.data.data.user);
            //         window.location.replace(
            //           "https://panel.trekkingitalia.teaweb.it/"
            //         );
            //       }
            //     });
            //   }
        },
    });

    useEffect(() => {
        setFromAssocia(localStorage.getItem('from_associa'))
    }, [localStorage.getItem('from_associa')])

    return (
        <>
            <DialogCustom
                handleClose={handleClose}
                open={open}
                content={"Verificare che le credenziali sia esatte e riprovare"}
                title={"Credenziali non valide"}
                button={"CHIUDI"}
                closeModal={handleClose}
            />
            <form onSubmit={formik.handleSubmit}>
                <div className="py-4 shadow-2xl">
                    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                        <div className="w-full bg-[#f0e9e3] rounded-lg shadow dark:border md:mt-0 sm:max-w-lg xl:p-0">
                            <div className="p-6 gap-4 flex flex-row items-center">
                                {
                                    loginTo === '' ?
                                        <IconButton onClick={() => navigate("/")}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                        : ''
                                }
                                <div className="font-bold text-[#4a4137] text-2xl">
                                    {fromAssocia === 'true' ? 'Per procedere con l\'associazione è necessario essere registrati' : 'Inserisci le credenziali per accedere'}
                                </div>
                            </div>
                            <div className="px-6 py-4">
                                <TextField
                                    label={"Username"}
                                    name={"username"}
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    error={
                                        formik.touched.username && Boolean(formik.errors.username)
                                    }
                                    helperText={formik.touched.username && formik.errors.username}
                                />
                            </div>
                            <div className="px-6 py-4">
                                <TextField
                                    type={showPassword ? 'text' : "password"}
                                    label={"Password"}
                                    name={"password"}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={()=>{setShowPassword(prev => !prev)}}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                    error={
                                        formik.touched.password && Boolean(formik.errors.password)
                                    }
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                            </div>
                            <div
                                className={
                                    "inline-flex pl-6 text-md  leading-tight tracking-tight text-[#4a4137]"
                                }>
                                Password dimenticata?{" "}
                                <div
                                    onClick={() => navigate("/reset-pass")}
                                    className={
                                        "underline ml-1 hover:cursor-pointer hover:text-[#df5b23]"
                                    }>
                                    Clicca qui
                                </div>
                            </div>
                            <div className={"w-full flex flex-col md:flex-row gap-6 my-6 justify-center items-center"}>
                                <button
                                    type={"submit"}
                                    className={
                                        "uppercase cursor-pointer hover:opacity-50 text-center text-white text-lg w-[170px] md-w-1/3 p-2 bg-[#df5b23]"
                                    }
                                    style={{ fontFamily: "gimlet-text" }}>
                                    ACCEDI
                                </button>
                                <div
                                    onClick={() => navigate(fromAssocia === 'true' ? '/register/socio' : "/register")}
                                    className={
                                        "uppercase cursor-pointer hover:opacity-50 text-center text-white text-lg w-[170px] md-w-1/3 p-2 bg-[#df5b23]"
                                    }
                                    style={{ fontFamily: "gimlet-text" }}>
                                    REGISTRATI
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

const mapState = (state) => ({
    count: state.count,
});

export default connect(mapState)(Login);

import { Alert } from "@mui/material";
import FooterDown from "../Sezioni/FooterDown";
import FooterTop from "../Sezioni/FooterTop";
import TopBarIndex from "../components/TopBarIndex";
import Login from "./Login";
import { useEffect } from "react";


const LoginPage = () => {

    useEffect(() => {
        document.getElementById('main_title').innerText = 'Login - Trekking Italia'
    }, [])

    return (
        <>
            <TopBarIndex />
            <div className="w-full flex flex-col items-center pb-36">
                {/* <Alert variant="filled" className="flex items-center w-full max-w-[1500px]" severity="warning">
                    <div className="text-2xl">
                        I Soci che avevano accesso al precedente sito devono impostare la nuova password premendo “Password dimenticata?”
                    </div>
                </Alert>
                
                <Alert variant="filled" className="mt-2 flex items-center w-full max-w-[1500px]" severity="warning">
                    <div className="text-2xl">
                    I non Soci e chi non ha mai avuto alcun accesso dovranno procedere con una nuova registrazione.
                    </div>
                </Alert> */}
            </div>
            <Login />
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default LoginPage;

import React from "react";
import BackgroundTitle from "../components/BackgroundTitle";
import TopBarIndex from "../components/TopBarIndex";
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from "./FooterTop";
import FooterDown from "./FooterDown";
import Template1 from "../components/templateSezioni/Template1";
import Template2 from "../components/templateSezioni/Template2";
import Template3 from "../components/templateSezioni/Template3";
import { useParams } from "react-router-dom";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import renderHTML from "react-render-html";
import parse from 'html-react-parser';
const PagineDinamiche = ({ template = 0 }) => {
    const { paginatemplate, paginatemplatefiglia } = useParams()

    const fetcher1 = (url) => api.get(url).then((res) => res);
    const { data: page } = useSWR([`section/page/${paginatemplatefiglia ? paginatemplatefiglia : paginatemplate}`], fetcher1);


    return (
        <>
            <TopBarIndex />
            <BackgroundTitle title={page && page.data && page.data.data ? page.data.data.title : ''} img={page && page.data && page.data.data && page.data.data.image_sfondo && page.data.data.image_sfondo.img ? page.data.data.image_sfondo.img : img1} position={"center"} />
            <div
                className={
                    " bg-white relative z-40 pt-20 flex flex-col justify-center items-center"
                }>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}>
                        <div>Sezione &gt; </div>
                        <div className={"font-bold"}>Immagini dei trek</div>
                    </div>
                    {/* Content */}
                    <div
                        className={"my-6 mb-[2rem] text-4xl text-[#DF5B23]"}
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}>
                        {page && page.data && page.data.data ? page.data.data.title.toUpperCase() : ''}
                    </div>
                    <div>
                        {page && page.data && page.data.data && page.data.data.template === 'text' ? (
                            <Template1 text={parse(page.data.data.main_text ? page.data.data.main_text : '')} />
                        ) : page && page.data && page.data.data && page.data.data.template === 'text_galley' ? (
                            <Template2 text={parse(page.data.data.main_text ? page.data.data.main_text : '')}
                                gallery={page.data.data.text_gallery.map((image) => {
                                    return image.image_path
                                })} />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                {
                    page && page.data && page.data.data && page.data.data.template === 'text_img' ?
                        <Template3
                            text={parse(page.data.data.main_text ? page.data.data.main_text : '')}
                            fourImage={{
                                image: [
                                    page.data.data.thumb1.length > 0 ? page.data.data.thumb1[0].image_path : '',
                                    page.data.data.thumb2.length > 0 ? page.data.data.thumb2[0].image_path : '',
                                    page.data.data.thumb3.length > 0 ? page.data.data.thumb3[0].image_path : '',
                                    page.data.data.thumb4.length > 0 ? page.data.data.thumb4[0].image_path : '',
                                ],
                                active: page.data.data ? (page.data.data.thumb1.length > 0 ||
                                    page.data.data.thumb2.length > 0 ||
                                    page.data.data.thumb3.length > 0 ||
                                    page.data.data.thumb4.length > 0) : false
                            }}
                            singleImage={{
                                image: page.data.data.secondimg.length > 0 ? page.data.data.secondimg[0].image_path : '',
                                active: page.data.data ? page.data.data.secondimg.length > 0 : false
                            }}
                            firstText={{
                                title: page.data.data ? page.data.data.subtitle_1 : '',
                                text: page.data.data && page.data.data.second_text_1 ? parse(page.data.data.second_text_1) : '',
                                active: page.data.data ? (page.data.data.subtitle_1 !== '' ||
                                    page.data.data.second_text_1 !== '') : false
                            }}
                            secondText={{
                                title: page.data.data ? page.data.data.subtitle_2 : '',
                                text: page.data.data && page.data.data.second_text_2 ? parse(page.data.data.second_text_2) : '',
                                active: page.data.data ? (page.data.data.subtitle_2 !== '' ||
                                    page.data.data.second_text_2 !== '') : false
                            }}
                        />
                        : ""
                }
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default PagineDinamiche;

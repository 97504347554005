import React from "react";
import img from "../assets/img/Mario-Rossi-img-profilo.png";

const DiconoDiNoi = () => {
  return (
    <div className={"h-full py-8 bg-[#e2d5ca]"}>
      <h1
        style={{ fontFamily: "gimlet-text" }}
        className={"text-[#df5b23] text-3xl text-center mt-6"}
      >
        Dicono di noi...
      </h1>
      <div
        className={
          "w-full flex relative justify-center mt-36 items-center flex-col"
        }
      >
        <img
          src={img}
          alt={"img_dicono_di_noi"}
          className={"object-contain absolute -top-32 z-30"}
        />
        <div
          className={
            "text-xl text-[#796557] flex flex-col justify-center gap-12 items-center py-12 pt-36 px-6 mx-4 bg-[#f1eae5] rounded-xl"
          }
        >
          <p>
            “Trekking ben strutturati, percorsi insoliti che regalano viste
            mozzafiato, accompagnatori preparati e soprattutto la giusta
            compagnia, gli ingredienti per una domenica fuori dall’ordinario!”
          </p>
          <div className={"font-bold"}>MARIO ROSSI</div>
        </div>
      </div>
    </div>
  );
};

export default DiconoDiNoi;

import React, { useEffect, useState } from 'react'
import TopBarIndex from '../components/TopBarIndex'
import FooterDown from '../Sezioni/FooterDown'
import montagne1 from "../assets/img/footer-marrone.png";
import style from "./IscrizioniCorsiEventi.module.css"
import { useSelector } from 'react-redux';
import { api } from '../conf/apiConf';
import CardStorico from '../components/CardStorico';
import trekimg from "../assets/img/img-trek-1.png";
import LoadingComponent from '../components/LoadingComponent';
import ErrorsComponent from '../components/ErrorsComponent';
import useSWR from 'swr';
import { useLocation, useNavigate } from 'react-router-dom';
import TableIscrizione from '../components/TableIscrizione';
import DialogCustom from '../components/DialogCustom';
import InputBilo from '../components/InputBilo';
import env from '../conf/variables';

function InscrizioniCorsiEventi() {

    useEffect(() => {
        document.getElementById('main_title').innerText = 'Iscrizioni corsi e eventi - Trekking Italia'
    }, [])

    const [isEvent, setIsEvent] = useState(true)
    const [eventi, setEventi] = useState([])
    const [corsi, setCorsi] = useState([])
    const [storicoEventi, setStoricoEventi] = useState([])
    const [storicoCorsi, setStoricoCorsi] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false)
    const [errorElimina, setErrorElimina] = useState({
        open: false,
        error: ''
    })
    const [openSaldoNotEnough, setOpenSaldoNotEnough] = useState({
        error: '',
        open: false
    })
    const [openErrorWallet, setOpenErrorWallet] = useState({
        error: '',
        open: false
    })

    const [openChoosePaymentsMethod, setOpenChoosePaymentsMethod] = useState({
        data: {},
        open: false
    })
    const [openElimina, setOpenElimina] = useState({
        open: false,
        subscription_id: null,
        type: null
    })

    const count = useSelector(state => state.count)
    const navigate = useNavigate()
    const location = useLocation()


    api.setHeaders({
        Authorization: "Bearer " + count.token,
    });
    const fetcher = (url) => api.get(url).then((res) => {
        setEventi(res.data.data)
        setIsLoading(false)
        return res
    });
    const { data: events } = useSWR(['/users/' + count.profile.id + "/eventi/iscrizioni"], fetcher);

    const fetcher2 = (url) => api.get(url).then((res) => {
        setCorsi(res.data.data)
        setIsLoading(false)
        return res
    });
    const { data: courses } = useSWR(['/users/' + count.profile.id + "/corsi/iscrizioni"], fetcher2);

    const fetcher3 = (url) => api.get(url).then((res) => {
        setStoricoEventi(res.data.data)
        setIsLoading(false)
        return res
    });
    const { data: eventsHistory } = useSWR(['/users/' + count.profile.id + "/eventi/effettuati"], fetcher3);

    const fetcher4 = (url) => api.get(url).then((res) => {
        setStoricoCorsi(res.data.data)
        setIsLoading(false)
        return res
    });
    const { data: coursesHistory } = useSWR(['/users/' + count.profile.id + "/corsi/effettuati"], fetcher4);

    function deleteRegistration(onlineRegistration, type, id) {
        if (onlineRegistration) {
            setOpenElimina({
                open: true,
                subscription_id: id,
                type: type
            })
        }
    }

    function proceedRegistration(onlineRegistration, subscriberStatus, type, id) {
        if (onlineRegistration && subscriberStatus) {
            console.log('Procedi al pagamento')
        } else {
            console.log('No proceed')
        }
    }

    function payFee(saldaButton, type, id, balance, subscription_id) {
        if (saldaButton) {
            setOpenChoosePaymentsMethod({
                data: {
                    type,
                    id,
                    balance,
                    subscription_id: subscription_id
                },
                open: true
            })

        } else {
            console.log('No pay fee')
        }

    }


    if (error) {
        return <ErrorsComponent />
    }

    return (
        <>
            <TopBarIndex />

            <div className={style.container}>
                <div className={style['btn-container']}>
                    <button onClick={() => { setIsEvent(true) }} style={{ fontFamily: "gimlet-text", fontWeight: 500 }} className={style['btn-events'] + " py-3 px-3 text-2xl m-10" + (isEvent ? " bg-[#DF5B23] text-white" : " bg-transparent text-[#DF5B23]")}>EVENTI</button>
                    <button onClick={() => { setIsEvent(false) }} style={{ fontFamily: "gimlet-text", fontWeight: 500 }} className={style['btn-corsi'] + " py-3 px-3 text-2xl m-10" + (!isEvent ? " bg-[#DF5B23] text-white" : " bg-transparent text-[#DF5B23]")}>CORSI</button>
                </div>
                <div className={"text-3xl text-[#DF5B23] ml-[2.5rem] mb-[2rem]"} style={{ fontFamily: "gimlet-text", fontWeight: 500 }}>Le mie iscrizioni</div>
                {
                    !isLoading ?
                        (
                            isEvent ?
                                <>

                                    {
                                        <TableIscrizione tableData={eventi}
                                            type='eventi'
                                            isLoading={isLoading}
                                            deleteRegistration={deleteRegistration}
                                            proceedRegistration={proceedRegistration}
                                            payFee={payFee}
                                        />
                                    }
                                    <div className={"text-3xl text-[#DF5B23] ml-[2.5rem] my-[2rem]"} style={{ fontFamily: "gimlet-text", fontWeight: 500 }}>
                                        Eventi a cui ho partecipato
                                    </div>
                                    <div className='p-[2.5rem] w-full flex flex-row flex-wrap justify-start'>
                                        {

                                            storicoEventi && storicoEventi.length > 0 ?
                                                storicoEventi.map((evento, index) => {
                                                    return (
                                                        <CardStorico key={index} img={trekimg} res={evento} isEvento={true} />
                                                    )
                                                })
                                                :
                                                "Non hai ancora partecipato a nessun evento"
                                        }
                                    </div>
                                </>
                                :
                                <>

                                    {
                                        <TableIscrizione tableData={corsi}
                                            type='corsi'
                                            isLoading={isLoading}
                                            deleteRegistration={deleteRegistration}
                                            proceedRegistration={proceedRegistration}
                                            payFee={payFee}
                                        />
                                    }
                                    <div className={"text-3xl text-[#DF5B23] ml-[2.5rem] my-[2rem]"} style={{ fontFamily: "gimlet-text", fontWeight: 500 }}>
                                        Corsi a cui ho partecipato
                                    </div>
                                    <div className='p-[2.5rem] w-full flex flex-row flex-wrap justify-start'>
                                        {

                                            storicoCorsi && storicoCorsi.length > 0 ?
                                                storicoCorsi.map((corso, index) => {
                                                    return (
                                                        <CardStorico key={index} img={trekimg} res={corso} isEvento={false} />
                                                    )
                                                })
                                                :
                                                "Non hai ancora partecipato a nessun corso"
                                        }
                                    </div>
                                </>
                        )
                        : <LoadingComponent />
                }

            </div>

            <div className={"2xl:hidden flex flex-col items-center gap-2 bg-[#f0e9e3] mt-8 py-8 relative"}>
                <img
                    src={montagne1}
                    alt={"img"}
                    className={"object-contain absolute z-30 -bottom-3"}
                />
            </div>
            {/* Scelta del metodo di pagamento */}
            <DialogCustom
                open={openChoosePaymentsMethod.open}
                title={'Scegli'}
                content={
                    <>
                        <InputBilo className={'text-2xl'} title={`Importo: €${(Number(openChoosePaymentsMethod.data.balance)).toFixed(2)}`} />
                        <InputBilo className={'text-xl'} title={env.borsellinoFunction ? "Preferisci pagare con il borsellino elettronico o con Carta?" : 'Con cosa vuoi pagare?'} />
                    </>
                }
                handleClose={() => {
                    setOpenChoosePaymentsMethod(prevState => {
                        return {
                            data: prevState.data,
                            open: false
                        }
                    })

                    api.post(`users/borsellino/prepareWallet${openChoosePaymentsMethod.data.type.slice(0, 1).toUpperCase() + openChoosePaymentsMethod.data.type.slice(1, -1) + 'o'}`, {
                        user_id: count.profile.id,
                        id: openChoosePaymentsMethod.data.id
                    }).then((res) => {
                        if (res.data && res.data.errors && res.data.errors.price) {
                            setOpenSaldoNotEnough({
                                error: res.data.errors.price[0],
                                open: true
                            })
                        }
                        if (res.data && res.data.errors && res.data.errors.wallet) {
                            setOpenErrorWallet({
                                error: res.data.errors.wallet[0],
                                open: true
                            })
                        }
                        return res
                    });
                }}
                closeModal={() => {
                    setOpenChoosePaymentsMethod(prevState => {
                        return {
                            data: prevState.data,
                            open: false
                        }
                    })
                }}
                handleClose2={() => {
                    api.post(`subscriptions/requestWithoutWallet`, {
                        user_id: count.profile.id,
                        id: openChoosePaymentsMethod.data.id,
                        type: openChoosePaymentsMethod.data.type === 'eventi' ? 'event' : openChoosePaymentsMethod.data.type === 'corsi' ? 'course' : openChoosePaymentsMethod.data.type,
                        balance: openChoosePaymentsMethod.data.balance,
                        subscription_id: openChoosePaymentsMethod.data.subscription_id
                    }).then((res) => {
                        localStorage.setItem('lastpage', location.pathname)
                        if (res && res.data && res.data.data) {
                            window.open(res.data.data)
                        }
                        return res
                    });

                    setOpenChoosePaymentsMethod({
                        data: '',
                        open: false
                    })
                }}
                button={env.borsellinoFunction ? "Borsellino elettronico" : null}
                button2={'Carta'}
            />
            {/* Saldo insufficiente */}
            <DialogCustom
                open={openSaldoNotEnough.open}
                title={'Errore'}
                content={
                    <>
                        <InputBilo className={'text-xl'} title={openSaldoNotEnough.error} />
                        <InputBilo className={'text-xl'} title={'Ricarica il borsellino o scegli di pagare senza il borsellino'} />
                    </>
                }
                handleClose={() => {
                    setOpenSaldoNotEnough({
                        error: '',
                        open: false
                    }); navigate('/borsellino-elettronico')
                }}
                closeModal={() => {
                    setOpenSaldoNotEnough({
                        error: '',
                        open: false
                    })
                }}
                handleClose2={() => {
                    setOpenSaldoNotEnough({
                        error: '',
                        open: false
                    })
                    setOpenChoosePaymentsMethod(prevState => {
                        return {
                            data: prevState.data,
                            open: true
                        }
                    })
                }}
                button={'Ricarica'}
                button2={'Paga con altro metodo'}
            />
            {/* Wallet error*/}
            <DialogCustom
                open={openErrorWallet.open}
                title={'Errore'}
                content={
                    <>
                        <InputBilo className={'text-xl'} title={openErrorWallet.error} />
                        <InputBilo className={'text-xl'} title={'Attivare il borsellino.'} />
                        <InputBilo className={'text-xl'} title={'Se già attivo assicurarsi di aver abilitato l\'opzione "Abilita utilizzo per pagamenti".'} />
                        <InputBilo className={'text-xl'} title={'Altrimenti cambiare metodo di pagamento.'} />
                    </>
                }
                handleClose={() => {
                    setOpenErrorWallet({
                        error: '',
                        open: false
                    }); navigate('/borsellino-elettronico')
                }}
                closeModal={() => {
                    setOpenErrorWallet({
                        error: '',
                        open: false
                    })
                }}
                handleClose2={() => {
                    setOpenErrorWallet({
                        error: '',
                        open: false
                    })
                    setOpenChoosePaymentsMethod(prevState => {
                        return {
                            data: prevState.data,
                            open: true
                        }
                    })
                }}
                button={'Vai a Borsellino'}
                button2={'Paga con altro metodo'}
            />
            {/* ELIMINA ISCRIZIONE */}
            <DialogCustom
                title={`Elimina ${openElimina.type === 'corsi' ? 'corso' : 'evento'}`}
                fullScreen={false}
                open={openElimina.open}
                handleClose2={() => {
                    setOpenElimina({
                        open: false,
                        subscription_id: null,
                        type: null
                    })
                }}
                handleClose={() => {
                    api.post('/subscriptions/delete-subscription', {
                        subscription_id: openElimina.subscription_id,
                        user_id: count ? count.profile.id : null
                    }).then(res => {
                        if (!(res && res.data && res.data.data)) {
                            setErrorElimina({
                                open: true,
                                error: res && res.data && res.data.errors ? res.data.errors[Object.keys(res.data.errors)[0]][0] : ''
                            })
                        }
                        setOpenElimina({
                            open: false,
                            subscription_id: null,
                            type: null
                        })
                        window.location.reload()
                    })
                }}
                closeModal={
                    () => setOpenElimina({
                        open: false,
                        subscription_id: null,
                        type: null
                    })
                }
                content={<InputBilo
                    title={`Sicuro di voler eliminare questo ${openElimina.type === 'corsi' ? 'corso' : 'evento'}?`}
                />}
                button={"SI"}
                button2={"NO"}
            />
            {/* Errore Elimina */}
            <DialogCustom
                open={errorElimina.open}
                title={'Errore'}
                content={
                    <>
                        <InputBilo className={'text-xl'} title={errorElimina.error} />
                    </>
                }
                button={'Chiudi'}
                handleClose={() => {
                    (setErrorElimina(prevState => {
                        return {
                            open: false,
                            error: ''
                        }
                    }))
                }}
                closeModal={() => {
                    (setErrorElimina(prevState => {
                        return {
                            open: false,
                            error: ''
                        }
                    }))
                }}
            />
            <FooterDown />
        </>
    )
}

export default InscrizioniCorsiEventi
import { Box, Button, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputGimlet from '../components/InputGimlet'
import ItemNews from '../components/ItemNews';
import { api } from '../conf/apiConf';
import useSWR from 'swr';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
};

function NesModaleAchivio({ openArchivio, setOpenArchivio }) {

    const [archivio, setArchivio] = useState([])

    useEffect(()=>{
        if(openArchivio){
            api
            .post('/news', {
                archive: 1,
                order_by_last: 1,
                year: openArchivio ? openArchivio : 0
                // limit: 12,
            })
            .then((res) => {
                setArchivio(res.data.data)
                return res
            });
        }

    }, [openArchivio])

    return (
        <Modal
            open={openArchivio}
            onClose={() => setOpenArchivio(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className='w-full max-w-[1000px]'>
                    <InputGimlet
                        title={`Archivio ${openArchivio}`}
                        className={'text-2xl text-[#df5b23]'}
                    />
                </div>
                <div
                    className={
                        "xl:mx-56 p-2 xl:grid xl:grid-cols-2 2xl:grid-cols-3 lg:grid lg:grid-cols-2 flex flex-col gap-6 justify-items-center mt-6"
                    }
                    style={{
                        maxHeight: "45rem",
                        overflow: "auto",
                    }}
                >
                    {archivio && archivio.map((res, index) => {
                        return (
                                <ItemNews key={index} res={res} />
                        );
                    })}
                </div>
                <div
                    className={`w-full mt-6 flex flex-row items-center gap-4 justify-center`}>
                    <Button
                        variant={"contained"}
                        className={
                            "shadow-md uppercase w-full max-w-[300px] !text-2xl italic !font-normal"
                        }
                        onClick={() => {
                            setOpenArchivio(false)
                            setArchivio([])
                        }}>
                        Chiudi
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}

export default NesModaleAchivio
import React from "react";
import useSWR from "swr";
import { api } from "../conf/apiConf";
import { useSelector } from "react-redux";
import ErrorsComponent from "../components/ErrorsComponent";
import LoadingComponent from "../components/LoadingComponent";
import { useNavigate } from "react-router-dom";
import ItemNews from "../components/ItemNews";

const News = () => {
  const navigate = useNavigate();
  const { token } = useSelector((rootState) => rootState.count);
  api.setHeaders({
    Authorization: "Bearer " + token,
  });
  const fetcher = (url) =>
    api
      .post(url, {
        active: 1,
        order_by_last: 1,
        limit: 3,
      })
      .then((res) => res);
  const { data } = useSWR([`/news`], fetcher);

  if (data && !data.ok) return <ErrorsComponent />;
  if (!data) return <LoadingComponent />;
  return (
    <div className={"pb-12"}>
      <div
        style={{ fontFamily: "gimlet-text" }}
        className={
          "text-[#df5b23] text-3xl lg:mx-0 mx-6 text-center lg:mt-6 mt-12"
        }
      >
        Ultime news da Trekking Italia
      </div>
      <div
        className={
          "xl:mx-56 p-2 xl:grid xl:grid-cols-2 2xl:grid-cols-3 lg:grid lg:grid-cols-2 flex flex-col gap-6 justify-items-center mt-6"
        }
        style={{
          maxHeight: "45rem",
          overflow: "auto",
        }}
      >
        {data.data.data.map((res, index) => {
          return (
            <React.Fragment key={index}>
              <ItemNews res={res} />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default News;

import React from "react";
import { TextField, Typography } from "@mui/material";
import { withStyles } from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';

const MyTextField = withStyles({
    root: {
        "& input + fieldset, & div + input + svg + fieldset": {
            borderRadius: "50px !important",
            border: "2px solid black",
        },
    },
})(TextField);

const InputCustom = (props) => {
    return (
        <div className={"flex flex-col w-full " + props.classcontainer}>
            {props.title && (
                <div className={`${props.notleftspace === "true" ? 'pl-0' : 'pl-10'} pb-2 flex flex-row items-center`}>
                    <Typography className={"capitalize"}>
                        {props.title.includes("*") === true
                            ? props.title.slice(0, -1)
                            : props.title}
                    </Typography>
                    {props.title.includes("*") === true && (
                        <div className={"text-red-500"}>*</div>
                    )}
                </div>
            )}
            {
                props.search ?
                    <div className="flex justify">
                        <MyTextField variant="outlined" className={'w-full'} InputProps={{
                            startAdornment: <SearchIcon />,
                        }} {...props} />
                    </div>
                    :
                    <MyTextField value={props.value} variant="outlined" {...props}>
                        {props.children}
                    </MyTextField>
            }

        </div>
    );
};

export default InputCustom;

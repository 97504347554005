export const count = {
    state: {
        token: null,
        profile: null,
        password: null
    },
    reducers: {
        setPassword(state, password) {
            return { ...state, password };
        },
        setToken(state, token) {
            return { ...state, token };
        },
        setProfile(state, profile) {
            return { ...state, profile };
        },
    },
    effects: (dispatch) => ({}),
};

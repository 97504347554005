import BackgroundTitle from "../components/BackgroundTitle";
import TopBarIndex from "../components/TopBarIndex";
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { useParams } from "react-router-dom";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import InputBilo from "../components/InputBilo";
import zaino from "../assets/imgTrek/zaino.svg";
import InputGimlet from "../components/InputGimlet";
import LoadingComponent from "../components/LoadingComponent";
import { useEffect, useState } from "react";
import noImage from "../assets/img/no_image.jpg";
import clock from "../assets/imgTrek/clock.svg"
import dislivelloMax from "../assets/imgTrek/dislivelloMax.svg"
import percorso from "../assets/imgTrek/percorso.svg"
import bussola from "../assets/imgTrek/bussola.svg"
import trekker from "../assets/imgTrek/trek.svg"
import TrekList from "../Sezioni/TrekList";
import parse from 'html-react-parser';

const AccompagnatoreSingolo = () => {

    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [pageEffettuati, setPageEffettuati] = useState(1)
    const [pageAccompagnati, setPageAccompagnati] = useState(1)

    const fetcher2 = (url) => api.post(url, {
        id: id
    }).then((res) => {
        setIsLoading(false)
        return res.data.data[0]
    });
    const { data: companion } = useSWR([`/escorts-list`], fetcher2);

    const fetcher3 = (url) => api.get(url).then((res) => {
        setIsLoading(false)
        return res.data.data
    });
    const { data: trekAccompagnati } = useSWR([`/users/treks-escort/${id}`], fetcher3);

    const fetcher4 = (url) => api.get(url).then((res) => {
        setIsLoading(false)
        return res.data.data
    });
    const { data: trekEffettuati } = useSWR([`/users/treks/${id}`], fetcher4);

    const fetcher5 = (url) => api.get(url).then((res) => {
        setIsLoading(false)
        return res.data.data
    });
    const { data: trekFuturi } = useSWR([`/users/treks-escort-next/${id}`], fetcher5);

    function addAsterischi(value){
        let stringaAsterischi = ''
        for(let i = 0; i < value.length; i++){
            stringaAsterischi+= '*'
        }
        return stringaAsterischi
    }

    if (isLoading) {
        return <LoadingComponent />
    }
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={'I nostri accompagnatori'}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
                <div
                    className={"flex flex-row items-center gap-1"}
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    <div>I nostri trek  {">"}</div>
                    <div>Accompagnatori {">"}</div>
                    <div className={"font-bold"}> {companion ? companion.name + ' ' + companion.surname : ''}</div>
                </div>

            </div>
            <div className="bg-[#F0E9E3] grid grid-cols-1 sm:grid-cols-2 lg:flex justify-between items-stretch py-10 px-8">
                <div className="w-full flex flex-col items-center sm:grid grid-cols-1 lg:flex lg:flex-row justify-center lg:items-stretch">
                    <div>
                        <img className=" w-[210px] h-[210px] min-w-[210px] rounded-full border-[5px] border-[#ffffff]" src={companion && companion.profile_image ? companion.profile_image : noImage} alt="" />
                    </div>
                    <div className=" w-full text-center sm:w-auto sm:text-left flex flex-col justify-between p-5">
                        <InputBilo
                            className={'text-2xl text-[#796557] whitespace-pre-wrap'}
                            fontWeight={700}
                            title={companion ? `${companion.name} 
${companion.surname}` : ''}
                        />
                        <div>
                            <InputBilo
                                className={'text-[#7E6A5D]'}
                                fontWeight={500}
                                title={'SEZIONE'}
                            />
                            <InputBilo
                                className={'text-2xl text-[#796557]'}
                                fontWeight={700}
                                title={companion ? companion.sezione : ''}
                            />
                        </div>

                    </div>
                </div>
                <div className="w-full flex flex-col justify-between items-start lg:items-center sm:pl-5 lg:pl-0 py-5">
                    <div className="w-full text-center sm:w-auto sm:text-left">
                        <InputBilo
                            className={'text-[#7E6A5D] text-center'}
                            fontWeight={500}
                            title={'CONTATTO MAIL'}
                        />
                        <InputBilo
                            className={'text-2xl text-[#796557] text-center'}
                            fontWeight={700}
                            title={companion ? addAsterischi(companion.email) : ''}
                        />
                    </div>
                    <div className="w-full text-center sm:w-auto sm:text-left">
                        <InputBilo
                            className={'text-[#7E6A5D]'}
                            fontWeight={500}
                            title={'CONTATTO TELEFONICO'}
                        />
                        <InputBilo
                            className={'text-2xl text-[#796557]'}
                            fontWeight={700}
                            title={companion ? addAsterischi(companion['cell_phone']) : ''}
                        />
                    </div>
                </div>
                <div className="w-full flex flex-col justify-end items-start lg:items-center sm:pl-5 lg:pl-0 py-5">
                    <div className="w-full text-center sm:w-auto sm:text-left">
                        <InputBilo
                            className={'text-[#7E6A5D]'}
                            fontWeight={500}
                            title={'SOCIO DAL'}
                        />
                        <InputBilo
                            className={'text-2xl text-[#796557]'}
                            fontWeight={700}
                            title={companion && companion.socio ? companion.socio.year_membership : '-'}
                        />
                    </div>
                </div>
                <div className="w-full flex flex-col justify-end items-center sm:items-start lg:items-center py-5">
                    <div className="bg-[#ffffff] p-2 px-5 relative top-[10px]">
                        <InputBilo
                            className={'text-[#7E6A5D]'}
                            fontWeight={500}
                            title={'SOCIO ACCOMPAGNATORE DAL'}
                        />
                        <InputBilo
                            className={'text-2xl text-[#DF5B23]'}
                            fontWeight={700}
                            title={companion && companion.accompagnatore_dal}
                        />
                    </div>
                </div>
            </div>
            <div className="bg-[#E7D5C7] py-10 px-10">
                <div>
                    <InputBilo
                        className={'text-2xl text-[#796557] mb-10'}
                        fontWeight={700}
                        title={'IN TREKKING ITALIA'}
                    />
                </div>
                <div className="flex flex-col items-center md:grid md:grid-cols-2 lg:grid-cols-3 gap-10">
                    <div className="flex justify-start items-center">
                        <img src={trekker} alt="" />
                        <div className="ml-1 bg-[#F0E9E3] rounded-full w-[300px] py-2 px-10">
                            <InputBilo
                                className={'text-[#7E6A5D]'}
                                fontWeight={500}
                                title={'TREKKING EFFETTUATI'}
                            />
                            <InputBilo
                                className={'text-2xl text-[#796557]'}
                                fontWeight={700}
                                title={trekEffettuati ? trekEffettuati.length : 0}
                            />
                        </div>
                    </div>
                    <div className="flex justify-start items-center">
                        <img src={bussola} alt="" />
                        <div className="ml-1 bg-[#F0E9E3] rounded-full w-[300px] py-2 px-10">
                            <InputBilo
                                className={'text-[#7E6A5D]'}
                                fontWeight={500}
                                title={'TREK ACCOMPAGNATI'}
                            />
                            <InputBilo
                                className={'text-2xl text-[#796557]'}
                                fontWeight={700}
                                title={trekAccompagnati ? trekAccompagnati.length : 0}
                            />
                        </div>
                    </div>
                    <div className="flex justify-start items-center">
                        <img src={clock} alt="" />
                        <div className="ml-1 bg-[#F0E9E3] rounded-full w-[300px] py-2 px-10">
                            <InputBilo
                                className={'text-[#7E6A5D]'}
                                fontWeight={500}
                                title={'GIORNI TREK'}
                            />
                            <InputBilo
                                className={'text-2xl text-[#796557]'}
                                fontWeight={700}
                                title={companion ? companion.ggTrek : 0}
                            />
                        </div>
                    </div>
                    {/* <div className="flex justify-start items-center">
                        <img src={percorso} alt="" />
                        <div className="ml-1 bg-[#F0E9E3] rounded-full w-[300px] py-2 px-10">
                            <InputBilo
                                className={'text-[#7E6A5D]'}
                                fontWeight={500}
                                title={'KM PERCORSI'}
                            />
                            <InputBilo
                                className={'text-2xl text-[#796557]'}
                                fontWeight={700}
                                title={companion ? companion.total_route_length_km : 0}
                            />
                        </div>
                    </div>
                    <div className="flex justify-start items-center">
                        <img src={dislivelloMax} alt="" />
                        <div className="ml-1 bg-[#F0E9E3] rounded-full w-[300px] py-2 px-10">
                            <InputBilo
                                className={'text-[#7E6A5D]'}
                                fontWeight={500}
                                title={'MT DI DISLIVELLO'}
                            />
                            <InputBilo
                                className={'text-2xl text-[#796557]'}
                                fontWeight={700}
                                title={companion ? companion['difference_climb'] : 0}
                            />
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="bg-[#ffffff] py-10 px-10">
                {
                    companion && companion['escort_biography'] &&
                    <>
                        <InputGimlet
                            className={'text-2xl text-[#DF5B23] mb-10'}
                            fontWeight={700}
                            title={'BIOGRAFIA'}
                            fontStyle='italic'
                        />
                        <InputBilo
                            className={'text-2xl text-[#796557] mb-10'}
                            fontWeight={700}
                            title={companion && companion['escort_biography'] && parse(companion['escort_biography'])}
                        />
                    </>
                }

                {
                    companion && companion['escort_curriculum'] &&
                    <>
                        <InputGimlet
                            className={'text-2xl text-[#DF5B23] mb-10'}
                            fontWeight={700}
                            title={'CURRICULUM'}
                            fontStyle='italic'
                        />
                        <InputBilo
                            className={'text-2xl text-[#796557] mb-10'}
                            fontWeight={700}
                            title={companion && companion['escort_curriculum'] && parse(companion['escort_curriculum'])}
                        />
                    </>
                }
                <InputGimlet
                    className={'text-2xl text-[#DF5B23] mb-10'}
                    fontWeight={700}
                    title={'TREK FUTURI / ' + (trekFuturi ? trekFuturi.length : 0)}
                    fontStyle='italic'
                />
                {
                    trekFuturi && trekFuturi.length > 0 ?
                        <TrekList shouldScroll={false} data={trekFuturi.slice(((pageAccompagnati - 1) * 4), (pageAccompagnati * 4))} direct={true} page={pageAccompagnati} setPage={setPageAccompagnati} last_page={Math.ceil(trekFuturi.length / 4)} />
                        : <InputBilo
                            className={'text-2xl text-[#796557] mb-10'}
                            fontWeight={700}
                            title={'Nessun trek in programma'}
                        />
                }
                <InputGimlet
                    className={'text-2xl text-[#DF5B23] mb-10'}
                    fontWeight={700}
                    title={'TREK ACCOMPAGNATI / ' + (trekAccompagnati ? trekAccompagnati.length : 0)}
                    fontStyle='italic'
                />
                {
                    trekAccompagnati && trekAccompagnati.length > 0 ?
                        <TrekList shouldScroll={false} data={trekAccompagnati.slice(((pageAccompagnati - 1) * 4), (pageAccompagnati * 4))} direct={true} page={pageAccompagnati} setPage={setPageAccompagnati} last_page={Math.ceil(trekAccompagnati.length / 4)} />
                        : <InputBilo
                            className={'text-2xl text-[#796557] mb-10'}
                            fontWeight={700}
                            title={'Nessun trek da accompagnatore'}
                        />
                }
                <InputGimlet
                    className={'text-2xl text-[#DF5B23] mb-10'}
                    fontWeight={700}
                    title={'TREK EFFETTUATI / ' + (trekEffettuati ? trekEffettuati.length : 0)}
                    fontStyle='italic'
                />
                {
                    trekEffettuati && trekEffettuati.length > 0 ?

                        <TrekList shouldScroll={false} data={trekEffettuati.slice(((pageEffettuati - 1) * 4), (pageEffettuati * 4))} direct={true} page={pageEffettuati} setPage={setPageEffettuati} last_page={Math.ceil(trekEffettuati.length / 4)} />

                        : <InputBilo
                            className={'text-2xl text-[#796557] mb-10'}
                            fontWeight={700}
                            title={'Nessun trek effettuato'}
                        />
                }
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default AccompagnatoreSingolo;

import React from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import InputBilo from '../components/InputBilo'

const AreaGiovani = () => {

    let page = {
        title: "TITOLO",
        paragraph: [
            "La Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.",
            "La Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.",
            "La Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. La Lorem ipsum dolor sit amet, consectetur adipisicinaute ilaborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
        ]
    }

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Area giovani NEXT G"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Associazione &gt; </div>
                        <div>Progetti &gt; </div>
                        <div className={"font-bold"}>Area giovani</div>
                    </div>
                    {/* Content */}
                    <div
                        className={"my-6 mb-[2rem] text-4xl text-[#DF5B23]"}
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}
                    >
                        AREA GIOVANI
                    </div>
                    <div className="mb-5 text-xl" style={{ fontFamily: "Bilo" }}>
                        <InputBilo
                            title={'Il trekking può essere un\'esperienza meravigliosa per i giovani che vogliono godersi la natura e mantenere uno stile di vita attivo.'}
                        />
                        <br />
                        <div className="">
                            <InputBilo
                                title={'Trekking Italia ha un progetto dedicato proprio ai giovani: '}
                                fontWeight={700}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'si chiama '}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'NextG '}
                                fontWeight={700}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'(Next Generation) e ha l’obiettivo di '}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'favorire la loro presenza, partecipazione e rappresentanza come soci nella nostra Associazione. '}
                                fontWeight={700}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'Ne è espressione l’'}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'Area Giovani '}
                                fontWeight={700}
                                className={'inline'}
                            />
                            <InputBilo
                                title={', nata il 6 novembre 2022 a seguito del seminario nazionale di Loppiano (FI) “Cambia-mente: accompagniamoci nel futuro” dove si è manifestata l\'esigenza di formare un’Area specifica che organizzi escursioni e attività dedicate ai più giovani di tutte le Sezioni. '}
                                className={'inline'}
                            />
                            <br />
                            <br />
                            <InputBilo
                                title={'Così sta accadendo dal gennaio 2023: vengono organizzati '}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'escursioni '}
                                fontWeight={700}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'ed '}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'eventi '}
                                fontWeight={700}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'dedicati ai giovani e proposte iniziative di vario tipo per favorirne la presenza e la rappresentanza. Si tratta di occasioni per costruire nuove amicizie, affrontare avventure all\'aria aperta in modo sicuro e divertente e creare un senso di comunità in una atmosfera positiva e amichevole.'}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'Le destinazioni e la tipologia dei trek sono le più varie e qualsiasi escursionista - dal principiante all’esperto - può trovare quello più adatto.'}
                                className={''}
                            />
                            <br />
                            <InputBilo
                                title={'Tutte le proposte dedicate ai giovani sono presenti nel nostro '}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'catalogo '}
                                fontWeight={700}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'con l’'}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'etichetta NextG '}
                                fontWeight={700}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'sul sito dell\'Associazione ma puoi seguire tutti gli aggiornamenti relativi al progetto NextG e all’Area Giovani anche tramite i canali social dedicati: Facebook, Instagram, Community Whatsapp.'}
                                className={'inline'}
                            />
                            <br />
                            <br />
                            <InputBilo
                                title={'Per informazioni scrivere a '}
                                className={'inline'}
                            />
                            <a
                                href={`mailto:giovani@trekkingitalia.org`}
                                style={{
                                    fontFamily: "bilo",
                                    fontStyle: "normal",
                                }}
                                className={"text-xl mb-5"}>
                                giovani@trekkingitalia.org
                            </a>
                            

                        </div>

                    </div>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default AreaGiovani;
import { create } from "apisauce";
import env from "./variables";

export const api = create({
  baseURL: env.url_api,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

import React from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/vitaAssociativa.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";

const VitaAssociativa = () => {
  return (
    <>
      <TopBarIndex />
      <BackgroundTitle title={"Vita associativa"} img={img1} />
      <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
        <div
          className={"flex flex-row items-center gap-1"}
          style={{
            fontFamily: "bilo",
            fontWeight: 500,
            fontStyle: "normal",
          }}
        >
          <div>Home ></div>
          <div>Associazione ></div>
          <div className={"font-bold"}> Vita associativa</div>
        </div>
        <div
          className={"my-6 text-3xl text-[#DF5B23]"}
          style={{ fontFamily: "gimlet-text" }}
        >
          LA VITA DELL'ASSOCIAZIONE
        </div>
        <div
          className={"my-4 text-xl text-[#796557]"}
          style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
        >
          <div className={"my-4"}>
            La vita dell’Associazione si esprime e si sviluppa, come prevedono
            lo Statuto e il Regolamento, a livello nazionale e locale. A livello
            nazionale principalmente attraverso l’Assemblea dei Delegati, il
            Presidente e il Vice Presidente, il Consiglio Esecutivo Nazionale.
          </div>
          <div className={"my-4"}>
            Questi organi sono affiancati da “Aree di Attività” per
            l’elaborazione di analisi e proposte in tema di organizzazione e
            formazione, strategie di sviluppo, amministrazione e controllo,
            comunicazione, attività caratteristiche (i trek) e Trek Scuola.
          </div>
          <div className={"my-4"}>
            Trekking Italia vive grazie al contributo e all’attività volontaria
            e gratuita dei suoi soci. I Soci Accompagnatori organizzano e
            accompagnano i Trek Giornalieri, i Week-end, i Grandi Trek. Si
            occupano di tutti gli aspetti organizzativi e logistici del trek:
            trasporti, alloggi, ristorazione, gestione delle eventuali guide
            locali.
          </div>
          <div className={"my-4"}>
            I Soci Accompagnatori individuano i percorsi, li “sperimentano” ne
            valutano la difficoltà secondo criteri definiti. Si fanno carico
            della gestione del gruppo, seguono i partecipanti in ogni
            situazione. Nelle varie sezioni i Soci Volontari tengono il rapporto
            quotidiano con i soci, seguono le problematiche organizzative e
            contabili, gestiscono le biblioteche di sezione, si occupano delle
            relazioni con le scuole.
          </div>
          <div className={"my-4"}>
            Trekking Italia promuove continuamente a tutti i livelli il
            coinvolgimento attivo dei soci, nella consapevolezza che essi
            rappresentano la ricchezza prima dell’Associazione. La Sezione
            rappresenta l’articolazione territoriale dell’Associazione in
            termini di governo e auto-governo, eleggendo il proprio direttivo e
            coordinatore ed i propri rappresentanti nell’assemblea nazionale.
          </div>
          <div className={"my-4"}>
            È anche l’ambito di competenza per la programmazione e
            organizzazione dei trek giornalieri e week-end, nonché dei grandi
            trek in Italia e all’estero, che vengono poi calendarizzati e
            coordinati a livello nazionale.
          </div>
          <div>
            Trekking Italia è attualmente presente sul territorio nazionale con
            sette sezioni regionali e, nel riaffermare il carattere unitario
            dell’Associazione, intende rafforzare la propria presenza sul
            territorio, promuovendo l’apertura di nuove sedi o anche di semplici
            gruppi attivi, sia nell’ambito delleregioni di attuale insediamento,
            sia nelle regioni del centro e del sud ancora “scoperte”.
          </div>
        </div>
      </div>
      <FooterTop />
      <FooterDown />
    </>
  );
};

export default VitaAssociativa;

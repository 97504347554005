import TopBarIndex from '../components/TopBarIndex'
import FooterDown from '../Sezioni/FooterDown'
import montagne1 from "../assets/img/footer-marrone.png";
import style from "./IscrizioniTrek.module.css"
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../conf/apiConf';
import ErrorsComponent from '../components/ErrorsComponent';
import TableIscrizione from '../components/TableIscrizione';
import { useLocation, useNavigate } from 'react-router-dom';
import DialogCustom from '../components/DialogCustom';
import InputBilo from '../components/InputBilo';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import env from '../conf/variables';

function InscrizioniTrek() {

    useEffect(() => {
        document.getElementById('main_title').innerText = 'Iscrizioni ai trek - Trekking Italia'
    }, [])

    const [openPagamentoOnline, setOpenPagamentoOnline] = useState(false)
    const [trekIscritto, setTrekIscritto] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [openPrenotati, setOpenPrenotati] = useState(false)
    const [openAnticipoOrSaldo, setOpenAnticipoOrSaldo] = useState({
        data: {
            advance_payment_methods: '',
            balance_payment_methods: '',
        },
        open: false
    }
    )
    const [isAnticipo, setIsAnticipo] = useState(false)
    const [errorElimina, setErrorElimina] = useState({
        open: false,
        error: ''
    })
    const [openSaldoNotEnough, setOpenSaldoNotEnough] = useState({
        error: '',
        open: false
    })
    const [openErrorWallet, setOpenErrorWallet] = useState({
        error: '',
        open: false
    })

    const [openChoosePaymentsMethod, setOpenChoosePaymentsMethod] = useState({
        data: {},
        open: false
    })
    const [openElimina, setOpenElimina] = useState({
        open: false,
        subscription_id: null,
        type: null
    })
    const [openAlertElimnia, setOpenAlertElimnia] = useState(false)
    const [success, setSuccess] = useState(
        {
            open: false,
            message: ''
        }
    )

    const navigate = useNavigate()
    const location = useLocation()

    const count = useSelector(state => state.count)
    const fetchDataUser = useCallback(() => {

        setIsLoading(true)


        api.setHeaders({
            Authorization: "Bearer " + count.token,
            Accept: 'application/json'
        });
        api.get('/users/' + count.profile.id + "/trek/iscrizioni").then((res) => {
            if (!res.ok) {
                throw new Error()
            }
            setTrekIscritto(res.data.data)
            setIsLoading(false)
        })
            .catch((errore) => {
                console.log("error: ", errore)
                setError(true)
            })
    }, [count])

    useEffect(() => {
        fetchDataUser()
    }, [fetchDataUser])

    function deleteRegistration(onlineRegistration, type = 'trek', id) {
        if (onlineRegistration) {
            // setOpenElimina({
            //     open: true,
            //     subscription_id: id,
            //     type: 'trek'
            // })
            setOpenAlertElimnia(true)
        }
    }

    function proceedRegistration(onlineRegistration, subscriberStatus, type, id, balance, subscription_id, advance_payment_methods, balance_payment_methods) {
        if (onlineRegistration && subscriberStatus) {
            setOpenChoosePaymentsMethod({
                data: {
                    type,
                    id,
                    balance,
                    subscription_id: subscription_id,
                },
                open: false
            })
            setOpenAnticipoOrSaldo({
                data: {
                    advance_payment_methods: advance_payment_methods,
                    balance_payment_methods: balance_payment_methods,
                },
                open: true
            })
        } else {
        }
    }

    function payFee(saldaButton, type, id, balance, subscription_id, advance_payment_methods, balance_payment_methods) {
        if (saldaButton) {
            setOpenChoosePaymentsMethod({
                data: {
                    type,
                    id,
                    balance,
                    subscription_id: subscription_id,
                },
                open: false
            })
            setOpenAnticipoOrSaldo({
                data: {
                    advance_payment_methods: advance_payment_methods,
                    balance_payment_methods: balance_payment_methods,
                },
                open: true
            })
        } else {
            console.log('No pay fee')
        }

    }

    function handleClickPagamento(payment_method, subscription_id, id, importo) {
        api.post('subscriptions/add-subscription-without-payment', {
            user_id: count.profile.id,
            id: id,
            type: 'trek',
            userIDs: [],
            balance: importo,
            payment_method: payment_method,
            subscription_id: subscription_id
        }).then(res => {
            if (res.data.errors) {
                setError({
                    open: true,
                    error: res.data.errors.user[0]
                })
            } else {
                setSuccess({
                    open: true,
                    message: 'La informiamo che ha effettuato correttamente l\'iscrizione'
                })
            }

            return res
        })
    }

    if (error) {
        return <ErrorsComponent />
    }


    return (
        <>
            <TopBarIndex />

            <div className={style.container}>
                <div className={"text-3xl text-[#DF5B23] ml-[2.5rem] mb-[2rem]"} style={{ fontFamily: "gimlet-text", fontWeight: 500 }}>Le mie iscrizioni ai trek</div>

                <TableIscrizione tableData={trekIscritto}
                    type='trek'
                    isLoading={isLoading}
                    deleteRegistration={deleteRegistration}
                    proceedRegistration={proceedRegistration}
                    payFee={payFee}
                />

            </div>

            <div className={"2xl:hidden flex flex-col items-center gap-2 bg-[#f0e9e3] mt-8 py-8 relative"}>
                <img
                    src={montagne1}
                    alt={"img"}
                    className={"object-contain absolute z-30 -bottom-3"}
                />
            </div>
            <FooterDown />
            {/* SCEGLI SE PAGARE L'ANTICIPO O IL SALDO */}
            <DialogCustom
                open={openAnticipoOrSaldo.open}
                title={'Scegli'}
                content={
                    <>
                        <InputBilo className={'text-xl'} title={'Preferisci pagare l\'anticipo o l\'Importo intero?'} />
                    </>
                }
                handleClose={() => {

                    // setImporto(trek && trek.data && trekIscritto? && trekIscritto? && trekIscritto?.advance_fee)
                    setIsAnticipo(true)
                    setOpenChoosePaymentsMethod((prev) => {
                        return {
                            ...prev,
                            open: true
                        }
                    })
                    setOpenAnticipoOrSaldo((prev) => {
                        return {
                            ...prev,
                            open: false
                        }
                    })
                }}
                closeModal={() => {
                    setOpenAnticipoOrSaldo((prev) => {
                        return {
                            ...prev,
                            open: false
                        }
                    })
                }}
                handleClose2={() => {
                    // setImporto(trek && trek.data && trekIscritto? && trekIscritto? && trekIscritto?.quote_trek)
                    setIsAnticipo(false)
                    setOpenChoosePaymentsMethod((prev) => {
                        return {
                            ...prev,
                            open: true
                        }
                    })
                    setOpenAnticipoOrSaldo((prev) => {
                        return {
                            ...prev,
                            open: false
                        }
                    })
                }}
                button={'Anticipo'}
                button2={'Importo intero'}
                buttonDisabled={!(
                    openAnticipoOrSaldo.data.advance_payment_methods.includes('Online') && openChoosePaymentsMethod.data.balance && openChoosePaymentsMethod.data.balance.anticipo_da_pagare)}
                button2Disabled={!(
                    openAnticipoOrSaldo.data.balance_payment_methods.includes('Online') && openChoosePaymentsMethod.data.balance && openChoosePaymentsMethod.data.balance.saldo_da_pagare)}

            />
            {/* Scelta del metodo di pagamento corsi/eventi o pagamento online trek */}
            {
                (location.pathname !== '/iscrizioni-trek' || openPagamentoOnline) &&
                <DialogCustom
                    open={openChoosePaymentsMethod.open}
                    title={'Scegli'}
                    content={
                        <>
                            <InputBilo className={'text-2xl'} title={`Importo: €${(Number(openChoosePaymentsMethod.data && openChoosePaymentsMethod.data.balance ? (isAnticipo ? openChoosePaymentsMethod.data.balance.anticipo_da_pagare : openChoosePaymentsMethod.data.balance.saldo_da_pagare) : '')).toFixed(2)}`} />
                            <InputBilo className={'text-xl'} title={env.borsellinoFunction ? "Preferisci pagare con il borsellino elettronico o con Carta?" : 'Con cosa vuoi pagare?'} />
                        </>
                    }
                    handleClose={() => {
                        setOpenChoosePaymentsMethod(prevState => {
                            return {
                                data: prevState.data,
                                open: false
                            }
                        })

                        api.post(`subscriptions/prepareWalletSubscription`, {
                            user_id: count.profile.id,
                            id: openChoosePaymentsMethod.data.id,
                            type: 'trek',
                            balance: isAnticipo ? openChoosePaymentsMethod.data.balance.anticipo_da_pagare : openChoosePaymentsMethod.data.balance.saldo_da_pagare,
                            only_advance: isAnticipo,
                            subscription_id: openChoosePaymentsMethod.data.subscription_id,
                        }).then((res) => {
                            if (res.data && !res.data.errors) {
                                window.location.reload()
                            }
                            if (res.data && res.data.errors && res.data.errors.price) {
                                setOpenSaldoNotEnough({
                                    error: res.data.errors.price[0],
                                    open: true
                                })
                            }
                            if (res.data && res.data.errors && res.data.errors.wallet) {
                                setOpenErrorWallet({
                                    error: res.data.errors.wallet[0],
                                    open: true
                                })
                            }
                            return res
                        });
                        setOpenPagamentoOnline(false)
                    }}
                    closeModal={() => {
                        setOpenChoosePaymentsMethod(prevState => {
                            return {
                                data: prevState.data,
                                open: false
                            }
                        })
                        setOpenPagamentoOnline(false)
                    }}
                    handleClose2={() => {
                        // console.log(openChoosePaymentsMethod.data.balance.id)
                        api.post(`subscriptions/requestWithoutWallet`, {
                            user_id: count.profile.id,
                            id: openChoosePaymentsMethod.data.id,
                            type: openChoosePaymentsMethod.data.type === 'eventi' ? 'event' : openChoosePaymentsMethod.data.type === 'corsi' ? 'course' : openChoosePaymentsMethod.data.type,
                            balance: isAnticipo ? openChoosePaymentsMethod.data.balance.anticipo_da_pagare : openChoosePaymentsMethod.data.balance.saldo_da_pagare,
                            subscription_id: openChoosePaymentsMethod.data.subscription_id,
                            only_advance: isAnticipo
                        }).then((res) => {
                            localStorage.setItem('lastpage', location.pathname)
                            if (res && res.data && res.data.data) {
                                window.open(res.data.data)
                            }
                            return res
                        });

                        setOpenChoosePaymentsMethod({
                            data: '',
                            open: false
                        })
                        setOpenPagamentoOnline(false)
                    }}
                    button={env.borsellinoFunction ? "Borsellino elettronico" : null}
                    button2={'Carta'}
                />
            }

            {/* Scelta del metodo di pagamento trek */}
            {
                (location.pathname === '/iscrizioni-trek' && !openPagamentoOnline) &&
                <Dialog
                    open={openChoosePaymentsMethod.open}
                    onClose={() => {
                        setOpenChoosePaymentsMethod(prevState => {
                            return {
                                data: prevState.data,
                                open: false
                            }
                        })
                    }}
                    maxWidth={"lg"}
                    fullWidth={true}>
                    <>
                        <DialogTitle>
                            Come desideri pagare?
                        </DialogTitle>
                        <DialogContent>
                            {
                                trekIscritto?.filter(trek => trek?.id === openChoosePaymentsMethod?.data?.id).map((trek, index) => {
                                    return <React.Fragment key={index}>
                                        {
                                            trek?.type_trek !== 'Giornaliero' ?
                                            <button
                                                className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                style={{
                                                    fontFamily: "gimlet-text",
                                                    fontWeight: 500,
                                                    fontStyle: "italic",
                                                }}
                                                onClick={() => {
                                                    setOpenPagamentoOnline(true)
                                                }}>
                                                Pagamento online
                                            </button>
                                            :
                                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  items-stretch gap-2">
                                                {
                                                    trek?.quota_management &&
                                                    (
                                                        (trek?.quota_management === 'advance_balance' && trek?.advance_payment_methods && trek?.advance_payment_methods.includes('Online'))
                                                        ||
                                                        (trek?.quota_management === 'balance' && trek?.balance_payment_methods && trek?.balance_payment_methods.includes('Online'))
                                                    ) &&
                                                    <button
                                                        className={"w-full mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] hover:opacity-80"}
                                                        style={{
                                                            fontFamily: "gimlet-text",
                                                            fontWeight: 500,
                                                            fontStyle: "italic",
                                                        }}
                                                        onClick={() => {
                                                            setOpenPagamentoOnline(true)
                                                        }}>
                                                        Pagamento online
                                                    </button>
                                                }
                                                {
                                                    trek?.quota_management &&
                                                    (
                                                        (trek?.quota_management === 'advance_balance' && trek?.advance_payment_methods && trek?.advance_payment_methods.includes('Bonifico'))
                                                        ||
                                                        (trek?.quota_management === 'balance' && trek?.balance_payment_methods && trek?.balance_payment_methods.includes('Bonifico'))
                                                    ) &&
                                                    <button
                                                        className={"w-full mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] hover:opacity-80"}
                                                        style={{
                                                            fontFamily: "gimlet-text",
                                                            fontWeight: 500,
                                                            fontStyle: "italic",
                                                        }}
                                                        onClick={() => {
                                                            handleClickPagamento('Bonifico', openChoosePaymentsMethod?.data?.subscription_id, openChoosePaymentsMethod?.data?.id, openChoosePaymentsMethod?.data?.balance.saldo_da_pagare)
                                                        }}>
                                                        Bonifico
                                                    </button>
                                                }
                                                {
                                                    trek?.quota_management &&
                                                    (
                                                        (trek?.quota_management === 'advance_balance' && trek?.advance_payment_methods && trek?.advance_payment_methods.includes('Pagamento in sede'))
                                                        ||
                                                        (trek?.quota_management === 'balance' && trek?.balance_payment_methods && trek?.balance_payment_methods.includes('Pagamento in sede'))
                                                    ) &&
                                                    <button
                                                        className={"w-full mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] hover:opacity-80"}
                                                        style={{
                                                            fontFamily: "gimlet-text",
                                                            fontWeight: 500,
                                                            fontStyle: "italic",
                                                        }}
                                                        onClick={() => {
                                                            handleClickPagamento('Pagamento in sede', openChoosePaymentsMethod?.data?.subscription_id, openChoosePaymentsMethod?.data?.id, openChoosePaymentsMethod?.data?.balance.saldo_da_pagare)
                                                        }}>
                                                        Pagamento in sede
                                                    </button>
                                                }
                                                {
                                                    trek?.quota_management &&
                                                    (
                                                        (trek?.quota_management === 'advance_balance' && trek?.advance_payment_methods && trek?.advance_payment_methods.includes('Pagamento alla partenza'))
                                                        ||
                                                        (trek?.quota_management === 'balance' && trek?.balance_payment_methods && trek?.balance_payment_methods.includes('Pagamento alla partenza'))
                                                    ) &&
                                                    <button
                                                        className={"w-full mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] hover:opacity-80"}
                                                        style={{
                                                            fontFamily: "gimlet-text",
                                                            fontWeight: 500,
                                                            fontStyle: "italic",
                                                        }}
                                                        onClick={() => {
                                                            handleClickPagamento('Pagamento alla partenza', openChoosePaymentsMethod?.data?.subscription_id, openChoosePaymentsMethod?.data?.id, openChoosePaymentsMethod?.data?.balance.saldo_da_pagare)
                                                        }}>
                                                        Pagamento alla partenza
                                                    </button>
                                                }
                                            </div>
                                        }
                                    </React.Fragment>
                                })
                            }
                        </DialogContent>
                    </>

                </Dialog >
            }



            {/* Saldo insufficiente */}
            <DialogCustom
                open={openSaldoNotEnough.open}
                title={'Errore'}
                content={
                    <>
                        <InputBilo className={'text-xl'} title={openSaldoNotEnough.error} />
                        <InputBilo className={'text-xl'} title={'Ricarica il borsellino o scegli di pagare senza il borsellino'} />
                    </>
                }
                handleClose={() => {
                    setOpenSaldoNotEnough({
                        error: '',
                        open: false
                    }); navigate('/borsellino-elettronico')
                }}
                closeModal={() => {
                    setOpenSaldoNotEnough({
                        error: '',
                        open: false
                    })
                }}
                handleClose2={() => {
                    setOpenSaldoNotEnough({
                        error: '',
                        open: false
                    })
                    setOpenChoosePaymentsMethod(prevState => {
                        return {
                            data: prevState.data,
                            open: true
                        }
                    })
                }}
                button={'Ricarica'}
                button2={'Paga con altro metodo'}
            />
            {/* Wallet error*/}
            <DialogCustom
                open={openErrorWallet.open}
                title={'Errore'}
                content={
                    <>
                        <InputBilo className={'text-xl'} title={openErrorWallet.error} />
                        <InputBilo className={'text-xl'} title={'Attivare il borsellino.'} />
                        <InputBilo className={'text-xl'} title={'Se già attivo assicurarsi di aver abilitato l\'opzione "Abilita utilizzo per pagamenti".'} />
                        <InputBilo className={'text-xl'} title={'Altrimenti cambiare metodo di pagamento.'} />
                    </>
                }
                handleClose={() => {
                    setOpenErrorWallet({
                        error: '',
                        open: false
                    }); navigate('/borsellino-elettronico')
                }}
                closeModal={() => {
                    setOpenErrorWallet({
                        error: '',
                        open: false
                    })
                }}
                handleClose2={() => {
                    setOpenErrorWallet({
                        error: '',
                        open: false
                    })
                    setOpenChoosePaymentsMethod(prevState => {
                        return {
                            data: prevState.data,
                            open: true
                        }
                    })
                }}
                button={'Vai a Borsellino'}
                button2={'Paga con altro metodo'}
            />
            {/* ELIMINA ISCRIZIONE */}
            <DialogCustom
                title={`Elimina trek`}
                fullScreen={false}
                open={openElimina.open}
                handleClose2={() => {
                    setOpenElimina({
                        open: false,
                        subscription_id: null,
                        type: null
                    })
                }}
                handleClose={() => {
                    api.post('/subscriptions/delete-subscription', {
                        subscription_id: openElimina.subscription_id,
                        user_id: count ? count.profile.id : null
                    }).then(res => {
                        if (!(res && res.data && res.data.data)) {
                            setErrorElimina({
                                open: true,
                                error: res && res.data && res.data.errors ? res.data.errors[Object.keys(res.data.errors)[0]][0] : ''
                            })
                        }
                        setOpenElimina({
                            open: false,
                            subscription_id: null,
                            type: null
                        })
                        window.location.reload()
                    })
                }}
                closeModal={
                    () => setOpenElimina({
                        open: false,
                        subscription_id: null,
                        type: null
                    })
                }
                content={<InputBilo
                    title={`Sicuro di voler eliminare questo trek?`}
                />}
                button={"SI"}
                button2={"NO"}
            />
            {/* ALERT ELIMINA */}
            <DialogCustom
                title={`Elimina trek`}
                fullScreen={false}
                open={openAlertElimnia}
                handleClose={() => {
                    setOpenAlertElimnia(false)
                }}
                closeModal={
                    () => setOpenElimina({
                        open: false,
                        subscription_id: null,
                        type: null
                    })}
                content={<InputBilo
                    title={`Contatta la Sezione organizzatrice per annullare la tua iscrizione`}
                />}
                button={"CHIUDI"}
            />
            {/* Errore Elimina */}
            <DialogCustom
                open={errorElimina.open}
                title={'Errore'}
                content={
                    <>
                        <InputBilo className={'text-xl'} title={errorElimina.error} />
                    </>
                }
                button={'Chiudi'}
                handleClose={() => {
                    (setErrorElimina(prevState => {
                        return {
                            open: false,
                            error: ''
                        }
                    }))
                }}
                closeModal={() => {
                    (setErrorElimina(prevState => {
                        return {
                            open: false,
                            error: ''
                        }
                    }))
                }}
            />
            {/* PRENOTATI */}
            <DialogCustom
                open={openPrenotati}
                title={'Prenotati'}
                content={
                    <>
                        <InputBilo className={'text-xl'} title={
                            'Sicuro di volerti iscrivere a questo trek?'
                        } />
                    </>
                }
                button={'No'}
                button2={'Si'}
                handleClose={() => {
                    setOpenPrenotati(false)
                }}
                closeModal={() => {
                    setOpenPrenotati(false)
                }}
                handleClose2={() => {
                    api.post('subscriptions/add-reservation', {
                        user_id: count.profile.id,
                        id: trekIscritto ? trekIscritto.subscription_id : '',
                        type: 'trek',
                        balance: trekIscritto ? `${trekIscritto.quote_request.toFixed(2)}` : '',
                    }).then(res => {
                        if (res.data.errors) {
                            setError({
                                open: true,
                                error: res.data.errors.user[0]
                            })
                        } else {
                            setSuccess({
                                open: true,
                                message: 'La informiamo che la sua prenotazione è andata a buon fine'
                            })
                        }

                        return res
                    })


                    setOpenPrenotati(false)
                }}
            />
            {/* Success avvenuta prenotazione */}
            <Dialog
                open={success.open}
                onClose={() => {
                    setSuccess({
                        open: false,
                        message: ''
                    });
                }}
                maxWidth={"lg"}
                fullWidth={true}>
                <DialogTitle>
                    Prenotazione effettuata.
                </DialogTitle>
                <DialogContent>
                    <InputBilo
                        className={'text-2xl'}
                        title={success.message}
                    />
                    <button
                        onClick={() => {
                            setSuccess({
                                open: false,
                                message: ''
                            })
                        }}
                        className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}>
                        Chiudi
                    </button>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default InscrizioniTrek
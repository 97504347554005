import React from "react";
import Trek from "../components/Trek";
import trek from "../assets/img/img-trek-1.png";
import trek2 from "../assets/img/img-trek-2.png";
import PaginationCustom from "../components/PaginationCustom";
import InputBilo from "../components/InputBilo";

const TrekList = ({ data = null, direct = false, setPage, page = 1, last_page = 1, shouldScroll = true }) => {

    if ((direct === false && data.data && data.data.data && data.data.data.length === 0) || (direct === true && data && data.length === 0)) {
        return <InputBilo className={'text-2xl text-center mt-2'} title={'Nessun Trek'} />
    }

    return (
        <>
            <div
                className={
                    "grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2  grid-cols-1  mt-12 gap-12 mx-10"
                }>
                {direct === false && data.data && data.data.data ? data.data.data.map((res, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Trek
                                res={res}
                                img={index % 2 === 1 ? trek : trek2}
                                index={index}
                            />
                        </React.Fragment>
                    );
                }) : ''}
                {direct === true && data ? data.map((res, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Trek
                                res={res}
                                img={index % 2 === 1 ? trek : trek2}
                                index={index}
                            />
                        </React.Fragment>
                    );
                }) : ''}
            </div>
            <div
                className={
                    "flex flex-row w-full gap-4 justify-center items-center"
                }>
                <PaginationCustom page={page} onChange={
                    (e) => {
                        setPage(
                            Number(e.target.textContent) ?
                                Number(e.target.textContent)
                                : (
                                    e.target && e.target.childNodes && e.target.childNodes[0]?
                                        (
                                            e.target.childNodes[0].outerHTML.includes('M10') ?
                                                (prevPage) => { return (prevPage + 1) }
                                                : (prevPage) => { return (prevPage - 1) }
                                        )
                                        : prevPage => prevPage
                                )
                        )
                        if (shouldScroll) {
                            window.scrollTo(0, 800)
                        }
                    }
                } count={last_page} />
            </div>
        </>
    );
};

export default TrekList;

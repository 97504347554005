const env = {
    secret_key: 'Toad',
    url_panel: "http://staging-panel.trekkingitalia.teaweb.it/",
    url_api_panel: "https://staging-bfb.trekkingitalia.teaweb.it/api/v1",
    url_api_without_api: "https://staging-bff.trekkingitalia.teaweb.it/",
    url_api: "https://staging-bff.trekkingitalia.teaweb.it/api/v1",
    borsellinoFunction: true,
    version: '1.2.18'
}

export default env
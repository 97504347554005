import React from "react";
import img1 from "../assets/img/img-slider-home.jpg";
import slider1 from "../assets/img/img-slider1-home.jpg";
import slider2 from "../assets/img/img-slider2-home.jpeg";
import slider3 from "../assets/img/img-slider3-home.jpg";
import slider4 from "../assets/img/img-slider4-home.jpg";
import montagne1 from "../assets/img/testata-def-home.png";
import montagne2 from "../assets/img/montagne-dietro-slider-home.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "../css/slide.css";
import ButtonCustom from "../components/ButtonCustom";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Slider = () => {

    const navigate = useNavigate()
    const count = useSelector(state => state.count)
    return (
        <div id="main" className={`h-full lg:h-[calc(100vh-74px)] relative`}>
            <Carousel
                autoPlay={true}
                showStatus={false}
                infiniteLoop={true}
                showThumbs={false}
                showArrows={false}
                showIndicators={true}
                interval={6000}
                stopOnHover={false}
                transitionTime={2500}>
                <div className={"relative bg-black h-full"}>
                    <img
                        className="opacity-50 h-full object-cover"
                        alt="Slider Associazione"
                        src={slider1}
                    />
                    <div
                        className={
                            "absolute top-0 min-[600px]:top-1/4 min-[600px]:left-1/2 left-0 transform min-[600px]:-translate-x-1/2 min-[600px]:-translate-y-1/2 min-[600px]:text-4xl text-min-[600px] text-white z-40 font-bold italic text-center "
                        }>
                        <div className={"mb-10 w-1/2 mx-auto"}>
                            <div
                                className={
                                    "uppercase text-md min-[600px]:text-4xl border-b-2 pb-1 border-[#df5b23]"
                                }>
                                L’ASSOCIAZIONE
                            </div>
                        </div>
                        <div
                            className={"text-md min-[600px]:text-4xl min-[600px]:mx-0 mx-6"}
                            style={{
                                fontFamily: "gimlet-text",
                                fontWeight: 500,
                                fontStyle: "italic",
                            }}>
                            Da oltre 30 anni organizziamo gruppi in cammino
                        </div>
                        <div className="w-full flex justify-center mt-10">
                            <Link aria-label="Scopri di più sull'associazione" to={'/associazione'}>
                                <ButtonCustom
                                    children={'Scopri di più'.toUpperCase()}
                                    className="!w-fit"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"relative bg-black h-full"}>
                    <img
                        className="opacity-50 h-full object-cover"
                        alt="Slider Ambiente"
                        src={slider2}
                    />
                    <div
                        className={
                            "absolute  top-0 min-[600px]:top-1/4 min-[600px]:left-1/2 left-0 transform min-[600px]:-translate-x-1/2 min-[600px]:-translate-y-1/2 min-[600px]:text-4xl text-min-[600px] text-white z-40 font-bold italic text-center "
                        }>
                        <div className={"mb-10 w-1/2 mx-auto"}>
                            <div
                                className={
                                    "uppercase text-md min-[600px]:text-4xl border-b-2 pb-1 border-[#df5b23]"
                                }>
                                AMBIENTE
                            </div>
                        </div>
                        <div
                            className={"text-md min-[600px]:text-4xl min-[600px]:mx-0 mx-6"}
                            style={{
                                fontFamily: "gimlet-text",
                                fontWeight: 500,
                                fontStyle: "italic",
                            }}>
                            Il rispetto dell’ambiente è rispetto per noi stessi
                        </div>
                        <div className="w-full flex justify-center mt-10">
                            <Link aria-label="Scopri di più sull'ambiente" to={'/'}>
                                <ButtonCustom
                                    children={'Scopri di più'.toUpperCase()}
                                    className="!w-fit"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"relative bg-black h-full"}>
                    <img
                        className="opacity-50 h-full object-cover"
                        alt={"Slider Soci accompagnatori"}
                        src={slider3}
                    />
                    <div
                        className={
                            "absolute top-0 min-[600px]:top-1/4 min-[600px]:left-1/2 left-0 transform min-[600px]:-translate-x-1/2 min-[600px]:-translate-y-1/2 min-[600px]:text-4xl text-min-[600px] text-white z-40 font-bold italic text-center "
                        }>
                        <div className={"mb-4 w-1/2 mx-auto"}>
                            <div
                                className={
                                    "uppercase text-md min-[600px]:text-4xl border-b-2 pb-1 border-[#df5b23]"
                                }>
                                SOCI ACCOMPAGNATORI
                            </div>
                        </div>
                        <div
                            className={"text-md min-[600px]:text-4xl min-[600px]:mx-0 mx-6"}
                            style={{
                                fontFamily: "gimlet-text",
                                fontWeight: 500,
                                fontStyle: "italic",
                            }}>
                            Preparazione e passione per divertirsi in sicurezza
                        </div>
                        <div className="w-full flex justify-center mt-10">
                            <Link aria-label="Scopri di più sui nostri accompagnatori" to={'/accompagnatori'}>
                                <ButtonCustom
                                    children={'Scopri di più'.toUpperCase()}
                                    className="!w-fit"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <div className={"relative bg-black h-full"}>
                    <img
                        className="opacity-100 h-full object-cover"
                        alt={"img_slider_slider4"}
                        src={slider4}
                    />
                    {
                        ((document.getElementById('button_rinnova_tessera') !== undefined && document.getElementById('button_rinnova_tessera') !== null) || (count && !count.profile)) &&
                        <div
                            className={
                                "z-40 absolute top-[40%] left-1/2 transform -translate-x-1/2 min-[600px]:text-4xl text-min-[600px] text-white z-40 font-bold italic text-center "
                            }>
                            <div className="w-full flex justify-center Smt-10">
                                <div onClick={() => {
                                    let buttonRinnovaTessera = document.getElementById('button_rinnova_tessera')
                                    if (buttonRinnovaTessera) {
                                        buttonRinnovaTessera.click()
                                    }else{
                                        navigate('login')
                                    }
                                }}>
                                    <ButtonCustom
                                        children={'Rinnova ora'.toUpperCase()}
                                        className="!w-fit min-[600px]:text-4xl"
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div> */}
            </Carousel>
            <img
                src={montagne1}
                alt={"profilo montagne"}
                className={
                    "object-contain absolute z-30 w-full min-[600px]:-bottom-11 -bottom-3"
                }
            />
            {/* <img
        src={montagne2}
        alt={"img_slider_slider4"}
        className={
          "object-contain absolute z-30 w-full min-[600px]:-bottom-11 -bottom-3"
        }
      /> */}
        </div>
    );
};

export default Slider;

import React, { useState } from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/dettaglioNews.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import ErrorsComponent from "../components/ErrorsComponent";
import LoadingComponent from "../components/LoadingComponent";
import renderHTML from "react-render-html";
import parse from 'html-react-parser';
import ReactPlayer from "react-player";
import { Dialog } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import pdf from "../assets/imgTrek/pdf.svg";

const NewsDetails = () => {
    const [openGallery, setOpenGallery] = useState(null);
    const [openFoto, setOpenFoto] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const { token } = useSelector((rootState) => rootState.count);
    api.setHeaders({
        Authorization: "Bearer " + token,
    });
    const fetcher = (url) => api.get(url).then((res) => res);
    const { data } = useSWR([`/show-news/` + id], fetcher);

    if (data && !data.ok) return <ErrorsComponent />;
    if (!data) return <LoadingComponent />;
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={data && data.data && data.data.data && data.data.data.title}
                img={
                    data && data.data && data.data.data && data.data.data?.preview_image_path &&
                        data.data.data?.preview_image_path?.file ?
                        data.data.data?.preview_image_path?.file
                        : img1
                }
            />
            <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
                <div className={"flex flex-row items-center gap-1"}>
                    <div
                        className={"cursor-pointer hover:opacity-50"}
                        onClick={() => navigate(-1)}
                    >
                        Home {">"}
                    </div>
                    <div className={"font-bold"}> News</div>
                </div>
                <div
                    className={"my-6 text-3xl text-left  uppercase w-full text-[#DF5B23]"}
                    style={{ fontFamily: "gimlet-text" }}
                >
                    {data && data.data && data.data.data && data.data.data.subtitle}
                </div>

                {data.data.data.template === "classic" ? (
                    <div className={"flex flex-col"}>
                        <div className={"flex flex-row gap-12 items-center"}>
                            {/* <div
                className={"mt-8 text-2xl text-[#796557]"}
                style={{
                  fontFamily: "bilo",
                  fontWeight: "bold",
                  fontStyle: "normal",
                }}
              >
                {data.data.data.preview_description}
              </div> */}
                            {
                                data?.data?.data?.cover_path_image?.file ?
                                    <img
                                        onClick={() => { setOpenFoto(true) }}
                                        alt={"img"}
                                        src={data?.data?.data?.cover_path_image?.file}
                                        className={`object-contain h-56 w-full object-center`}
                                    />
                                    : ''
                            }
                        </div>
                        <div className={"mt-8 text-[#796557]"}>
                            {data.data.data.description && (
                                <div
                                    className={"my-4 text-xl"}
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    }}
                                >
                                    {parse(data.data.data.description)}
                                </div>
                            )}
                        </div>
                    </div>
                ) : data.data.data.template === "gallery_image" ? (
                    <div className={"flex flex-col "}>
                        <div className={"text-[#796557]"}>
                            {data.data.data.description && (
                                <div
                                    className={"my-4 text-xl"}
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    }}
                                >
                                    {parse(data.data.data.description)}
                                </div>
                            )}
                        </div>
                        <div
                            className={"text-3xl text-[#DF5B23] my-4"}
                            style={{ fontFamily: "gimlet-text" }}
                        >
                            GALLERIA FOTO
                        </div>
                        <div
                            className={
                                "flex flex-col lg:grid lg:grid-cols-4 gap-4 h-full w-full p-2"
                            }
                            style={{
                                maxHeight: "45rem",
                                overflow: "auto",
                            }}
                        >
                            {data && data.data && data.data.data && data.data.data && data.data.data.gallery.map((res, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <img
                                            onClick={() => { setOpenGallery(index) }}
                                            className={"w-full h-96 rounded-3xl object-cover"}
                                            alt={index}
                                            src={res.image_path}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <div className={"flex flex-col gap-12"}>
                        <div className={"text-[#796557]"}>
                            {data.data.data.description && (
                                <div
                                    className={"my-4 text-xl"}
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    }}
                                >
                                    {parse(data.data.data.description)}
                                </div>
                            )}
                        </div>
                        {data.data.data.cover_path_video && (
                            <>
                                <div
                                    className={"text-3xl text-[#DF5B23] my-4"}
                                    style={{ fontFamily: "gimlet-text" }}
                                >
                                    {data?.data?.data?.cover_path_video?.title &&
                                        data?.data?.data?.cover_path_video?.title}
                                </div>
                                <div className={"h-screen"}>
                                    <ReactPlayer
                                        controls={true}
                                        height={"50%"}
                                        width={"80%"}
                                        url={data?.data?.data?.cover_path_video?.file}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                )}

                {
                    data && data.data && data.data.data && data.data.data.attachments && data.data.data.attachments.length > 0 &&
                    <span className="text-2xl text-[#DF5B23] mt-4">
                        Allegati
                    </span>
                }

                <div className="w-full mt-4">
                        
                    {
                        data && data.data && data.data.data && data.data.data.attachments && data.data.data.attachments.map((attachment, index) => {
                            if (attachment.type === 'pdf') {
                                return <a
                                    href={attachment.attachment_path} target="_blank" rel="noopener noreferrer"
                                    className={
                                        "w-fit flex flex-col lg:flex-row cursor-pointer hover:opacity-75 items-center lg:mr-20 mr-20 gap-2 border-2 p-2 rounded-lg shadow-lg"
                                    }>
                                    <img
                                        alt={"img"}
                                        src={pdf}
                                        className={"w-6 h-6 object-contain bg-black rounded-full"}
                                    />
                                    <span className={"whitespace-nowrap text-md"}>
                                        {attachment.title}
                                    </span>
                                </a>
                            }
                            return (
                                <a
                                    href={attachment.attachment_path} target="_blank" rel="noopener noreferrer"
                                    className={
                                        "w-fit flex flex-col cursor-pointer hover:opacity-75 items-center lg:mr-20 mr-20 gap-2 border-2 p-2 rounded-lg shadow-lg"
                                    }>
                                    <img
                                        alt={"img"}
                                        src={attachment.attachment_path}
                                        className={"w-[250px] h-[250] object-contain rounded-lg"}
                                    />
                                    <span className={"whitespace-nowrap text-md"}>
                                        {attachment.title}
                                    </span>
                                </a>
                            )
                        })
                    }
                </div>
            </div>
            <Dialog
                open={openGallery === null ? false : true}
                onClose={() => {
                    setOpenGallery(null);
                }}
                maxWidth={"lg"}
                fullWidth={true}
                sx={{ backgroundColor: "transparent" }}>
                <div className="w-full h-[850px] flex justify-around items-center bg-[trasparent]">
                    <KeyboardArrowLeftIcon
                        sx={{ fontSize: "90px" }}
                        onClick={() => {
                            setOpenGallery((prevIndex) =>
                                prevIndex === 0 ? data && data.data && data.data.data && data.data.data && data.data.data.gallery.length - 1 : prevIndex - 1
                            );
                        }}
                    />
                    <div>
                        <img
                            className="object-cover h-full md:w-[700px] lg:w-[1000px] xl:w-[1200px] 2xl:w-[1500px]"
                            src={data && data.data && data.data.data && data.data.data && data.data.data.gallery && data.data.data.gallery[openGallery] && data.data.data.gallery[openGallery].image_path}
                            alt=""
                        />
                    </div>

                    <KeyboardArrowRightIcon
                        sx={{ fontSize: "90px" }}
                        onClick={() => {
                            setOpenGallery((prevIndex) =>
                                prevIndex === (data && data.data && data.data.data && data.data.data && data.data.data.gallery.length - 1) ? 0 : prevIndex + 1
                            );
                        }}
                    />
                </div>
            </Dialog>
            <Dialog
                open={openFoto}
                onClose={() => {
                    setOpenFoto(false);
                }}
                maxWidth={"lg"}
                fullWidth={true}
                sx={{ backgroundColor: "transparent" }}>
                <div className="w-full h-[850px] bg-[trasparent]">
                    <div>
                        <img
                            className="object-cover h-full md:w-[700px] lg:w-[1000px] xl:w-[1200px] 2xl:w-[1500px]"
                            src={data && data.data && data.data.data && data.data.data && data.data.data.cover_path_image && data.data.data.cover_path_image.file}
                            alt=""
                        />
                    </div>
                </div>
            </Dialog>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default NewsDetails;

import React, { useCallback, useEffect, useState } from 'react'
import TopBarIndex from '../components/TopBarIndex';
import FooterDown from '../Sezioni/FooterDown';
import montagne1 from "../assets/img/footer-marrone.png";
import { useSelector } from 'react-redux';
import { api } from '../conf/apiConf';
import LoadingComponent from '../components/LoadingComponent';
import ErrorsComponent from '../components/ErrorsComponent';
import ListInfiniteScroll from '../components/ListInfiniteScroll';
import InputBilo from '../components/InputBilo';
import TrekList from '../Sezioni/TrekList';


function TrekEffettuati() {

    useEffect(() => {
        document.getElementById('main_title').innerText = 'Trek effettuati - Trekking Italia'
    }, [])

    const [trekEffettuati, setTrekEffettuati] = useState([])
    const [showedTrekEffettuati, setShowedTrekEffettuati] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [counter, setCounter] = useState(0)
    const count = useSelector(state => state.count)
    const [pageEffettuati, setPageEffettuati] = useState(1)

    const fetchDataUser = useCallback(() => {

        setIsLoading(true)


        api.setHeaders({
            Authorization: "Bearer " + count.token,
            Accept: 'application/json'
        });
        api.get('/users/' + count.profile.id + "/trek/effettuati").then((res) => {
            if (!res.ok) {
                throw new Error()
            }
            setTrekEffettuati(res.data.data)
            setShowedTrekEffettuati(res.data.data.slice(0, 10))
            setIsLoading(false)
        })
            .catch((errore) => {
                console.log("error: ", errore)
                setError(true)
            })
    }, [count])

    useEffect(() => {
        fetchDataUser()
    }, [fetchDataUser])

    // function loadMore() {
    //     console.log(trekEffettuati)
    //     let trekCopy = trekEffettuati
    //     if ((window.innerHeight + document.documentElement.scrollTop + 5 >= document.scrollingElement.scrollHeight)) {
    //         setIsLoading(true)
    //         setCounter(prevState => { return prevState + 1 })
    //         console.log(trekCopy.slice(0, (10 + (10 * (counter + 1)))))
    //         setShowedTrekEffettuati(trekCopy ? trekCopy.slice(0, (10 + (10 * (counter + 1)))) : [])
    //         setTimeout(() => {
    //             setIsLoading(false)
    //         }, 1000)
    //     }
    // }




    // console.log(window.innerHeight + document.documentElement.scrollTop + 5, 
    //     document.scrollingElement.scrollHeight, 
    //     showedTrekEffettuati.length, 
    //     trekEffettuati.length, counter)

    // useEffect(() => {
    //     window.addEventListener('scroll', loadMore);
    //     console.log('ooo')
    //     return () => {
    //         window.removeEventListener('scroll', loadMore);
    //     }
    // }, [])

    // if ((showedTrekEffettuati.length === trekEffettuati.length) && showedTrekEffettuati.length !== 0) {
    //     window.removeEventListener('scroll', loadMore);
    //     console.log('boh')
    // }

    if (error) {
        return <ErrorsComponent />
    }

    return (
        <>
            <TopBarIndex />

            <div className={"text-2xl pt-5 pl-5 text-[#DF5B23] bg-[#F0E9E3]"} style={{ fontFamily: "gimlet-text", fontWeight: 500, mixBlendMode: "multiply" }}>Trek effettuati</div>



            {/* <ListInfiniteScroll data={trekEffettuati} isLoading={isLoading}/> */}
            {
                trekEffettuati && !isLoading && trekEffettuati.length > 0 ?

                    <TrekList shouldScroll={false} data={trekEffettuati.slice(((pageEffettuati - 1) * 4), (pageEffettuati * 4))} direct={true} page={pageEffettuati} setPage={setPageEffettuati} last_page={Math.ceil(trekEffettuati.length / 4)} />

                    : (isLoading ?
                        <LoadingComponent />
                        :
                        <InputBilo
                            className={'text-2xl text-[#796557] mb-10'}
                            fontWeight={700}
                            title={'Nessun trek effettuato'}
                        />
                    )
            }
            <div className='mt-2'></div>
            <div className={"2xl:hidden flex flex-col items-center gap-2 bg-[#f0e9e3] mt-40 py-8 relative"}>
                <img
                    src={montagne1}
                    alt={"img"}
                    className={"object-contain absolute z-30 -bottom-3"}
                />
            </div>
            <FooterDown />
        </>
    );
}

export default TrekEffettuati
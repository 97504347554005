import React from "react";
import img from "../assets/img/maschera-socio-home.png";
import montagne1 from "../assets/img/footer-marrone.png";
import montagne2 from "../assets/img/montagne-davanti-footer-home.png";
import img1 from "../assets/img/maschera-come-attrezzarsi-home.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const FooterTop = () => {

    const count = useSelector(state => state.count)
    const navigate = useNavigate()

    return (
        <>
            {/*  MOBILE*/}
            <div
                className={
                    "2xl:hidden flex flex-col items-center gap-2 bg-[#f0e9e3] mt-20 py-8 relative "
                }>
                {
                    count.profile && (count.profile['user_partner'] === '1' || count.profile['user_partner_state'] === 'pending') ? ''
                        : <>
                            <div className={"bg-white rounded-xl p-4  mx-4"}>
                                <div className={"flex flex-col gap-8 w-full"}>
                                    <div
                                        style={{ fontFamily: "gimlet-text" }}
                                        className={"mt-12 mb-6 text-3xl text-[#DF5B23]"}>
                                        Non sei ancora socio?
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "bilo",
                                            fontWeight: 500,
                                            fontStyle: "normal",
                                        }}
                                        className={"text-xl"}>
                                        Entra a far parte di Trekking Italia per partecipare ai nostri
                                        trekking e avere molti altri vantaggi!
                                    </div>
                                    <div className={"flex flex-row w-full justify-center"}>
                                        <div
                                            onClick={() => { 
                                                localStorage.setItem('from_associa', true)
                                                window.scrollTo({
                                                    top: 0,
                                                    left: 0,
                                                    behavior: "smooth",
                                                });
                                                navigate(count.profile ? '/register/socio' : '/login' ) 
                                            }}
                                            className={
                                                "uppercase cursor-pointer hover:opacity-50 text-center text-white text-lg w-1/2  p-2 bg-[#df5b23]"
                                            }
                                            style={{ fontFamily: "gimlet-text" }}>
                                            ASSOCIATI
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img
                                alt={"img"}
                                src={img}
                                className={"object-contain w-full h-96 mt-4 mb-20"}
                            />
                        </>
                }
                <img
                    src={montagne1}
                    alt={"img"}
                    className={"object-contain absolute z-30 -bottom-3"}
                />
                {/* <img
          src={montagne2}
          alt={"img"}
          className={"object-contain absolute z-30 -bottom-3"}
        /> */}
            </div>
            {/*  END MOBILE*/}
            <div className={"hidden relative 2xl:block pt-8 bg-[#f0e9e3]"}>
                {
                    count.profile && (count.profile['user_partner'] === '1' || count.profile['user_partner_state'] === 'pending')?
                        <div className="w-10 h-[250px]"></div>
                        : <div
                            className={
                                "w-full flex flex-row  pb-80 pt-36 justify-center items-start"
                            }>
                            <div
                                className={
                                    "bg-white flex flex-row items-center  w-1/2 relative rounded-r-xl p-8"
                                }>
                                <div
                                    className={"pl-56 flex flex-col items-end gap-8 relative w-full"}>
                                    <img
                                        alt={"img_non_sei_ancora_socio"}
                                        src={img}
                                        className={
                                            "object-contain w-full absolute -top-28 -left-96 z-20"
                                        }
                                        style={{ width: "50rem", height: "35rem" }}
                                    />
                                    <div
                                        style={{ fontFamily: "gimlet-text" }}
                                        className={"my-6 text-4xl text-[#DF5B23]"}>
                                        Non sei ancora socio?
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "bilo",
                                            fontWeight: 500,
                                            fontStyle: "normal",
                                        }}
                                        className={"text-2xl w-80 text-end text-[#796557]"}>
                                        Entra a far parte di Trekking Italia per partecipare ai nostri
                                        trekking e avere molti altri vantaggi!
                                    </div>
                                    <button
                                        onClick={() => {
                                            window.scrollTo({
                                                top: 0,
                                                left: 0,
                                                behavior: "smooth",
                                            });
                                            navigate('/login')
                                        }}
                                        className={
                                            "uppercase cursor-pointer hover:opacity-50 text-white text-center text-xl w-1/3 p-2 bg-[#DF5B23]"
                                        }
                                        style={{ fontFamily: "gimlet-text" }}>
                                        ASSOCIATI
                                    </button>
                                </div>
                            </div>
                        </div>
                }

                <img
                    src={montagne1}
                    alt={"img_montagne_footer"}
                    className={"object-contain w-full absolute z-30 -bottom-11"}
                />
                {/* <img
          src={montagne2}
          alt={"img"}
          className={"object-contain w-full absolute z-30 -bottom-11"}
        /> */}
            </div>
        </>
    );
};

export default FooterTop;

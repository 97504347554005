import React from "react";
import TopBarIndex from "../components/TopBarIndex";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { Paper } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

const PaymentSuccess = () => {
    const navigate = useNavigate()
    // Inizializza useSearchParams per ottenere i parametri di query
    const [searchParams] = useSearchParams();

    // Leggi i valori dei parametri dall'URL
    const newyear_membership = searchParams.get('newyear_membership');
    const open_membership = searchParams.get('open_membership');
    let membership_newyear_open = null;
    if (newyear_membership && open_membership) {
        const [giorno, mese, anno] = open_membership.split('-');
        membership_newyear_open = new Date(
            `${anno}-${mese}-${giorno}`
        ).getTime() <= new Date().getTime()
    }


    return (
        <div>
            <TopBarIndex />
            <div className="h-screen w-full flex justify-center items-center">
                <div className="container mx-auto text-center justify-center items-center flex flex-col text-3xl p-4 -translate-y-1/4">
                    <div>
                        <Paper elevation={6}>
                            <div className="p-4 border ronded shadow-orange-100 rounded">
                                Operazione completata con successo!
                            </div>
                        </Paper>
                        <Paper elevation={6} className="mt-4">
                            {
                                newyear_membership && open_membership &&
                                <div className="p-4 ronded shadow-orange-100 rounded text-sm ">
                                    {` N.B. La conferma dell’iscrizione a questo trek necessita del rinnovo della tessera associativa per l’anno ${newyear_membership} ${membership_newyear_open ? '(se non è già stato fatto)' : ''}, che dovrà essere effettuato a partire dal ${open_membership}.`}
                                </div>
                            }
                        </Paper>
                        <button
                            className={"mt-24 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                            style={{
                                fontFamily: "gimlet-text",
                                fontWeight: 500,
                                fontStyle: "italic",
                            }}
                            onClick={() => { navigate(localStorage.getItem('lastpage')) }}>
                            Torna Indietro
                        </button>
                    </div>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </div>
    );
};

export default PaymentSuccess;

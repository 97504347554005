import React, { useEffect, useState } from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import ItemAccompagnatori from "../components/ItemAccompagnatori";
import { useSelector } from "react-redux";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import PaginationCustom from "../components/PaginationCustom";
import LoadingComponent from "../components/LoadingComponent";
import InputGimlet from "../components/InputGimlet";
import InputBilo from "../components/InputBilo";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import ErrorsComponent from "../components/ErrorsComponent";
import InputCustom from "../components/InputCustom";

const staticText = `Trekking Italia progetta ed organizza consapevolmente tutti i propri trek, con la massima attenzione per la sicurezza di chi accompagna e di chi partecipa. 

Più di duecento socie e soci accompagnano i gruppi di persone nei percorsi di trekking e diffondono i valori dell’Associazione. 

Sono volontari non retribuiti, che seguono un percorso di formazione specifico e a loro dedicato, svolto da professionisti di varie discipline (attrezzatura, cartografia, sicurezza in montagna, interpretazione ambientale, meteorologia, allenamento e alimentazione) e da specialisti per la gestione del primo soccorso.

I Soci Accompagnatori conoscono l’ambiente e i sentieri, sanno individuare e consigliare il trek più adatto al livello di allenamento, chiariscono i diversi gradi di difficoltà dello stesso, agiscono con prontezza nel risolvere eventuali difficoltà dei partecipanti. 
Consideriamo la formazione uno dei più importanti servizi che l’Associazione offre a tutti i soci, a cui sono dedicati corsi di escursionismo, di botanica e diverse altre iniziative, proposte sia dalle sezioni sia a livello nazionale.
`

const Accompagnatori = () => {
    const [page, setPage] = useState(1)
    const [escortViewed, setEscortViewed] = useState([])
    const [selectedValue, setSelectedValue] = useState(0)
    const { token } = useSelector((rootState) => rootState.count);
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState(false)
    const [cognome, setCognome] = useState('')

    api.setHeaders({
        Authorization: "Bearer " + token,
    });

    useEffect(() => {
        setPage(localStorage.getItem('accompagnatorePage') ? localStorage.getItem('accompagnatorePage') : 1)
        setSelectedValue(localStorage.getItem('accompagnatoreSez') ? localStorage.getItem('accompagnatoreSez') : 0)
    }, [])

    const fetcher = (url) => api.post(url, {
        department_id: selectedValue === 0 || selectedValue === '0' ? null : selectedValue,
        cognome: cognome,
        exclude_data: 1
    }).then((res) => {
        setLoading(false)
        if (!res.ok) {
        }
        return res
    })
    const { data } = useSWR([`/escorts-list`, selectedValue, search], fetcher);


    const fetcher2 = (url) => api.get(url).then((res) => res);
    const { data: departments } = useSWR([`/all-departments`], fetcher2);

    function HandleChangedPage(page) {
        setEscortViewed(data && data.data && data.data.data ? data.data.data.slice((0 + ((page - 1) * 12)), (12 + ((page - 1) * 12))) : [])
        window.scrollTo({
            top: 1500,
            left: 0,
            behavior: "smooth",
        });
        setPage(page)
    }

    function handleChangedDepartment(e) {
        window.scrollTo({
            top: 1700,
            left: 0,
            behavior: "smooth",
        })
        setLoading(true)
        setSelectedValue(e.target.value)
    }

    useEffect(() => {
        setEscortViewed(data && data.data && data.data.data ? data.data.data.slice((0 + (((page ? page : 1) - 1) * 12)), (12 + (((page ? page : 1) - 1) * 12))) : [])
    }, [data])

    if ((data && !data.ok) || departments && !departments.ok) {
        return <ErrorsComponent />
    }

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"I nostri accompagnatori"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
                <div
                    className={"flex flex-row items-center gap-1"}
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    <div>Home {">"}</div>
                    <div>I nostri trek {">"}</div>
                    <div className={"font-bold"}> Accompagnatori</div>
                </div>
                <InputGimlet className={'text-4xl text-[#DF5B23] my-10'} fontStyle='italic' title='DIVERTIRSI IN SICUREZZA' />
                <InputBilo className={'whitespace-pre-wrap text-2xl'} title={staticText} />
                <div className="p-10 mt-10 w-full flex flex-col sm:flex-row justify-center sm:justify-end items-center sm:items-end gap-4 sm:gap-2">
                    <Box sx={{ minWidth: 250, height: 'auto' }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" className="!text-2xl !-top-[5px] bg-[#fff]">Sezione</InputLabel>
                            {
                                departments && departments.data && departments.data.data &&
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue ? selectedValue.toString() : '0'}
                                    label="Sezioni"
                                    onChange={handleChangedDepartment}
                                >
                                    {
                                        departments && departments.data && departments.data.data ?
                                            departments.data.data.map((department, index) => {
                                                if (department.name === 'Nazionale') {
                                                    return <MenuItem key={index} value={0}>Tutti</MenuItem>
                                                }
                                                return <MenuItem key={index + 1} value={department.id}>
                                                    {department.name.includes('Sezione') ? department.name.slice(8) : department.name}
                                                </MenuItem>
                                            })
                                            : ''
                                    }
                                </Select>
                            }
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 250 }}>
                        <div className="w-full flex gap-2">
                            <FormControl fullWidth>

                                {
                                    <TextField
                                        label="Cognome"
                                        variant="outlined"
                                        value={cognome}
                                        onChange={(e) => {
                                            setCognome(e.target.value)
                                        }}
                                        sx={{
                                            '& .MuiFormLabel-root': {
                                                fontSize: '22px',
                                                top: '-5px',
                                                backgroundColor: 'white'

                                            }
                                        }}
                                    />
                                }
                            </FormControl>
                            <button
                                onClick={() => {
                                    setPage(1);
                                    setSearch(prev => !prev)
                                }}
                                className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                style={{
                                    fontFamily: "gimlet-text",
                                    fontWeight: 500,
                                    fontStyle: "italic",
                                }}
                            >
                                CERCA
                            </button>
                        </div>
                    </Box>
                </div>
                {
                    loading ?
                        <LoadingComponent />
                        :
                        data && escortViewed && escortViewed.length > 0 ?
                            <>
                                <div className={"mt-10 flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"}>
                                    {
                                        escortViewed.map((res, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <ItemAccompagnatori
                                                        sesso={res.gender}
                                                        img={res.img}
                                                        name={res.name + ' ' + res.surname}
                                                        sezione={res.sezione}
                                                        filterSez={selectedValue}
                                                        page={page}
                                                        id={res.id}
                                                    />
                                                </React.Fragment>
                                            );
                                        })
                                    }
                                </div>
                                <div
                                    className={
                                        "flex flex-row w-full gap-4 justify-center items-center mt-20"
                                    }>
                                    <PaginationCustom page={page ? Number(page) : 1} onChange={(e, page) => { HandleChangedPage(Number(page)) }} count={data && data.data && data.data.data ? Math.ceil(data.data.data.length / 12) : 1} />
                                </div>
                            </>
                            : <InputBilo className={'text-2xl'} title={'Nessun Accompagnatore'} />
                }
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default Accompagnatori;

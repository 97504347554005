import React, { useState } from "react";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import Topbar from "../components/Topbar";
import NuovoForm from "../components/NuovoForm";
import TopBarIndex from "../components/TopBarIndex";
import { useSelector } from "react-redux";
import NuovoForm2 from "../components/NuovoForm2";

const DatiPersonali = () => {
  const [reload, setReload] = useState(false);
  const count = useSelector(state => state.count)
  return (
    <>
      <TopBarIndex />
      <NuovoForm2
        setOpen={() => false}
        reload={reload}
        setReload={setReload}
        type={"edit"}
        count={count}
      />
      <FooterTop />
      <FooterDown />
    </>
  );
};

export default DatiPersonali;

// import React, { useCallback, useEffect, useState } from 'react'
// import TopBarIndex from '../components/TopBarIndex';
// import FooterDown from '../Sezioni/FooterDown';
// import { useFormik } from "formik";
// import * as yup from "yup";
// import montagne1 from "../assets/img/footer-marrone.png";
// import style from "./DatiPersonali.module.css"
// import { useSelector } from 'react-redux';
// import { api } from '../conf/apiConf';
// import { changeUrlBased } from '../Auth/auth';
// import { useField } from 'formik';

// const validationSchema = yup.object({
//     telefono: yup.string().min(5, "Il telefono deve contenere almeno almeno 5 caratteri"),
//     cellulare: yup.string().min(5, "Il cellulare deve contenere almeno almeno 5 caratteri"),
//     nazione: yup.string().min(2, "La nazione deve contenere almeno almeno 2 caratteri"),
//     comune: yup.string().min(2, "Il comune deve contenere almeno almeno 2 caratteri"),
//     indirizzo: yup.string().min(3, "La password deve contenere almeno almeno 3 caratteri"),
//     nazione_domicilio: yup.string().when('residenza_stesso_dimicilio', {
//         is: false,
//         then: yup.string().min(2, "La nazione deve contenere almeno almeno 2 caratteri").required('Campo obbligatorio'),
//         otherwise: yup.string().notRequired(),
//       }),
//       comune_domicilio: yup.string().when('residenza_stesso_dimicilio', {
//         is: false,
//         then: yup.string().min(2, "Il comune deve contenere almeno almeno 2 caratteri").required('Campo obbligatorio'),
//         otherwise: yup.string().notRequired(),
//       }),
//       indirizzo_domicilio: yup.string().when('residenza_stesso_dimicilio', {
//         is: false,
//         then: yup.string().min(3, "La password deve contenere almeno almeno 3 caratteri").required('Campo obbligatorio'),
//         otherwise: yup.string().notRequired(),
//       }),
// });

// /*

// aggiungere input se il domicilio è diverso dalla residenza

// */

// function DatiPersonali() {
//     const [user, setUser] = useState({})
//     const [residenzaStessoDimicilio, setResidenzaStessoDimicilio] = useState(true)
//     const count = useSelector(state => state.count)

//     // const [field, meta, helpers] = useField(props);

//     //form dei dati modificabili dall'utente
//     const formik = useFormik({
//         enableReinitialize: true,
//         initialValues: {
//             telefono: user.telefono ? user.telefono : "",
//             cellulare: user.cellulare ? user.cellulare : "",
//             nazione: user.nazione ? user.nazione : "",
//             comune: user.comune ? user.comune : "",
//             indirizzo: user.indirizzo ? user.indirizzo : "",
//             iban: user.iban ? user.iban : "",
//             intestatario_iban: user.intestatario_iban ? user.intestatario_iban : "",
//             residenza_stesso_dimicilio: user.residenza_stesso_dimicilio? user.residenza_stesso_dimicilio : 'false',
//             nazione_domicilio: user.nazione_domicilio ? user.nazione_domicilio : "",
//             comune_domicilio: user.comune_domicilio ? user.comune_domicilio : "",
//             indirizzo_domicilio: user.indirizzo_domicilio ? user.indirizzo_domicilio : "",
//         },
//         validationSchema: validationSchema,
//         onSubmit: (values) => {
//             changeUrlBased()
//             api.post('/users' + count.profile.id, values).then((res) => {
//                 //bisogna aggiornare lo stato di user
//             })
//         }
//     });

//     let formInputs = [
//         {
//             nome: 'telefono',
//             label: 'Telefono',
//             type: 'number',
//         },
//         {
//             nome: 'cellulare',
//             label: 'Cellulare',
//             type: 'number',
//         },
//         {
//             nome: 'nazione',
//             label: 'Nazione di residenza',
//             type: 'text',
//         },
//         {
//             nome: 'comune',
//             label: 'Comune di residenza',
//             type: 'text',
//         },
//         {
//             nome: 'indirizzo',
//             label: 'Indirizzo di residenza',
//             type: 'text',
//         }
//     ]

//     //gestione errori in input
//     useEffect(() => {
//         formInputs.map((input, index) => {
//             if (formik.touched[Object.keys(formik.touched)[index]] && Boolean(formik.errors[Object.keys(formik.errors)[index]])) {
//                 console.log(formik.touched[Object.keys(formik.touched)[index]] && formik.errors[Object.keys(formik.errors)[index]])

//             }
//             return 0
//         })
//     }, [formik.errors, formik.touched])

//     const fetchDataUser = useCallback(()=>{
//         setUser(count.profile)
//         setResidenzaStessoDimicilio(count.profile.residenza_stesso_dimicilio === 'true' ? true : false)
//     },[count.profile])

//     useEffect(() => {
//         fetchDataUser()
//     }, [fetchDataUser])

//     useEffect(()=>{
//         setResidenzaStessoDimicilio(formik.values.residenza_stesso_dimicilio === 'true' ? true : false)
//     }, [formik.values.residenza_stesso_dimicilio])

//     return (
//         <>
//             <TopBarIndex />
//             <div className='bg-[#F0E9E3]'>
//                 <div className={style['container-top']}>
//                     <div className={style.profilo}>
//                         <div className={style.img}>
//                             <img src="https://previews.123rf.com/images/djoronimo/djoronimo1706/djoronimo170600026/79618603-donna-felice-%C3%A8-in-escursione-in-montagna-e-godendo-la-natura.jpg" alt="" />
//                             <div className={style['copy-img']}>
//                                 <div className={style.foto}>
//                                     <img src="https://img.icons8.com/material-outlined/512/camera--v2.png" alt="" />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className={style["info"]}>
//                         <div className={style["info-top"]}>
//                             <div className={"text-3xl text-[#DF5B23]"} style={{ fontFamily: "gimlet-text", fontWeight: 500 }}>{user.nome} {user.cognome}</div>
//                             <div className={"text-3xl text-[#DF5B23]"} style={{ fontFamily: "gimlet-text", fontWeight: 300 }}>{user.nickname}</div>
//                         </div>
//                         <div className={style["info-bottom"]}>
//                             <div className="text-xl" style={{ fontFamily: "Bilo" }}>ID Socio: <div style={{ display: "inline", fontWeight: 500 }}> {user["socio_id"]} </div></div>
//                             <div className="text-xl" style={{ fontFamily: "Bilo" }}>Mail: <div style={{ display: "inline", fontWeight: 500 }}> {user.mail} </div></div>
//                             <div className="text-xl" style={{ fontFamily: "Bilo" }}>Genere: <div style={{ display: "inline", fontWeight: 500 }}> {user.genere} </div></div>
//                             <div className="text-xl" style={{ fontFamily: "Bilo" }}>Data di nascita: <div style={{ display: "inline", fontWeight: 500 }}> {user["data_di_nascita"]} </div></div>
//                         </div>
//                     </div>
//                     <div className={style["info"]}>
//                         <div className={style["info-top"]}>
//                             <div className={"text-3xl text-[#DF5B23]"} style={{ fontFamily: "gimlet-text", fontWeight: 500 }}>
//                                 <div className={"text-xl text-[#DF5B23] inline"} style={{ fontFamily: "gimlet-text", fontWeight: 300 }}>
//                                     sez.
//                                 </div>
//                                 LOMBARDIA
//                             </div>
//                         </div>
//                         <div className={style["info-bottom"]}>
//                             <div className="text-xl" style={{ fontFamily: "Bilo" }}>Nazione di nascita: <div style={{ display: "inline", fontWeight: 500 }}> {user["nazione_di_nascita"]} </div></div>
//                             <div className="text-xl" style={{ fontFamily: "Bilo" }}>Comune di nascita: <div style={{ display: "inline", fontWeight: 500 }}> {user["comune_di_nascita"]} </div></div>
//                             <div className="text-xl" style={{ fontFamily: "Bilo" }}>Regione di nascita: <div style={{ display: "inline", fontWeight: 500 }}> {user["regione_di_nascita"]} </div></div>
//                             <div className="text-xl" style={{ fontFamily: "Bilo" }}>Codice Fiscale: <div style={{ display: "inline", fontWeight: 500 }}> {user.cf} </div></div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className={style["container-bottom"]}>
//                     <div className={style['container-form']}>
//                         <div className={"text-3xl text-[#DF5B23]"} style={{ fontFamily: "gimlet-text", fontWeight: 500 }}>Dati personali</div>
//                         <form onSubmit={formik.handleSubmit}>
//                             <div className={style['container-input']}>
//                                 {formInputs.map((input, index) => {
//                                     return (
//                                         <div key={index} className={"px-6 py-4 " + style.input}>
//                                             <label htmlFor={input.nome}>{input.label}</label>
//                                             <input name={input.nome} type={input.type} id={input.nome}
//                                                 onChange={formik.handleChange}
//                                                 value={formik.values[Object.keys(formik.values)[index]]}
//                                             />
//                                         </div>
//                                     )
//                                 })}
//                             </div>

//                             <div className={"px-6 py-4 " + style['input-radio']}>
//                                 <div style={{
//                                     fontFamily: "bilo",
//                                     fontWeight: 300,
//                                     fontStyle: "italic",
//                                     fontSize: "1.5rem"
//                                 }}>Il domicilio è diverso dalla residenza</div>
//                                 <div>
//                                     <div>
//                                         <input type="radio" name="residenza_stesso_dimicilio" id="residenza_stesso_dimicilio"
//                                             onChange={formik.handleChange}
//                                             checked={formik.values["residenza_stesso_dimicilio"] === 'false' }
//                                             value={'false'}
//                                         />
//                                         <label style={{ fontSize: "1.2rem" }} htmlFor="residenza_stesso_dimicilio">Si</label>
//                                     </div>

//                                     <div>
//                                         <input type="radio" name="residenza_stesso_dimicilio" id="residenza_diverso_dimicilio"
//                                             onChange={formik.handleChange}
//                                             checked={formik.values["residenza_stesso_dimicilio"] === 'true'}
//                                             value={'true'}
//                                         />
//                                         <label style={{ fontSize: "1.2rem" }} htmlFor="residenza_diverso_dimicilio">No</label>
//                                     </div>

//                                 </div>
//                             </div>

//                             {
//                                 !residenzaStessoDimicilio ?
//                                     <div className={style['container-input']}>
//                                         <div className={"px-6 py-4 " + style.input}>
//                                             <label htmlFor={'nazione_domicilio'}>Nazione di domicilio</label>
//                                             <input name={'nazione_domicilio'} type={'text'} id={'nazione_domicilio'}
//                                                 onChange={formik.handleChange}
//                                                 value={formik.values["nazione_domicilio"]}
//                                             />
//                                         </div>
//                                         <div className={"px-6 py-4 " + style.input}>
//                                             <label htmlFor={'comune_domicilio'}>Comune di domicilio</label>
//                                             <input name={'comune_domicilio'} type={'text'} id={'comune_domicilio'}
//                                                 onChange={formik.handleChange}
//                                                 value={formik.values["comune_domicilio"]}
//                                             />
//                                         </div>
//                                         <div className={"px-6 py-4 " + style.input}>
//                                             <label htmlFor={'indirizzo_domicilio'}>Indirizzo di domicilio</label>
//                                             <input name={'indirizzo_domicilio'} type={'text'} id={'indirizzo_domicilio'}
//                                                 onChange={formik.handleChange}
//                                                 value={formik.values["indirizzo_domicilio"]}
//                                             />
//                                         </div>
//                                     </div>
//                                     : ""
//                             }

//                             <div className='border-t-[1px] border-solid border-black w-[85vw] relative left-1/2 -translate-x-1/2'></div>

//                             <div className={style['container-input']}>
//                                 <div className={"px-6 py-4 " + style.input}>
//                                     <label htmlFor={"iban"}>IBAN</label>
//                                     <input name={"iban"} type="text" id={"iban"}
//                                         onChange={formik.handleChange}
//                                         value={formik.values["iban"]}
//                                     />
//                                 </div>
//                                 <div className={"px-6 py-4 " + style.input}>
//                                     <label htmlFor={"intestatario_iban"}>Intestatario IBAN</label>
//                                     <input name={"intestatario_iban"} type="text" id={"intestatario_iban"}
//                                         onChange={formik.handleChange}
//                                         value={formik.values['intestatario_iban']}
//                                     />
//                                 </div>
//                             </div>

//                             <button
//                                 type="submit"
//                                 className={"px-6 py-1 " + style['btn-update']} style={{
//                                     fontFamily: "bilo",
//                                     fontWeight: 500,
//                                     fontStyle: "italic",
//                                 }}>AGGIORNA</button>

//                         </form>
//                     </div>
//                 </div>
//                 <div className={"2xl:hidden flex flex-col items-center gap-2 bg-[#f0e9e3] mt-8 py-8 relative"}>
//                     <img
//                         src={montagne1}
//                         alt={"img"}
//                         className={"object-contain absolute z-30 -bottom-3"}
//                     />
//                 </div>
//             </div>
//             <FooterDown />
//         </>
//     );

// }

// export default DatiPersonali

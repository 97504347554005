import React from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from '../Sezioni/FooterTop';
import FooterDown from '../Sezioni/FooterDown';
import InputBilo from '../components/InputBilo';
import InputGimlet from '../components/InputGimlet';

function CopertureAssicurative() {
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Coperture assicurative"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Associazione &gt; </div>
                        <div className={"font-bold"}>Coperture assicurative</div>
                    </div>
                    {/* Content */}
                    <InputGimlet
                        title={'Coperture assicurative'}
                        fontWeight={500}
                        fontStyle="italic"
                        className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                    />
                    <div className="mb-5 text-xl">
                        <div>
                            <InputBilo
                                title={'A) '}
                                className={'inline text-2xl'}
                                fontWeight={700}
                            />
                            <InputBilo
                                title={'Polizza infortuni Unipol Sai'}
                                className={'underline inline text-2xl'}
                                fontWeight={700}
                            />
                        </div>
                        <br />
                        <ul style={{ listStyle: 'disc', paddingLeft: 40 }}>
                            <li>
                                <InputBilo
                                    title={'Assicurati'}
                                    fontWeight={700}
                                />
                                <ul style={{ listStyle: 'circle', paddingLeft: 80 }}>
                                    <li>
                                        <InputBilo
                                            title={'soci '}
                                            fontWeight={700}
                                            className={'inline'}
                                        />
                                        <InputBilo
                                            title={'certificati dall\'Associazione;'}
                                            className={'inline'}
                                        />
                                    </li>
                                    <li>
                                        <InputBilo
                                            title={'non soci '}
                                            fontWeight={700}
                                            className={'inline'}
                                        />
                                        <InputBilo
                                            title={'partecipanti a iniziative dell\'Associazione previo trasmissione elenco nominativo fino al giorno precedente all’iniziativa'}
                                            className={'inline'}
                                        />
                                    </li>
                                </ul>
                            </li>
                            <br />
                            {/* <li>
                                <InputBilo
                                    title={'Studenti e loro accompagnatori '}
                                    fontWeight={700}
                                    className={'inline'}
                                />
                                <InputBilo
                                    title={'partecipanti ad attività organizzate da Trekking Italia. Le scuole devono trasmettere gli elenchi dei partecipanti a Trekking Italia fino al giorno antecedente partenza delle attività.'}
                                    className={'inline'}
                                />
                            </li> */}
                        </ul>
                        <br />
                        <InputBilo
                            title={'Attività comprese'}
                            fontWeight={700}
                        />
                        <ul style={{ listStyle: 'disc', paddingLeft: 40 }}>
                            <li>
                                <InputBilo
                                    title={'escursioni e trekking (con grado di difficoltà non superiore al terzo grado scala U.I.A.A.);'}
                                />
                            </li>
                            <li>
                                <InputBilo
                                    title={'racchette da neve e sci di fondo;'}
                                />
                            </li>
                            <li>
                                <InputBilo
                                    title={'corsi di escursionismo, di progressione su neve;'}
                                />
                            </li>
                            <li>
                                <InputBilo
                                    title={'assemblee, congressi, convegni, raduni, riunioni, manifestazioni, spettacoli, mostre, esposizioni, conferenze, proiezioni;'}
                                />
                            </li>
                            <li>
                                <InputBilo
                                    title={'attività secondaria e minoritaria di manutenzione dei sentieri tramite segnatura rosso-bianca e decespugliamento;'}
                                />
                            </li>
                            <li>
                                <InputBilo
                                    title={'è escluso il rischio in itinere,'}
                                />
                            </li>
                        </ul>
                        <br />
                        <InputBilo
                            title={'Coperture'}
                            fontWeight={700}
                        />
                        <ul style={{ listStyle: 'disc', paddingLeft: 40 }}>
                            <li>
                                <InputBilo
                                    title={'rimborso spese sanitarie: € 1.200 con scoperto 10% a carico dell’assicurato; la garanzia è operante se l\'infortunio ha comportato un ricovero in istituto di cura con almeno un pernottamento; il rimborso viene effettuato a cura ultimata;'}
                                />
                            </li>
                            <li>
                                <InputBilo
                                    title={'diaria da ricovero a seguito infortunio'}
                                    className={'inline underline'}
                                />
                                <InputBilo
                                    title={': € 50,00;'}
                                    className={'inline'}
                                />
                            </li>
                            <li>
                                <InputBilo
                                    title={'validità permanente '}
                                    className={'inline underline'}
                                />
                                <InputBilo
                                    title={'€ 80.000. Non viene riconosciuto l\'indennizzo se l\'invalidità permanente è di grado non superiore al 5%; se superiore, l\'indennizzo viene corrisposto soltanto per la parte eccedente.'}
                                    className={'inline'}
                                />
                                <InputBilo
                                    title={' La tabella delle condizioni di polizza infortuni per la determinazione della invalidità permanente (tabella A art.2.5) è sostituita dalla tabella Inail (D.P.R. n. 1124 del 30/06/1965 e successive modifiche).'}
                                />
                            </li>
                            <li>
                                <InputBilo
                                    title={'morte '}
                                    className={'inline underline'}
                                />
                                <InputBilo
                                    title={'€ 40.000'}
                                    className={'inline'}
                                />
                            </li>
                            <li>
                                <InputBilo
                                    title={'per gli Assicurati con età superiore a 70 e fino a 80 anni, con deduzione di una franchigia assoluta pari al 10% del grado d’Invalidità Permanente accertato'}
                                />
                            </li>
                            <li>
                                <InputBilo
                                    title={'la copertura cessa, per ciascun assicurato, alla scadenza del premio successivo al compimento degli 80 anni.'}
                                />
                            </li>
                        </ul>
                        <br />
                        <InputBilo
                            title={'Esonero denuncia infermità, difetti fisici e malformazioni'}
                            fontWeight={700}
                        />
                        <InputBilo
                            title={'L\'Associazione è esonerata dalla denuncia di infermità, difetti fisici o malformazioni di cui gli assicurati fossero affetti al momento della stipulazione o che dovessero in seguito sopravvenire.'}
                        />
                        <br />
                        <InputBilo
                            title={'Termini di denuncia sinistro'}
                            fontWeight={700}
                        />
                        <InputBilo
                            title={'In forma scritta, utilizzando il previsto modulo, entro 3 giorni da quando se ne ha la possibilità.'}
                        />
                        <br />
                        <div>
                            <InputBilo
                                title={'B) '}
                                className={'inline text-2xl'}
                                fontWeight={700}
                            />
                            <InputBilo
                                title={'Polizza Responsabilità Civile verso Terzi Unipol Sai per tutti i soci'}
                                className={'underline inline text-2xl'}
                                fontWeight={700}
                            />
                        </div>
                        <InputBilo
                            title={'La polizza Unipol Sai copre la Responsabilità Civile verso Terzi per danni corporali (morte e lesioni) e danni materiali (distruzione e danneggiamento di cose) involontariamente cagionati a terzi in conseguenza di un fatto accidentale verificatosi in relazione allo svolgimento di tutte le attività organizzate da Trekking Italia. Gli associati sono considerati terzi tra di loro. '}
                        />
                        <InputBilo
                            title={'La franchigia per ogni sinistro è pari a 1.500 €. '}
                        />
                        <br />
                        <div>
                            <InputBilo
                                title={'C) '}
                                className={'inline text-2xl'}
                                fontWeight={700}
                            />
                            <InputBilo
                                title={'Polizza assistenza, spese mediche, bagaglio Allianz'}
                                className={'underline inline text-2xl'}
                                fontWeight={700}
                            />
                        </div>
                        <InputBilo
                            title={'Per alcuni trek di più giorni, soprattutto all\'estero, vengono attivate ad integrazione anche le coperture previste dalla polizza assicurativa sottoscritta con Allianz relativamente ad assistenza, spese mediche e bagaglio. Il pagamento del premio è a carico dei soci partecipanti al trek.'}
                        />
                    </div>

                </div>


            </div>
            <FooterTop />
            <FooterDown />
        </>
    )
}

export default CopertureAssicurative
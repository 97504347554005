import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogCustom = ({
    title,
    content,
    open,
    handleClose,
    handleClose2,
    fullScreen,
    button,
    button2,
    closeModal,
    size,
    button2Disabled,
    buttonDisabled,
}) => {
    return (
        <Dialog
            aria-modal='true'
            role="dialog"
            maxWidth={size ? size : "sm"}
            fullScreen={fullScreen}
            open={open}
            fullWidth
            TransitionComponent={Transition}
            keepMounted>
            <DialogTitle>
                <div className={"flex flex-row items-center justify-between w-full"}>
                    <div>{title}</div>
                    <IconButton onClick={closeModal} aria-label="close-rinnova-tessera">
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent sx={{ textAlign: "center", fontSize: 24 }}>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{ justifyContent: "center", gap: 5 }}
                disableSpacing={true}>
                {
                    button &&
                    <Button disabled={buttonDisabled} variant={"contained"} onClick={handleClose}>
                        {button}
                    </Button>
                }
                {button2 && (
                    <Button disabled={button2Disabled} variant={"contained"} onClick={handleClose2}>
                        {button2}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default DialogCustom;

import React, { useCallback, useEffect, useState } from "react";
import TopBarIndex from "../components/TopBarIndex";
import FooterDown from "../Sezioni/FooterDown";
import montagne1 from "../assets/img/footer-marrone.png";
import style from "./TesseraAssociativa.module.css";
import logo from "../assets/img/tesseraBase.JPG";
import { useSelector } from "react-redux";
import { changeUrlBased } from "../Auth/auth";
import { api } from "../conf/apiConf";
import ErrorsComponent from "../components/ErrorsComponent";
import InputBilo from "../components/InputBilo";
import noImage from "../assets/img/no_image.jpg";
import InputCustom from "../components/InputCustom";
import { MenuItem } from "@mui/material";
import DialogCustom from "../components/DialogCustom";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";
import env from "../conf/variables";

function TesseraAssociativa() {

    useEffect(() => {
        document.getElementById('main_title').innerText = 'Tessera associativa - Trekking Italia'
    }, [])

    const [user, setUser] = useState({});
    const count = useSelector((state) => state.count);
    const [error, setError] = useState(false);
    const [rinnova, setRinnova] = useState(false);
    const [rinnovaAnticipata, setRinnovaAnticipata] = useState(false);
    const [errorAssoc, setErrorAssoc] = useState({
        open: false,
        error: "",
    });
    const [openRinnova, setOpenRinnova] = useState(false);
    const [assocType, setAssocType] = useState([]);
    const [errorAssocValue, setErrorAssocValue] = useState(false);
    const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
    const [selectedAsscType, setSelectedAssocType] = useState(null)
    //get infpo carta

    const navigate = useNavigate();
    const location = useLocation();

    api.setHeaders({
        Authorization: "Bearer " + count.token,
        Accept: "application/json",
    });

    const fetchDataUser = useCallback(() => {
        api
            .get("/users/" + count.profile.id + "/carta")
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                let detail = {
                    carta: {
                        scadenza: "05/07/2024",
                    },
                };
                setUser(res.data.data);
            })
            .catch((errore) => {
                console.log("error: ", errore);
                setError(true);
            });
    }, [count]);

    const fetcher = (url) => api.get(url).then((res) => res);
    const { data: departments } = useSWR([`/all-departments`], fetcher);

    useEffect(() => {
        fetchDataUser();
    }, [fetchDataUser]);


    useEffect(() => {
        let today = new Date();
        let todayFormatted = `${today.getMonth() + 1 >= 10
            ? today.getMonth() + 1
            : "0" + (today.getMonth() + 1)
            }/${today.getDate()}/${today.getFullYear()}`;
        let date1 = () => {
            let date = user
                ? user["scadenza"]
                : `${new Date().getDate()}/${new Date().getMonth() + 1 >= 10
                    ? new Date().getMonth() + 1
                    : "0" + (new Date().getMonth() + 1)
                }/${new Date().getFullYear()}`;
            let day = date ? Number(date.slice(0, 2)) : 1;
            let monthIndex = date ? Number(date.slice(3, 5)) : 0;
            let year = date ? Number(date.slice(7)) : 1900;
            return `${monthIndex}/${day}/${year}`;
        };
        if (new Date(todayFormatted) >= new Date(date1()) || user.length === 0) {
            setRinnova(true);
            setRinnovaAnticipata(false)
        } else {
            setRinnova(false);
            setRinnovaAnticipata(false)
        }
        let dateRinnovo = departments && departments.data && departments.data.data && departments.data.data.length && departments.data.data[0] &&
            new Date(`${departments.data.data[0].open_membership_month}-${departments.data.data[0].open_membership_day}-${new Date().getFullYear()}`)
        if ((new Date(todayFormatted) >= dateRinnovo) && user && user.year_membership <= new Date(todayFormatted).getFullYear()) {
            setRinnova(true);
            setRinnovaAnticipata(true)
        }
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            assoc_type: {
                name: "-",
                value: 0,
                id: null
            },
        },
    });

    function dowloadImage() {
        // Crea un elemento <canvas>
        // const canvas = document.createElement('canvas');
        // const context = canvas.getContext('2d');
        // // Crea un elemento <img> con l'immagine PNG di base
        // const baseImg = new Image();
        // baseImg.src = logo
        // // Carica l'immagine di base e attendi il suo caricamento
        // baseImg.onload = () => {
        //     // Imposta le dimensioni del canvas sulle dimensioni dell'immagine di base
        //     canvas.width = baseImg.width;
        //     canvas.height = baseImg.height;
        //     // Disegna l'immagine di base sul canvas
        //     context.drawImage(baseImg, 0, 0);
        //     // Aggiungi testo colorato
        //     context.fillStyle = 'black';
        //     context.font = '60px Arial';
        //     context.fillText(count && count.profile ? `${count.profile.name} ${count.profile.surname}` : '', 50, 420);
        //     context.font = '36px Arial';
        //     context.fillStyle = 'red';
        //     context.fillText(count && count.profile ? `${count.profile.department_name}` : '', 50, 470);
        //     context.fillStyle = 'red';
        //     context.font = '70px Arial';
        //     context.fillText('2023', baseImg.width - baseImg.width / 4, 450);
        //     // Carica un'altra immagine
        //     const smallImg = new Image();
        //     smallImg.style.mixBlendMode = 'color-burn'
        //     smallImg.crossOrigin="" 
        //     smallImg.src = count.profile['profile_image'] ? count.profile['profile_image'] : noImage;
        //     // Attendiamo il caricamento dell'immagine aggiuntiva
        //     smallImg.onload = () => {
        //         // Disegna l'immagine aggiuntiva sul canvas
        //         context.drawImage(smallImg, 50, 70, 300, 300); // Imposta la posizione e le dimensioni dell'immagine
        //         // Ottieni l'immagine finale come dati PNG
        //         canvas.toBlob((blob) => {
        //             // Crea un URL oggetto dalla blob
        //             const url = URL.createObjectURL(blob);
        //             // Crea un link temporaneo
        //             const link = document.createElement('a');
        //             link.href = url;
        //             link.download = `tessera_associativa_${count && count.profile ? `${count.profile.name}_${count.profile.surname}.png` : ''}`
        //             // Simula un click sull'elemento <a> per avviare il download
        //             link.click();
        //             // Rilascia la risorsa URL dopo il download
        //             URL.revokeObjectURL(url);
        //         }, 'image/png');
        //     }
        // };
        // Crea un link temporaneo
        const link = document.createElement('a');
        link.href = user && user.path;
        link.download = `tessera_associativa_${count && count.profile ? `${count.profile.name}_${count.profile.surname}.png` : ''}`
        // Simula un click sull'elemento <a> per avviare il download
        link.click();
        // Rilascia la risorsa URL dopo il download
        URL.revokeObjectURL(user && user.path);
    }

    if (error) {
        return <ErrorsComponent />
    }
    return (
        <>
            <TopBarIndex />
            <div className={style['main-container'] + ' border-[#F0E9E3] border-t-[90px] min-[670px]:!border-[90px]'}>
                <div
                    className={"my-6 mb-[2rem] text-2xl md:text-4xl text-[#DF5B23] " + style['title']}
                    style={{
                        fontFamily: "gimlet-text",
                        fontWeight: 500,
                        fontStyle: "normal",
                    }}
                >
                    La mia tessera associativa
                </div>
                <div className={style['container-tessera']}>
                    <div className={style['tessera']}>
                        <img style={{ borderRadius: 20 }} className='object-cover' src={logo} alt="" />
                        <div className='ml-2 max-w-[120px] max-h-[120px] w-[30%] h-[100%] relative top-[7.5%]'>
                            <img className='object-cover rounded-full' src={count.profile['profile_image'] ? count.profile['profile_image'] : noImage} alt="" />
                        </div>
                        <div className='ml-4 absolute top-[45%]'>
                            <InputBilo className={'text-[2rem]'} title={count.profile.name + ' ' + count.profile.surname} />
                        </div>
                        <div className='ml-4 absolute top-[60%]'>
                            <InputBilo className={'text-[1rem] text-[#ff0000]'} title={count.profile.department_name} />
                        </div>
                        <div className='absolute text-end right-[20px] min-[410px]:!right-[30px] top-1/2'>
                            <InputBilo fontWeight={700} className={'text-[1.5rem] min-[380px]:!text-[2.5rem] text-[#ff0000]'} title={user && user.year_membership ? user.year_membership : ''} />
                        </div>

                    </div>
                </div>
                <button
                    onClick={dowloadImage}
                    className={"py-3 px-3 mb-[2rem] text-2xl text-white bg-[#DF5B23] " + style['btn-scarica']}
                    style={{
                        fontFamily: "gimlet-text",
                        fontWeight: 500,
                        fontStyle: "italic",
                    }}
                >
                    SCARICA IL JPG
                </button>
            </div>
            <div className={' ml-[90px] mt-[30px] ' + (rinnova ? 'flex items-center gap-2' : '')}>
                {
                    rinnovaAnticipata &&
                    <p
                        className={"text-2xl inline"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 300,
                            fontStyle: "normal",
                        }}>
                        La tua iscrizione scadrà il {user ? user.scadenza : ""}
                        <br />
                        Sono aperte le iscrizioni per l'anno {user ? user.year_membership + 1 : ""}
                    </p>
                }
            </div>

            <div className={style["container-rinnova"] + ' ml-[90px] mb-[140px] ' + (rinnova ? 'flex items-center gap-2' : '')}>

                <p
                    className={"my-6 mb-[2rem] text-2xl inline"}
                    style={{
                        fontFamily: "bilo",
                        fontWeight: 300,
                        fontStyle: "normal",
                    }}>
                    {rinnova
                        ? "Per pagare la quota associativa, clicca "
                        : "La tua iscrizione scadrà il"}{" "}
                </p>
                <p
                    className={"my-6 mb-[2rem] text-2xl inline"}
                    style={{
                        fontFamily: "bilo",
                        fontWeight: 600,
                        fontStyle: "normal",
                    }}>
                    {user && !rinnova ? user.scadenza : ""}
                </p>
                {rinnova ? (
                    <button
                        onClick={() => {
                            api.get("/users/get-association-types").then((res) => {
                                setAssocType(res.data.data);
                                return res;
                            });
                            setOpenRinnova(true);
                        }}
                        className={
                            "py-3 px-3 mb-[2rem] text-2xl text-white bg-[#DF5B23] " +
                            style["btn-rinnova"]
                        }
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}>
                        QUÌ
                    </button>
                ) : (
                    ""
                )}
            </div>

            <div
                className={
                    "2xl:hidden flex flex-col items-center gap-2 bg-[#f0e9e3] mt-8 py-8 relative"
                }>
                <img
                    src={montagne1}
                    alt={"img"}
                    className={"object-contain absolute z-30 -bottom-3"}
                />
            </div>
            <FooterDown />
            {/* RINNOVA TESSERA */}
            <DialogCustom
                title={"Rinnova tessera"}
                fullScreen={false}
                open={openRinnova}
                handleClose={() => {
                    if (
                        formik.values.assoc_type.name === "Sostenitore" &&
                        formik.values.assoc_type.value < 40
                    ) {
                    } else {
                        setOpenRinnova(false);
                        setOpenPaymentMethod(true);
                    }
                }}
                closeModal={() => setOpenRinnova(false)}
                content={
                    <form className="flex items-center justify-center gap-2">
                        <InputCustom
                            select
                            fullWidth
                            title={"Tipo di associazione*"}
                            name={"assoc_type"}
                            value={formik.values.assoc_type.name}
                            onChange={(e) => {
                                let value = assocType && assocType.filter(assoc => assoc.name === e.target.value) && assocType.filter(assoc => assoc.name === e.target.value)[0] && assocType.filter(assoc => assoc.name === e.target.value)[0].value
                                let id = assocType && assocType.filter(assoc => assoc.name === e.target.value) && assocType.filter(assoc => assoc.name === e.target.value)[0] && assocType.filter(assoc => assoc.name === e.target.value)[0].id
                                setErrorAssocValue(false);
                                formik.setFieldValue("assoc_type", {
                                    name: e.target.value,
                                    value: Number(value),
                                    id: id
                                });
                            }}>
                            <MenuItem
                                value={'-'}
                                key={0}>
                                -
                            </MenuItem>
                            {assocType.map((assoc, index) => {
                                return (
                                    <MenuItem value={assoc.name} key={index + 1}>
                                        {assoc.name}
                                    </MenuItem>
                                );
                            })}
                        </InputCustom>
                        <div>
                            <InputCustom
                                classcontainer={"w-auto"}
                                disabled={formik.values.assoc_type.name !== "Sostenitore"}
                                title={formik.values.assoc_type.name === "Sostenitore" ? "a partire da*" : "Importo richiesto*"}
                                value={formik.values.assoc_type.value}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min:
                                            formik.values.assoc_type.name === "Sostenitore"
                                                ? 40
                                                : formik.values.assoc_type.value,
                                    },
                                }}
                                onChange={(e) => {
                                    if (
                                        formik.values.assoc_type.name === "Sostenitore" &&
                                        e.target.value < 40
                                    ) {
                                        setErrorAssocValue(true);
                                    } else {
                                        setErrorAssocValue(false);
                                    }
                                    formik.setFieldValue("assoc_type", {
                                        ...formik.values.assoc_type,
                                        value: e.target.value,
                                    });
                                }}
                            />
                            {errorAssocValue && (
                                <InputBilo
                                    className={"text-[#ff0000]"}
                                    title={
                                        'Il tipo associzione "Sostenitore" prevede una quota minima di 40€'
                                    }
                                />
                            )}
                        </div>
                    </form>
                }
                button={"PROCEDI"}
                buttonDisabled={(formik.values.assoc_type.name === '-') || (formik.values.assoc_type.name === 'Sostenitore' && formik.values.assoc_type.value < 40)}

            />
            {/*PAGAMENTO RINNOVO*/}
            <DialogCustom
                open={openPaymentMethod}
                title={"Scegli"}
                content={
                    <>
                        <InputBilo
                            className={"text-2xl"}
                            title={`Tipo di associazione: ${formik.values.assoc_type.name}`}
                        />
                        <InputBilo
                            className={"text-2xl"}
                            title={`Importo: €${formik.values.assoc_type.value}`}
                        />
                        <InputBilo
                            className={"text-xl"}
                            title={
                                env.borsellinoFunction ? "Preferisci pagare con il borsellino elettronico o con Carta?" : 'Con cosa vuoi pagare?'
                            }
                        />
                    </>
                }
                handleClose={() => {
                    api
                        .post(
                            `users/${count.profile.id}/borsellino/prepareWalletAssociation`,
                            {
                                user_id: count.profile.id,
                                association_type_id: formik.values.assoc_type.id,
                                price: formik.values.assoc_type.value,
                            }
                        )
                        .then((res) => {
                            localStorage.setItem("lastpage", location.pathname);
                            if (res && res.data && res.data.data) {
                                navigate("/payment-success");
                            } else {
                                setErrorAssoc({
                                    open: true,
                                    error:
                                        res && res.data && res.data.errors
                                            ? res.data.errors[Object.keys(res.data.errors)[0]][0]
                                            : "",
                                });
                            }
                            return res;
                        });
                    setOpenPaymentMethod(false);
                }}
                closeModal={() => {
                    setOpenPaymentMethod(false);
                }}
                handleClose2={() => {
                    api
                        .post(`users/${count.profile.id}/associationWithoutWallet`, {
                            user_id: count.profile.id,
                            association_type_id: formik.values.assoc_type.id,
                            price: formik.values.assoc_type.value,
                        })
                        .then((res) => {
                            localStorage.setItem("lastpage", location.pathname);
                            if (res && res.data && res.data.data) {
                                window.open(res.data.data);
                            } else {
                                setErrorAssoc({
                                    open: true,
                                    error:
                                        res && res.data && res.data.errors
                                            ? res.data.errors[Object.keys(res.data.errors)[0]][0]
                                            : "",
                                });
                            }
                            return res;
                        });

                    setOpenPaymentMethod(false);
                }}
                button={env.borsellinoFunction ? "Borsellino elettronico" : null}
                button2={"Carta"}
            />
            {/* ERROR PAGAMENTO */}
            <DialogCustom
                open={errorAssoc.open}
                title={"Errore"}
                content={
                    <>
                        <InputBilo className={"text-xl"} title={errorAssoc.error} />
                    </>
                }
                button={"Chiudi"}
                handleClose={() => {
                    setErrorAssoc((prevState) => {
                        return {
                            open: false,
                            error: "",
                        };
                    });
                }}
                closeModal={() => {
                    setErrorAssoc((prevState) => {
                        return {
                            open: false,
                            error: "",
                        };
                    });
                }}
            />
        </>
    );
}

export default TesseraAssociativa;

import React from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import InputBilo from "../components/InputBilo";

const Contatti = () => {

    const fetcher1 = (url) =>
        api.get(url).then((res) => {
            return res;
        });
    const { data: departments } = useSWR([`/all-departments`], fetcher1);

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Contatti"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Associazione &gt; </div>
                        <div className={"font-bold"}>Contatti</div>
                    </div>
                    {/* Content */}
                    <div
                        className={"my-6 mb-[3.5rem] text-4xl text-[#DF5B23]"}
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}
                    >
                        I CONTATTI DI OGNI SEZIONE
                    </div>
                    <div>
                        {
                            departments && departments.data && departments.data.data && departments.data.data.map((department, index) => {
                                if (department.name === 'Nazionale') { return '' }
                                return (
                                    <React.Fragment key={index}>
                                        <InputBilo title={department.name} fontWeight={500} className="text-2xl text-[#DF5B23]" />
                                        <div className="w-full flex">
                                            <a
                                                href={`mailto:${department ? department["email_info"] : ""}`}
                                                style={{
                                                    fontFamily: "bilo",
                                                    fontStyle: "normal",
                                                }}
                                                className={"text-xl mb-5"}>
                                                {department ? department["email_info"] : ""}
                                            </a>
                                            {
                                                department.phonenumbers.map((tel, index) => {
                                                    return (
                                                        <div className="flex" key={index}>
                                                            <div className="whitespace-pre h-full text-xl">
                                                                {' | '}
                                                            </div>
                                                            <InputBilo
                                                            className="whitespace-pre text-xl"
                                                            fontWeight={300}
                                                            title={'tel. '}
                                                            />
                                                            <a
                                                                href={`tel:${tel ? tel.phone_number : ""}`}
                                                                style={{
                                                                    fontFamily: "bilo",
                                                                    fontStyle: "normal",
                                                                }}
                                                                className={"text-xl mb-5"}>
                                                                {tel ? tel.phone_number : ""}
                                                            </a>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                department.faxs.map((fax, index) => {
                                                    return (
                                                        <div className="flex" key={index}>
                                                            <div className="whitespace-pre h-full text-xl">
                                                                {' | '}
                                                            </div>
                                                            <a
                                                                href={`fax:${fax ? fax.fax : ""}`}
                                                                style={{
                                                                    fontFamily: "bilo",
                                                                    fontStyle: "normal",
                                                                }}
                                                                className={"text-xl mb-5"}>
                                                                fax. {fax ? fax.fax : ""}
                                                            </a>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>


            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default Contatti;
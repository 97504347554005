import React from "react";
import Topbar from "../components/Topbar";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/cataloghi.jpg";
import ItemCatalog from "../components/ItemCatalog";
import TopBarIndex from "../components/TopBarIndex";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import ErrorsComponent from "../components/ErrorsComponent";
import LoadingComponent from "../components/LoadingComponent";

const Cataloghi = () => {
  const navigate = useNavigate();
  const { token } = useSelector((rootState) => rootState.count);
  api.setHeaders({
    Authorization: "Bearer " + token,
  });

  const fetcher = (url) =>
    api
      .post(url, {
        active: 1,
        order_by_last: 1,
        limit: 12,
      })
      .then((res) => res);
  const { data } = useSWR([`/catalogs`], fetcher);

  if (data && !data.ok) return <ErrorsComponent />;
  if (!data) return <LoadingComponent />;
  return (
    <>
      <TopBarIndex />
      <BackgroundTitle title={"Cataloghi"} img={img1} />
      <div
        className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}
        style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
      >
        <div className={"flex flex-row items-center gap-1"}>
          <div>Home {">"}</div>
          <div className={"font-bold"}> Cataloghi</div>
        </div>
        <div className={"mt-20 flex flex-col lg:grid lg:grid-cols-3 gap-4"}>
          {data.data.data.map((res, index) => {
            return (
              <React.Fragment key={index}>
                <ItemCatalog
                  img={res.preview_image_path}
                  title={res.title}
                  badge={index === 0}
                  res={res}
                  colSpan={index === 0 && "col-span-2"}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <FooterTop />
      <FooterDown />
    </>
  );
};

export default Cataloghi;

import moment from "moment";

const CardStorico = ({ img, res, isEvento = true }) => {

    return (
        <div className="max-w-[399px] max-h-[528px] w-[30vh] h-[40vh] mr-[50px] mb-[50px]">
            <div className="h-[76%] relative">
                <div
                    style={{
                        fontFamily: "bilo",
                        fontWeight: 300,
                        fontStyle: "normal",
                    }}
                    className="absolute top-4 left-1/2 translate-x-[-50%] text-2xl z-10 text-white">{isEvento ? "EVENTO" : "CORSO"}
                </div>
                <div style={{
                    fontFamily: "bilo",
                    fontWeight: 600,
                    fontStyle: "normal",
                }}
                    className="absolute top-10 left-1/2 translate-x-[-50%] text-2xl z-10 whitespace-nowrap text-white">{res && res.nome ?res.nome.toUpperCase() : ''}
                </div>
                <div className="h-full">
                    <img className="w-full h-full object-cover opacity-50 z-5 rounded-t-3xl" src={img} alt="" />
                </div>
                <div className="h-[72px] w-[72px] absolute bg-transparent top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 rounded-full border-4 border-solid border-[#F0E9E3]"></div>
            </div>
            <div className="h-[24%] bg-white rounded-b-3xl">
                <div style={{
                    fontFamily: "bilo",
                    fontWeight: 300,
                    fontStyle: "normal",
                }} className="text-center text-xl pt-[10px] text-[#796557]">Partecipato il</div>
                <div style={{
                    fontFamily: "bilo",
                    fontWeight: 600,
                    fontStyle: "normal",
                }} className="text-center text-2xl text-[#796557]">{isEvento ? `${res.date_event.split('-')[2]}-${res.date_event.split('-')[1]}-${res.date_event.split('-')[0]}` : moment(res.date_start).format('DD-MM-YYYY')}</div>
            </div>
        </div>
    );
};

export default CardStorico;

import React from "react";
import Topbar from "./Topbar";
import TopbarMobile from "./TopbarMobile";

const TopBarIndex = (props) => {
  return (
    <>
      <div className={"hidden lg:block"}>
        <Topbar logged={props.logged}/>
      </div>
      <div className={"block lg:hidden"}>
        <TopbarMobile setDrawerIsOpen={props.setDrawerIsOpen}/>
      </div>
    </>
  );
};

export default TopBarIndex;

import React from "react";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { Link, useNavigate } from "react-router-dom";
import ButtonCustom from "./ButtonCustom";

const ItemArchivioNews = ({ index, setOpenArchivio }) => {

    const navigate = useNavigate();
    return (
        <div
            className={
                "rounded-xl p-4 flex-flex-col bg-[#f0e9e3] xl:w-80 lg:w-full lg:h-80 h-56 lg:m-8 lg:m-4 max-h-full"
            }
        >
            <div className={"flex flex-row items-center gap-4 lg:mb-24 mb-2"}>
                <div
                    className={"flex flex-col bg-[#DF5B23] rounded-xl p-2 text-center "}
                >
                    <StickyNote2Icon
                        className={"text-white"}
                        sx={{ height: 40, width: 40 }}
                    />
                </div>
                <div className={"flex flex-col text-[#796557]"}>
                    <div className={"text-lg"}>News</div>
                    <div className={"font-bold text-xl"}>ANNO {index + 2024}</div>
                </div>
            </div>
            <div style={{ fontFamily: "gimlet-text" }} className={"text-xl mb-4"}>
                Tutte le news pubblicate in questo anno
            </div>
            {/* <Link
        to={"/news/"}
        style={{ fontFamily: "gimlet-text" }}
        className={"text-xl cursor-pointer hover:opacity-50"}
      >
        LEGGI DI PIU'
      </Link> */}
            <button
            onClick={()=>{setOpenArchivio(index + 2024)}}
                style={{ fontFamily: "gimlet-text" }}
                className={"text-xl cursor-pointer hover:opacity-50"}>
                LEGGI DI PIU'
            </button>
        </div>
    );
};

export default ItemArchivioNews;

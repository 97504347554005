import React, { useEffect, useRef, useState } from "react";
import BackgroundTitle from "../components/BackgroundTitle";
import TopBarIndex from "../components/TopBarIndex";
import img1 from "../assets/img/img-slider-home.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import InputBilo from "../components/InputBilo";
import InputGimlet from "../components/InputGimlet";
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Rating, TextField } from "@mui/material";
import select from "../assets/img/freccina-drop-down-home.png";
import mappa from "../assets/img/icona_mappa.png";
import rettangolo from "../assets/img/rettangolo.png";
import trekimg from "../assets/img/img-trek-1.png";
import trekimg2 from "../assets/img/img-trek-2.png";
import { useSelector } from "react-redux";
import { api } from "../conf/apiConf";
import { CancelToken } from 'apisauce'
import useSWR from "swr";
import ErrorsComponent from "../components/ErrorsComponent";
import LoadingComponent from "../components/LoadingComponent";
import Trek from "../components/Trek";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import TrekItaliaMaps from "./TrekItaliaMaps";
import MapContainer from "./MapContainer";
import LandscapeIcon from "@mui/icons-material/Landscape";
import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";
import { useTranslation } from "react-i18next";
import i18App from "../conf/i18n";
import style from "./TrekItalia.module.css";
import legendDifficulty from "../assets/img/scheda_valutazione.61de7991.jpg";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";
import PaginationCustom from "../components/PaginationCustom";
import GoogleMapContainer from "./GoogleMapContainer";
import InputCustom from "../components/InputCustom";
import DialogCustom from "../components/DialogCustom";
import Login from "../Auth/Login";
import { debounce } from "lodash"
import SearchIcon from '@mui/icons-material/Search';
import RatingCustom from "../components/RatingCustom";
import filterIcon from "../assets/img/filtri_icon.png"

let countFetched = 0; //variabile creata al solo scopo di non far finire in errore la pagina

export function dataFormatter(value = "") {
    let date = "";
    if (value === "") {
        date = new Date();
    } else {
        date = value;
    }
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);
    let milliseconds = ("00" + date.getMilliseconds()).slice(-3);

    let formattedDate =
        year +
        "-" +
        month +
        "-" +
        day +
        "T" +
        hours +
        ":" +
        minutes +
        ":" +
        seconds +
        "." +
        milliseconds +
        "Z";
    return formattedDate;
}

// da query string a oggetto js
function parseQueryParams(queryString, pos) {
    let formattedDate = dataFormatter();
    if (queryString === "") {
        queryString =
            "?date=" +
            formattedDate +
            "&dateEnd=null&maps=false&type_trek=null&destination=null&difficulty=&regions=null&typology=&departments=&title=&label=null&position=" + pos;

    }
    const urlParams = new URLSearchParams(queryString);
    const params = {};
    for (let [key, value] of urlParams.entries()) {
        if (Number(value) || Number(value) === 0) {
            value = Number(value);
        }
        if (value === "null") {
            value = null;
        }
        if (value === "true") {
            value = true;
        }
        if (value === "false") {
            value = false;
        }
        if (value === "undefined") {
            value = "";
        }
        if (!params[key]) {
            params[key] = value;
        } else {
            if (Array.isArray(params[key])) {
                params[key].push(value);
            } else {
                params[key] = [params[key], value];
            }
        }
    }
    return params;

}

// da oggetto js a query string
function createUrlFromObject(baseUrl, params, listFiltriApplicati) {
    const url = new URL(baseUrl);
    const urlParams = new URLSearchParams();
    const urlListFiltriApplicati = new URLSearchParams();
    // Aggiungi i parametri all'URLSearchParams
    for (const key in params) {
        if (Array.isArray(params[key])) {
            if (params[key].length > 0) {
                params[key].forEach((value) => urlParams.append(key, value));
            } else {
                urlParams.append(key, ""); // Aggiungi un parametro vuoto per l'array vuoto
            }
        } else {
            urlParams.append(key, params[key]);
        }
    }

    listFiltriApplicati.map((filtroApplicato) => {
        for (const key in filtroApplicato) {
            if (Array.isArray(filtroApplicato[key])) {
                if (filtroApplicato[key].length > 0) {
                    filtroApplicato[key].forEach((value) =>
                        urlListFiltriApplicati.append(key, value)
                    );
                } else {
                    urlListFiltriApplicati.append(key, ""); // Aggiungi un parametro vuoto per l'array vuoto
                }
            } else if (key === "set") {
            } else {
                urlListFiltriApplicati.append(key, filtroApplicato[key]);
            }
        }
        return 0;
    });

    // Aggiungi la stringa di query all'URL
    // il 'filtri=&' dopo '&' serve per identificare i parametri che riguardano la lista dei filtri
    url.search =
        urlParams.toString() +
        (urlListFiltriApplicati.toString() === ""
            ? ""
            : "&filtri=&" + urlListFiltriApplicati.toString());

    return url.search;
}

function insertDinamicArray(id, set, value, univoco = false, name = "") {
    if (typeof value === "object" && value.includes(id)) {
        set(value.filter((selectedId) => selectedId !== id));
    } else if (value === id) {
        if (name !== "") {
            set(id);
        } else {
            set(0);
        }
    } else {
        if (univoco) {
            typeof value === "object" ? set([id]) : set(id);
        } else {
            typeof value === "object" ? set([...value, id]) : set(id);
        }
    }
}

let positionDummy = [
    {
        id: 1,
        name: "Italia",
    },
    {
        id: 2,
        name: "Estero",
    },
];

const TrekItalia = ({ pos = 'Italia', coords = null }) => {


    const [type, setType] = useState("default");
    const [type2, setType2] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [type3, setType3] = useState("default");
    const [countries, setCountries] = useState("default");
    const [difficulty, setDifficulty] = useState(0);
    const [difficultyState, setDifficultyState] = useState([false, false, false, false, false, false]);
    const [trek, setTrek] = React.useState([]);
    const [bool, setBool] = React.useState(pos === 'Geolocalizzato' || (localStorage.getItem('mapIsActive') === 'true') ? true : false);
    const [typology, setTypology] = React.useState([]);
    const [departmentsArray, setDepartmentsArray] = React.useState([]);
    const [apply, setApply] = React.useState(false);
    const { t, i18n } = useTranslation();
    const [mobile, setMobile] = useState();
    const [listaFiltri, setListaFiltri] = useState(false);
    const [firstTime, setfirstTime] = useState(true);
    const [firstTime2, setfirstTime2] = useState(true);
    const [filtriApplicati, setFiltriApplicati] = useState([]);
    const [position, setPosition] = useState(pos)
    const [title, setTitle] = useState('')
    const [infoDifficolta, setInfoDifficolta] = useState(false);
    const [page, setPage] = useState(1);
    const [changedPage, setChangedPage] = useState(1);
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false)
    const [source, setSource] = useState(CancelToken.source())
    const [openSalvaRicerca, setOpenSalvaRicerca] = useState(false)
    const [titleSalvaRicerca, setTitleSalvaRicerca] = useState('')
    const [isSaved, setIsSaved] = useState({
        open: false,
        data: null
    })
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenMobile, setIsOpenMobile] = useState(false)
    const [isOpenClass, setIsOpenClass] = useState(false)
    const [isOpenClassMobile, setIsOpenClassMobile] = useState(false)
    const [stateFiltered, setStateFiltered] = useState(false)
    const [statesShown, setStatesShown] = useState([])
    const [regionsShown, setRegionsShown] = useState([])
    const [getTreksPoint, setGetTreksPoint] = useState([])
    const [errorDate, setErrorDate] = useState(false)
    // const { t, tChoice } = useLaravelReactI18n();

    const endAdornmentDest = useRef(null)
    const count = useSelector(state => state.count)

    const [debouncedInputValue, setDebouncedInputValue] = useState("");

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedInputValue(title);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [title, 500]);

    const objSetState = {
        setType2: setType2,
        setDateEnd: setDateEnd,
        setDifficulty: setDifficulty,
        setType: setType,
        setTypology: setTypology,
        setDepartmentsArray: setDepartmentsArray,
        setType3: setType3,
        setPosition: setPosition,
        setTitle: setTitle,
        setCountries: setCountries
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const { token } = useSelector((rootState) => rootState.count);
    api.setHeaders({
        Authorization: "Bearer " + token,
    });

    //appena carica la pagina prende dall'url i filtri dei trek
    useEffect(() => {
        const ricercaSalvata = parseQueryParams(
            window.location.search.split("filtri=&")[0],
            pos
        );
        const listaFiltriApplicati = parseQueryList(
            window.location.search.split("filtri=&")[1]
        );
        if (ricercaSalvata.typology === "" || ricercaSalvata.typology === 0) {
            ricercaSalvata.typology = [];
            if (listaFiltriApplicati.typology) {
                listaFiltriApplicati.typology = [];
            }
        }
        if (ricercaSalvata.difficulty === "" || ricercaSalvata.difficulty === 0) {
            ricercaSalvata.difficulty = [];
            if (listaFiltriApplicati.difficulty) {
                listaFiltriApplicati.difficulty = [];
            }
        }
        if (ricercaSalvata.departments === "" || ricercaSalvata.departments === 0) {
            ricercaSalvata.departments = [];
            if (listaFiltriApplicati.departments) {
                listaFiltriApplicati.departments = [];
            }
        }
        if (Number(ricercaSalvata.typology)) {
            ricercaSalvata.typology = [ricercaSalvata.typology];
            if (listaFiltriApplicati.typology) {
                listaFiltriApplicati.typology = [listaFiltriApplicati.typology];
            }
        }
        if (Number(ricercaSalvata.difficulty)) {
            ricercaSalvata.difficulty = [ricercaSalvata.difficulty];
            if (listaFiltriApplicati.difficulty) {
                listaFiltriApplicati.difficulty = [listaFiltriApplicati.difficulty];
            }
        }
        if (Number(ricercaSalvata.departments)) {
            ricercaSalvata.departments = [ricercaSalvata.departments];
            if (listaFiltriApplicati.departments) {
                listaFiltriApplicati.departments = [listaFiltriApplicati.departments];
            }
        }

        listaFiltriApplicati.map((filtro) => {
            if (
                filtro.nameSet === "setTypology" ||
                filtro.departments === "setDepartmentsArray"
            ) {
                filtro.initialState = [];
            }
            return 0;
        });
        let difficulty = [false, false, false, false, false, false]
        ricercaSalvata.difficulty.map((item, index) => {
            difficulty[item - 1] = true
            return 0
        })
        setType2(ricercaSalvata.date);
        setDateEnd(ricercaSalvata.dateEnd);
        setDifficultyState(difficulty);
        setType(ricercaSalvata.regions);
        setTypology(ricercaSalvata.typology);
        setDepartmentsArray(ricercaSalvata.departments);
        setType3(ricercaSalvata.label);
        setPosition(ricercaSalvata.position);
        setTitle(ricercaSalvata.title === 0 ? '' : ricercaSalvata.title)
        setFiltriApplicati(listaFiltriApplicati);
    }, []);

    //Ricarica se i filtri cambiano
    useEffect(() => {
        setApply((a) => !a);
    }, [
        type2,
        dateEnd,
        type,
        typology,
        departmentsArray,
        type3,
        position,
        countries,
        filtriApplicati
    ]);

    //se viene caricata per la prima volta 
    //non bisogna aggiungere/trogliere nulla all'url
    useEffect(() => {

        if (firstTime2 === true) {
            setfirstTime2(false);
        } else {
            addUrl();
        }
    }, [apply]);

    //Aggiungi i filtri all'url
    function addUrl() {
        let formattedDate = dataFormatter();
        let difficulty = new Array()
        if (difficultyState && difficultyState.includes(true)) {
            difficultyState.map((diff, index) => {
                if (diff === true) {
                    difficulty.push(index + 1)
                }
            })
        }
        setSearchParams(
            createUrlFromObject(
                window.location.href,
                {
                    date: type2 ? type2 : formattedDate,
                    dateEnd: dateEnd,
                    maps: false,
                    type_trek: null,
                    destination: countries === "default" ? null : countries === "undefined" ? "" : countries,
                    difficulty: difficulty,
                    regions: type === "default" ? null : type === "undefined" ? "" : type,
                    typology: typology,
                    departments: departmentsArray,
                    label: type3 === "default" ? null : type3 === "undefined" ? "" : type3,
                    position: pos,
                    title: title && title !== 0 ? title : '',
                },
                filtriApplicati
            )
        );
    }

    //Raccoglie le info su l'url (la parte dei filtri apllictai)
    function parseQueryList(queryString) {
        const urlList = new URLSearchParams("?" + queryString);
        const list = [];
        let [key, value] = urlList.entries();
        let i = 0;
        let j = 0;
        let obj = {};
        for (let [key, value] of urlList.entries()) {
            if (Number(value) || value === "0") {
                value = Number(value);
            }
            if (value === "null") {
                value = null;
            }
            if (value === "true") {
                value = true;
            }
            if (value === "false") {
                value = false;
            }
            if (value === "undefined") {
                value = "";
            }
            obj[key] = value;
            if (i === 3) {
                i = 0;
                j++;
                list.push({ ...obj });
            }
            i++;
        }
        return list;
    }

    const fetcher = async (url) => {
        try {
            setLoading(true)
            source.cancel()
            const newSource = CancelToken.source()
            setSource(newSource)
            countFetched++;
            if (countFetched === 7) {
                checkError()
            }
            let difficulty = new Array()
            if (difficultyState && difficultyState.includes(true)) {
                difficultyState.map((diff, index) => {
                    if (diff === true) {
                        difficulty.push(index + 1)
                    }
                })
            }
            const res = await api.post(url, {
                date: type2 ? type2 : dataFormatter(),
                dateEnd: dateEnd,
                maps: false,
                type_trek: null,
                destination: countries === "default" ? null : countries === "undefined" ? "" : countries,
                difficulty: difficulty,
                regions: type === "default" ? null : type,
                typology: typology,
                departments: departmentsArray,
                label: type3 === "default" ? null : type3,
                position: pos,
                title: title ? title : '',
            }, { cancelToken: newSource.token });
            setLoading(res.ok ? false : true)
            setTrek(res.ok ? res.data.data : []);
            return res;
        } catch (error) {
            // Gestione dell'errore, se necessario
            console.error("Errore durante la chiamata API:", error);
            throw error;
        }
    };

    const { data } = useSWR(
        [`/search-trek?page=${page}`, apply, page],
        fetcher
    );

    const fetcher2 = async (url) => {
        try {
            countFetched++;
            if (countFetched === 7) {
                checkError()
            }
            let difficulty = new Array()
            if (difficultyState && difficultyState.includes(true)) {
                difficultyState.map((diff, index) => {
                    if (diff === true) {
                        difficulty.push(index + 1)
                    }
                })
            }
            const res = await api.post(url, {
                date: type2 ? type2 : dataFormatter(),
                dateEnd: dateEnd,
                maps: false,
                type_trek: null,
                destination: countries === "default" ? null : countries === "undefined" ? "" : countries,
                difficulty: difficulty,
                regions: type === "default" ? null : type,
                typology: typology,
                departments: departmentsArray,
                label: type3 === "default" ? null : type3,
                position: pos,
                title: title ? title : '',
            });
            setGetTreksPoint(res.data.data);
            return res;
        } catch (error) {
            // Gestione dell'errore, se necessario
            console.error("Errore durante la chiamata API:", error);
            throw error;
        }
    }
    const { data: treks } = useSWR([`/get-treks-point`, apply], fetcher2);

    const fetcher3 = (url) => api.get(url).then((res) => { countFetched++; if (countFetched === 7) { checkError() }; return res });
    const { data: regions } = useSWR([`/regions/118`], fetcher3);

    const fetcher4 = (url) => api.get(url).then((res) => { countFetched++; if (countFetched === 7) { checkError() }; return res });
    const { data: departments } = useSWR([`/all-departments`], fetcher4);

    const fetcher5 = (url) => api.get(url).then((res) => { countFetched++; if (countFetched === 7) { checkError() }; return res });
    const { data: filter } = useSWR([`/all-formats`], fetcher5);

    const fetcher6 = (url) => api.get(url).then((res) => { countFetched++; if (countFetched === 7) { checkError() }; return res });
    const { data: label } = useSWR([`/all-label`], fetcher6);

    const fetcher7 = (url) => api.get(url).then((res) => {
        countFetched++; if (countFetched === 7) { checkError() };
        return res
    });
    const { data: states } = useSWR([`/filter-list`], fetcher7);

    useEffect(() => {

        return () => {
            setGetTreksPoint([])
        }
    }, [])

    //visualizza solo gli stati èresenti nei trek(da finire)
    useEffect(() => {
        if (pos === 'Estero' && states && treks && states.ok && getTreksPoint && getTreksPoint.length > 0) {
            states.data && states.data.data && states.data.data.states &&
                setStatesShown(
                    states.data.data.states.filter((state) => {
                        if (state.name === 'Italia') { return false }
                        return getTreksPoint.some((item) => {

                            return item.countries.includes(state.name)
                        })
                    }))
        } else {
            setStatesShown([])
        }
        if (pos === 'Italia' && regions && regions.ok && treks && getTreksPoint && getTreksPoint.length > 0) {
            regions.data && regions.data.data &&
                setRegionsShown(
                    regions.data.data.filter((region) => {
                        return getTreksPoint.some((item) => {
                            if (!item.regions) {
                                console.log(item)
                            }

                            return item.regions ? item.regions.includes(region.name) : false
                        })
                    }))
        } else {
            setRegionsShown([])
        }
    }, [pos, getTreksPoint, apply])

    //funzione per aggiungere/togliere i filtri da applicare
    function applicaFiltri2(
        name,
        set,
        value,
        filterState,
        initialState,
        nameSet,
        unico = true,
        viewed = true,
        fetch = true
    ) {
        if (!bool) {
            window.scrollTo({
                top: 800,
                left: 0,
                behavior: "smooth",
            });
        }
        if (unico) {
            let same = false;
            filtriApplicati.map((filtro) => {
                if (filtro.name === name || filtro.nameSet === nameSet) {
                    same = true;
                    return true;
                }
                return false;
            });
            if (viewed) {
                if (same) {
                    let newValue = filtriApplicati.filter((filtro) => {
                        return filtro.nameSet !== nameSet
                    })
                    setFiltriApplicati([
                        ...newValue,
                        {
                            name: name,
                            value: value,
                            initialState: initialState,
                            set: set,
                            nameSet: nameSet,
                        }
                    ])
                } else {
                    setFiltriApplicati((prevState) => [
                        ...prevState,
                        {
                            name: name,
                            value: value,
                            initialState: initialState,
                            set: set,
                            nameSet: nameSet,
                        }
                    ])
                }

            }
            set(value);
        } else {
            let same = false;
            filtriApplicati.map((filtro) => {
                if (filtro.name === name) {
                    same = true;
                    return true;
                }
                return false;
            });
            if (same) {
                //elimina filtro
                if (viewed) {
                    setFiltriApplicati(
                        filtriApplicati.filter((filtro) => {
                            return filtro.name !== name;
                        })
                    );
                }
                set(
                    filterState.filter((valueState) => {
                        return valueState !== value;
                    })
                );
            } else {
                //aggiungi filtro
                if (viewed) {
                    setFiltriApplicati((prevState) => [
                        ...prevState,
                        {
                            name: name,
                            value: value,
                            initialState: initialState,
                            set: set,
                            nameSet: nameSet,
                        },
                    ]);
                }
                set((prevState) => [...prevState, value]);
            }
        }
        if (fetch) {
            setPage(1);
            setApply(a => !a);
        }
    }

    //Rebounce function
    // const handleSearchDebounced = debounce((value)=>{
    //     applicaFiltri2(
    //         value,
    //         setTitle,
    //         value,
    //         title,
    //         '',
    //         "setTitle"
    //     );
    // }, 300)

    // //Cancella il debounce quando il componente viente smontato
    // useEffect(()=>{
    //     return ()=>{
    //         handleSearchDebounced.cancel()
    //     }
    // }, handleSearchDebounced)

    //media query
    useEffect(() => {
        let x = window.matchMedia("(max-width: 700px)");
        if (x.matches) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, []);

    const fomrSalvaRicerca = () => {
        return (
            <InputCustom
                title={"Nome ricerca*"}
                value={titleSalvaRicerca}
                fullWidth
                type="text"
                onChange={(e) => setTitleSalvaRicerca(e.target.value)}
            />
        )
    }

    function checkError() {
        if ((data && !data.ok) ||
            (treks && !treks.ok) ||
            (regions && !regions.ok) ||
            (departments && !departments.ok) ||
            (filter && !filter.ok) ||
            (label && !label.ok) ||
            (states && !states.ok))

            return <ErrorsComponent />;
    }
    if (
        !data ||
        !treks ||
        !regions ||
        !departments ||
        !filter ||
        !label ||
        !states
    )
        return <LoadingComponent />;

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={pos === 'Italia' ? "I trek in Italia" : (pos === 'Estero' ? "I trek all'estero" : 'Trek geolocalizzati')}
                img={img1}
                subtitle={pos === 'Italia' ? "Scopri i trekking in partenza nel nostro Bel Paese" : ''}
                position={"center"}
            />
            {
                /* info difficoltà */
                infoDifficolta ? (
                    <div className="transition-shadow shadow-[0_0_6000px_6000px_rgba(33,33,33,0.3)] fixed z-[1] bg-white fixed top-[20px]  left-1/2 -translate-x-[50%] w-[80%] h-[90vh]">
                        <button
                            className="m-2 w-[25px] h-[25px] rounded-full border-[1px] border-black flex justify-center items-center mx-[10px]"
                            onClick={() => {
                                setInfoDifficolta(false);
                                setListaFiltri(true);
                                document.body.style.overflow = "auto";
                            }}>
                            X
                        </button>
                        <img src={legendDifficulty} alt="legenda" />
                    </div>
                ) : (
                    ""
                )
            }
            <div
                className={
                    "2xl:mb-[200px] flex flex-col lg:grid lg:grid-cols-4 py-24 px-8 text-black bg-white"
                }>
                <div className="hidden lg:flex flex-col mt-[12px]">
                    {/* <h1>{t("title")}</h1>
            <p>{t("description")}</p> */}
                    <InputBilo className="text-lg" title={"I nostri trek"} />

                    <div className="rounded-t-xl bg-[#DF5B23] mt-6 text-white">
                        <div className="flex flex-row items-center w-full justify-end gap-2 px-6">
                            <div className="uppercase font-light text-lg mr-8">
                                Visualizza come
                            </div>
                            <div
                                className={`min-w-[30px] cursor-pointer mr-2 hover:opacity-75 ${!bool && "bg-black rounded-b-xl"
                                    } p-2`}
                                onClick={() => {

                                    localStorage.setItem('mapIsActive', false)
                                    setBool(false)
                                }}>
                                <img src={rettangolo} alt={"img"} className="w-8" />
                            </div>
                            <div
                                className={`min-w-[30px] cursor-pointer mr-2 hover:opacity-75 ${bool && "bg-black rounded-b-xl"
                                    } p-2`}
                                onClick={() => {
                                    localStorage.setItem('mapIsActive', true)
                                    setBool(true)
                                }}>
                                <img src={mappa} alt={"img"} className="w-8" />
                            </div>
                        </div>
                        <div className="w-full flex flex-row items-center justify-center text-center">
                            <InputGimlet
                                className="text-2xl py-2"
                                title={"Trova il tuo prossimo trek"}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap w-full bg-[#F0E9E3] p-2">
                        {filtriApplicati.map((filtro, index) => {
                            return (
                                <div
                                    key={index}
                                    className="flex items-center mr-[10px] mt-[10px] bg-[#df5b23] text-white p-1">
                                    {filtro.name}
                                    <button
                                        onClick={() => {
                                            if (filtro.nameSet === 'setDifficultyState') {
                                                setDifficultyState(prev => {
                                                    let newValue = prev
                                                    newValue[filtro.value] = false
                                                    return newValue
                                                })
                                                setFiltriApplicati(() => {
                                                    return filtriApplicati.filter((filtroApplicato) => {
                                                        return filtroApplicato.name !== filtro.name;
                                                    })
                                                })
                                            } else {
                                                objSetState[filtro.nameSet]((prevState) => {
                                                    let newValue;
                                                    if (Array.isArray(prevState)) {
                                                        newValue = prevState.filter((selectedValue) => {
                                                            return selectedValue !== filtro.value;
                                                        });
                                                    } else {
                                                        newValue = filtro.initialState;
                                                    }
                                                    return newValue;
                                                });
                                                setFiltriApplicati(
                                                    filtriApplicati.filter((selectedName) => {
                                                        return selectedName !== filtro;
                                                    })
                                                );
                                            }

                                            // filtro.set(filtro.initialState)
                                        }}
                                        className="ml-[5px] w-[30px] h-[30px] hover:opacity-[0.5] flex justify-center items-center rounded-full border-[1px] border-solid border-white">
                                        x
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                    <div className="bg-[#F0E9E3] text-lg p-4 pt-12 flex">
                        <button
                            type={"button"}
                            onClick={() => {
                                setOpenSalvaRicerca(true)
                            }}
                            className={
                                "m-1 p-2 uppercase text-xl w-1/2 text-center font-bold bg-[#df5b23] text-white mt-2 cursor-pointer hover:opacity-50"
                            }
                            title={"SALVA RICERCA"}
                        >
                            SALVA RICERCA
                        </button>
                        <button
                            onClick={() => {
                                setDepartmentsArray([]);
                                setTypology([]);
                                setType("default");
                                setType2(null);
                                setDifficultyState([false, false, false, false, false, false]);
                                setType3("default");
                                setDateEnd(null);
                                setFiltriApplicati([]);
                                setCountries(null)
                                setPage(1);
                                setTitle('')
                                api
                                    .post("/search-trek", {
                                        date: null,
                                        dateEnd: null,
                                        type_trek: null,
                                        destination: null,
                                        maps: false,
                                        difficulty: null,
                                        regions: null,
                                        states: null,
                                        typology: [],
                                        departments: [],
                                        label: null,
                                        title: '',
                                        position: pos
                                    })
                                    .then((res) => {
                                        setApply(!apply);
                                    })
                                    .catch((err) => console.log(err));
                                api
                                    .post("/get-treks-point", {
                                        date: null,
                                        dateEnd: null,
                                        type_trek: null,
                                        destination: null,
                                        maps: false,
                                        difficulty: null,
                                        regions: null,
                                        states: null,
                                        typology: [],
                                        departments: [],
                                        label: null,
                                        title: '',
                                        position: pos
                                    })
                                    .then((res) => {
                                        setApply(!apply);
                                    })
                                    .catch((err) => console.log(err));
                                window.scrollTo(0, 800);
                            }}
                            className={
                                "m-1 p-2 uppercase text-xl w-1/2 text-center font-bold bg-[#df5b23] text-white mt-2 cursor-pointer hover:opacity-50"
                            }

                        >ELIMINA FILTRI
                        </button>
                    </div>

                    <div className="bg-[#F0E9E3] text-lg p-4 pt-12 flex items-center">
                        <InputCustom
                            classcontainer={"font-bold text-[#34291F] pb-4 whitespace-nowrap"}
                            title={'Ricerca per titolo'}
                            value={title}
                            onChange={(e) => {
                                const { value } = e.target
                                setTitle(value)
                                if (value === '') {
                                    setFiltriApplicati(prev => {
                                        return prev.filter((filtro) => {
                                            return filtro.nameSet !== 'setTitle';
                                        })
                                    })
                                }
                                // else {
                                //     applicaFiltri2(
                                //         value,
                                //         setTitle,
                                //         value,
                                //         title,
                                //         '',
                                //         "setTitle",
                                //         true,
                                //         true,
                                //         false
                                //     );
                                // }
                            }}
                            onKeyDown={(e) => {
                                const { value } = e.target
                                if (e.key === 'Enter' && value !== '') {
                                    applicaFiltri2(
                                        value,
                                        setTitle,
                                        value,
                                        title,
                                        '',
                                        "setTitle"
                                    );
                                }
                                if (value === '') {
                                    setApply(!apply)
                                }
                            }}
                            onBlur={(e) => {
                                const { value } = e.target
                                if (value !== '') {
                                    applicaFiltri2(
                                        value,
                                        setTitle,
                                        value,
                                        title,
                                        '',
                                        "setTitle"
                                    );
                                }
                                if (value === '') {
                                    setApply(!apply)
                                }
                            }}

                        />
                        <InputGimlet
                            type={"button"}
                            className={
                                "relative top-[5px] m-1 p-2 uppercase text-xl w-1/2 text-center font-bold bg-[#df5b23] text-white mt-2 cursor-pointer hover:opacity-50"
                            }
                            title={"CERCA"}
                        />
                    </div>
                    <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                        <FormControl sx={{ width: '100%' }}>
                            <FormLabel id="demo-controlled-radio-buttons-group">Posizione</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={pos}
                                onChange={(e) => {
                                    if (e.target.value === 'Estero') {
                                        navigate('/trek-estero')
                                    } else if (e.target.value === 'Italia') {
                                        navigate('/trek-italia')
                                    } else {
                                        navigate('/vicino-a-me')
                                    }
                                    // navigate(e.target.value === 'Estero' ? "/trek-estero" : "/trek-italia");
                                }}
                            >
                                <FormControlLabel sx={{ margin: 0, marginBottom: 1 }} className=" w-full flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg" value="Italia" control={<Radio />} label="Italia" />
                                <FormControlLabel sx={{ margin: 0, marginBottom: 1 }} className="m-0 w-full flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg" value="Estero" control={<Radio />} label="Estero" />
                                {/* <FormControlLabel sx={{ margin: 0 }} className="m-0 w-full flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg" value="Geolocalizzato" control={<Radio />} label="Geolocalizzato" /> */}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {pos === "Estero" ? (
                        <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                            <InputBilo
                                className="font-bold text-[#34291F]"
                                title={"Destinazione"}
                            />
                            <TextField
                                onClick={!isOpen ? () => {
                                    setIsOpen(true)
                                } : () => { }}
                                select
                                name={"trek"}
                                className={"w-full"}
                                value={countries === null ? "default" : countries}
                                sx={{
                                    backgroundColor: "#e6dbd1",
                                    borderColor: "#e6dbd1",
                                    borderRadius: 3,
                                    fieldset: { borderColor: "#e6dbd1" },
                                }}
                                onChange={(e) => {
                                    if (e.target.value === "default") {
                                        setFiltriApplicati(
                                            filtriApplicati.filter((filtro) => {
                                                let elimina = "";
                                                statesShown.data.data.map((region) => {
                                                    if (region.name === filtro.name) {
                                                        elimina = filtro.name;
                                                    }
                                                });
                                                return filtro.name !== elimina;
                                            })
                                        );
                                        setCountries(null)
                                    }
                                }}
                                SelectProps={{ IconComponent: () => null, open: isOpen, onClose: () => { setIsOpen(false) } }}
                                InputProps={{
                                    sx: {
                                        color: "#34291F",
                                        borderColor: "#F0E9E3",
                                        borderRadius: 3,
                                    },
                                    endAdornment: (
                                        <img
                                            alt={"img"}
                                            src={select}
                                            className={"object-contain hover:cursor-pointer"}
                                            onClick={() => {
                                                setIsOpen(true)
                                            }}
                                        />
                                    ),
                                }}>
                                <MenuItem disabled value={"default"}>Scegli il Paese</MenuItem>
                                {statesShown
                                    ? statesShown.map((res, index) => {
                                        if (res.name === "Italia") {
                                            return "";
                                        }
                                        return (
                                            <MenuItem
                                                onClick={() => {
                                                    applicaFiltri2(
                                                        res.name,
                                                        setCountries,
                                                        res.name,
                                                        countries,
                                                        "default",
                                                        "setCountries"
                                                    );
                                                }}
                                                key={res.id}
                                                value={res.name}>
                                                {res.name}
                                            </MenuItem>
                                        );
                                    })
                                    : ""}
                            </TextField>
                        </div>
                    ) : (
                        ""
                    )}
                    {pos === "Italia" ? (
                        <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                            <InputBilo
                                className="font-bold text-[#34291F]"
                                title={"Destinazione"}
                            />
                            <TextField
                                onClick={!isOpen ? () => {
                                    setIsOpen(true)
                                } : () => { }}
                                ref={endAdornmentDest}
                                select
                                name={"trek"}
                                className={"w-full"}
                                value={type === null ? "default" : type}
                                sx={{
                                    backgroundColor: "#e6dbd1",
                                    borderColor: "#e6dbd1",
                                    borderRadius: 3,
                                    fieldset: { borderColor: "#e6dbd1" },
                                }}
                                onChange={(e) => {
                                    if (e.target.value === "default") {
                                        setFiltriApplicati(
                                            filtriApplicati.filter((filtro) => {
                                                let elimina = "";
                                                regions.data.data.map((region) => {
                                                    if (region.name === filtro.name) {
                                                        elimina = filtro.name;
                                                    }
                                                });
                                                return filtro.name !== elimina;
                                            })
                                        );
                                        setType(null)
                                    }
                                }}
                                SelectProps={{ IconComponent: () => null, open: isOpen, onClose: () => { setIsOpen(false) } }}
                                InputProps={{
                                    sx: {
                                        color: "#34291F",
                                        borderColor: "#F0E9E3",
                                        backgroundColor: "transparent",
                                        borderRadius: 3,
                                    },
                                    endAdornment: (
                                        <img
                                            alt={"img"}
                                            src={select}
                                            className={"object-contain hover:cursor-pointer"}
                                            onClick={() => {
                                                setIsOpen(true)
                                            }}
                                        />
                                    ),
                                }}>
                                <MenuItem value={"default"}>Scegli la regione</MenuItem>
                                {regionsShown
                                    ? regionsShown.map((res, index) => {
                                        return (
                                            <MenuItem
                                                onClick={() => {
                                                    applicaFiltri2(
                                                        res.name,
                                                        setType,
                                                        res.name,
                                                        type,
                                                        null,
                                                        "setType",
                                                        true
                                                    );
                                                    // applicaFiltri(res.name, setType, 3, res.id, true);
                                                }}
                                                key={res.id}
                                                value={res.name}>
                                                {res.name}
                                            </MenuItem>
                                        );
                                    })
                                    : ""}
                            </TextField>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                        <InputBilo
                            className="font-bold text-[#34291F]"
                            title={"Tipologia di trek"}
                        />
                        {filter.data.data
                            ? filter.data.data.map((res, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg">
                                            <Checkbox
                                                id={res.id.toString()}
                                                checked={typology.includes(res.id)}
                                                onChange={(e) => {
                                                    // insertDinamicArray(res.id, setTypology, typology);
                                                    // applicaFiltri(res.name, setTypology, 1, res.id);
                                                    applicaFiltri2(
                                                        res.name,
                                                        setTypology,
                                                        res.id,
                                                        typology,
                                                        [],
                                                        "setTypology",
                                                        false
                                                    );
                                                }}
                                            />
                                            <InputBilo
                                                className="text-[#34291F]"
                                                title={res.name}
                                            />
                                        </div>
                                    </React.Fragment>
                                );
                            })
                            : ""}
                    </div>
                    <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                        <InputBilo
                            className="font-bold text-[#34291F]"
                            title={"Data partenza trek"}
                        />
                        <div className="flex flex-row items-center gap-4 mt-4">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label='da'
                                    className="w-full"
                                    inputFormat="dd/MM/yyyy"
                                    value={type2 ?? new Date()}
                                    onChange={(newValue) => {
                                        // setApply(!apply); setType2(newValue);

                                        // applicaFiltri(
                                        //     "Data inizio",
                                        //     setType2,
                                        //     4,
                                        //     "Data inizio",
                                        //     true
                                        // );
                                        let regex = /^\d\d\/\d\d\/\d\d\d\d/;
                                        if (newValue &&
                                            regex.test(`${newValue.getDate() > 9 ? newValue.getDate() : `0${newValue.getDate()}`}/${(newValue.getMonth() + 1) > 9 ? (newValue.getMonth() + 1) : `0${newValue.getMonth() + 1}`}/${newValue.getFullYear()}`)
                                        ) {
                                            applicaFiltri2(
                                                "Data inizio",
                                                setType2,
                                                dataFormatter(newValue),
                                                type2,
                                                null,
                                                "setType2",
                                                true
                                            );
                                        }
                                        if (type2 && newValue === null) {
                                            applicaFiltri2(
                                                "Data inizio",
                                                setType2,
                                                dataFormatter(new Date()),
                                                type2,
                                                null,
                                                "setType2",
                                                true
                                            );
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className={"w-full"}
                                            sx={{
                                                backgroundColor: "#e6dbd1",
                                                borderRadius: 3,
                                                "& .MuiOutlinedInput-root": {
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        border: "none",
                                                    },
                                                },
                                                '& .MuiFormLabel-root': {
                                                    fontSize: '24px',
                                                    top: '-5px'
                                                }
                                            }}
                                            {...params}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker

                                    label='fino al'
                                    className="w-full"
                                    inputFormat="dd/MM/yyyy"
                                    minDate={new Date(type2) ?? new Date()}
                                    value={dateEnd ?? null}
                                    onChange={(newValue) => {
                                        // setApply(!apply); setDateEnd(newValue);

                                        // applicaFiltri(
                                        //     "Data fine",
                                        //     setDateEnd,
                                        //     7,
                                        //     "Data fine",
                                        //     true
                                        // );
                                        let regex = /^\d\d\/\d\d\/\d\d\d\d/;
                                        if (newValue !== null) { newValue.setDate(newValue.getDate() + 1) }
                                        // console.log(new Date(type2), newValue)
                                        if (newValue &&
                                            regex.test(`${newValue.getDate() > 9 ? newValue.getDate() : `0${newValue.getDate()}`}/${(newValue.getMonth() + 1) > 9 ? (newValue.getMonth() + 1) : `0${newValue.getMonth() + 1}`}/${newValue.getFullYear()}`) &&
                                            new Date(type2) < newValue
                                        ) {
                                            setErrorDate(false)
                                            newValue.setDate(newValue.getDate() - 1)
                                            applicaFiltri2(
                                                "Data fine",
                                                setDateEnd,
                                                dataFormatter(newValue),
                                                dateEnd,
                                                null,
                                                "setDateEnd",
                                                true
                                            );
                                        } else {
                                            setErrorDate(true)
                                        }

                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            error={errorDate}
                                            helperText={errorDate ? 'Data non valida' : ''}
                                            className={"w-full"}
                                            sx={{
                                                backgroundColor: "#e6dbd1",
                                                borderRadius: 3,
                                                "& .MuiOutlinedInput-root": {
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        border: "none",
                                                    },
                                                },
                                                '& .MuiInputLabel-shrink': {
                                                    fontSize: '24px',
                                                    top: '-5px'
                                                },
                                                '& .MuiFormHelperText-contained': {
                                                    backgroundColor: '#F0E9E3',
                                                    color: 'red',
                                                    margin: 0,
                                                    paddingLeft: '10px'
                                                }
                                            }}
                                            {...params}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                        <InputBilo
                            className="font-bold text-[#34291F]"
                            title={"Classificazione"}
                        />
                        <TextField
                            onClick={isOpenClass ? () => { } : () => { setIsOpenClass(true) }}
                            select
                            name={"trek3"}
                            className={"w-full"}
                            value={type3 === null ? "default" : type3}
                            sx={{
                                backgroundColor: "#e6dbd1",
                                borderColor: "#e6dbd1",
                                borderRadius: 3,
                                fieldset: { borderColor: "#e6dbd1" },
                            }}
                            onChange={(e) => {
                                if (e.target.value === "default") {
                                    setFiltriApplicati(
                                        filtriApplicati.filter((filtro) => {
                                            return filtro.nameSet !== 'setType3';
                                        })
                                    )
                                    setType3(null)
                                }
                            }}
                            SelectProps={{ IconComponent: () => null, open: isOpenClass, onClose: () => { setIsOpenClass(false) } }}
                            InputProps={{
                                sx: {
                                    color: "#34291F",
                                    borderColor: "#F0E9E3",
                                    borderRadius: 3,
                                },
                                endAdornment: (
                                    <img alt={"img"} src={select} className={"object-contain hover:cursor-pointer"}
                                        onClick={() => {
                                            setIsOpenClass(true)
                                        }}
                                    />

                                ),
                            }}>
                            <MenuItem value={"default"}>
                                -
                            </MenuItem>
                            {label.data.data
                                ? label.data.data.map((res, index) => {
                                    return (
                                        <MenuItem
                                            onClick={() => {
                                                // applicaFiltri(res.name, setType3, 6, res.id, true);
                                                applicaFiltri2(
                                                    res.name,
                                                    setType3,
                                                    res.id,
                                                    type3,
                                                    "default",
                                                    "setType3",
                                                    true
                                                );
                                            }}
                                            key={index}
                                            value={res.id}>
                                            {res.name}
                                        </MenuItem>
                                    );
                                })
                                : ""}
                        </TextField>
                    </div>
                    <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                        <InputBilo
                            className="font-bold text-[#34291F]"
                            title={"Sezione organizzatrice"}
                        />
                        {departments.data.data
                            ? departments.data.data.filter(res => res.id !== 1).map((res, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg">
                                            <Checkbox
                                                id={res.id.toString()}
                                                checked={departmentsArray.includes(res.id)}
                                                onChange={() => {
                                                    // insertDinamicArray(
                                                    //     res.id,
                                                    //     setDepartmentsArray,
                                                    //     departmentsArray
                                                    // );
                                                    // applicaFiltri(
                                                    //     res.name,
                                                    //     setDepartmentsArray,
                                                    //     2,
                                                    //     res.id
                                                    // );
                                                    applicaFiltri2(
                                                        'Sez. ' + res.name,
                                                        setDepartmentsArray,
                                                        res.id,
                                                        departmentsArray,
                                                        [],
                                                        "setDepartmentsArray",
                                                        false
                                                    );
                                                }}
                                            />
                                            <InputBilo
                                                className="text-[#34291F]"
                                                title={res.name}
                                            />
                                        </div>
                                    </React.Fragment>
                                );
                            })
                            : ""}
                    </div>
                    <div className="bg-[#F0E9E3] text-lg p-4 pt-12 rounded-b-xl">
                        <div className="flex items-center">
                            <InputBilo
                                className="font-bold text-[#34291F]"
                                title={"Difficoltà"}
                            />
                            <button
                                className="w-[25px] h-[25px] rounded-full border-[1px] border-black flex justify-center items-center mx-[10px]"
                                onClick={() => {
                                    setInfoDifficolta(true);
                                    document.body.style.overflow = "hidden";
                                }}>
                                i
                            </button>
                        </div>

                        <RatingCustom
                            mobile={false}
                            max={6}
                            value={difficultyState}
                            setDifficultyState={setDifficultyState}
                            setApply={setApply}
                            setFiltriApplicati={setFiltriApplicati}
                            bool={bool}
                        />
                    </div>
                </div>
                <div className={"lg:hidden"}>
                    <div className="flex flex-row">
                        <div
                            className={`w-full cursor-pointer mr-2 hover:opacity-75 bg-orange-600 p-2 flex justify-center items-center h-fit p-0`}
                            onClick={() => setBool(false)}>
                            <img
                                src={rettangolo}
                                alt={"img"}
                                className="w-[1.5rem] h-[1.5rem]"
                            />
                            <InputGimlet
                                className="text-2xl py-2 ml-3 text-white"
                                title={"LISTA"}
                            />
                        </div>
                        <div
                            className={`w-full cursor-pointer mr-2 hover:opacity-75 bg-black p-2 flex justify-center items-center h-fit p-0`}
                            onClick={() => setBool(true)}>
                            <img src={mappa} alt={"img"} className="w-[1.5rem] h-[1.5rem]" />
                            <InputGimlet
                                className="text-2xl py-2 ml-3 text-white"
                                title={"MAPPA"}
                            />
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            setListaFiltri(true);
                            if (firstTime === true) {
                                setfirstTime(false);
                            }
                            document.body.classList.add("no-overflow");
                        }}
                        className="w-[97px] h-[101px] rounded-tr-[35px] rounded-br-[35px] bg-[#F0E9E3] fixed left-0 bottom-[15px] shadow-[0_0_22px_0_rgba(12,12,12,0.7)] z-[999]">
                        <div className="w-[59px] h-[59px] rounded-full relative bg-[#796557] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                            <img src={filterIcon} alt="filtri_img" />
                        </div>
                    </div>
                    {/* Filri mobile */}
                    <div
                        className={`${style["lista-filtri"]} ${firstTime
                            ? "-translate-x-full"
                            : listaFiltri
                                ? style["in-lista-filtri"]
                                : style["out-lista-filtri"]
                            } fixed top-0 left-0 z-[999] w-[70%]`}>
                        <div className="bg-[#df5b23] h-[42px] flex flex-row justify-around items-center rounded-tr-[34px]">
                            <div
                                onClick={() => {
                                    setListaFiltri(false);
                                    document.body.classList.remove("no-overflow");
                                }}
                                className="rounded-full w-[25px] h-[25px] border-2 border-white text-white flex justify-center items-center text-[19px]">
                                x
                            </div>
                            <InputGimlet
                                className="text-[15px] text-white"
                                title={"Trova il tuo prossimo trek"}
                            />
                        </div>
                        <div className="bg-[#F0E9E3] text-lg p-2 flex">
                            <button
                                type="button"
                                onClick={() => {
                                    setOpenSalvaRicerca(true)
                                }}
                                className={
                                    "m-1 p-2 uppercase text-xl w-full text-center font-bold bg-[#df5b23] text-white mt-2 cursor-pointer hover:opacity-50"
                                }
                            >SALVA RICERCA</button>
                            <button
                                onClick={() => {
                                    setDepartmentsArray([]);
                                    setTypology([]);
                                    setType("default");
                                    setType2(null);
                                    setDifficultyState([false, false, false, false, false, false]);
                                    setType3("default");
                                    setDateEnd(null);
                                    setFiltriApplicati([]);
                                    setCountries(null)
                                    setPage(1);
                                    setTitle('');
                                    api
                                        .post("/search-trek", {
                                            date: null,
                                            dateEnd: null,
                                            type_trek: null,
                                            destination: null,
                                            maps: false,
                                            difficulty: null,
                                            regions: null,
                                            states: null,
                                            typology: [],
                                            departments: [],
                                            label: null,
                                            title: '',
                                            position: pos
                                        })
                                        .then((res) => {
                                            setApply(!apply);
                                        })
                                        .catch((err) => console.log(err));
                                    api
                                        .post("/get-treks-pointk", {
                                            date: null,
                                            dateEnd: null,
                                            type_trek: null,
                                            destination: null,
                                            maps: false,
                                            difficulty: null,
                                            regions: null,
                                            states: null,
                                            typology: [],
                                            departments: [],
                                            label: null,
                                            title: '',
                                            position: pos
                                        })
                                        .then((res) => {
                                            setApply(!apply);
                                        })
                                        .catch((err) => console.log(err));
                                    window.scrollTo(0, 800);
                                }}
                                className={
                                    "m-1 p-2 uppercase text-xl w-full text-center font-bold bg-[#df5b23] text-white mt-2 cursor-pointer hover:opacity-50"
                                }

                            >ELIMINA FILTRI</button>
                        </div>
                        {/* inizio filtri */}
                        <div className={`${style["h-full-calc"]} overflow-y-auto`}>

                            <div className={"bg-[#F0E9E3] p-4 pt-12 "}>
                                <InputCustom
                                    notleftspace='true'
                                    classcontainer={"font-bold text-[#34291F]"}
                                    title={'Ricerca per titolo'}
                                    value={title}
                                    onChange={(e) => {
                                        const { value } = e.target
                                        setTitle(value)
                                        if (value === '') {
                                            setFiltriApplicati(prev => {
                                                return prev.filter((filtro) => {
                                                    return filtro.nameSet !== 'setTitle';
                                                })
                                            })
                                        } else {
                                            applicaFiltri2(
                                                value,
                                                setTitle,
                                                value,
                                                title,
                                                '',
                                                "setTitle",
                                                true,
                                                true,
                                                false
                                            );
                                        }
                                    }}
                                    // onKeyDown={(e) => {
                                    //     const { value } = e.target
                                    //     if (e.key === 'Enter' && value !== '') {
                                    //         applicaFiltri2(
                                    //             value,
                                    //             setTitle,
                                    //             value,
                                    //             title,
                                    //             '',
                                    //             "setTitle"
                                    //         );
                                    //     }
                                    //     if (value === '') {
                                    //         setApply(!apply)
                                    //     }
                                    // }}
                                    onBlur={(e) => {
                                        const { value } = e.target
                                        if (value !== '') {
                                            applicaFiltri2(
                                                value,
                                                setTitle,
                                                value,
                                                title,
                                                '',
                                                "setTitle"
                                            );
                                        }
                                        if (value === '') {
                                            setApply(!apply)
                                        }
                                    }}
                                />
                            </div>

                            <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                                <FormControl sx={{ width: '100%' }}>
                                    <FormLabel
                                        id="demo-controlled-radio-buttons-group"
                                        className={"bg-[#F0E9E3] text-lg p-4 pt-12 "}>Posizione</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={pos}
                                        onChange={(e) => {
                                            if (e.target.value === 'Estero') {
                                                navigate('/trek-estero')
                                            } else if (e.target.value === 'Italia') {
                                                navigate('/trek-italia')
                                            } else {
                                                navigate('/vicino-a-me')
                                            }
                                            // navigate(e.target.value === 'Estero' ? "/trek-estero" : "/trek-italia");
                                        }}
                                    >
                                        <FormControlLabel sx={{
                                            margin: 0,
                                            marginBottom: 1,
                                            padding: '4px !important',
                                            "& .MuiTypography-root": {
                                                fontSize: '12px !important'
                                            },
                                            "& .MuiButtonBase-root": {
                                                padding: '0px !important'
                                            }
                                        }} className=" w-full flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg" value="Italia" control={<Radio />} label="Italia" />
                                        <FormControlLabel sx={{
                                            margin: 0,
                                            marginBottom: 1,
                                            padding: '4px !important',
                                            "& .MuiTypography-root": {
                                                fontSize: '12px !important'
                                            },
                                            "& .MuiButtonBase-root": {
                                                padding: '0px !important'
                                            }
                                        }} className="m-0 w-full flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg" value="Estero" control={<Radio />} label="Estero" />
                                        {/* <FormControlLabel sx={{
                                            margin: 0,
                                            padding: '4px !important',
                                            "& .MuiTypography-root": {
                                                fontSize: '12px !important'
                                            },
                                            "& .MuiButtonBase-root": {
                                                padding: '0px !important'
                                            }
                                        }} className="m-0 w-full flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg" value="Geolocalizzato" control={<Radio />} label="Geolocalizzato" /> */}
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            {pos === "Estero" ? (
                                <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                                    <InputBilo
                                        className="font-bold text-[#34291F]"
                                        title={"Destinazione"}
                                    />
                                    <TextField
                                        onClick={!isOpenMobile ? () => {
                                            setIsOpenMobile(true)
                                        } : () => { }}
                                        select
                                        name={"trek"}
                                        className={"w-full"}
                                        value={countries === null ? "default" : countries}
                                        sx={{
                                            backgroundColor: "#e6dbd1",
                                            borderColor: "#e6dbd1",
                                            borderRadius: 3,
                                            fieldset: { borderColor: "#e6dbd1" },
                                            "& .MuiSelect-select": {
                                                padding: "2.5px 0 2.5px 10px",
                                            },
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === "default") {
                                                setFiltriApplicati(
                                                    filtriApplicati.filter((filtro) => {
                                                        let elimina = "";
                                                        statesShown.data.data.map((region) => {
                                                            if (region.name === filtro.name) {
                                                                elimina = filtro.name;
                                                            }
                                                        });
                                                        return filtro.name !== elimina;
                                                    })
                                                );
                                                setCountries(null)
                                            }
                                        }}
                                        SelectProps={{ IconComponent: () => null, open: isOpenMobile, onClose: () => { setIsOpenMobile(false) } }}
                                        InputProps={{
                                            sx: {
                                                color: "#34291F",
                                                borderColor: "#F0E9E3",
                                                borderRadius: 3,
                                            },
                                            endAdornment: (
                                                <img
                                                    alt={"img"}
                                                    src={select}
                                                    className={"object-contain hover:cursor-pointer"}
                                                    onClick={() => {
                                                        setIsOpenMobile(true)
                                                    }}
                                                />
                                            ),
                                        }}>
                                        <MenuItem value={"default"}>Scegli il Paese</MenuItem>
                                        {statesShown
                                            ? statesShown.map((res, index) => {
                                                if (res.name === "Italia") {
                                                    return "";
                                                }
                                                return (
                                                    <MenuItem
                                                        onClick={() => {
                                                            applicaFiltri2(
                                                                res.name,
                                                                setType,
                                                                res.name,
                                                                type,
                                                                "default",
                                                                "setType"
                                                            );
                                                        }}
                                                        key={res.id}
                                                        value={res.name}>
                                                        {res.name}
                                                    </MenuItem>
                                                );
                                            })
                                            : ""}
                                    </TextField>
                                </div>
                            ) : (
                                ""
                            )}
                            {pos === "Italia" ? (
                                <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                                    <InputBilo
                                        className="font-bold text-[#34291F]"
                                        title={"Destinazione"}
                                    />
                                    <TextField
                                        onClick={!isOpenMobile ? () => {
                                            setIsOpenMobile(true)
                                        } : () => { }}
                                        select
                                        name={"trek"}
                                        className={"w-full"}
                                        value={type === null ? "default" : type}
                                        sx={{
                                            backgroundColor: "#e6dbd1",
                                            borderColor: "#e6dbd1",
                                            borderRadius: 3,
                                            fieldset: { borderColor: "#e6dbd1" },
                                            "& .MuiSelect-select": {
                                                padding: "2.5px 0 2.5px 10px",
                                            },
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === "default") {
                                                setFiltriApplicati(
                                                    filtriApplicati.filter((filtro) => {
                                                        let elimina = "";
                                                        regions.data.data.map((region) => {
                                                            if (region.name === filtro.name) {
                                                                elimina = filtro.name;
                                                            }
                                                        });
                                                        return filtro.name !== elimina;
                                                    })
                                                );
                                                setType(null)
                                            }
                                        }}
                                        SelectProps={{ IconComponent: () => null, open: isOpenMobile, onClose: () => { setIsOpenMobile(false) } }}
                                        InputProps={{
                                            sx: {
                                                color: "#34291F",
                                                borderColor: "#F0E9E3",
                                                borderRadius: 3,
                                            },
                                            endAdornment: (
                                                <img
                                                    alt={"img"}
                                                    src={select}
                                                    className={"object-contain hover:cursor-pointer"}
                                                    onClick={() => {
                                                        setIsOpenMobile(true)
                                                    }}
                                                />
                                            ),
                                        }}>
                                        <MenuItem value={"default"}>Scegli la regione</MenuItem>
                                        {regionsShown
                                            ? regionsShown.map((res, index) => {
                                                return (
                                                    <MenuItem
                                                        onClick={() => {
                                                            applicaFiltri2(
                                                                res.name,
                                                                setType,
                                                                res.name,
                                                                type,
                                                                null,
                                                                "setType",
                                                                true
                                                            );
                                                            // applicaFiltri(res.name, setType, 3, res.id, true);
                                                        }}
                                                        key={res.id}
                                                        value={res.name}>
                                                        {res.name}
                                                    </MenuItem>
                                                );
                                            })
                                            : ""}
                                    </TextField>
                                </div>
                            ) : (
                                ""
                            )}

                            <div className={"bg-[#F0E9E3] text-lg p-4 pt-12 "}>
                                <InputBilo
                                    className="font-bold text-[#34291F]"
                                    title={"Tipologia di trek"}
                                />
                                {filter.data.data
                                    ? filter.data.data.map((res, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] rounded-[14px]">
                                                    <Checkbox
                                                        id={res.id.toString()}
                                                        checked={typology.includes(res.id)}
                                                        onChange={(e) => {
                                                            // insertDinamicArray(res.id, setTypology, typology);
                                                            // applicaFiltri(res.name, setTypology, 1, res.id);
                                                            applicaFiltri2(
                                                                res.name,
                                                                setTypology,
                                                                res.id,
                                                                typology,
                                                                [],
                                                                "setTypology",
                                                                false
                                                            );
                                                        }}
                                                        sx={{
                                                            padding: "0 0 0 4px",
                                                            "& .MuiSvgIcon-root": { width: "20px" },
                                                        }}
                                                    />
                                                    <InputBilo
                                                        className="text-[#34291F] text-sm"
                                                        title={res.name}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );
                                    })
                                    : ""}
                            </div>

                            <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                                <InputBilo
                                    className="font-bold text-[#34291F]"
                                    title={"Data partenza trek"}
                                />
                                <div className="flex flex-col items-center gap-4 mt-4">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker

                                            label='da'
                                            className="w-full"
                                            inputFormat="dd/MM/yyyy"
                                            value={type2 ?? new Date()}
                                            onChange={(newValue) => {
                                                // setApply(!apply); setType2(newValue);

                                                // applicaFiltri(
                                                //     "Data inizio",
                                                //     setType2,
                                                //     4,
                                                //     "Data inizio",
                                                //     true
                                                // );
                                                let regex = /^\d\d\/\d\d\/\d\d\d\d/;
                                                if (newValue &&
                                                    regex.test(`${newValue.getDate() > 9 ? newValue.getDate() : `0${newValue.getDate()}`}/${(newValue.getMonth() + 1) > 9 ? (newValue.getMonth() + 1) : `0${newValue.getMonth() + 1}`}/${newValue.getFullYear()}`)
                                                ) {
                                                    applicaFiltri2(
                                                        "Data inizio",
                                                        setType2,
                                                        dataFormatter(newValue),
                                                        type2,
                                                        null,
                                                        "setType2",
                                                        true
                                                    );
                                                }
                                            }
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    className={"w-full"}
                                                    sx={{
                                                        backgroundColor: "#e6dbd1",
                                                        borderRadius: 3,
                                                        "& .MuiOutlinedInput-root": {
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                border: "none",
                                                            },
                                                        },
                                                        '& .MuiFormLabel-root': {
                                                            fontSize: '24px',
                                                            top: '-5px'
                                                        }
                                                    }}
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                    {/* 19/12/1234 */}
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label='fino al'
                                            className="w-full"
                                            inputFormat="dd/MM/yyyy"
                                            minDate={type2 ?? new Date()}
                                            value={dateEnd ?? null}
                                            onChange={(newValue) => {
                                                // setApply(!apply); setDateEnd(newValue);

                                                // applicaFiltri(
                                                //     "Data fine",
                                                //     setDateEnd,
                                                //     7,
                                                //     "Data fine",
                                                //     true
                                                // );
                                                let regex = /^\d\d\/\d\d\/\d\d\d\d/;
                                                if (newValue &&
                                                    regex.test(`${newValue.getDate() > 9 ? newValue.getDate() : `0${newValue.getDate()}`}/${(newValue.getMonth() + 1) > 9 ? (newValue.getMonth() + 1) : `0${newValue.getMonth() + 1}`}/${newValue.getFullYear()}`) &&
                                                    new Date(type2) < newValue
                                                ) {
                                                    applicaFiltri2(
                                                        "Data fine",
                                                        setDateEnd,
                                                        dataFormatter(newValue),
                                                        dateEnd,
                                                        null,
                                                        "setDateEnd",
                                                        true
                                                    );
                                                }

                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    className={"w-full"}
                                                    sx={{
                                                        backgroundColor: "#e6dbd1",
                                                        borderRadius: 3,
                                                        "& .MuiOutlinedInput-root": {
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                border: "none",
                                                            },
                                                        },
                                                        '& .MuiFormLabel-root': {
                                                            fontSize: '24px',
                                                            top: '-5px'
                                                        }
                                                    }}
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                                <InputBilo
                                    className="font-bold text-[#34291F]"
                                    title={"Classificazione"}
                                />
                                <TextField
                                    onClick={isOpenClassMobile ? () => { } : () => { setIsOpenClassMobile(true) }}
                                    select
                                    name={"trek3"}
                                    className={"w-full"}
                                    value={type3 === null ? "default" : type3}
                                    sx={{
                                        backgroundColor: "#e6dbd1",
                                        borderColor: "#e6dbd1",
                                        borderRadius: 3,
                                        fieldset: { borderColor: "#e6dbd1" },
                                    }}
                                    onChange={(e) => setType3(e.target.value)}
                                    SelectProps={{ IconComponent: () => null, open: isOpenClassMobile, onClose: () => { setIsOpenClassMobile(false) } }}
                                    InputProps={{
                                        sx: {
                                            color: "#34291F",
                                            borderColor: "#F0E9E3",
                                            borderRadius: 3.5,
                                            fieldset: { borderColor: "#e6dbd1" },
                                            "& .MuiSelect-select": {
                                                padding: "2.5px 0 2.5px 10px",
                                            },
                                        },
                                        endAdornment: (
                                            <img
                                                alt={"img"}
                                                src={select}
                                                className={"object-contain hover:cursor-pointer"}
                                                onClick={() => {
                                                    setIsOpenClass(true)
                                                }}
                                            />
                                        ),
                                    }}>
                                    <MenuItem value={"default"}>
                                        Scegli
                                    </MenuItem>
                                    {label.data.data
                                        ? label.data.data.map((res, index) => {
                                            return (
                                                <MenuItem
                                                    onClick={() => {
                                                        // applicaFiltri(res.name, setType3, 6, res.id, true);
                                                        applicaFiltri2(
                                                            res.name,
                                                            setType3,
                                                            res.id,
                                                            type3,
                                                            "default",
                                                            "setType3",
                                                            true
                                                        );
                                                    }}
                                                    key={index}
                                                    value={res.id}>
                                                    {res.name}
                                                </MenuItem>
                                            );
                                        })
                                        : ""}
                                </TextField>
                            </div>
                            <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                                <InputBilo
                                    className="font-bold text-[#34291F]"
                                    title={"Sezione organizzatrice"}
                                />
                                {departments.data.data
                                    ? departments.data.data.map((res, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg">
                                                    <Checkbox
                                                        id={res.id.toString()}
                                                        checked={departmentsArray.includes(res.id)}
                                                        onChange={() => {
                                                            // insertDinamicArray(
                                                            //     res.id,
                                                            //     setDepartmentsArray,
                                                            //     departmentsArray
                                                            // );
                                                            // applicaFiltri(
                                                            //     res.name,
                                                            //     setDepartmentsArray,
                                                            //     2,
                                                            //     res.id
                                                            // );
                                                            applicaFiltri2(
                                                                res.name,
                                                                setDepartmentsArray,
                                                                res.id,
                                                                departmentsArray,
                                                                [],
                                                                "setDepartmentsArray",
                                                                false
                                                            );
                                                        }}
                                                        sx={{
                                                            padding: "0 0 0 4px",
                                                            "& .MuiSvgIcon-root": { width: "20px" },
                                                        }}
                                                    />
                                                    <InputBilo
                                                        className="text-[#34291F] text-sm"
                                                        title={res.name}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );
                                    })
                                    : ""}
                            </div>
                            <div className="bg-[#F0E9E3] text-lg p-4 pt-12 rounded-b-xl mb-16">
                                <div className="flex items-center">
                                    <InputBilo
                                        className="font-bold text-[#34291F]"
                                        title={"Difficoltà"}
                                    />
                                    <button
                                        className="w-[25px] h-[25px] rounded-full border-[1px] border-black flex justify-center items-center mx-[10px]"
                                        onClick={() => {
                                            setInfoDifficolta(true);
                                            setListaFiltri(false);
                                            document.body.style.overflow = "hidden";
                                        }}>
                                        i
                                    </button>
                                </div>
                                {/* <Rating
                                    precision={1}
                                    name="simple-controlled"
                                    value={difficulty}
                                    onChange={(event, newValue) => {
                                        if (newValue === null) {
                                            newValue = difficulty;
                                        }
                                        // setDifficulty(newValue);
                                        // setPage(1);
                                        // insertDinamicArray(
                                        //     newValue,
                                        //     setDifficulty,
                                        //     difficulty,
                                        //     true,
                                        //     "difficulty"
                                        // );
                                        // applicaFiltri("difficulty", setDifficulty, 5, difficulty, true);
                                        applicaFiltri2(
                                            "difficulty",
                                            setDifficulty,
                                            newValue,
                                            difficulty,
                                            null,
                                            "setDifficulty",
                                            true
                                        );
                                    }}
                                    max={6}
                                    size="large"
                                    icon={
                                        <LandscapeIcon
                                            sx={{ width: 35, height: 35 }}
                                            fontSize="inherit"
                                        />
                                    }
                                    emptyIcon={
                                        <LandscapeOutlinedIcon
                                            sx={{ width: 35, height: 35 }}
                                            style={{ opacity: 0.55 }}
                                            fontSize="inherit"
                                        />
                                    }
                                /> */}
                                <RatingCustom
                                    mobile={true}
                                    max={6}
                                    value={difficultyState}
                                    setDifficultyState={setDifficultyState}
                                    setApply={setApply}
                                    setFiltriApplicati={setFiltriApplicati}
                                    bool={bool}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-3">
                    {!bool ? (
                        <>
                            <div className="hidden lg:grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 lg:grid-rows-3 mt-12 gap-12 mx-10">
                                {trek && trek.data && !loading
                                    ? trek.data.map((res, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <Trek
                                                    maps={false}
                                                    res={res}
                                                    img={index % 2 === 1 ? trekimg : trekimg2}
                                                    index={index}
                                                />
                                            </React.Fragment>
                                        );
                                    })
                                    : (loading ? <LoadingComponent /> : '')}
                            </div>
                            <div className="lg:hidden flex flex-wrap w-full justify-center items-stretch">
                                {trek && trek.data && !loading
                                    ? trek.data.map((res, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <Trek
                                                    maps={false}
                                                    res={res}
                                                    img={index % 2 === 1 ? trekimg : trekimg2}
                                                    index={index}
                                                />
                                            </React.Fragment>
                                        );
                                    })
                                    : (loading ? <LoadingComponent /> : '')}
                            </div>
                            {!_.isEmpty(trek.data) ? (
                                // <div
                                //     className={
                                //         "flex flex-row w-full gap-4 justify-center items-center"
                                //     }>
                                //     {trek.current_page > 1 && (
                                //         <button
                                //             onClick={() => {
                                //                 setPage(page - 1);
                                //                 window.scrollTo(0, 800);
                                //             }}
                                //             className={
                                //                 "p-2 px-3 uppercase text-xl flex font-bold bg-[#df5b23] text-white mt-8 text-center justify-center items-center cursor-pointer hover:opacity-50"
                                //             }
                                //             style={{ fontFamily: "gimlet-text" }}>
                                //             {"<"}
                                //         </button>
                                //     )}
                                //     {page != "1" && (
                                //         <button
                                //             onClick={() => {
                                //                 setPage(1);
                                //                 window.scrollTo(0, 800);
                                //             }}
                                //             className={
                                //                 "p-2 px-3 uppercase text-xl flex font-bold bg-[#df5b23] text-white mt-8 text-center justify-center items-center cursor-pointer hover:opacity-50"
                                //             }
                                //             style={{ fontFamily: "gimlet-text" }}>
                                //             {"1"}
                                //         </button>
                                //     )}
                                //     <button
                                //         disabled
                                //         className={
                                //             "p-2 px-3 uppercase text-xl flex font-bold bg-[#df5b23] text-white mt-8 text-center justify-center items-center cursor-pointer hover:opacity-50"
                                //         }
                                //         style={{ fontFamily: "gimlet-text" }}>
                                //         {trek.current_page}
                                //     </button>
                                //     <div
                                //         className={
                                //             "p-2 uppercase text-xl flex font-bold bg-[#df5b23] text-white mt-8 text-center justify-center items-center"
                                //         }
                                //         style={{ fontFamily: "gimlet-text" }}>
                                //         {"..."}
                                //     </div>
                                //     <button
                                //         onClick={() => {
                                //             setPage(trek.last_page);
                                //             window.scrollTo(0, 800);
                                //         }}
                                //         className={
                                //             "p-2 px-3 uppercase text-xl flex font-bold bg-[#df5b23] text-white mt-8 text-center justify-center items-center cursor-pointer hover:opacity-50"
                                //         }
                                //         style={{ fontFamily: "gimlet-text" }}>
                                //         {trek.last_page}
                                //     </button>
                                //     <button
                                //         onClick={() => {
                                //             setPage(page + 1);
                                //             window.scrollTo(0, 800);
                                //         }}
                                //         className={
                                //             "p-2 px-3 uppercase text-xl flex font-bold bg-[#df5b23] text-white mt-8 text-center justify-center items-center cursor-pointer hover:opacity-50"
                                //         }
                                //         style={{ fontFamily: "gimlet-text" }}>
                                //         {">"}
                                //     </button>
                                // </div>
                                <div
                                    className={
                                        "flex flex-row w-full gap-4 justify-center items-center"
                                    }>
                                    <PaginationCustom page={page} onChange={
                                        (e) => {
                                            setPage(
                                                Number(e.target.textContent) ?
                                                    Number(e.target.textContent)
                                                    : (
                                                        e.target.dataset.testid === "NavigateNextIcon" ?
                                                            (prevPage) => { return (prevPage + 1) }
                                                            : (prevPage) => { return (prevPage - 1) }
                                                    )
                                            )
                                            window.scrollTo(0, 800);
                                        }
                                    } count={trek['last_page']} />
                                </div>
                            ) : (
                                <div className="text-center text-xl w-full pb-32">
                                    Nessun trek disponibile
                                </div>
                            )}
                        </>
                    ) : (
                        <div
                            className="-translate-y-[3.25%] sticky top-[80px] mt-24 lg:ml-4"
                            style={{ height: "100vh", width: "100%" }}>
                            {
                                pos === 'Italia' ?
                                    <MapContainer zoom={6} treks={treks.data.data} />
                                    : (pos === 'Estero' ? <MapContainer zoom={2} treks={treks.data.data} /> : <GoogleMapContainer coords={coords} />)
                                // <MapContainer treks={treks.data.data} details={coords ? { longitude_start: coords.longitude, latitude_start: coords.latitude } : null} />
                            }
                        </div>
                    )}
                </div>
            </div>
            {/* MODALE SALVA RICERCA*/}
            {
                count && count.profile ?
                    <DialogCustom
                        title={"Salva ricerca"}
                        fullScreen={false}
                        open={openSalvaRicerca}
                        handleClose={() => {
                            setOpenSalvaRicerca(false)
                            api.post('/users/save-trek-search', {
                                name: titleSalvaRicerca,
                                url: `${pos === 'Italia' ? '/trek-italia' : (pos === 'Estero' ? '/trek-estero' : '/vicino-a-me')}${window.location.search}`
                            }).then(res => {
                                setIsSaved({
                                    open: true,
                                    data: res.data.message
                                })
                            })

                        }}
                        closeModal={() => setOpenSalvaRicerca(false)}
                        content={fomrSalvaRicerca()}
                        button={"SALVA"}
                    />
                    :
                    <Dialog
                        open={openSalvaRicerca}
                        onClose={() => {
                            setOpenSalvaRicerca(false)
                        }}
                        maxWidth={"lg"}
                        fullWidth={true}>
                        <>
                            <DialogTitle>
                                Per salvare la ricerca devi effettuare l’accesso
                            </DialogTitle>
                            <DialogContent>
                                <Login loginTo={`/${pos === 'Italia' ? 'trek-italia' + window.location.search : (pos === 'Estero' ? 'trek-estero' + window.location.search : 'vicino-a-me')}`} />
                            </DialogContent>
                        </>

                    </Dialog>
            }

            <DialogCustom
                title={'Messaggio di conferma'}
                button={'CHIUDI'}
                fullScreen={false}
                open={isSaved.open}
                handleClose={() => {
                    setIsSaved({
                        open: false,
                        data: null
                    })
                }}
                closeModal={() => {
                    setIsSaved({
                        open: false,
                        data: null
                    })
                }}
                content={
                    <InputBilo
                        title={isSaved.data}
                    />
                }
            />
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default TrekItalia;

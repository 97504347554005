import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
const PrivateRoutes = () => {
  const count = useSelector((state) => state.count);
  return count.profile ? (
    <Outlet id={count.profile.id} logged={count.logged} />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;

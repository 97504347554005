import React, { useEffect, useState } from "react";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import Topbar from "../components/Topbar";
import NuovoForm from "../components/NuovoForm";
import TopBarIndex from "../components/TopBarIndex";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NuovoForm2 from "../components/NuovoForm2";

const Register = (props) => {

    useEffect(() => {
        document.getElementById('main_title').innerText = (props.socio ? 'Associati' : 'Registrati') + ' - Trekking Italia'
    }, [])

    const [reload, setReload] = useState(false);
    const count = useSelector((state) => state.count);
    return (
        <>
            <TopBarIndex />
            <div className={"w-full justify-center bg-white flex flex-row"}>
                <NuovoForm
                    socioChecked={props.socio ? true : false}
                    setOpen={() => false}
                    reload={reload}
                    setReload={setReload}
                    type={count.profile && (count.profile['user_base'] !== 0) ? 'edit' : 'new'}
                />
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default Register;

import React from "react";
import InputBilo from "../InputBilo";

function Template1({text}) {
  return (
    <InputBilo className="whitespace-pre-wrap text-2xl mb-20" title={text} />
  );
}

export default Template1;

import TopBarIndex from '../components/TopBarIndex';
import FooterDown from '../Sezioni/FooterDown';
import montagne1 from "../assets/img/footer-marrone.png";
import img1 from "../assets/img/accompagnatori.jpg";
import style from "./Privacy.module.css"
import BackgroundTitle from '../components/BackgroundTitle';
import InputBilo from '../components/InputBilo';
import { useSelector } from 'react-redux';
import { api } from '../conf/apiConf';
import useSWR from 'swr';
import { Autocomplete, Button, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import env from '../conf/variables';
import { useFormik } from 'formik';
import InputCustom from '../components/InputCustom';
import { useEffect, useState } from 'react';


function PrivacyPublic() {
    
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Privacy"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Associazione &gt; </div>
                        <div className={"font-bold"}>Privacy</div>
                    </div>
                    {/* Content */}
                    <div
                        className={"my-6 mb-[3.5rem] text-4xl text-[#DF5B23]"}
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}
                    >
                        Privacy
                    </div>
                    {/* <div className='my-12'>
                        <InputBilo
                            className={"text-xl font-bold"}
                            title="Privacy utente"
                        />
                        <div className="my-6 max-h-56 overflow-y-auto">
                            {Privacy()}
                        </div>
                        <InputBilo
                            className={"text-xl font-bold"}
                            title={`Accettata in data: ${user && user.data && user.data.data ? formattedDate(user.data.data.created_at) : ''} `}
                        />
                    </div>
                    {
                        user && user.data && user.data.data && user.data.data.user_partner === '1' &&
                        <div className='my-12'>
                            <InputBilo
                                className={"text-xl font-bold"}
                                title="Privacy socio"
                            />
                            <div className="my-6 max-h-56 overflow-y-auto">
                                {Privacy2()}
                            </div>
                            <InputBilo
                                className={"text-xl font-bold"}
                                title={`Accettata in data: ${user && user.data && user.data.data.associate ? formattedDate(user.data.data.associate.created_at) : ''} `}
                            />
                        </div>

                    } */}
                    <div className={"flex flex-col mb-4"}>
                        <Typography className={'!mt-4'} component={"h1"}>Informativa Privacy:</Typography>
                        <iframe
                            title="Informativa Privacy"
                            className="w-full"
                            height="800"
                            src={`${env.url_api_without_api}document/informativa_privacy.pdf`}
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className={"2xl:hidden flex flex-col items-center gap-2 bg-[#f0e9e3] mt-8 py-8 relative"}>
                <img
                    src={montagne1}
                    alt={"img"}
                    className={"object-contain absolute z-30 -bottom-3"}
                />
            </div>
            <FooterDown />

        </>
    );
}

export default PrivacyPublic
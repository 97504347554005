import React from "react";
import ricerca from "../assets/img/icona-ricerca-trek-home.png";
import select from "../assets/img/freccina-drop-down-home.png";
import { MenuItem, TextField, Typography } from "@mui/material";
import { styled } from "@material-ui/core/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TrekList from "./TrekList";
import { useSelector } from "react-redux";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import _ from "lodash";
import ErrorsComponent from "../components/ErrorsComponent";
import LoadingComponent from "../components/LoadingComponent";
import { dataFormatter } from "../Trek/TrekItalia";
import { useNavigate } from "react-router-dom";

const SearchTrek = () => {
    const [type, setType] = React.useState(null);
    const [destinationValue, setDestinationValue] = React.useState(null);
    const [date, setDate] = React.useState(null);
    const [trek, setTrek] = React.useState([]);
    const [reload, setReload] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [lastPage, setLastPage] = React.useState(1);
    const [position, setPosition] = React.useState('Italia')
    const [typology, setTypology] = React.useState({
        value: '',
        name: 'Posizione'
    })

    const navigate = useNavigate()
    const { token } = useSelector((rootState) => rootState.count);
    api.setHeaders({
        Authorization: "Bearer " + token,
    });

    const fetcher = (url) =>
        api
            .post(url, {
                date: date ? date : new Date(),
                departments: [],
                maps: false,
            })
            .then((res) => {
                setLastPage(res.data.data.last_page)
                setTrek(res.data.data.data);
                return res;
            });
    const { data } = useSWR([`/search-trek?page=${page}`], fetcher, reload);
    const fetcher2 = (url) => api.get(url).then((res) => res);
    const { data: typeTrek } = useSWR([`/type-trek`], fetcher2);

    const fetcher3 = (url) => api.get(url).then((res) => res);
    const { data: destination } = useSWR([`/destination`], fetcher3);

    const fetcher5 = (url) => api.get(url).then((res) => { return res });
    const { data: typologys } = useSWR([`/all-formats`], fetcher5);

    //   console.log(lastPage, trek)
    if (
        (data && !data.ok) ||
        (typeTrek && !typeTrek.ok) ||
        (destination && !destination.ok)
    )
        return <ErrorsComponent />;

    if (!data || !typeTrek) return <LoadingComponent />;

    return (
        <>
            <div
                className={
                    "flex justify-center items-center relative z-40 w-full bg-[#e2d5ca] flex-col gap-4 pb-36"
                }>
                <img
                    alt={"Cannocchiale"}
                    src={ricerca}
                    className={
                        "object-contain lg:h-24 h-16 absolute z-30 lg:-top-10 -top-7"
                    }
                />
                <form action="" id='ricerca-trek' onSubmit={event => {
                    event.preventDefault()
                    let formattedDate = dataFormatter(date === null || date === '' ? '' : date);
                    navigate(`${position === 'Estero' ? 'trek-estero' : 'trek-italia'}?date=${formattedDate}&dateEnd=null&maps=false&type_trek=null&destination=&difficulty=%5B%5D&regions=&typology=%5B${typology.value}%5D&departments=%5B%5D&title=&label=&position=${position}`)
                }}>
                    <fieldset className="flex flex-col w-full items-center">
                        <legend>
                            <h1
                                style={{
                                    fontFamily: "gimlet-text",
                                    fontWeight: 500,
                                    fontStyle: "italic",
                                }}
                                className={"mt-20 text-[#A23E00] text-2xl font-bold"}>
                                Trova il tuo prossimo trek
                            </h1>
                        </legend>
                        <div className={"mt-12 flex lg:flex-row flex-col items-center gap-8"}>
                            <TextField
                                label='Tipologia'
                                select
                                name={"trek"}
                                className={"w-80"}
                                value={typology.value}
                                sx={{ backgroundColor: "#f0e9e3", borderRadius: 20 }}
                                onChange={(e) => {
                                    setTypology({
                                        value: e.target.value,
                                        name: e.target.value === 40 ? 'Grande + Trek' : (e.target.value === 41 ? 'Weekend' : 'Giornaliero')
                                    })
                                }
                                }
                                variant="standard"
                                SelectProps={{ IconComponent: () => null }}
                                InputLabelProps={{
                                    sx: { paddingX: 2 },
                                    shrink: true
                                }}
                                InputProps={{
                                    sx: { color: "#7a6658", borderRadius: 20, paddingX: 1.5 },
                                    disableUnderline: true,
                                    endAdornment: (
                                        <img alt={"freccia per selezionare la tipologia"} src={select} className={"object-contain "} />
                                    ),
                                }}>
                                <MenuItem disabled value={"default"}>
                                    Tipologia di trek
                                </MenuItem>
                                <MenuItem value={''}>-</MenuItem>
                                {typologys &&
                                    typologys.data.data.map((res, index) => {
                                        return (
                                            <MenuItem key={index} value={res.id}>
                                                {res.name}
                                            </MenuItem>
                                        );
                                    })}
                            </TextField>
                            <TextField
                                label='Posizione'
                                select
                                name={"trek"}
                                className={"w-80"}
                                value={position}
                                defaultValue={''}
                                sx={{ backgroundColor: "#f0e9e3", borderRadius: 20, borderWidth: 1, borderColor: 'black', marginY: 0.75 }}
                                onChange={(e) => setPosition(e.target.value)}
                                variant="standard"
                                InputLabelProps={{
                                    sx: { paddingX: 2 }
                                }}
                                SelectProps={{ IconComponent: () => null }}
                                InputProps={{
                                    sx: { color: "#7a6658", borderRadius: 20, paddingX: 1.5 },
                                    disableUnderline: true,
                                    endAdornment: (
                                        <img alt={"img"} src={select} className={"object-contain "} />
                                    ),
                                }}>
                                <MenuItem disabled value={""}>
                                    Posizione
                                </MenuItem>
                                <MenuItem value={'Italia'}>
                                    Italia
                                </MenuItem>
                                <MenuItem value={'Estero'}>
                                    Estero
                                </MenuItem>
                            </TextField>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Data partenza"
                                    inputFormat="dd/MM/yyyy"
                                    value={date ?? new Date()}
                                    type='date'
                                    className={"w-80"}
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                    }}
                                    sx={{ backgroundColor: "#f0e9e3", borderRadius: 10 }}
                                    renderInput={(params) => (
                                        <TextField
                                            label='data-start'
                                            aria-label="date-start"
                                            hiddenLabel={true}
                                            type="date"
                                            sx={{
                                                backgroundColor: "#f0e9e3",
                                                borderRadius: 10,
                                                "& fieldset": {
                                                    borderRadius: "10px",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderRadius: 10,
                                                },
                                            }}
                                            InputProps={{
                                                "aria-label": "start-date",
                                                type: 'date',
                                                sx: { color: "#7a6658", borderRadius: 10 },
                                                disableUnderline: true,
                                                endAdornment: (
                                                    <img
                                                        alt={"img"}
                                                        src={select}
                                                        className={"object-contain "}
                                                    />
                                                ),
                                            }}
                                            {...params}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                        <button
                            type="submit"
                            className={
                                "p-2 uppercase text-xl font-bold bg-[#df5b23] text-white mt-8 cursor-pointer hover:opacity-50"
                            }
                            style={{ fontFamily: "gimlet-text" }}>
                            AVVIA RICERCA
                        </button>
                    </fieldset>
                </form>
            </div>
            <TrekList data={trek} direct={true} page={page} setPage={setPage} last_page={lastPage} />
            {/* {!_.isEmpty(trek.data) ? (
        <div
          className={"flex flex-row w-full gap-4 justify-center items-center"}>
          {trek.current_page > 1 && (
            <button
              onClick={() => {
                setPage(page - 1);
                window.scrollTo(0, 800);
              }}
              className={
                "p-2 px-3 uppercase text-xl flex font-bold bg-[#df5b23] text-white mt-8 text-center justify-center items-center cursor-pointer hover:opacity-50"
              }
              style={{ fontFamily: "gimlet-text" }}>
              {"<"}
            </button>
          )}
          {page != "1" && (
            <button
              onClick={() => {
                setPage(1);
                window.scrollTo(0, 800);
              }}
              className={
                "p-2 px-3 uppercase text-xl flex font-bold bg-[#df5b23] text-white mt-8 text-center justify-center items-center cursor-pointer hover:opacity-50"
              }
              style={{ fontFamily: "gimlet-text" }}>
              {"1"}
            </button>
          )}
          <button
            disabled
            className={
              "p-2 px-3 uppercase text-xl flex font-bold bg-[#df5b23] text-white mt-8 text-center justify-center items-center cursor-pointer hover:opacity-50"
            }
            style={{ fontFamily: "gimlet-text" }}>
            {trek.current_page}
          </button>
          <div
            className={
              "p-2 uppercase text-xl flex font-bold bg-[#df5b23] text-white mt-8 text-center justify-center items-center"
            }
            style={{ fontFamily: "gimlet-text" }}>
            {"..."}
          </div>
          <button
            onClick={() => {
              setPage(trek.last_page);
              window.scrollTo(0, 800);
            }}
            className={
              "p-2 px-3 uppercase text-xl flex font-bold bg-[#df5b23] text-white mt-8 text-center justify-center items-center cursor-pointer hover:opacity-50"
            }
            style={{ fontFamily: "gimlet-text" }}>
            {trek.last_page}
          </button>
          <button
            onClick={() => {
              setPage(page + 1);
              window.scrollTo(0, 800);
            }}
            className={
              "p-2 px-3 uppercase text-xl flex font-bold bg-[#df5b23] text-white mt-8 text-center justify-center items-center cursor-pointer hover:opacity-50"
            }
            style={{ fontFamily: "gimlet-text" }}>
            {">"}
          </button>
        </div>
      ) : (
        <div className="text-center text-xl w-full pb-32">
          Nessun trek disponibile
        </div>
      )} */}
        </>
    );
};

export default SearchTrek;

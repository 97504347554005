import React from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import InputBilo from "../components/InputBilo";
import InputGimlet from "../components/InputGimlet"

const TrekSolidali = () => {

    let page = {
        title: "TREK SOLIDALI",
        paragraph: [
            "Con AlterTrek camminiamo con persone con disabilità visiva (in collaborazione con l’Unione Italiana Ciechi) e con persone affette da autismo (in collaborazione con l’Associazione Nazionale Genitori Persone con Autismo) condividendo con loro le emozioni che la montagna ed il contatto con la natura sanno donarci.",
            "Progettiamo e realizziamo trek di solidarietà.",
            "Andiamo in zone dove, passo dopo passo, il nostro contributo può aiutare, favorendo la rinascita di una o più comunità, come il trek Nelle Terre Mutate, che attraversa i territori colpiti dal sisma nell’Italia centrale del 2016, oppure può sostenere le economie locali, come i trek in Perù o a Capo Verde.",
            "Coi nostri trek aiutiamo Associazioni che svolgono interventi sanitari in Africa e in altre parti del mondo."
        ]
    }

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Trek inclusivi e solidali"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Associazione &gt; </div>
                        <div>Progetti &gt; </div>
                        <div className={"font-bold"}>Trek inclusivi e solidali</div>
                    </div>
                    {/* Content */}
                    <div className="mb-5 text-xl">
                        <InputGimlet
                            title={'Trek inclusivi'}
                            fontWeight={500}
                            fontStyle="italic"
                            className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                        />
                        <InputBilo
                            title={'Sui sentieri siamo tutti uguali, vogliamo offrire opportunità di escursioni rivolte a tutti, indipendentemente dalle disabilità, in un\'ottica di inclusione totale in cui anche gli “abili” potranno scoprire nuovi modi di vedere e nuove prospettive di viaggio in natura.'}
                        />
                        <InputBilo
                            title={'Provare le stesse sensazioni, in uno scambio reciproco realmente e materialmente alla pari.'}
                        />
                        <InputBilo
                            title={'Instaurare una relazione più profonda in cui il cammino assume la valenza simbolica di “camminare insieme”.'}
                        />
                        <br />
                        <InputBilo
                            title={'AlterTrek: un altro cammino è possibile'}
                            fontWeight={700}
                        />
                        <InputBilo
                            title={'È il progetto di cammino con persone disabili dell’Associazione.'}
                        />
                        <InputBilo
                            title={'Organizziamo trek aperti a persone non vedenti o ipovedenti con l’ausilio di soci volontari, trek con ragazzi affetti da disabilità intellettive quali autismo e sindrome di down accompagnati dalle famiglie o da educatori delle Associazioni che collaborano con noi.'}
                        />
                        <br />
                        <InputBilo
                            title={'UnDueTrek'}
                            fontWeight={700}
                        />
                        <InputBilo
                            title={'È il gruppo di trekking per famiglie dell’Associazione: bambini e adulti appassionati di sentieri, ambiente, natura, alla scoperta di sé stessi e di un nuovo modo di stare insieme nel cammino.'}
                        />
                        <InputBilo
                            title={'Ai trek concepiti per ragazze e ragazzi dai 6 ai 14 anni accompagnati da un socio adulto abbiniamo proposte di taglio storico-artistico, scientifico-naturalistico o semplicemente ludiche.'}
                        />
                        <InputGimlet
                            title={'Trek solidali'}
                            fontWeight={500}
                            fontStyle="italic"
                            className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                        />
                        <InputBilo
                            title={'Organizziamo trek finalizzati al sostegno delle economie locali in Italia e all’estero.'}
                        />
                        <InputBilo
                            title={'Lo scopo di questi viaggi è quello di portare il nostro contributo concreto affinché il turismo tradizionale si trasformi in qualcosa di utile, evitando fenomeni di iniquità sociale ed economica a danno delle popolazioni locali. Tutto questo presuppone sobrietà, attenzione, rispetto delle popolazioni locali, curiosità, giustizia economica, diritto delle popolazioni ad essere centrali nella loro comunità.'}
                        />
                        <br />
                        <InputBilo
                            title={'Abbiamo ora deciso di dedicare un’attenzione particolare a questi trek, aumentando il loro numero all’interno di un progetto strutturato e condiviso dalle Sezioni di Trekking Italia.'}
                        />
                        <br />
                        <InputBilo
                            title={'I nostri trek solidali sono di due tipi: organizzati direttamente sulla base di un’idea o di un’esperienza diretta dei soci accompagnatori, o in collaborazione con ONG che operano nei diversi campi della solidarietà internazionale.'}
                        />
                        
                    </div>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default TrekSolidali;

import TopBarIndex from '../components/TopBarIndex';
import FooterDown from '../Sezioni/FooterDown';
import montagne1 from "../assets/img/footer-marrone.png";
import style from "./TrekSalvati.module.css"
import { useEffect, useState } from 'react';
import { api } from '../conf/apiConf';
import { useSelector } from 'react-redux';
import LoadingComponent from '../components/LoadingComponent';
import ErrorsComponent from '../components/ErrorsComponent';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Delete, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import InputBilo from '../components/InputBilo';
import DialogCustom from '../components/DialogCustom';


function TrekSalvati() {

    useEffect(() => {
        document.getElementById('main_title').innerText = 'Ricerche salvate - Trekking Italia'
    }, [])

    const [trekPreferiti, setTrekPreferiti] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [openElimina, setOpenElimina] = useState({
        open: false,
        name: '',
        id: null
    })
    const [isDeleted, setIsDeleted] = useState({
        open: false,
        data: null
    })
    const navigate = useNavigate()
    const count = useSelector(state => state.count)

    api.setHeaders({
        Authorization: "Bearer " + count.token,
    });

    const fetcher3 = (url) => api.get(url).then((res) => { return res.data.data });
    const { data: ricercheSalvate } = useSWR([`/users/get-trek-search`], fetcher3);

    const columns = [
        {
            field: 'name',
            headerName: 'Nome',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'azioni',
            headerName: 'Azioni',
            type: 'actions',
            width: 110,
            getActions: (url) => [
                <GridActionsCellItem
                    showInMenu={true}
                    icon={<Visibility />}
                    onClick={() => {

                        let urlSaved = url.row.url
                        if (url.row.url && (!url.row.url.includes('initialState') || !url.row.url.includes('filtri'))) {

                            if (urlSaved.includes('difficulty=null')) {
                                urlSaved = urlSaved.replace("difficulty=null", "difficulty=[]");
                            }
                            if (urlSaved.includes('typology=null')) {
                                urlSaved = urlSaved.replace("typology=null", "typology=[]");
                            }
                            if (urlSaved.includes('departments=null')) {
                                urlSaved = urlSaved.replace("departments=null", "departments=[]");
                            }
                            navigate(urlSaved)
                        } else {
                            const urlPos = urlSaved.split('?')[0]
                            const params = urlSaved.split('?')[1].split('&filtri')[0].split('&');
                            const paramObject = {};
                            params.forEach(param => {
                                let [key, value] = param.split('=');

                                // Gestire il caso di 'difficulty=null'
                                if (key === 'difficulty' || key === 'typology' || key === 'departments') {
                                    if (value === 'null') {
                                        value = '[]';
                                    } else {
                                        if (paramObject.hasOwnProperty(key)) {
                                            paramObject[key].push(value);
                                        } else {
                                            paramObject[key] = [Number(value)];
                                        }
                                    }
                                } else {
                                    paramObject[key] = value;
                                }
                            });

                            // Convertire l'oggetto dei parametri in una stringa formattata
                            const formattedString = Object.entries(paramObject).map(([key, values]) => {
                                // Unire i valori in un array se sono più di uno, altrimenti restituire il singolo valore
                                let formattedValue = values
                                if(Array.isArray(values)){
                                    formattedValue = values.length > 1 ? `[${values.join(', ')}]` : `[${values[0]}]`;
                                }
                                return `${key}=${formattedValue}`;
                            }).join('&');
                            navigate(`${urlPos}?${formattedString}`)
                        }
                    }}
                    label={'Visualizza'}
                />,
                <GridActionsCellItem
                    showInMenu={true}
                    icon={<Delete />}
                    onClick={() => {
                        setOpenElimina({
                            open: true,
                            name: url.row.name,
                            id: url.row.id
                        })

                    }}
                    label={'Elimina'}
                />
            ]
        },
    ];


    if (error) {
        return <ErrorsComponent />
    }
    if (isLoading) {
        return <isLoading />
    }

    return (
        <>
            <TopBarIndex />

            <div className={"text-2xl pt-5 pl-5 text-[#DF5B23] bg-[#F0E9E3]"} style={{ fontFamily: "gimlet-text", fontWeight: 500, mixBlendMode: "multiply" }}>Le tue ricerche salvate</div>

            {
                <div className={style['treks-container']}>
                    <DataGrid
                        rows={ricercheSalvate ? ricercheSalvate : []}
                        columns={columns}
                        sx={[{ maxWidth: '1000px' }]}
                        disableColumnMenu
                    />
                </div>
            }
            <div className={"2xl:hidden flex flex-col items-center gap-2 bg-[#f0e9e3] mt-8 py-8 relative"}>
                <img
                    src={montagne1}
                    alt={"img"}
                    className={"object-contain absolute z-30 -bottom-3"}
                />
            </div>
            <FooterDown />
            {/* ELIMINA RICERCA */}
            <DialogCustom
                title={"Elimina ricerca"}
                fullScreen={false}
                open={openElimina.open}
                handleClose2={() => {
                    setOpenElimina({
                        open: false,
                        name: '',
                        id: null
                    })
                }}
                handleClose={() => {
                    api.post('/users/delete-trek-search', {
                        search_id: openElimina.id
                    }).then(res => {
                        setIsDeleted({
                            open: true,
                            data: res.data.message
                        })
                    })
                    setOpenElimina({
                        open: false,
                        name: '',
                        id: null
                    })
                    window.location.reload()

                }}
                closeModal={() => setOpenElimina(false)}
                content={<InputBilo
                    title={`Sicuro di voler eliminare la ricerca "${openElimina.name}"?`}
                />}
                button={"SI"}
                button2={"NO"}
            />
            {/* AVVENUTA ELIMINAZIONE */}
            <DialogCustom
                title={'Messaggio di conferma'}
                button={'CHIUDI'}
                fullScreen={false}
                open={isDeleted.open}
                handleClose={() => {
                    setIsDeleted({
                        open: false,
                        data: null
                    })
                }}
                closeModal={() => {
                    setIsDeleted({
                        open: false,
                        data: null
                    })
                }}
                content={
                    <InputBilo
                        title={isDeleted.data}
                    />
                }
            />
        </>
    );
}

export default TrekSalvati
import React from "react";
import InputBilo from "../InputBilo";
import InputGimlet from "../InputGimlet";

function Template3({text, fourImage, singleImage, firstText, secondText}) {

  return (
    <>
      <div className="w-[85%]">
        <InputBilo
          className="whitespace-pre-wrap text-2xl mb-20"
          title={text}
        />
      </div>
      {fourImage.active || firstText.active ? (
        <div className="w-[99vw] bg-[#F1E9E4] flex justify-center items-center py-[5rem]">
          <div
            className={`bg-[${
              firstText.active ? "#ffffff" : "transparent"
            }] w-[95%] flex flex-col xl:flex-row`}>
            {fourImage.active ? (
              <div
                className={
                  (firstText.active ? "xl:w-1/2" : "") +
                  " w-full justify-center flex flex-col"
                }>
                <div
                  className={
                    (firstText.active ? "" : "justify-center ") +
                    "flex md:flex-row flex-col relative items-center"
                  }>
                  {fourImage.image[0] !== '' ? <img
                    className={`object-cover rounded-[30px] relative ${
                      firstText.active
                        ? " mb-[20px] md:mb-[0px] md:mr-10 -top-[20px]"
                        : "m-5 max-w-[700px]"
                    } w-1/2 aspect-square`}
                    src={fourImage.image[0]}
                    alt=""
                  /> : ''}
                  {fourImage.image[1] !== '' ? <img
                    className={`object-cover rounded-[30px] relative ${
                      firstText.active ? "-top-[20px]" : "m-5 max-w-[700px]"
                    } w-1/2 aspect-square`}
                    src={fourImage.image[1]}
                    alt=""
                  /> : ''}
                </div>
                <div
                  className={
                    (firstText.active ? "" : "justify-center ") +
                    "flex md:flex-row flex-col relative items-center"
                  }>
                  {fourImage.image[2] !== '' ? <img
                    className={`object-cover rounded-[30px] relative ${
                      firstText.active
                        ? "md:mr-10 -bottom-[20px]"
                        : "m-5 max-w-[700px]"
                    } w-1/2 aspect-square`}
                    src={fourImage.image[2]}
                    alt=""
                  /> : ''}
                  {fourImage.image[3] !== '' ? <img
                    className={`object-cover rounded-[30px] relative ${
                      firstText.active
                        ? "mt-[20px] md:mt-[0px] -bottom-[20px]"
                        : "m-5 max-w-[700px]"
                    } w-1/2 aspect-square`}
                    src={fourImage.image[3]}
                    alt=""
                  /> : ''}
                </div>
              </div>
            ) : (
              ""
            )}
            {firstText.active ? (
              <div
                className={
                  (fourImage.active ? "xl:w-1/2" : "") +
                  " w-full py-[30px] px-[2rem] xl:px-[5rem] flex flex-col justify-center items-center"
                }>
                <InputGimlet
                  className={"my-6 mb-[2rem] text-4xl text-[#DF5B23] w-full"}
                  title={firstText?.title?.toUpperCase()}
                />
                <InputBilo
                  className={"whitespace-pre-wrap text-2xl"}
                  title={firstText.text}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {singleImage.active || secondText.active ? (
        <div className="w-[99vw] bg-[#FFFFFF] flex justify-center items-center py-[5rem]">
          <div className="bg-[#F1E9E4] w-[95%] flex flex-col-reverse xl:flex-row rounded-[30px]">
            {secondText.active ? (
              <div
                className={
                  (singleImage.active ? "xl:w-1/2" : "") +
                  " w-full py-[30px] px-[2rem] xl:px-[5rem] flex flex-col justify-center items-center"
                }>
                <InputGimlet
                  className={"my-6 mb-[2rem] text-4xl text-[#DF5B23] w-full"}
                  title={secondText?.title?.toUpperCase()}
                />
                <InputBilo
                  className={"whitespace-pre-wrap text-2xl"}
                  title={secondText.text}
                />
              </div>
            ) : (
              ""
            )}
            {singleImage.active ? (
              <div
                className={
                  (secondText.active ? "xl:w-1/2" : "") +
                  " w-full justify-center items-center flex flex-col"
                }>
                <img
                  className={`object-cover scale-110 rounded-[30px] relative ${
                    secondText.active ? "" : "m-5 max-w-[700px]"
                  } w-2/3 aspect-square`}
                  src={singleImage.image}
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Template3;

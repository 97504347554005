import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    it: {
      translation: null, // lasciamo vuoto il valore delle traduzioni inizialmente
    },
    en: {
      translation: null,
    },
  },
});
// carichiamo il file JSON delle traduzioni attraverso una richiesta HTTP https://webhook.site/89373348-9f4f-4559-8f86-a43f5c3bfbe9
// fetch("https://webhook.site/c7705264-dbfd-4621-b045-42a543338917")
//   .then((response) => response.json())
//   .then((data) => {
//     console.log("+++++++++++", data);
//     // quando il file viene caricato con successo, lo utilizziamo per impostare le traduzioni
//     i18n.addResourceBundle("it", "translation", data.it);
//     i18n.addResourceBundle("en", "translation", data.en);
//   });

export default i18n;

import React from "react";
import img1 from "../assets/img/maschera-come-attrezzarsi-home.png";
import img2 from "../assets/img/maschera-dove-andiamo-home.png";
import { useNavigate } from "react-router-dom";

const TrekkingConsigliaMobile = () => {

    const navigate = useNavigate()

    return (
        <div className={"bg-[#f0e9e3] pb-12 pt-8 flex flex-col"}>
            <h1
                style={{ fontFamily: "gimlet-text" }}
                className={"text-[#df5b23] text-4xl mt-6 text-center mt-6"}
            >
                Trekking Italia
            </h1>
            <div className={"bg-white rounded-xl p-8 m-8"}>
                <div className={"flex flex-col gap-2 w-full"}>
                    <h2 className={"text-[#df5b23] text-xl"}>DOVE ANDIAMO?</h2>
                    <p style={{ fontFamily: "gimlet-text" }} className={"text-xl"}>
                        Le destinazioni più amate per camminare in compagnia
                    </p>
                    <div
                        onClick={() => { navigate('/trek-italia') }}
                        className={
                            "uppercase cursor-pointer hover:opacity-50 text-center text-lg w-1/2 p-2 bg-[#f0e9e3]"
                        }
                        style={{ fontFamily: "gimlet-text" }}
                    >
                        SCOPRILE
                    </div>
                </div>
            </div>
            <img alt={"trek"} src={img1} className={"object-contain w-full"} />
            <div className={"bg-white rounded-xl p-8 m-8"}>
                <div className={"flex flex-col gap-2 w-full"}>
                    <h2 className={"text-[#df5b23] text-xl"}>DOVE ANDIAMO?</h2>
                    <p style={{ fontFamily: "gimlet-text" }} className={"text-xl"}>
                        Preparati per i tuoi trekking di uno o più giorni con i nostri
                        volontari
                    </p>
                    <button
                        role="button"
                        className={
                            "uppercase cursor-pointer hover:opacity-50 text-center text-lg w-1/2 p-2 bg-[#f0e9e3]"
                        }
                        style={{ fontFamily: "gimlet-text" }}
                    >
                        LEGGI DI PIU'
                    </button>
                </div>
            </div>
            <img alt={"paesaggio"} src={img2} className={"object-contain w-full"} />
        </div>
    );
};

export default TrekkingConsigliaMobile;

import React from "react";

const ErrorsComponent = (props) => {
  return (
    <div
      className={"h-screen w-full flex flex-col items-center justify-center"}
    >
      <div>{props.message? props.message : "Qualcosa è andato storto...riprova"}</div>
    </div>
  );
};

export default ErrorsComponent;

import React, { useEffect } from "react";
import Topbar from "../components/Topbar";
import Slider from "../Sezioni/Slider";
import SearchTrek from "../Sezioni/SearchTrek";
import TrekList from "../Sezioni/TrekList";
import Numbers from "../Sezioni/Numbers";
import TrekkingConsiglia from "../Sezioni/TrekkingConsiglia";
import DiconoDiNoi from "../Sezioni/DiconoDiNoi";
import News from "../Sezioni/News";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import TopbarMobile from "../components/TopbarMobile";
import NumbersMobile from "./NumbersMobile";
import TrekkingConsigliaMobile from "./TrekkingConsigliaMobile";
import TopBarIndex from "../components/TopBarIndex";

const Home = () => {
    useEffect(()=>{
        document.getElementById('main_title').innerText = 'Trekking Italia'
    }, [])
  return (
    <>
      <TopBarIndex />
      <Slider />
      <SearchTrek />
      {/*<TrekList />*/}
      <div className={"hidden lg:block"}>
        <Numbers />
      </div>
      <div className={"block lg:hidden"}>
        <NumbersMobile />
      </div>
      <div className={"hidden lg:block"}>
        <TrekkingConsiglia />
      </div>
      <div className={"block lg:hidden"}>
        <TrekkingConsigliaMobile />
      </div>
      <DiconoDiNoi />
      <News />
      <FooterTop />
      <FooterDown />
    </>
  );
};

export default Home;

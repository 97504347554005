import React from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import "./map.css";
import blu from "../assets/PointMappa/blu.png";
import verde from "../assets/PointMappa/verde.png";
import arancione from "../assets/PointMappa/arancione.png";
import Trek from "../components/Trek";
import trekimg from "../assets/img/img-trek-1.png";
import _ from "lodash";

const mapStyles = {
  width: "100%",
  height: "100%",
};

const mapStylesDetails = {
  width: "100%",
  height: "50%",
};

export class MapContainer extends React.Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    initialCenter: {
      lat: this.props.details ? this.props.details.latitude_end : 41.9027835,
      lng: this.props.details ? this.props.details.longitude_end : 12.4963655,
    },
    apply: true
  };

  componentDidUpdate(prevProps, prevState) {
    // console.log(prevProps)
    
  }

  onMarkerClick = (resItem) =>
    this.setState({
      selectedPlace: resItem,
    });

  onMapClick = () => {
    this.setState({
      selectedPlace: null,
    });
  };

  render() {
    return (
      <div className="map-container">
        {this.props.details ? (
          <Map
            onClick={() => {
              this.onMapClick();
            }}
            google={this.props.google}
            zoom={12}
            style={ mapStylesDetails }
            initialCenter={this.state.initialCenter}
            className="map relative">
            {!_.isEmpty(this.state.selectedPlace) && (
              <div className="absolute p-4 -top-24">
                <Trek
                  res={this.state.selectedPlace}
                  img={trekimg}
                  maps={true}
                />
              </div>
            )}
            {this.props.treks ? (
              this.props.treks.map((resItem, index) => {
                return (
                  <Marker
                    name={index}
                    onClick={() => this.onMarkerClick(resItem)}
                    icon={
                      resItem.formatName === "Grande Trek"
                        ? blu
                        : resItem.formatName === "Giornaliero"
                        ? arancione
                        : verde
                    }
                    position={{
                      lat: resItem.latitude_end ,
                      lng: resItem.longitude_end,
                    }}
                  />
                );
              })
            ) : (
              <Marker
                name={this.props.details.latitude_end}
                onClick={() => this.onMarkerClick(this.props.details)}
                position={{
                  lat: this.props.details.latitude_end,
                  lng: this.props.details.longitude_end,
                }}
              />
            )}
            {/* <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}>
            <div>
              <h3>{this.state.selectedPlace.name}</h3>
            </div>
          </InfoWindow> */}
          </Map>
        ) : (
          <Map
            onClick={() => {
              this.onMapClick();
            }}
            google={this.props.google}
            zoom={this.props.zoom}
            style={ mapStyles}
            initialCenter={this.state.initialCenter}
            className="map relative">
            {!_.isEmpty(this.state.selectedPlace) && (
              <div className="absolute p-4 -top-24">
                <Trek
                  res={this.state.selectedPlace}
                  img={trekimg}
                  maps={true}
                  close={this.onMapClick}
                />
              </div>
            )}
            {this.props.treks ? (
              this.props.treks.map((resItem, index) => {
                return (
                  <Marker
                    name={index}
                    onClick={() => this.onMarkerClick(resItem)}
                    icon={
                      resItem.formatName === "Grande Trek"
                        ? blu
                        : resItem.formatName === "Giornaliero"
                        ? arancione
                        : verde
                    }
                    position={{
                      lat: resItem.latitude_end,
                      lng: resItem.longitude_end,
                    }}
                  />
                );
              })
            ) : ''}
            {/* <InfoWindow
        marker={this.state.activeMarker}
        visible={this.state.showingInfoWindow}>
        <div>
          <h3>{this.state.selectedPlace.name}</h3>
        </div>
      </InfoWindow> */}
          </Map>
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyB0QTd2qpVsJefoIwGs82dAzIKxxPmP1eA",
})(MapContainer);

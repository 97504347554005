import React from "react";

const ButtonCustom = ({ children, onClickCustom, className = '', bg=false }) => {
  return (
    <div
      onClick={onClickCustom}
      className={`uppercase cursor-pointer hover:opacity-50 text-center text-white text-lg p-2 ${bg ? bg : ' bg-[#c44116]'}  ${className}`}>
      {children}
    </div>
  );
};

export default ButtonCustom;

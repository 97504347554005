import React, { useState } from "react";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { Alert, Autocomplete, Box, Button, Checkbox, Divider, FormControlLabel, Modal, Typography } from "@mui/material";
import TopBarIndex from "../components/TopBarIndex";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import InputCustom from "../components/InputCustom";
import env from "../conf/variables";
import { useSelector } from "react-redux";
import { connect } from "react-redux";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
};


const validationSchema = yup.object({
    username: yup.string().required("Campo obbligatorio"),
    password: yup
        .string()
        .min(8, "La password deve contenere almeno almeno 8 caratteri")
        .required("Password obbligatoria"),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "Le password devono essere identiche"),
});

const axiosConfig = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
};

const ChangePassword = ({ dispatch }) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [errorForm, setErrorForm] = useState();
    const [openSuccess, setOpenSuccess] = useState(false);

    const fetcher2 = (url) => api.post(url, {
        username: searchParams.get("username"),
    }).then((res) => {
        return res
    });
    const { data: user } = useSWR([`/user-verification-data`], fetcher2);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: searchParams.get("username"),
            password: "",
            password_confirmation: "",
            accept_partnerconsent_1: user && user.data && user.data.data && `${user.data.data.accept_partnerconsent_1 === '1'}`,
            accept_partnerconsent_2: user && user.data && user.data.data && `${user.data.data.accept_partnerconsent_2 === '1'}`,
            accept_partnerconsent_3: user && user.data && user.data.data && `${user.data.data.accept_partnerconsent_3 === '1'}`,
            accept_partnerconsent_4: user && user.data && user.data.data && (user.data.data.accept_partnerconsent_4 === '1' ? 'true' : (user.data.data.accept_partnerconsent_4 === '0' ? 'false' : 'null')),
            disability_type: user && user.data && user.data.data && user.data.data.disability_type ? user.data.data.disability_type : [],
            accept_privacy: user && user.data && user.data.data && `${user.data.data.accept_privacy === '1'}`,
            accept_privacy_partner: user && user.data && user.data.data && `${user.data.data.accept_privacy_partner === '1'}`,
            accept_rule_partner: user && user.data && user.data.data && `${user.data.data.accept_rule_partner === '1'}`,
            accept_statute_partner: user && user.data && user.data.data && `${user.data.data.accept_statute_partner === '1'}`,
            first_login: (user && user.data && user.data.data && (!user.data.data.email_verified_at || user.data.data.email_verified_at === null)) || (user && user.data && user.data.data && user.data.data.user_state && user.data.data.user_state === 'pending')
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            api
                .post(`/reset-password`, {
                    ...values,
                    token: searchParams.get("token"),
                })
                .then((res) => {
                    if (res.ok) {
                        dispatch.count.setToken(null);
                        dispatch.count.setProfile(null);
                        setOpenSuccess(true)
                    } else {
                        setErrorForm(res.data.errors);
                    }
                });
        },
    });

    return (
        <>
            <TopBarIndex />
            <>
                <form onSubmit={formik.handleSubmit}>
                    <div class="bg-white my-36">
                        <div class="flex flex-col  items-center justify-center px-6 py-8 mx-auto lg:py-0">
                            <div class="w-full  bg-[#f0e9e3] rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
                                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                                    <h1 class="text-xl font-bold leading-tight tracking-tight text-[#4a4137] md:text-2xl">
                                        Inserisci una nuova password
                                    </h1>
                                </div>
                                <div className="px-6 py-4">
                                    <TextField
                                        disabled
                                        label={"Username"}
                                        name={"username"}
                                        value={formik.values.username}
                                        onChange={formik.handleChange}
                                        fullWidth
                                        error={
                                            formik.touched.username && Boolean(formik.errors.username)
                                        }
                                        helperText={
                                            formik.touched.username && formik.errors.username
                                        }
                                    />
                                </div>
                                <div className="px-6 py-4">
                                    <TextField
                                        type={"password"}
                                        label={"Password"}
                                        name={"password"}
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        fullWidth
                                        error={
                                            formik.touched.password && Boolean(formik.errors.password)
                                        }
                                        helperText={
                                            formik.touched.password && formik.errors.password
                                        }
                                    />
                                </div>
                                <div className="px-6 py-4">
                                    <TextField
                                        type={"password"}
                                        label={"Conferma Password"}
                                        name={"password_confirmation"}
                                        value={formik.values.password_confirmation}
                                        onChange={formik.handleChange}
                                        fullWidth
                                        error={
                                            formik.touched.password_confirmation &&
                                            Boolean(formik.errors.password_confirmation)
                                        }
                                        helperText={
                                            formik.touched.password_confirmation &&
                                            formik.errors.password_confirmation
                                        }
                                    />
                                </div>


                                {
                                    !((user && user.data && user.data.data && (!user.data.data.email_verified_at || user.data.data.email_verified_at === '')) || (user && user.data && user.data.data && user.data.data.user_state && user.data.data.user_state === 'pending')) &&
                                    <>
                                        {
                                            errorForm &&
                                            Object.values(errorForm).map((res, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Alert className={"mb-2 mx-6"} severity="error">
                                                            {res}
                                                        </Alert>
                                                    </React.Fragment>
                                                );
                                            })
                                        }
                                        <div
                                            className={"w-full flex flex-row gap-6 my-6 justify-center"}
                                        >
                                            <button
                                                type={"submit"}
                                                className={
                                                    "uppercase cursor-pointer hover:opacity-50 text-center text-white text-lg w-1/2 p-2 bg-[#df5b23]"
                                                }
                                                style={{ fontFamily: "gimlet-text" }}
                                            >
                                                RESET PASSWORD
                                            </button>
                                        </div>
                                    </>

                                }
                            </div>
                            {
                                ((user && user.data && user.data.data && (!user.data.data.email_verified_at || user.data.data.email_verified_at === '')) || (user && user.data && user.data.data && user.data.data.user_state && user.data.data.user_state === 'pending')) &&
                                <>
                                    {/* ACCETTAZIONE PRIVACY */}
                                    <div>
                                        <div className="px-10 pt-10">
                                            <Typography component={'p'}>Ciao {searchParams.get("username")},</Typography>
                                            <Typography component={'p'}>Questo è il tuo primo accesso a questo sito e ti chiediamo di accettare la privacy.</Typography>
                                        </div>
                                        <div className={"flex flex-col pt-5"}>
                                            <div className={"flex flex-col "}>
                                                <Typography className={'!mt-4'} component={"h1"}>Informativa Privacy:</Typography>
                                                <iframe
                                                    title="Informativa Privacy"
                                                    className="w-full"
                                                    height="400"
                                                    src={`${env.url_api_without_api}document/informativa_privacy.pdf`}
                                                    allowFullScreen
                                                ></iframe>
                                                <div className="flex items-center">
                                                    <FormControlLabel
                                                        sx={{ margin: 0 }}
                                                        control={
                                                            <Checkbox
                                                                checked={formik.values.accept_privacy === 'true'}
                                                                onChange={(event) =>
                                                                    formik.setFieldValue(
                                                                        "accept_privacy",
                                                                        `${event.target.checked}`
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label="Dichiaro di aver preso visione e accetto il Trattamento dei dati Personali"
                                                    />
                                                    <div className={"text-red-500 "}>*</div>
                                                </div>
                                            </div>

                                        </div>
                                        {user && user.data && user.data.data && user.data.data.user_partner === '1' && (
                                            <>
                                                <div className="flex flex-col gap-6 mt-8 mb-4">
                                                    <Divider />
                                                    <div>
                                                        <Typography className={'!mt-4'} component={"h1"}>Consenso soci:</Typography>
                                                        <iframe
                                                            title="Consenso soci"
                                                            className="w-full"
                                                            height="800"
                                                            src={`${env.url_api_without_api}document/consenso_soci.pdf`}
                                                            allowFullScreen
                                                        ></iframe>
                                                        <Typography className={'!mt-4'} component={"h1"}>
                                                            Invio di materiale informativo e/o pubblicitario anche mediante telefono o internet (Newsletter/cataloghi).
                                                        </Typography>
                                                        <div className={"flex flex-row items-center"}>
                                                            <FormControlLabel
                                                                sx={{ margin: 0 }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={formik.values.accept_partnerconsent_1 === "true"}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "accept_partnerconsent_1",
                                                                                "true"
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label="CONSENTO"
                                                            />
                                                            <FormControlLabel
                                                                sx={{ margin: 0, whiteSpace: 'nowrap' }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={formik.values.accept_partnerconsent_1 === "false"}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "accept_partnerconsent_1",
                                                                                "false"
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label="NON CONSENTO"
                                                            />
                                                            <div className='w-full text-center max-w-[600px]'>
                                                                {
                                                                    user && user.data && user.data.data && user.data.data.accept_partnerconsent_1_date ?
                                                                        `(Accettato in data ${user.data.data.accept_partnerconsent_1_date.split('-')[2].split(' ')[0]}/${user.data.data.accept_partnerconsent_1_date.split('-')[1]}/${user.data.data.accept_partnerconsent_1_date.split('-')[0]} ${user.data.data.accept_partnerconsent_1_date.split(' ')[1]})`
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <Typography className={'!mt-4'} component={"h1"}>
                                                            Pubblicazione della mia immagine e/o videoripresa sul sito web e/o social network e/o brochure istituzionali
                                                            in via gratuita.
                                                        </Typography>
                                                        <div className={"flex flex-row items-center"}>
                                                            <FormControlLabel
                                                                sx={{ margin: 0 }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={formik.values.accept_partnerconsent_2 === "true"}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "accept_partnerconsent_2",
                                                                                "true"
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label="CONSENTO"
                                                            />
                                                            <FormControlLabel
                                                                sx={{ margin: 0, whiteSpace: 'nowrap' }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={formik.values.accept_partnerconsent_2 === "false"}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "accept_partnerconsent_2",
                                                                                "false"
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label="NON CONSENTO"
                                                            />
                                                            <div className='w-full text-center max-w-[600px]'>
                                                                {
                                                                    user && user.data && user.data.data && user.data.data.accept_partnerconsent_2_date ?
                                                                        `(Accettato in data ${user.data.data.accept_partnerconsent_2_date.split('-')[2].split(' ')[0]}/${user.data.data.accept_partnerconsent_2_date.split('-')[1]}/${user.data.data.accept_partnerconsent_2_date.split('-')[0]} ${user.data.data.accept_partnerconsent_2_date.split(' ')[1]})`
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <Typography className={'!mt-4'} component={"h1"}>
                                                            Invio via SMS, mail, whatsApp, etc. di comunicazioni tra i soci partecipanti, utili per lo svolgimento e
                                                            organizzazione delle escursioni.
                                                        </Typography>
                                                        <div className={"flex flex-row items-center"}>
                                                            <FormControlLabel
                                                                sx={{ margin: 0 }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={formik.values.accept_partnerconsent_3 === "true"}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "accept_partnerconsent_3",
                                                                                "true"
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label="CONSENTO"
                                                            />
                                                            <FormControlLabel
                                                                sx={{ margin: 0, whiteSpace: 'nowrap' }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={formik.values.accept_partnerconsent_3 === "false"}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "accept_partnerconsent_3",
                                                                                "false"
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label="NON CONSENTO"
                                                            />
                                                            <div className='w-full text-center max-w-[600px]'>
                                                                {
                                                                    user && user.data && user.data.data && user.data.data.accept_partnerconsent_3_date ?
                                                                        `(Accettato in data ${user.data.data.accept_partnerconsent_3_date.split('-')[2].split(' ')[0]}/${user.data.data.accept_partnerconsent_3_date.split('-')[1]}/${user.data.data.accept_partnerconsent_3_date.split('-')[0]} ${user.data.data.accept_partnerconsent_3_date.split(' ')[1]})`
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <Typography className={'!mt-4'} component={"h1"}>
                                                            Trattamento dati sanitari in riferimento alle condizioni psicofisiche del partecipante alle escursioni itineranti.
                                                        </Typography>
                                                        <div className={"flex flex-row items-center"}>
                                                            <FormControlLabel
                                                                sx={{ margin: 0 }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={formik.values.accept_partnerconsent_4 === "true"}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "accept_partnerconsent_4",
                                                                                "true"
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label="CONSENTO"
                                                            />
                                                            <FormControlLabel
                                                                sx={{ margin: 0, whiteSpace: 'nowrap' }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={formik.values.accept_partnerconsent_4 === "false"}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "accept_partnerconsent_4",
                                                                                "false"
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label="Non Consento"
                                                            />
                                                            <FormControlLabel
                                                                sx={{ margin: 0, whiteSpace: 'nowrap' }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={formik.values.accept_partnerconsent_4 === "null"}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "accept_partnerconsent_4",
                                                                                "null"
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label="NON APPLICABILE"
                                                            />
                                                            <div className='w-full text-center max-w-[422px]'>
                                                                {
                                                                    user && user.data && user.data.data && user.data.data.accept_partnerconsent_4_date ?
                                                                        `(Accettato in data ${user.data.data.accept_partnerconsent_4_date.split('-')[2].split(' ')[0]}/${user.data.data.accept_partnerconsent_4_date.split('-')[1]}/${user.data.data.accept_partnerconsent_4_date.split('-')[0]} ${user.data.data.accept_partnerconsent_4_date.split(' ')[1]})`
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <Autocomplete
                                                            defaultValue={user && user.data && user.data.data && JSON.parse(user.data.data.disability_type) ? JSON.parse(user.data.data.disability_type) : []}
                                                            fullWidth
                                                            multiple
                                                            onChange={(e, value) => { formik.setFieldValue("disability_type", value) }}
                                                            options={['fisica', 'psichica', 'sensoriale']}
                                                            getOptionLabel={(option) => option}
                                                            filterSelectedOptions
                                                            renderInput={(params) => {
                                                                return <InputCustom
                                                                    name={"disability_type"}
                                                                    title="Selezionare tipologia di disabilità"
                                                                    {...params}
                                                                />
                                                            }}
                                                        />
                                                    </div>

                                                    <Divider />
                                                    <div>
                                                        <Typography className={'!mt-4'} component={"h1"}>Statuto:</Typography>
                                                        <iframe
                                                            title="Statuto"
                                                            className="w-full"
                                                            height="400"
                                                            src={`${env.url_api_without_api}/document/statuto.pdf`}
                                                            allowFullScreen
                                                        ></iframe>
                                                        <div className={"flex flex-row items-center"}>
                                                            <FormControlLabel
                                                                sx={{ margin: 0 }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={formik.values.accept_statute_partner === 'true'}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "accept_statute_partner",
                                                                                `${event.target.checked}`
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label="Dichiaro di aver preso visione e accetto lo Statuto"
                                                            />
                                                            <div className={"text-red-500 "}>*</div>
                                                        </div>
                                                    </div>

                                                    <Divider />
                                                    <div>
                                                        <Typography className={'!mt-4'} component={"h1"}>REGOLAMENTO DI ATTUAZIONE DELLO STATUTO:</Typography>
                                                        <iframe
                                                            title="REGOLAMENTO DI ATTUAZIONE DELLO STATUTO"
                                                            className="w-full"
                                                            height="400"
                                                            src={`${env.url_api_without_api}/document/regolamento_statuto.pdf`}
                                                            allowFullScreen
                                                        ></iframe>
                                                        <div className={"flex flex-row items-center"}>
                                                            <FormControlLabel
                                                                sx={{ margin: 0 }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={formik.values.accept_rule_partner === 'true'}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "accept_rule_partner",
                                                                                `${event.target.checked}`
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label="Dichiaro di aver preso visione e accetto il REGOLAMENTO DI ATTUAZIONE DELLO STATUTO"
                                                            />
                                                            <div className={"text-red-500 "}>*</div>
                                                        </div>
                                                    </div>
                                                    <Divider />

                                                    <div>
                                                        <Typography className={'!mt-4'} component={"h1"}>REGOLAMENTO SUL FUNZIONAMENTO DELLE ASSEMBLEE:</Typography>
                                                        <iframe
                                                            title="REGOLAMENTO SUL FUNZIONAMENTO DELLE ASSEMBLEE"
                                                            className="w-full"
                                                            height="400"
                                                            src={`${env.url_api_without_api}/document/regolamento_assemblee.pdf`}
                                                            allowFullScreen
                                                        ></iframe>
                                                        <div className={"flex flex-row items-center "}>
                                                            <FormControlLabel
                                                                sx={{ margin: 0 }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={formik.values.accept_privacy_partner === 'true'}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "accept_privacy_partner",
                                                                                `${event.target.checked}`
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label="Dichiaro di aver preso visione e accetto il REGOLAMENTO SUL FUNZIONAMENTO DELLE ASSEMBLEE"
                                                            />
                                                            <div className={"text-red-500 "}>*</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {
                                        errorForm &&
                                        Object.values(errorForm).map((res, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <Alert className={"mb-2 mx-6"} severity="error">
                                                        {res}
                                                    </Alert>
                                                </React.Fragment>
                                            );
                                        })
                                    }
                                    {/* RESET PASSWORD BUTTON */}
                                    <div
                                        className={"w-full flex flex-row gap-6 my-6 justify-center"}
                                    >
                                        <button
                                            type={"submit"}
                                            className={
                                                "uppercase cursor-pointer hover:opacity-50 text-center text-white text-lg w-1/2 p-2 bg-[#df5b23]"
                                            }
                                            style={{ fontFamily: "gimlet-text" }}
                                        >
                                            RESET PASSWORD
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </form>
            </>
            <Modal
                open={openSuccess}
                onClose={() => setOpenSuccess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{ m: 10, textAlign: 'center' }}>
                        Password cambiata!
                    </Typography>
                    <div
                        className={`w-full mt-6 flex flex-row items-center gap-4 justify-center`}>
                        <Button
                            variant={"contained"}
                            className={
                                "shadow-md uppercase w-full max-w-[300px] !text-2xl italic !font-normal"
                            }
                            onClick={() => {
                                window.scroll(0, 0)
                                setOpenSuccess(false)
                                navigate("/")
                            }}>
                            Chiudi
                        </Button>
                    </div>
                </Box>
            </Modal>
            <FooterTop />
            <FooterDown />
        </>
    );
};

const mapState = (state) => ({
    count: state.count,
});

export default connect(mapState)(ChangePassword);

import React, { useEffect, useState } from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/organismi.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import Divider from "@mui/material/Divider";
import ItemOrganigramma from "../components/ItemOrganigramma";
import DividerCustom from "../components/DividerCustom";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import styles from './Organismi.module.css'
import InputGimlet from "../components/InputGimlet";
import InputBilo from "../components/InputBilo";

const InitialStateOrganigramma = {
    presidente: {
        name: '',
        surname: '',
        title: 'Presidente',
    },
    vicePresidente: {
        name: '',
        surname: '',
        title: 'Vice Presidente',
    },
    comitatoEsecutivoNazionale: [],
    organiDiControllo: [
        {
            name: 'Corrado',
            surname: 'Colombo',
            work: 'Presidente'
        }
    ],
}

const Organismi = () => {

    const [organigramma2, setOrganigramma2] = useState({
        presidente: {
            name: '',
            surname: '',
            title: 'Presidente',
        },
        vicePresidente: {
            name: '',
            surname: '',
            title: 'Vice Presidente',
        },
        comitatoEsecutivoNazionale: [],
        organiDiControllo: [
            {
                name: 'Corrado',
                surname: 'Colombo',
                work: 'Presidente'
            }
        ],
    })
    let organigramma = {
        presidente: {
            name: 'Franco',
            surname: 'Napoli',
            title: 'Presidente',
        },
        vicePresidente: {
            name: 'Doriana',
            surname: 'Sanavio',
            title: 'Vice Presidente',
        },
        comitatoEsecutivoNazionale: [
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: 'Tesoriere'
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: 'Segretario'
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },


        ],
        organiDiControllo: [
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: 'Presidente'
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
            {
                name: 'Doriana',
                surname: 'Sanavio',
                work: ''
            },
        ],
    }

    const fetcher = (url) => api.get(url).then((res) => {
        return res
    });
    const { data } = useSWR([`/departments/positions-managerial/1`], fetcher);
    useEffect(() => {
        if (data && data.data && data.data.data) {
            let organigrammaTemp = { ...InitialStateOrganigramma }
            data.data.data.map((user, index) => {
                if (user.id_incarico === 'Presidente') {
                    organigrammaTemp.presidente.name = user.nome
                    organigrammaTemp.presidente.surname = user.surname
                    organigrammaTemp.presidente.title = 'Presidente'
                }
                if (user.id_incarico === 'Vice-Presidente') {
                    organigrammaTemp.vicePresidente.name = user.nome
                    organigrammaTemp.vicePresidente.surname = user.surname
                    organigrammaTemp.vicePresidente.title = 'Vicepresidente'
                }
                if (user.id_incarico === 'Consigliere Consiglio Esecutivo Nazionale' ||
                    user.id_incarico === 'Tesoriere Nazionale' ||
                    user.id_incarico === 'Segretario Nazionale') {
                    organigrammaTemp.comitatoEsecutivoNazionale = [...organigrammaTemp.comitatoEsecutivoNazionale, {
                        name: user.nome,
                        surname: user.surname,
                        work: user.id_incarico === 'Consigliere Consiglio Esecutivo Nazionale' ? '' : user.id_incarico,
                    }]
                }
                if (user.id_incarico === 'Delegato Assemblea Nazionale' || user.id_incarico === 'Organo di Controllo' ||
                user.id_incarico ===  "Revisore dei Conti") {
                    organigrammaTemp.organiDiControllo = [...organigrammaTemp.organiDiControllo, {
                        name: user.nome,
                        surname: user.surname,
                        work: (user.id_incarico === 'Delegato Assemblea Nazionale' ||
                        user.id_incarico ===  "Revisore dei Conti") ? '' : user.id_incarico,
                    }]
                }

            })
            setOrganigramma2(organigrammaTemp)
        }
    }, [data])

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle title={"Organismi"} img={img1} />
            <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
                <div
                    className={"flex flex-row items-center gap-1"}
                    style={{
                        fontFamily: "bilo",
                        fontWeight: 500,
                        fontStyle: "normal",
                    }}
                >
                    <div>Home {">"}</div>
                    <div>Associazione {">"}</div>
                    <div className={"font-bold"}> Organismi</div>
                </div>
                <InputGimlet
                    title={'organismi'}
                    fontWeight={500}
                    fontStyle="italic"
                    className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                />
                <div className="mb-5 text-xl">
                    <InputBilo
                        title={'Trekking Italia opera nel rispetto del Codice del Terzo Settore ed è un’Associazione nazionale strutturata in Sezioni regionali.'}
                    />
                    <br />
                    <InputBilo
                        title={'Trekking Italia è iscritta al RUNTS (Registro Unico Nazionale Terzo Settore) dal 19/1/2023 e la sede legale è a Milano.'}
                    />
                    <br />
                    <InputBilo
                        title={'Gli organismi decisionali sono nazionali e regionali come prevedono lo Statuto e il Regolamento di attuazione dello Statuto. I componenti restano in carica tre anni.'}
                    />
                    <br />
                    <InputBilo
                        title={'Organismi nazionali dell\'Associazione'}
                        fontWeight={700}
                        className={'underline text-2xl'}
                    />
                    <br />
                    <InputBilo
                        title={'Assemblea nazionale'}
                        className={'inline'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={': delibera gli indirizzi generali, i programmi dell\'Associazione, approva i bilanci; si è data un proprio Regolamento. I componenti sono i delegati eletti dai soci nelle assemblee delle Sezioni regionali.'}
                        className={'inline'}
                    />
                    <br />
                    <br />
                    <InputBilo
                        title={'Consiglio Esecutivo Nazionale (CEN):'}
                        className={'inline'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={': è l’organo amministrativo ed è composto dal Presidente, dal Vicepresidente e da 18 consiglieri.'}
                        className={'inline'}
                    />
                    <br />
                    <br />
                    <InputBilo
                        title={'Presidente'}
                        className={'inline'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={': esegue le delibere del CEN, coordina le iniziative e l\'attuazione del programma, è il rappresentante legale dell\'Associazione.'}
                        className={'inline'}
                    />
                    <br />
                    <br />
                    <InputBilo
                        title={'Vicepresidente'}
                        className={'inline'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={': sostituisce il Presidente con gli stessi poteri in caso di sua assenza o impedimento e svolge inoltre funzioni operative.'}
                        className={'inline'}
                    />
                    <br />
                    <br />
                    <InputBilo
                        title={'Organo di Controllo'}
                        className={'inline'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={': effettua il controllo contabile dei conti, attesta che il bilancio economico patrimoniale e il bilancio sociale siano stati redatti in conformità della legge; è composto da tre componenti, due volontari e un Presidente professionista che possiede i requisiti previsti dalla legge.'}
                        className={'inline'}
                    />
                    <br />
                    <br />
                    <InputBilo
                        title={'Organismi regionali'}
                        fontWeight={700}
                        className={'underline text-2xl'}
                    />
                    <br />
                    <InputBilo
                        title={'Le 7 Sezioni regionali esistenti (Piemonte, Valle d’Aosta, Lombardia, Liguria, Triveneto, Emilia-Romagna, Toscana, Lazio), in un quadro di unitarietà nazionale, hanno autonomia organizzativa e gestionale senza avere autonomia giuridica e di bilancio'}
                        className={'inline'}
                    />
                    <br />
                    <br />
                    <InputBilo
                        title={'Assemblea di Sezione'}
                        className={'inline'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={': delibera i programmi della Sezione, esamina il rendiconto economico consuntivo e preventivo.'}
                        className={'inline'}
                    />
                    <br />
                    <br />
                    <InputBilo
                        title={'Consiglio direttivo'}
                        className={'inline'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={': attua i programmi con la gestione di tutte le attività e soprattutto l\'organizzazione dei trek giornalieri, week end e grandi trek (GT) in Italia e all\'estero che vengono poi coordinati e calendarizzati a livello nazionale.'}
                        className={'inline'}
                    />
                    <br />
                    <br />
                    <InputBilo
                        title={'La '}
                        className={'inline'}
                    />
                    <InputBilo
                        title={'Coordinatrice/Coordinatore '}
                        className={'inline'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={'coordina l\'attività della Sezione.'}
                        className={'inline'}
                    />
                </div>
                <div
                    className={"mt-12 mb-6 text-3xl text-[#DF5B23]"}
                    style={{ fontFamily: "gimlet-text" }}
                >
                    ORGANIGRAMMA NAZIONALE
                </div>
                {/* ORGANIGRAMMA MOBILE */}
                <div
                    className={
                        "block sm:flex md:flex lg:flex xl:flex 2xl:hidden flex flex-col gap-6"
                    }
                >
                    <InputBilo
                        title={`Presidente: ${organigramma2.presidente.name} ${organigramma2.presidente.surname}`}
                        fontWeight={700}
                        className={"text-[#796557] text-2xl"}
                    />
                    <InputBilo
                        title={`Vicepresidente: ${organigramma2.vicePresidente.name} ${organigramma2.vicePresidente.surname}`}
                        fontWeight={700}
                        className={"text-[#796557] text-2xl"}
                    />
                    <div
                        style={{ fontFamily: "gimlet-text" }}
                        className={"mt-12 mb-6 text-3xl text-[#DF5B23]"}
                    >
                        COMITATO ESECUTIVO NAZIONALE
                    </div>
                    <div className={"flex flex-col gap-0"}>
                        {
                            organigramma2.comitatoEsecutivoNazionale.map((user, index) => {
                                return <div key={index}>
                                    <InputBilo
                                        title={`${user.name} ${user.surname}`}
                                        className={`text-[#796557] text-2xl ${user.work !== '' ? 'inline' : ''}`}
                                    />
                                    {
                                        (user.work !== '') &&
                                        <InputBilo
                                            key={index}
                                            title={` (${user.work})`}
                                            className={'text-[#796557] inline text-2xl'}
                                        />
                                    }
                                </div>
                            })
                        }

                    </div>
                    <div className={"text-[#796557] text-xl font-semibold mt-8"}>
                        ORGANO DI CONTROLLO
                    </div>
                    <div className="flex flex-col gap-0">
                        {
                            organigramma2.organiDiControllo.map((user, index) => {
                                return <div key={index}>
                                    <InputBilo
                                        title={`${user.name} ${user.surname}`}
                                        className={`text-[#796557] text-2xl ${user.work !== '' ? 'inline' : ''}`}
                                    />
                                    {
                                        (user.work !== '') &&
                                        <InputBilo
                                            key={index}
                                            title={` (${user.work})`}
                                            className={'text-[#796557] inline text-2xl'}
                                        />
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
                {/* ORGANIGRAMMA DESKTOP */}
                <div
                    className={
                        "hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:flex  relative w-full flex flex-col items-center justify-center"
                    }
                >
                    <ItemOrganigramma
                        star={true}
                        title={organigramma2.presidente.title}
                        name={organigramma2.presidente.name}
                        surname={organigramma2.presidente.surname}
                    />
                    {/* <DividerCustom /> */}
                    <br />
                    <ItemOrganigramma
                        star={false}
                        title={organigramma2.vicePresidente.title}
                        name={organigramma2.vicePresidente.name}
                        surname={organigramma2.vicePresidente.surname}
                    />
                    {/* <DividerCustom /> */}
                    <br />
                    <div
                        style={{ fontFamily: "gimlet-text" }}
                        className={
                            "p-4 text-[#DF5B23] text-2xl bg-[#F0E9E3] w-full rounded-3xl text-center "
                        }
                    >
                        Comitato Esecutivo Nazionale
                    </div>
                    {/* <div className={"flex flex-row gap-56"}>
                        {organigramma2.comitatoEsecutivoNazionale.slice(0, 7).map((item, index) => {
                            return <DividerCustom key={index} />
                        })}
                    </div> */}
                    <br />

                    <>
                        {
                            [...new Array(Math.ceil((organigramma2.comitatoEsecutivoNazionale.length / 6.5)))].map((row, index) => {
                                if (index % 2 === 0) {
                                    return <div key={index} className={`${index === 0 ? '' : 'mt-4'} grid grid-cols-${organigramma2.comitatoEsecutivoNazionale.slice(0, 7).length} gap-12`}>
                                        {organigramma2.comitatoEsecutivoNazionale.slice(0 + (index * 6.5), 7 + (index * 6.5)).map((item, index) => {
                                            return (
                                                <ItemOrganigramma
                                                    key={index}
                                                    index={index}
                                                    star={false}
                                                    name={item.name}
                                                    surname={item.surname}
                                                    work={item.work === '' ? false : item.work}
                                                />
                                            )
                                        })}
                                    </div>
                                } else {
                                    return <div key={index} className={`mt-4 grid grid-cols-${organigramma2.comitatoEsecutivoNazionale.slice(0, 6).length} gap-12`}>
                                        {organigramma2.comitatoEsecutivoNazionale.slice(0.5 + (index * 6.5), 6.5 + (index * 6.5)).map((item, index) => {
                                            return (
                                                <ItemOrganigramma
                                                    key={index}
                                                    index={index}
                                                    star={false}
                                                    name={item.name}
                                                    surname={item.surname}
                                                    work={item.work === '' ? false : item.work}
                                                />
                                            )
                                        })}
                                    </div>
                                }
                            })
                        }
                    </>

                    <div
                        style={{ fontFamily: "gimlet-text" }}
                        className={
                            "p-4 text-[#DF5B23] mt-12 text-2xl bg-[#F0E9E3] w-full rounded-3xl text-center relative"
                        }
                    >
                        Organi di controllo
                    </div>
                    {/* <div className={"flex flex-row gap-56"}>
                        {organigramma2.organiDiControllo.slice(0, 7).map((item, index) => {
                            return <DividerCustom key={index} />
                        })}
                    </div> */}
                    <br />
                    {
                        [...new Array(Math.ceil((organigramma2.organiDiControllo.length / 6.5)))].map((row, index) => {
                            if (index % 2 === 0) {
                                return <div key={index} className={`${index === 0 ? '' : 'mt-4'} grid grid-cols-${organigramma2.organiDiControllo.slice(0, 7).length} gap-12`}>
                                    {organigramma2.organiDiControllo.slice(0 + (index * 6.5), 7 + (index * 6.5)).map((item, index) => {
                                        return (
                                            <ItemOrganigramma
                                                key={index}
                                                index={index}
                                                star={false}
                                                name={item.name}
                                                surname={item.surname}
                                                work={item.work === '' ? false : item.work}
                                            />
                                        )
                                    })}
                                </div>
                            } else {
                                return <div key={index} className={`mt-4 grid grid-cols-${organigramma2.organiDiControllo.slice(0, 6).length} gap-12`}>
                                    {organigramma2.organiDiControllo.slice(0.5 + (index * 6.5), 6.5 + (index * 6.5)).map((item, index) => {
                                        return (
                                            <ItemOrganigramma
                                                key={index}
                                                index={index}
                                                star={false}
                                                name={item.name}
                                                surname={item.surname}
                                                work={item.work === '' ? false : item.work}
                                            />
                                        )
                                    })}
                                </div>
                            }
                        })
                    }
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default Organismi;

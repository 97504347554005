import React from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import InputBilo from "../components/InputBilo";

const IniziativeAmbiente = () => {

    let page = {
        title: "TITOLO",
        paragraph: [
            "La Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.",
            "La Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.",
            "La Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. La Lorem ipsum dolor sit amet, consectetur adipisicinaute ilaborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
        ]
    }

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Iniziative per l'ambiente"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Associazione &gt; </div>
                        <div>Progetti &gt; </div>
                        <div className={"font-bold"}>Iniziative per l'ambiente</div>
                    </div>
                    {/* Content */}
                    <div
                        className={"my-6 mb-[2rem] text-4xl text-[#DF5B23]"}
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}
                    >
                        Iniziative per l'ambiente
                    </div>
                    <div className="mb-5 text-xl">
                        <InputBilo
                            title={'Trekking Italia oggi più che mai sostiene e pratica, con azioni concrete, la tutela dell’ambiente.'}
                            className={''}
                        />
                        <br />
                        <InputBilo
                            title={'È quindi naturale unire al piacere del cammino l’educazione alla cura dei luoghi che percorriamo: il cammino è per noi anche un impegno attivo verso l’ambiente e la sua tutela e sostenibilità.'}
                            className={''}
                        />
                        <br />
                        <InputBilo
                            title={'Con le nostre forze - siamo più di seimila soci! - facciamo parte di un movimento più ampio che agisce con l’obiettivo di aumentare la consapevolezza di quanto un bene così prezioso vada difeso sia con azioni quotidiane, sia con scelte condivise.'}
                            className={''}
                        />
                        <br />
                        <InputBilo
                            title={'Lo scopo, tra gli altri, è quello di far crescere la consapevolezza dei soci sull\'impatto ambientale delle nostre azioni quotidiane come “cittadini".'}
                            className={''}
                        />
                        <br />
                        <InputBilo
                            title={'Autonomamente e in collaborazione con altre Associazioni di volontariato, e talvolta anche con le Istituzioni, '}
                            className={'inline'}
                        />
                        <InputBilo
                            title={'siamo parte attiva in iniziative che mirano a contrastare gli interventi che possono alterare e compromettere l’equilibrio ambientale.'}
                            fontWeight={700}
                            className={'inline'}
                        />
                        <br />
                        <br />
                        <InputBilo
                            title={'Ci adoperiamo per contribuire alla creazione di un’economia sostenibile nei territori montani e avversiamo progetti ambientalmente impattanti legati alla monocultura dello sci. Lavoriamo per pulire e liberare i sentieri dai rifiuti, per valorizzare i percorsi poco conosciuti e partecipiamo all’acquisto di boschi, per preservarli e tutelarne la biodiversità.'}
                            className={''}
                        />
                        <br />
                        <InputBilo
                            title={'Vogliamo camminare in modo ancora più sostenibile, da veri amici della natura, come recita il nostro logo.'}
                            fontWeight={700}
                            className={''}
                        />
                        <br />
                        <InputBilo
                            title={'I nostri progetti in corso: partecipazione alla difesa Monte San Primo (Como) contro il ripristino di impianti di sci; contributo per acquisto di un bosco a Corniolino nel Parco nazionale Foreste Casentinesi a difesa della biodiversità; adesione al Comitato “Un altro Appennino è possibile” contro la costruzione di una nuova seggiovia; pulizia dei sentieri in partnership con Legambiente, contributo finanziari al progetto Forestami della Città metropolitana di Milano per la messa a dimora di alberi.'}
                            className={''}
                        />
                        
                    </div>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    );
};

export default IniziativeAmbiente;
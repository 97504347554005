import { Checkbox, FormControlLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemTypes = {
    USER: 'user',
};

// Funzione per creare una copia profonda dell'oggetto
const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

// Componente Utente Draggable
function User({ user, roomId, room, changeBedType, setPredispostaDallaSede }) {
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.USER,
        item: { id: user.id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
        <div
            ref={drag}
            className='rounded-lg shadow-md'
            style={{
                opacity: isDragging ? 0.5 : 1,
                padding: '10px',
                border: '1px solid gray',
                backgroundColor: 'white',
            }}
        >
            {user.name ?? user.nome} {user.surname}

            <div className='col-span-2'>
                <Typography>Tipologia letto</Typography>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className='min-w-[200px]'
                    value={room[user.id] === 'Matrimoniale' ? 'Matrimoniale' : room[user.id] === 'Singola' ? 'Singola' : '-'}
                    label="Age"
                    onChange={(e) => {
                        let newValue = null
                        if (e.target.value !== '-') {
                            newValue = e.target.value
                            setPredispostaDallaSede(false)
                        } else {
                            setPredispostaDallaSede(true)
                        }
                        changeBedType(newValue, user.id, roomId)
                    }}
                >
                    <MenuItem disabled={true} value={'-'}>Predisposto dalla sede</MenuItem>
                    <MenuItem value={'Matrimoniale'}>Matrimoniale</MenuItem>
                    <MenuItem value={'Singola'}>Singolo</MenuItem>
                </Select>
            </div>

        </div>
    );
}

// Componente Camera Drop Target
function Room({ roomId, usersInRoom = [], moveUser, deleteRoom, room, changeBedType, predispostaDallaSede, setPredispostaDallaSede }) {
    const [, drop] = useDrop({
        accept: ItemTypes.USER,
        drop: (item) => {
            moveUser(item.id, roomId);
        },
    });

    const usersInRoomOrdered = usersInRoom.sort((userA, userB) => {
        if (
            (room[userA.id] === 'Matrimoniale' && room[userB.id] === 'Matrimoniale') ||
            (room[userA.id] !== 'Matrimoniale' && room[userB.id] !== 'Matrimoniale')
        ) {
            return 0;
        }

        if ((room[userA.id] === 'Matrimoniale' && room[userB.id] !== 'Matrimoniale')) {
            return -1
        } else {
            return 1
        }
    })

    const MatrimonialeUsers = usersInRoomOrdered.filter(user => room[user.id] === 'Matrimoniale')

    return (
        <div
            ref={drop}
            className='rounded-lg shadow-md'
            style={{
                padding: '10px',
                border: '1px solid black',
                margin: '10px',
                minHeight: '100px',
                backgroundColor: '#f0f0f0',
                position: 'relative',
                minWidth: '350px',
            }}
        >
            <div className='flex justify-between items-center'>
                <h3 className='text-lg'>Camera</h3>
                <FormControlLabel
                    label='Predisposto dalla sede'
                    control={
                        <Checkbox checked={predispostaDallaSede} onChange={() => { setPredispostaDallaSede(prev => !prev) }} />
                    }
                />
            </div>
            {usersInRoom.length > 0 ? (
                usersInRoomOrdered?.map((user, index) => {
                    let giveMargin = 0
                    if (room[user.id] === 'Matrimoniale' && index !== 0) {
                        // usersInRoomOrdered[index]
                        const indexMatrimoniale = MatrimonialeUsers.findIndex(userMatrimoniale => userMatrimoniale.id === user.id)
                        if (indexMatrimoniale % 2) {
                            giveMargin = 5
                        }
                    }

                    return (

                        <div key={user.id} className={`${room[user.id] === 'Matrimoniale' ? 'bg-orange-700' : ''} p-[5px] ${giveMargin ? 'mb-[5px]' : ''}`}>
                            <User
                                setPredispostaDallaSede={setPredispostaDallaSede}
                                key={user.id}
                                roomId={roomId} // Passa l'ID della stanza corrente
                                room={room}
                                user={user}
                                changeBedType={changeBedType}
                            />
                        </div>
                    )
                })
            ) : (
                <div>Vuota</div>
            )}

            {usersInRoom.length === 0 && (
                <button
                    onClick={() => deleteRoom(roomId)}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: 'red',
                        color: 'white',
                        border: 'none',
                        cursor: 'pointer',
                        padding: '5px',
                    }}
                >
                    Elimina
                </button>
            )}
        </div>
    );
}

// Componente principale DndRooms
function DndRooms({ initialRoomsData, userMap, setRoomsFinalState, roomsFinalState }) {
    const [rooms, setRooms] = useState(initialRoomsData);
    const [users, setUsers] = useState(userMap);
    const [predispostaDallaSede, setPredispostaDallaSede] = useState(false)

    useEffect(() => {
        setRoomsFinalState(rooms);
    }, [rooms, setRoomsFinalState]);

    useEffect(() => {
        if(roomsFinalState.length === 0){
            setPredispostaDallaSede(true)
        }
    }, [])

    useEffect(() => {
        users.map((user) => {
            changeBedType(predispostaDallaSede ? '-' : (rooms[0][user.id] !== '-' ? rooms[0][user.id] : 'Singola'), user.id, 0)
        })
    }, [predispostaDallaSede])

    // Funzione per spostare un utente da una camera all'altra
    const moveUser = (userId, targetRoomId) => {
        // Sposta l'utente e lo separa se era in un matrimoniale
        setUsers((prevUsers) =>
            prevUsers.map((user) => {
                if (user.id === userId) {
                    return { ...user, roomId: targetRoomId, sharing_room: [userId] }; // Rimuove il legame matrimoniale
                }
                return user;
            })
        );

        setRooms((prevRooms) => {
            const updatedRooms = deepCopy(prevRooms); // Crea una copia profonda delle camere

            let type = 'Singola'
            // Rimuovi l'utente dalla camera di origine
            for (let room of updatedRooms) {
                if (room.hasOwnProperty(userId)) {
                    type = room[userId]
                    delete room[userId]; // Rimuovi l'utente dalla camera originale
                }
            }

            // Aggiungi l'utente alla camera di destinazione
            updatedRooms[targetRoomId][userId] = type;

            return updatedRooms;
        });
    };


    // Funzione per aggiungere una nuova camera
    const addRoom = () => {
        setRooms((prevRooms) => [...prevRooms, {}]); // Aggiunge una camera vuota
    };

    // Funzione per eliminare una camera
    const deleteRoom = (roomId) => {
        setRooms((prevRooms) => prevRooms.filter((room, index) => index !== roomId));
    };

    const changeBedType = (type, userId, targetRoomId) => {
        setRooms((prevRooms) => {

            const updatedRooms = deepCopy(prevRooms);

            // Aggiungi l'utente alla camera di destinazione
            updatedRooms[targetRoomId][userId] = type;

            return updatedRooms;
        });
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div>
                {/* {
                    rooms.length < users.length &&
                    <button onClick={addRoom} style={{ marginBottom: '20px', padding: '10px' }}>
                        Aggiungi Camera
                    </button>
                } */}
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {rooms.map((room, index) => {
                        const usersInRoom = users.filter((user) => room.hasOwnProperty(user.id));
                        return (
                            <Room
                                setPredispostaDallaSede={setPredispostaDallaSede}
                                predispostaDallaSede={predispostaDallaSede}
                                key={index}
                                roomId={index}
                                usersInRoom={usersInRoom}
                                moveUser={moveUser}
                                deleteRoom={deleteRoom}
                                room={room}
                                changeBedType={changeBedType}
                            />
                        );
                    })}
                </div>
            </div>
        </DndProvider>
    );
}

export default DndRooms;

import { useGeolocated } from "react-geolocated";
import TrekItalia from "./TrekItalia";
import TrekItalia2 from "./trekitalia2";

function TrekGeo() {

    const { coords } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });


    return (
       <TrekItalia2 pos="Geolocalizzato" coords={coords}/>
    );
}

export default TrekGeo
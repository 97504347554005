import React from "react";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";

const ItemOrganigramma = ({ title, name, surname, star, work, className = '', index, supplente }) => {
    return (
        <div
            className={`rounded-3xl bg-[#F0E9E3] relative  w-44 h-38 ${!star && !title && "pt-8"
                } ${className}`}
        >
            {star && (
                <div
                    className={"absolute bg-[#F0E9E3] p-2 rounded-full"}
                    style={{ top: "-15%", left: "33%" }}
                >
                    <div className={"p-2 bg-white rounded-full"}>
                        <StarRateRoundedIcon
                            sx={{ color: "#DF5B23", width: 30, height: 30 }}
                        />
                    </div>
                </div>
            )}
            {title && (
                <>
                    <div
                        style={{ fontFamily: "gimlet-text" }}
                        className={`${star ? "my-10" : "my-6"
                            } mb-2 text-xl text-[#DF5B23] text-center w-full`}
                    >
                        {title}
                    </div>
                    <div className={"w-full justify-center flex flex-row"}>
                        <div className={"w-10 border border-[#796557]"} />
                    </div>
                </>
            )}
            <div className={"mt-2 pb-4 flex flex-col text-center  text-[#796557] "}>
                <div className={"text-xl"}>{name}</div>
                <div className={"font-bold text-xl uppercase"}>{surname}</div>
            </div>
            {(!star || !title) && (
                <div
                    className={`${work ? "bg-[#e3dad4] text-[#796557] h-[72px]" : "bg-[#F0E9E3] text-[#F0E9E3]"
                        } text-lg p-2 text-center rounded-b-3xl w-auto flex items-center justify-center`}
                >
                    {work ? work : "."}
                </div>
            )}
        </div>

    );
};

export default ItemOrganigramma;

import React from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import img1 from "../assets/img/accompagnatori.jpg";
import FooterTop from '../Sezioni/FooterTop';
import FooterDown from '../Sezioni/FooterDown';
import InputBilo from '../components/InputBilo';
import InputGimlet from '../components/InputGimlet';

function Volontari() {
  return (
    <>
            <TopBarIndex />
            <BackgroundTitle
                title={"I nostri volontari"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Associazione &gt; </div>
                        <div className={"font-bold"}>I nostri volontari</div>
                    </div>
                    {/* Content */}
                    <InputGimlet
                        title={'I nostri volontari'}
                        fontWeight={500}
                        fontStyle="italic"
                        className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                    />
                    <div className="mb-5 text-xl">
                        <InputBilo
                            title={'Trekking Italia svolge la sua attività in gran parte grazie al contributo e all\'attività volontaria e gratuita dei soci.'}
                            fontWeight={700}
                        />
                        <br />
                        <InputBilo
                            title={'I soci accompagnatori '}
                            fontWeight={700}
                            className={'inline'}
                        />
                        <InputBilo
                            title={'sono volontari non retribuiti che, dopo aver seguito un corso di formazione, progettano e accompagnano i Trek giornalieri, Week end ed i Grandi trek, individuano i percorsi, li sperimentano e ne valutano la difficoltà secondo criteri definiti, si fanno carico della gestione del gruppo.'}
                            className={'inline'}
                        />
                        <br />
                        <br />
                        <InputBilo
                            title={'Nelle Sezioni regionali i '}
                            className={'inline'}
                        />
                        <InputBilo
                            title={'soci volontari '}
                            fontWeight={700}
                            className={'inline'}
                        />
                        <InputBilo
                            title={'curano il rapporto e i contatti quotidiani con i soci, seguono le problematiche organizzative e contabili, promuovono altre attività oltre i suddetti trek (corsi di formazione per i soci, presentazione dei trek, eventi culturali, incontri tra soci, gestione di biblioteche per i soci, trek per le scuole, trek per giovanissimi e famiglie).'}
                            className={'inline'}
                        />
                        <br />
                        <br />
                        <InputBilo
                            title={'Trekking Italia promuove, a tutti i livelli, il coinvolgimento attivo dei soci, nella piena consapevolezza che ognuno di loro rappresenta la forza e la ricchezza primaria per l\'Associazione.'}
                            fontWeight={700}
                        />
                    </div>

                </div>


            </div>
            <FooterTop />
            <FooterDown />
        </>
  )
}

export default Volontari
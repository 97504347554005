import React from "react";
import WrapperComponent from "./WrapperComponent";

const InputGimlet = ({ title, className, onClick, fontWeight = 500, fontStyle = "italic", id, wrapperTag = 'p' }) => {
    return (
        <WrapperComponent
            id={id}
            wrapperTag={wrapperTag}
            onClick={onClick}
            className={className ? className : ""}
            style={{
                fontFamily: "gimlet-text",
                fontWeight: fontWeight,
                fontStyle: fontStyle,
            }}>
            {title}
        </WrapperComponent>
    );
};

export default InputGimlet;

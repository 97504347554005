import React from "react";
import WrapperComponent from "./WrapperComponent";

const InputBilo = ({ title, className, fontWeight = 500, fontStyle = "normal", id='', wrapperTag='p' }) => {
    return (
        <WrapperComponent
            id={id}
            wrapperTag={wrapperTag}
            className={className ? className : ""}
            style={{
                fontFamily: "bilo",
                fontWeight: fontWeight,
                fontStyle: fontStyle,
            }}>
            {title}
        </WrapperComponent>
    );
};

export default InputBilo;

import renderHTML from "react-render-html";
import InputBilo from "../components/InputBilo";
import { Link, useNavigate } from "react-router-dom";
import noImage from "../assets/img/no_image.jpg";
import parse from 'html-react-parser';
import React, { useState } from "react";
const ItemCorso = ({ res, gg }) => {

    function rimuoviHTML(htmlString) {
        var parser = new DOMParser();
        var doc = parser.parseFromString(htmlString, 'text/html');
        return doc.body.textContent || "";
    }

    return (
        <>
            <div className="rounded-t-3xl rounded-b-3xl shadow-2xl w-[300px] h-[320px]">
                <div className="h-[150px] relative">
                    <h3
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 300,
                            fontStyle: "normal",
                            maxWidth: 'fit-content'
                        }}
                        className="absolute top-0 left-1/2 translate-x-[-50%] text-2xl z-10 text-white whitespace-nowrap">{res.sezione.includes('Sezione') ? res.sezione : 'Sez. ' + res.sezione}
                    </h3>
                    <h2 style={{
                        fontFamily: "bilo",
                        fontWeight: 600,
                        fontStyle: "normal",
                        maxWidth: 'fit-content'
                    }}
                        className="relative w-[300px] top-12 left-1/2 translate-x-[-50%] text-sm md:text-2xl z-10 text-white pl-2 !leading-5">{res.title ? res.title.toUpperCase() : ''}
                    </h2>
                    <div className="absolute top-0 h-full bg-[#000000] rounded-t-3xl">
                        <img className="w-[300px] h-[150px] object-cover opacity-40 z-5 rounded-t-3xl" src={res['preview_image_path'] ? res['preview_image_path'] : noImage} alt="" />
                    </div>
                </div>
                <div className="h-[50%] bg-white rounded-b-3xl bg-[#f0e9e3]">
                    <h4 style={{
                        fontFamily: "bilo",
                        fontWeight: 300,
                        fontStyle: "normal",
                    }} className="text-center text-lg text-[#796557] leading-5">Totale Ore</h4>
                    <h3 style={{
                        fontFamily: "bilo",
                        fontWeight: 600,
                        fontStyle: "normal",
                    }} className="text-center text-xl text-[#796557] leading-5">{res['total_hours_course']}</h3>
                    <div className="flex overflow-hidden justify-center items-start h-[55%]">
                        <div className="text-center h-full">
                            <InputBilo
                                className={'text-center text-lg'}
                                title={'Descrizione: '}

                            />
                            <InputBilo
                                className={'text-center text-lg h-full break-words max-w-[288px] leading-5'}
                                fontWeight={700}
                                title={res.short_description ? (rimuoviHTML(res.short_description).length > 40 ? rimuoviHTML(res.short_description).slice(0, 40) + '...' : rimuoviHTML(res.short_description)) : ''}
                            />
                        </div>
                    </div>
                    <Link to={`/corsi/${res.internal_id}`}
                        className={"flex w-full p-2 rounded-b-3xl hover:opacity-80 cursor-pointer bg-[#34291F]"}>
                        <InputBilo className={'w-full text-center text-white text-xl'} title={'GUARDA CORSO'} />
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ItemCorso;

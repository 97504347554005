import React from "react";
import { Link, useNavigate } from "react-router-dom";
import imgBack from "../../src/assets/img/mapAccompagnatori.jpg";
import noImage from "../assets/img/no_image.jpg";

const ItemAccompagnatori = ({ sesso, img, name, sezione, id, filterSez, page }) => {
    const navigate = useNavigate()
    return (
        <div
            className={"flex flex-col"}
            style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
        >
            <div
                className={"rounded-t-3xl h-[17.5rem]"}
                style={{
                    backgroundImage: `url(${imgBack})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <div className={"flex flex-row w-full justify-center"}>
                    <div
                        className={
                            "text-center text-md text-white font-bold w-2/3 bg-orange-500 rounded-b-3xl"
                        }
                    >
                        {sesso === "F" ? "ACCOMPAGNATRICE" : "ACCOMPAGNATORE"}
                    </div>
                </div>
                <div
                    className={"w-full h-full justify-center flex flex-col items-center"}
                >
                    <div className={"bg-white rounded-full"}>
                        <img
                            alt={"immagine accompagnatore/trice "+ name}
                            src={img ? img : noImage}
                            img={'img'}
                            className={"rounded-full h-56 w-56 p-1"}
                        />
                    </div>
                </div>
            </div>
            <div
                className={
                    "flex flex-col py-4 text-xl items-center gap-2 w-full bg-[#f1e9e4] text-[#34291F]"
                }
            >
                <div className={"text-center px-2"}>{name}</div>
                <div className={"text-center inline-flex pl-[7px]"}>
                    {sezione &&
                        <p>Sezione <div className={"inline uppercase font-bold"}>{sezione}</div></p>
                    }
                </div>
            </div>
            <div
                className={
                    "py-4 rounded-b-3xl hover:opacity-50 cursor-pointer bg-[#34291F]"
                }
            >
                <Link to={'/accompagnatori/' + id} onClick={() => {
                    localStorage.setItem('accompagnatoreSez', filterSez)
                    localStorage.setItem('accompagnatorePage', page)
                }
                } className={"text-2xl text-white text-center w-full block"}>GUARDA SCHEDA</Link>
            </div>
        </div>
    );
};

export default ItemAccompagnatori;

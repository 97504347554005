import React from "react";
import img from "../assets/img/numeri-sfondo-home.png";
import zaino from "../assets/img/icona-trekking-diverso-home.png";
import passi from "../assets/img/icona-passi-insieme-home.png";
import line from "../assets/img/Linea_Trekking.png";
import associati from "../assets/img/icona-associati-home.png";
import amici from "../assets/img/icona-amici-volontari-home.png";

const NumbersMobile = () => {
  return (
    <div>
      <div
        className={"mt-8 p-8 w-full text-center"}
        style={{
          backgroundImage: `url(${img})`,
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100%",
        }}
      >
        <h1 className={"text-xl font-bold  text-[#df5b23]"}>
          TREKKING ITALIA IN NUMERI
        </h1>
        <h2
          style={{
            fontFamily: "gimlet-text",
            fontWeight: 500,
            fontStyle: "italic",
          }}
          className={"text-2xl mt-4 text-center text-[#34291f]"}
        >
          Fino ad oggi abbiamo fatto...
        </h2>
        <div className={"grid grid-cols-2 row-2 gap-4 mt-8"}>
          <div className={"flex flex-col  items-center"}>
            <img
              alt={"Zaino"}
              src={zaino}
              className={"object-contain w-20 h-20"}
            />
            <div
              style={{
                fontFamily: "gimlet-text",
                fontWeight: 500,
                fontStyle: "italic",
              }}
              className={"text-[#df5b23] text-xl font-bold"}
            >
              + 1.000
            </div>
            <div
              style={{
                fontFamily: "gimlet-text",
                fontWeight: 500,
                fontStyle: "italic",
              }}
              className={"text-lg text-[#34291f]"}
            >
              trekking diversi
            </div>
          </div>
          <div className={"flex flex-col items-center relative"}>
            <img
              alt={"Scarpa"}
              src={passi}
              className={"object-contain w-20 h-20"}
            />
            <div
              style={{
                fontFamily: "gimlet-text",
                fontWeight: 500,
                fontStyle: "italic",
              }}
              className={"text-[#df5b23] text-xl font-bold"}
            >
              + 350.000
            </div>
            <div
              style={{
                fontFamily: "gimlet-text",
                fontWeight: 500,
                fontStyle: "italic",
              }}
              className={"text-lg text-[#34291f]"}
            >
              passi insieme
            </div>
          </div>
          <div className={"flex flex-col items-center relative "}>
            <img
              alt={"Persona"}
              src={associati}
              className={"object-contain w-20 h-20"}
            />
            <div
              style={{
                fontFamily: "gimlet-text",
                fontWeight: 500,
                fontStyle: "italic",
              }}
              className={"text-[#df5b23] text-xl font-bold"}
            >
              + 7.000
            </div>
            <div
              style={{
                fontFamily: "gimlet-text",
                fontWeight: 500,
                fontStyle: "italic",
              }}
              className={"text-lg text-[#34291f]"}
            >
              associati
            </div>
          </div>
          <div className={"flex flex-col items-center "}>
            <img
              alt={"mano e cuore"}
              src={amici}
              className={"object-contain w-20 h-20"}
            />

            <div
              style={{
                fontFamily: "gimlet-text",
                fontWeight: 500,
                fontStyle: "italic",
              }}
              className={"text-[#df5b23] text-xl font-bold"}
            >
              + 300
            </div>
            <div
              style={{
                fontFamily: "gimlet-text",
                fontWeight: 500,
                fontStyle: "italic",
              }}
              className={"text-lg text-[#34291f]"}
            >
              amici volontari
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumbersMobile;

import React from "react";
import TopBarIndex from "../components/TopBarIndex";
import BackgroundTitle from "../components/BackgroundTitle";
import img1 from "../assets/img/img-slider-home.jpg";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import ErrorsComponent from "../components/ErrorsComponent";
import LoadingComponent from "../components/LoadingComponent";
import renderHTML from "react-render-html";
import parse from 'html-react-parser';
const TrekMagazineDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useSelector((rootState) => rootState.count);
  api.setHeaders({
    Authorization: "Bearer " + token,
  });
  const fetcher = (url) => api.get(url).then((res) => res);
  const { data } = useSWR([`/show-trekmagazine/` + id], fetcher);

  if (data && !data.ok) return <ErrorsComponent />;
  if (!data) return <LoadingComponent />;
  return (
    <>
      <TopBarIndex />
      <BackgroundTitle title={"Trek Magazine"} img={img1} />
      <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
        <div className={"flex flex-row items-center gap-1"}>
          <div
            className={"cursor-pointer hover:opacity-50"}
            onClick={() => navigate(-1)}
          >
            Home {">"}
          </div>
          <div className={"font-bold"}> Trek Magazine</div>
        </div>
        <div
          className={
            "my-6 text-4xl text-center  uppercase w-full text-[#DF5B23]"
          }
          style={{ fontFamily: "gimlet-text" }}
        >
          {data.data.data.title}
        </div>
        <div className={"text-[#796557]"}>
          {data.data.data.description && (
            <div
              className={"my-4 text-xl"}
              style={{
                fontFamily: "bilo",
                fontWeight: "bold",
                fontStyle: "normal",
              }}
            >
              {parse(data.data.data.description)}
            </div>
          )}
        </div>
      </div>
      <FooterTop />
      <FooterDown />
    </>
  );
};

export default TrekMagazineDetails;

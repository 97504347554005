import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import InputCustom from "../components/InputCustom";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    Switch,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import useSWR from "swr";
import LoadingComponent from "../components/LoadingComponent";
import ErrorsComponent from "../components/ErrorsComponent";
import Alert from "@mui/material/Alert";
import moment from "moment";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import { api } from "../conf/apiConf";
import _ from "lodash";
import InputGimlet from "./InputGimlet";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import RowHeaders from "./RowHeaders";
import percorso from "../assets/imgTrek/percorso.svg";



const NuovoForm2 = (props) => {

    const [newsletter, setNewsletter] = useState([])

    useEffect(()=>{
            document.getElementById('main_title').innerText = 'Dati personali - Trekking Italia'
    }, [])

    const navigate = useNavigate();
    api.setHeaders({
        Authorization: "Bearer " + props.count.token,
    });

    const route = useLocation();

    const fetcher2 = (url) => {
        return new Promise((resolve, reject) => {
            api
                .get(url)
                .then((res) => {
                    if (res.data.data.country_birth === "Italia") {
                        res.data.data.region_birth &&
                            api
                                .get(`/provinces/${res.data.data.region_birth}`)
                                .then((res) => {
                                    setProvince(res.data.data);
                                })
                                .catch((error) => console.log(error));
                        res.data.data.province_birth &&
                            api
                                .get(`/municipalities/${res.data.data.province_birth}`)
                                .then((res) => {
                                    setComune(res.data.data);
                                })
                                .catch((error) => console.log(error));
                    }
                    if (res.data.data.country_residence === "Italia") {
                        res.data.data.region_residence &&
                            api
                                .get(`/provinces/${res.data.data.region_residence}`)
                                .then((res) => setProvince1(res.data.data))
                                .catch((error) => console.log(error));
                        res.data.data.province_residence &&
                            api
                                .get(`/municipalities/${res.data.data.province_residence}`)
                                .then((res) => setComune1(res.data.data))
                                .catch((error) => console.log(error));
                    }
                    if (res.data.data.country_domicile === "Italia") {
                        res.data.data.region_domicile &&
                            api
                                .get(`/provinces/${res.data.data.region_domicile}`)
                                .then((res) => setProvince2(res.data.data))
                                .catch((error) => console.log(error));
                        res.data.data.province_domicile &&
                            api
                                .get(`/municipalities/${res.data.data.province_domicile}`)
                                .then((res) => setComune2(res.data.data))
                                .catch((error) => console.log(error));
                    }
                    resolve(res.data.data); // Risolve la Promise con i dati desiderati
                })
                .catch((error) => reject(error));
        });
    };

    const { data: view, error: errorView } = useSWR(
        props.count.profile ? `/users/get/${props.count.profile.id}` : null,
        fetcher2
    );

    const fetcher = (url) =>
        api.get(url).then((res) => {
            return res.data.data;
        });
    const { data, error } = useSWR(["/filter-list"], fetcher);

    const fetcher3 = (url) =>
        api.get(url).then((res) => {
            return res.data.data;
        });
    const { data: regioni, error: errorRegioni } = useSWR(
        [`/regions/118`],
        fetcher3
    );

    const fetcher4 = (url) => {
        setLoading(true)
        api.post(url, { id: props.count.profile.id }).then(res => {
            setLoading(false)
            setNewsletter(res.data.data)
            return res.data.data
        })
    }
    const { data: newsletterFetched, error: errorNewsletter } = useSWR(
        [`/newsletter/get-list`],
        fetcher4,
        {
            revalidateOnFocus: false,
        }
    );

    const [disable, setDisable] = useState(false);
    const [loadingCity, setLoadingCity] = React.useState(false);

    const [cityName, setCityName] = useState([]);
    const [cityName2, setCityName2] = useState("");
    const [cityName3, setCityName3] = useState("");

    const [provinceID, setProvinceID] = useState(null);
    const [comuneID, setComuneID] = useState(null);
    const [regioniID, setRegioniID] = useState(null);

    const [provinceID2, setProvinceID2] = useState(null);
    const [comuneID2, setComuneID2] = useState(null);
    const [regioniID2, setRegioniID2] = useState(null);

    const [provinceID3, setProvinceID3] = useState(null);
    const [comuneID3, setComuneID3] = useState(null);
    const [regioniID3, setRegioniID3] = useState(null);

    /*  const fetcher4 = (url) =>
          axios.post(url, { name: cityName }, axiosConfig).then((res) => {
            return res.data.data;
          });
        const { data: city, error: errorCity } = useSWR(
          [`${envURL.envURL}/departments/search-city`],
          fetcher4
        );*/

    const [errorForm, setErrorForm] = useState();
    const [errorUsername, setErrorUsername] = useState(null);
    const [files, setFiles] = useState();
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState({
        open: false,
        error: ''
    });
    const [files2, setFiles2] = useState();
    const [files3, setFiles3] = useState();
    const [loading, setLoading] = useState(false);
    const [sezioni, setSezioni] = useState([]);
    const [idRegione, setIDRegione] = useState();
    const { type } = useParams();
    const { id } = useParams();
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState("");

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            username:
                props.type !== "new"
                    ? view && view.username !== null
                        ? view.username
                        : ""
                    : "",
            surname:
                props.type !== "new"
                    ? view && view.surname !== null
                        ? view.surname
                        : ""
                    : "",
            name:
                props.type !== "new"
                    ? view && view.name !== null
                        ? view.name
                        : ""
                    : "",
            email:
                props.type !== "new"
                    ? view && view.email !== null
                        ? view.email
                        : ""
                    : "",
            gender:
                props.type !== "new"
                    ? view && view.gender !== null
                        ? view.gender
                        : ""
                    : "",
            date_birth:
                props.type !== "new"
                    ? view && view.date_birth !== null
                        ? view.date_birth
                        : ""
                    : "",
            country_birth:
                props.type !== "new"
                    ? view && view.country_birth !== null
                        ? view.country_birth
                        : ""
                    : "",
            region_birth:
                props.type !== "new"
                    ? view && view.region_birth !== null
                        ? view.region_birth
                        : ""
                    : "",
            province_birth:
                props.type !== "new"
                    ? view && view.province_birth !== null
                        ? view.province_birth
                        : ""
                    : "",
            city_birth:
                props.type !== "new"
                    ? view && view.city_birth !== null
                        ? view.city_birth
                        : ""
                    : "",
            fiscal_code:
                props.type !== "new"
                    ? view && view.fiscal_code !== null
                        ? view.fiscal_code
                        : ""
                    : "",
            check_fiscal_code: props.type !== "new" ? view && view.check_fiscal_code === 1 : false,
            accept_privacy: props.type !== "new" ? view && view.accept_privacy === "1" : false,
            accept_newsletter: props.type !== "new" ? view && view.accept_newsletter === "1" : false,
            user_base:
                props.type !== "new" ? !(view && view.user_base === "0") : false,
            user_partner: props.socio
                ? true
                : props.type !== "new"
                    ? !(view && view.user_partner === "0")
                    : false,
            phone:
                props.type !== "new"
                    ? view && view.phone !== null
                        ? view.phone
                        : ""
                    : "",
            cell_phone:
                props.type !== "new"
                    ? view && view.cell_phone !== null
                        ? view.cell_phone
                        : ""
                    : "",
            country_residence:
                props.type !== "new"
                    ? view && view.country_residence !== null
                        ? view.country_residence
                        : ""
                    : "",
            region_residence:
                props.type !== "new"
                    ? view && view.region_residence !== null
                        ? view.region_residence
                        : ""
                    : "",
            province_residence:
                props.type !== "new"
                    ? view && view.province_residence !== null
                        ? view.province_residence
                        : ""
                    : "",
            city_residence:
                props.type !== "new"
                    ? view && view.city_residence !== null
                        ? view.city_residence
                        : ""
                    : "",
            address_residence:
                props.type !== "new"
                    ? view && view.address_residence !== null
                        ? view.address_residence
                        : ""
                    : "",
            residence_same_domicile:
                props.type !== "new"
                    ? view && view.residence_same_domicile === "0"
                        ? "si"
                        : "si"
                    : "si",
            department_id1:
                props.type !== "new"
                    ? view && view.department_id !== null
                        ? view.department_id
                        : ""
                    : "",
            department_id2:
                props.type !== "new"
                    ? view && view.department_id !== null
                        ? view.department_id
                        : ""
                    : "",
            country_domicile:
                props.type !== "new"
                    ? view && view.country_domicile !== null
                        ? view.country_domicile
                        : ""
                    : "",
            region_domicile:
                props.type !== "new"
                    ? view && view.region_domicile !== null
                        ? view.region_domicile
                        : ""
                    : "",
            province_domicile:
                props.type !== "new"
                    ? view && view.province_domicile !== null
                        ? view.province_domicile
                        : ""
                    : "",
            city_domicile:
                props.type !== "new"
                    ? view && view.city_domicile !== null
                        ? view.city_domicile
                        : ""
                    : "",
            address_domicile:
                props.type !== "new"
                    ? view && view.address_domicile !== null
                        ? view.address_domicile
                        : ""
                    : "",
            profession:
                props.type !== "new"
                    ? view && view.profession !== null
                        ? view.profession
                        : ""
                    : "",
            degree_study:
                props.type !== "new"
                    ? view && view.degree_study !== null
                        ? view.degree_study
                        : ""
                    : "",
            iban:
                props.type !== "new"
                    ? view && view.iban !== null
                        ? view.iban
                        : ""
                    : "",
            cell_intestatario_iban:
                props.type !== "new"
                    ? view && view.intestatario_iban !== null
                        ? view.intestatario_iban
                        : ""
                    : "",
            language:
                props.type !== "new"
                    ? view
                        ? view.language !== null
                            ? JSON.parse(view.language)
                            : []
                        : []
                    : [],
            profile_image: "",
            document_image: "",
            document_consent: "",
            accept_privacy_partner: props.type !== "new" ? view && view.accept_privacy_partner === '1' : false,
            accept_rule_partner: props.type !== "new" ? view && view.accept_rule_partner === '1' : false,
            accept_statute_partner: props.type !== "new" ? view && view.accept_statute_partner === '1' : false,
            newsletter: newsletter ? [...newsletter] : []
        },
        onSubmit: (values) => {
            // setLoading(true);
            setDisable(true);
            api.setHeaders({
                "Content-Type": "multipart/x-www-form-urlencoded",
            });
            const beginDate = moment(values.date_birth).format("YYYY-MM-DD");
            const formData = new FormData();
            const objectArray = Object.entries(values);
            objectArray.forEach(([key, value]) => {
                if (key === "fiscal_code") {
                    formData.append(
                        "fiscal_code",
                        formik.values.fiscal_code.toUpperCase()
                    );
                }
                if (key === "department_id1") {
                    formData.append(
                        "department_id",
                        formik.values.department_id1 === ""
                            ? formik.values.department_id2
                            : formik.values.department_id1
                    );
                }
                if (key === "city_birth") {
                    formData.append(
                        "city_birth",
                        formik.values.city_birth
                    );
                }
                if (key === "city_residence") {
                    formData.append(
                        "city_residence",
                        formik.values.city_residence === "Italia"
                            ? formik.values.city_residence.name
                            : formik.values.city_residence
                    );
                }
                if (key === "city_domicile") {
                    formData.append(
                        "city_domicile",
                        formik.values.city_domicile === "Italia"
                            ? formik.values.city_domicile.name
                            : formik.values.city_domicile
                    );
                }
                if (key === "date_birth") {
                    formData.append("date_birth", beginDate);
                }
                if (key === "language") {
                    formData.append("language", JSON.stringify(value));
                }
                if (key === "profile_image" && files) {
                    formData.append("profile_image", files[0]);
                }
                if (key === "document_consent" && files3) {
                    formData.append("document_consent", files3[0]);
                }
                if (key === "newsletter") {
                    formData.append("newsletter", JSON.stringify(value.filter((item) => item.checked).map((item) => item.id)))
                }
                if (key === "document_image" && files2) {
                    formData.append("document_image", files2[0]);
                } else if (
                    key !== "profile_image" &&
                    key !== "document_image" &&
                    key !== "document_consent" &&
                    key !== "city_residence" &&
                    key !== "city_birth" &&
                    key !== "city_domicile" &&
                    key !== "language" &&
                    key !== "department_id1" &&
                    key !== "department_id2" &&
                    key !== "date_birth" &&
                    key !== "newsletter" &&
                    key !== "fiscal_code"
                ) {
                    formData.append(key, value);
                }
            });

            api
                .post(
                    props.type === "new"
                        ? `/register`
                        : `/users/update/` + props.count.profile.id,
                    formData
                )
                .then((res) => {
                    if (res.ok) {
                        setOpenSuccess(true);
                        setDisable(false);
                    } else {
                        setDisable(false);
                        setFiles(null);
                        setFiles2(null);
                        formik.setFieldValue("profile_image", "");
                        formik.setFieldValue("document_image", "");
                        setErrorForm(res.data.errors);
                    }
                });
        },
    });

    const [selectedImage, setSelectedImage] = useState(null);

    const [region, setRegion] = useState([]);
    const [province, setProvince] = useState([]);
    const [comune, setComune] = useState([]);

    const [region1, setRegion1] = useState([]);
    const [province1, setProvince1] = useState([]);
    const [comune1, setComune1] = useState([]);

    const [region2, setRegion2] = useState([]);
    const [province2, setProvince2] = useState([]);
    const [comune2, setComune2] = useState([]);
    const fileInputRef = useRef(null);

    if (error || errorRegioni || errorNewsletter) return <ErrorsComponent />;
    if (!data || !regioni || (props.count.profile && !view))
        return <LoadingComponent />;

    return (
        <div
            className={
                props.type === "edit"
                    ? "w-full bg-[#e2d5ca] flex flex-col justify-center pb-8"
                    : "w-full bg-[#e2d5ca] flex justify-center pb-8"
            }>
            {route.pathname !== "/register" && (
                <div className="w-full flex justify-center">
                    <div className="flex flex-col justify-center items-center lg:flex-row justify-around w-full max-w-[1920px] md:py-6 md:px-6 xl:py-20 xl:px-36 ">
                        <div className="rounded-full h-56 w-56 bg-[#C4B3A5] relative">
                            <img
                                src={
                                    selectedImage
                                        ? selectedImage
                                        : view.profile_image
                                            ? view.profile_image
                                            : ""
                                }
                                alt="profile_img"
                                className="object-cover rounded-full h-56 w-56 p-3"
                            />
                            <div className="absolute bottom-0 right-0 rounded-full  bg-white h-12 w-12">
                                <div className="absolute bottom-1 right-1 bg-[#796557] rounded-full h-10 w-10 flex justify-center items-center">
                                    <input
                                        multiple={false}
                                        type="file"
                                        name={"profile_image"}
                                        accept="image/"
                                        style={{ display: "none" }}
                                        ref={fileInputRef}
                                        value={formik.values.profile_image}
                                        onChange={(e) => {
                                            const selectedFile = e.target.files[0];
                                            const MAX_SIZE = 4194304
                                            if (selectedFile.size > MAX_SIZE) {
                                                setOpenError({
                                                    open: true,
                                                    error: 'Hai selezionato un\'immagine troppo grande, l\'immagine non deve superare i 4Mb'
                                                })
                                            } else {
                                                setFiles(e.target.files);
                                                formik.setFieldValue("profile_image", e.target.value);
                                                if (selectedFile) {
                                                    const reader = new FileReader();
                                                    reader.onload = () => {
                                                        setSelectedImage(reader.result);
                                                    };
                                                    reader.readAsDataURL(selectedFile);
                                                }
                                            }
                                        }}
                                    />
                                    <IconButton onClick={() => fileInputRef.current.click()}>
                                        <CameraAltIcon sx={{ color: "white" }} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <div className="max-[1023px]:flex flex-col items-center">
                            <InputGimlet
                                title={`${props.count.profile.name} ${props.count.profile.surname}`}
                                className={"text-[#df5b23] text-3xl"}
                                fontWeight={900}
                                fontStyle="normal"
                            />
                            <InputGimlet
                                title={props.count.profile.username}
                                className={"text-[#df5b23] text-xl"}
                                fontWeight={400}
                                fontStyle="normal"
                            />
                            <div className="h-full flex flex-col justify-center max-[1023px]:items-center mt-10">
                                {
                                    props.count.profile['user_partner'] === '1' &&
                                    <RowHeaders
                                        campo="ID Socio"
                                        data={props.count.profile.membership_number}
                                        className="whitespace-nowrap"
                                    />
                                }
                                <RowHeaders
                                    campo="Mail"
                                    data={props.count.profile.email}
                                    className="whitespace-nowrap"
                                />
                                <RowHeaders
                                    campo="Genere"
                                    data={props.count.profile.gender === "maschio" ? "M" : "F"}
                                />
                                <RowHeaders
                                    campo="Data di nascita"
                                    data={props.count.profile.data_di_nascita}
                                    className="whitespace-nowrap"
                                />
                            </div>
                        </div>
                        <div className="max-[1023px]:flex flex-col items-center">
                            <div className="flex flex-row justify-center items-center w-fit">
                                <div className="w-full flex flex-row justify-center lg:justify-start items-center bg-[#F2E3D8] p-4 max-[1023px]:mt-10">
                                    <img
                                        src={percorso}
                                        alt="img"
                                        className="w-8 h-8 object-contain mr-4"
                                    />
                                    <div className="flex flex-row-items-center gap-1">
                                        <InputGimlet
                                            title="sez."
                                            className={"text-[#df5b23] text-lg"}
                                            fontWeight={400}
                                        />
                                        <InputGimlet
                                            title={props.count && props.count.profile && props.count.profile.department_name ? props.count.profile.department_name : 'non specificata'}
                                            className={"text-[#df5b23] text-xl"}
                                            fontWeight={700}
                                            fontStyle="normal"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="h-full flex flex-col justify-center max-[1023px]:items-center mt-10">
                                <RowHeaders
                                    campo="Nazione di nascita"
                                    data={props.count.profile.country_birth}
                                    className="whitespace-nowrap"
                                />
                                <RowHeaders
                                    campo="Comune di nascita"
                                    data={props.count.profile.city_birth}
                                    className="whitespace-nowrap"
                                />
                                <RowHeaders
                                    campo="Regione di nascita"
                                    data={props.count.profile.region_birth}
                                    className="whitespace-nowrap"
                                />
                                <RowHeaders
                                    campo="Codice Fiscale"
                                    data={props.count.profile.fiscal_code}
                                    className="whitespace-nowrap uppercase"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="w-full flex justify-center bg-[#f0e9e3]">
                <form
                    className={
                        props.type === "new"
                            ? "max-w-[1920px] w-full mx-4 justify-center flex flex-col  bg-[#f0e9e3] rounded-xl lg:mx-36 px-4 my-12 p-8 shadow-2xl"
                            : "max-w-[1920px] w-full bg-[#f0e9e3] lg:px-36 px-6 py-10"
                    }
                    onSubmit={formik.handleSubmit}>
                    {/*{props.type === "edit" ? (
            <InputGimlet
              title="Dati personali"
              className={"text-[#df5b23] text-3xl mb-8 "}
              fontWeight={700}
              fontStyle="normal"
            />
          ) : (
            <div className="gap-2 mb-8 flex flex-row items-center">
              <IconButton onClick={() => navigate("/login")}>
                <ArrowBackIcon />
              </IconButton>
              <div className="font-bold text-[#4a4137] text-2xl">
                Registrati
              </div>
            </div>
          )}
          {errorUsername &&
            Object.values(errorUsername).map((res, index) => {
              return (
                <React.Fragment key={index}>
                  <Alert className={"mb-2"} severity="error">
                    {res}
                  </Alert>
                </React.Fragment>
              );
            })}
          <div className="flex lg:flex-row flex-col lg:items-end items-center gap-6 mb-4">
            {!props.anagrafica && (
              <InputCustom
                name={"username"}
                value={formik.values.username ?? " "}
                onChange={formik.handleChange}
                title={"username"}
                fullWidth
              />
            )}
            {props.type === "new" && (
              <div className={"uppercase w-full"}>
                <Button
                  className={"w-full"}
                  variant="contained"
                  onClick={() => {
                    api.setHeaders({
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    });
                    api
                      .post(`/users/autogenerate`, {
                        name: formik.values.name,
                        surname: formik.values.surname,
                      })
                      .then((res) => {
                        if (res.ok) {
                          formik.setFieldValue("username", res.data.data);
                          setErrorUsername(null);
                        } else {
                          setErrorUsername(error.response.data.errors);
                        }
                      });
                  }}>
                  Genera Username
                </Button>
              </div>
            )}
            <InputCustom
              name={"surname"}
              value={formik.values.surname ?? " "}
              onChange={formik.handleChange}
              title={"cognome"}
              fullWidth
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
          </div>

          <div className="flex lg:flex-row flex-col items-center gap-6 mb-4">
            <InputCustom
              name={"name"}
              value={formik.values.name ?? " "}
              onChange={formik.handleChange}
              title={"nome"}
              fullWidth
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
            <InputCustom
              name={"email"}
              value={formik.values.email ?? " "}
              onChange={formik.handleChange}
              title={"email"}
              fullWidth
            />
          </div>
          <div className="flex lg:flex-row flex-col items-center gap-6 mb-4">
            <div className={"w-full"}>
              <FormControl fullWidth>
                <div className={"flex flex-row items-center"}>
                  <Typography className={"capitalize"}>Genere</Typography>
                  <div className={"text-red-500"}>*</div>
                </div>
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={formik.values.gender ?? " "}
                  onChange={(event) =>
                    formik.setFieldValue("gender", event.target.value)
                  }>
                  <FormControlLabel
                    value={"maschio"}
                    control={<Radio />}
                    label="M"
                  />
                  <FormControlLabel
                    value={"femmina"}
                    control={<Radio />}
                    label="F"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                disableFuture={true}
                inputFormat="dd/MM/yyyy"
                value={formik.values.date_birth ?? " "}
                onChange={(newValue) => {
                  formik.setFieldValue("date_birth", newValue);
                }}
                renderInput={(params) => (
                  <InputCustom
                    title={"Data di Nascita"}
                    {...params}
                    error={Boolean(
                      formik.touched.date_birth && formik.errors.date_birth
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="flex lg:flex-row flex-col items-center gap-6 mb-4">
            <InputCustom
              select
              name={"country_birth"}
              value={formik.values.country_birth}
              onChange={(event) => {
                formik.setFieldValue("country_birth", event.target.value);
                formik.setFieldValue("city_birth", "");
              }}
              title={"Nazione di Nascita"}
              fullWidth>
              <MenuItem key={0} value={"default"}>
                -
              </MenuItem>
              {data &&
                data.states?.map((res, index) => {
                  return (
                    <MenuItem value={res.name} key={res.id + index}>
                      {res.name}
                    </MenuItem>
                  );
                })}
            </InputCustom>

            {formik.values.country_birth === "Italia" ? (
              <>
                <Autocomplete
                  value={{ name: formik.values.city_birth }}
                  fullWidth
                  autoComplete={true}
                  loading={loadingCity}
                  onInputChange={(e, value) => {
                    if (e !== null) {
                      formik.setFieldValue("city_birth", value);
                      setLoadingCity(true);
                      value.length > 3 &&
                        api
                          .post(`/departments/search-city`, { name: value })
                          .then((res) => {
                            setLoadingCity(false);
                            setCityName(res.data.data);
                            let id = _.find(res.data.data, { name: value });
                            api
                              .post(
                                `/departments/response-province-and-region`,
                                {
                                  id: id.id,
                                }
                              )
                              .then((res) => {
                                formik.setFieldValue(
                                  "province_birth",
                                  res.data.data.provinces.name
                                );
                                formik.setFieldValue(
                                  "region_birth",
                                  res.data.data.region.name
                                );
                                setProvinceID(res.data.data.provinces.id);
                                setRegioniID(res.data.data.region.id);
                                setComuneID(res.data.data.city.id);
                              })
                              .catch((error) => console.log(error));
                          });
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  options={cityName}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <InputCustom
                      name={"city_birth"}
                      title="Comune di Nascita"
                      {...params}
                    />
                  )}
                />
                <InputCustom
                  disabled
                  name={"province_birth"}
                  value={formik.values.province_birth}
                  onChange={formik.handleChange}
                  title={"Provincia di Nascita"}
                  fullWidth
                />
                <InputCustom
                  disabled
                  name={"region_birth"}
                  value={formik.values.region_birth}
                  onChange={formik.handleChange}
                  title={"Regione di Nascita"}
                  fullWidth
                />
              </>
            ) : (
              <InputCustom
                name={"city_birth"}
                value={formik.values.city_birth}
                onChange={formik.handleChange}
                title={"Comune di Nascita"}
                fullWidth
              />
            )}
          </div>
          <InputCustom
            disabled={formik.values.check_fiscal_code === true}
            name={"fiscal_code"}
            value={formik.values.fiscal_code}
            onChange={formik.handleChange}
            title={"codice fiscale"}
            className={"lg:w-1/2 w-full"}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
          {formik.values.country_birth !== "Italia" && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.check_fiscal_code}
                  onChange={(event) =>
                    formik.setFieldValue(
                      "check_fiscal_code",
                      event.target.checked
                    )
                  }
                />
              }
              label="Non ho il CF"
            />
          )}
          {props.type === "new" && (
            <div className={"flex flex-col mt-6 justify-end w-full"}>
              <Typography component={"div"}>
                Allegare documento consenso
              </Typography>
              <label className="block mt-2">
                <input
                  multiple={false}
                  value={formik.values.document_consent}
                  onChange={(e) => {
                    setFiles3(e.target.files);
                    formik.setFieldValue("document_consent", e.target.value);
                  }}
                  name={"document_consent"}
                  type={"file"}
                  className="block w-full text-sm text-slate-500
      file:mr-4 file:py-2 file:px-4
      file:rounded-lg file:border-0
      file:text-sm file:font-semibold
      file:bg-[#dc3d22] file:text-white file:uppercase
      hover:file:opacity-25 file:cursor-pointer"
                />
              </label>
            </div>
          )}
          {props.type === "new" && (
            <>
              <div className="flex flex-col gap-6 mt-6 mb-4">
                <div className={"flex flex-row items-center "}>
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      <Checkbox
                        checked={formik.values.accept_privacy}
                        onChange={(event) =>
                          formik.setFieldValue(
                            "accept_privacy",
                            event.target.checked
                          )
                        }
                      />
                    }
                    label="Trattamento dati Personali"
                  />
                  <div className={"text-red-500 "}>*</div>
                </div>
                <FormControlLabel
                  sx={{ margin: 0 }}
                  control={
                    <Checkbox
                      checked={formik.values.accept_newsletter}
                      onChange={(event) =>
                        formik.setFieldValue(
                          "accept_newsletter",
                          event.target.checked
                        )
                      }
                    />
                  }
                  label="Iscrizione alla Newsletter"
                />
              </div>
            </>
          )}
           {(props.type === "new" || props.type === "edit") && (
            <div className={"flex flex-col my-4 gap-4"}>
              <FormControlLabel
                sx={{ margin: 0 }}
                control={
                  <Checkbox
                    checked={formik.values.user_partner}
                    onChange={(event) =>
                      formik.setFieldValue("user_partner", event.target.checked)
                    }
                  />
                }
                label="Socio"
              />
            </div>
          )} */}
                    {formik.values.user_partner && (
                        <>
                            <div className="flex lg:flex-row flex-col items-center gap-6 mt-4 mb-4">
                                <InputCustom
                                    name={"phone"}
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    title={"telefono"}
                                    fullWidth
                                />
                                <InputCustom
                                    name={"cell_phone"}
                                    value={formik.values.cell_phone}
                                    onChange={formik.handleChange}
                                    title={"cellulare"}
                                    fullWidth
                                />
                            </div>

                            <div className="flex lg:flex-row flex-col items-center gap-6 mb-4">
                                <InputCustom
                                    select
                                    name={"country_residence"}
                                    value={formik.values.country_residence}
                                    onChange={(event) => {
                                        formik.setFieldValue(
                                            "country_residence",
                                            event.target.value
                                        );
                                        formik.setFieldValue("city_residence", "");
                                    }}
                                    title={"Nazione di Residenza"}
                                    fullWidth>
                                    <MenuItem key={0} value={"default"}>
                                        -
                                    </MenuItem>
                                    {data &&
                                        data.states?.map((res, index) => {
                                            return (
                                                <MenuItem value={res.name} key={res.id + index}>
                                                    {res.name}
                                                </MenuItem>
                                            );
                                        })}
                                </InputCustom>
                                {formik.values.country_residence === "Italia" ? (
                                    <>
                                        <Autocomplete
                                            value={{ name: formik.values.city_residence }}
                                            fullWidth
                                            autoComplete={true}
                                            loading={loadingCity}
                                            onInputChange={(e, value) => {
                                                if (e !== null) {
                                                    formik.setFieldValue("city_residence", value);
                                                    setLoadingCity(true);

                                                    value.length > 3 &&
                                                        api
                                                            .post(`/departments/search-city`, { name: value })
                                                            .then((res) => {
                                                                setLoadingCity(false);
                                                                setCityName(res.data.data);
                                                                let id = _.find(res.data.data, { name: value });
                                                                api
                                                                    .post(
                                                                        `/departments/response-province-and-region`,
                                                                        { id: id.id }
                                                                    )
                                                                    .then((res) => {
                                                                        formik.setFieldValue(
                                                                            "province_residence",
                                                                            res.data.data.provinces.name
                                                                        );
                                                                        formik.setFieldValue(
                                                                            "region_residence",
                                                                            res.data.data.region.name
                                                                        );
                                                                        setProvinceID2(res.data.data.provinces.id);
                                                                        setRegioniID2(res.data.data.region.id);
                                                                        setComuneID2(res.data.data.city.id);
                                                                        api
                                                                            .get(
                                                                                `/users/list-user-department-assignement/${res.data.data.region.id}`
                                                                            )
                                                                            .then((res) => {
                                                                                setSezioni(res.data.data);
                                                                                !_.isEmpty(res.data.data) &&
                                                                                    formik.setFieldValue(
                                                                                        "department_id1",
                                                                                        res.data.data[0].id
                                                                                    );
                                                                            })
                                                                            .catch((err) => console.log(err));
                                                                    })
                                                                    .catch((error) => console.log(error));
                                                            });
                                                }
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            options={cityName}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <InputCustom
                                                    name={"city_residence"}
                                                    title="Comune di Residenza"
                                                    {...params}
                                                />
                                            )}
                                        />
                                        <InputCustom
                                            disabled
                                            name={"province_residence"}
                                            value={formik.values.province_residence}
                                            onChange={formik.handleChange}
                                            title={"Provincia di Residenza"}
                                            fullWidth
                                        />
                                        <InputCustom
                                            disabled
                                            name={"region_residence"}
                                            value={formik.values.region_residence}
                                            onChange={formik.handleChange}
                                            title={"Regione di Residenza"}
                                            fullWidth
                                        />
                                    </>
                                ) : (
                                    <InputCustom
                                        name={"city_residence"}
                                        value={formik.values.city_residence}
                                        onChange={formik.handleChange}
                                        title={"Comune di Residenza"}
                                        fullWidth
                                    />
                                )}
                            </div>
                            <InputCustom
                                name={"address_residence"}
                                value={formik.values.address_residence}
                                onChange={formik.handleChange}
                                title={"indirizzo di residenza"}
                                className={"lg:w-1/2 w-full"}
                            />
                            <FormControl>
                                <div>La residenza è uguale dal domicilio?</div>
                                <RadioGroup
                                    row
                                    name="controlled-radio-buttons-group"
                                    value={formik.values.residence_same_domicile}
                                    onChange={(event) => {
                                        formik.setFieldValue(
                                            "residence_same_domicile",
                                            event.target.value
                                        );
                                        if (event.target.value === "si") {
                                            formik.setFieldValue("department_id2", "");
                                            formik.setFieldValue("country_domicile", "");
                                            formik.setFieldValue("city_domicile", "");
                                            formik.setFieldValue("province_domicile", "");
                                            formik.setFieldValue("address_domicile", "");
                                        } else {
                                            formik.setFieldValue("department_id1", "");
                                            formik.setFieldValue(
                                                "country_domicile",
                                                view && view.country_domicile !== null
                                                    ? view.country_domicile
                                                    : ""
                                            );
                                            formik.setFieldValue(
                                                "city_domicile",
                                                view && view.city_domicile !== null
                                                    ? view.city_domicile
                                                    : ""
                                            );
                                            formik.setFieldValue(
                                                "province_domicile",
                                                view && view.province_domicile !== null
                                                    ? view.province_domicile
                                                    : ""
                                            );
                                            formik.setFieldValue(
                                                "address_domicile",
                                                view && view.address_domicile !== null
                                                    ? view.address_domicile
                                                    : ""
                                            );
                                        }
                                    }}>
                                    <FormControlLabel value="si" control={<Radio />} label="Si" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                            {formik.values.residence_same_domicile === "no" && (
                                <>
                                    <div className="flex flex-row items-center gap-6 mb-4">
                                        <InputCustom
                                            select
                                            name={"country_domicile"}
                                            value={formik.values.country_domicile}
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    "country_domicile",
                                                    event.target.value
                                                );
                                                formik.setFieldValue("city_domicile", "");
                                            }}
                                            title={"Nazione di Domicilio"}
                                            fullWidth>
                                            <MenuItem key={0} value={"default"}>
                                                -
                                            </MenuItem>
                                            {data &&
                                                data.states?.map((res, index) => {
                                                    return (
                                                        <MenuItem value={res.name} key={res.id + index}>
                                                            {res.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </InputCustom>
                                        {formik.values.country_domicile === "Italia" ? (
                                            <>
                                                <Autocomplete
                                                    value={{ name: formik.values.city_domicile }}
                                                    fullWidth
                                                    autoComplete={true}
                                                    loading={loadingCity}
                                                    onInputChange={(e, value) => {
                                                        if (e !== null) {
                                                            formik.setFieldValue("city_domicile", value);
                                                            setLoadingCity(true);
                                                            value.length > 3 &&
                                                                api
                                                                    .post(`/departments/search-city`, {
                                                                        name: value,
                                                                    })
                                                                    .then((res) => {
                                                                        setLoadingCity(false);
                                                                        setCityName(res.data.data);
                                                                        let id = _.find(res.data.data, {
                                                                            name: value,
                                                                        });
                                                                        api
                                                                            .post(
                                                                                `/departments/response-province-and-region`,
                                                                                { id: id.id }
                                                                            )
                                                                            .then((res) => {
                                                                                formik.setFieldValue(
                                                                                    "province_domicile",
                                                                                    res.data.data.provinces.name
                                                                                );
                                                                                formik.setFieldValue(
                                                                                    "region_domicile",
                                                                                    res.data.data.region.name
                                                                                );
                                                                                setProvinceID3(
                                                                                    res.data.data.provinces.id
                                                                                );
                                                                                setRegioniID3(res.data.data.region.id);
                                                                                setComuneID3(res.data.data.city.id);
                                                                                api
                                                                                    .get(
                                                                                        `/users/list-user-department-assignement/${res.data.data.region.id}`
                                                                                    )
                                                                                    .then((res) => {
                                                                                        setSezioni(res.data.data);
                                                                                        !_.isEmpty(res.data.data) &&
                                                                                            formik.setFieldValue(
                                                                                                "department_id2",
                                                                                                res.data.data[0].id
                                                                                            );
                                                                                    })
                                                                                    .catch((err) => console.log(err));
                                                                            })
                                                                            .catch((error) => console.log(error));
                                                                    });
                                                        }
                                                    }}
                                                    options={cityName}
                                                    getOptionLabel={(option) => option.name}
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <InputCustom
                                                            name={"city_domicile"}
                                                            title="Comune di Domicilio"
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                                <InputCustom
                                                    disabled
                                                    name={"province_domicile"}
                                                    value={formik.values.province_domicile}
                                                    onChange={formik.handleChange}
                                                    title={"Provincia di Domicilio"}
                                                    fullWidth
                                                />
                                                <InputCustom
                                                    disabled
                                                    name={"region_domicile"}
                                                    value={formik.values.region_domicile}
                                                    onChange={formik.handleChange}
                                                    title={"Regione di Domicilio"}
                                                    fullWidth
                                                />
                                            </>
                                        ) : (
                                            <InputCustom
                                                name={"city_domicile"}
                                                value={formik.values.city_domicile}
                                                onChange={formik.handleChange}
                                                title={"Comune di Domicilio"}
                                                fullWidth
                                            />
                                        )}
                                    </div>
                                    {/* <div className={"mb-4"}>
                <InputCustom
                  select
                  name={"department_id2"}
                  value={formik.values.department_id2}
                  onChange={(event) => {
                    formik.setFieldValue("department_id2", event.target.value);
                  }}
                  title={"Sezioni"}
                  fullWidth
                >
                  {sezioni.map((res, index) => {
                    if (res.id !== 1) {
                      return (
                        <MenuItem value={res.id} key={res.id + index}>
                          {res.name}
                        </MenuItem>
                      );
                    }
                  })}
                </InputCustom>
              </div>*/}
                                    <InputCustom
                                        name={"address_domicile"}
                                        value={formik.values.address_domicile}
                                        onChange={formik.handleChange}
                                        title={"indirizzo di domicilio"}
                                        className={"lg:w-1/2 w-full"}
                                    />
                                </>
                            )}
                            {/* <div className="flex lg:flex-row flex-col items-center gap-6 mt-4 mb-4">
                <InputCustom
                  select
                  name={"profession"}
                  value={formik.values.profession}
                  onChange={formik.handleChange}
                  title={"professione"}
                  fullWidth>
                  {data.professions.map((res, index) => {
                    return (
                      <MenuItem value={res.name} key={index}>
                        {res.name}
                      </MenuItem>
                    );
                  })}
                </InputCustom>
                <InputCustom
                  select
                  name={"degree_study"}
                  value={formik.values.degree_study}
                  onChange={formik.handleChange}
                  title={"Titolo di studio"}
                  fullWidth>
                  {data.degree_studies.map((res, index) => {
                    return (
                      <MenuItem value={res.name} key={index}>
                        {res.name}
                      </MenuItem>
                    );
                  })}
                </InputCustom>
              </div>
              <div className="flex lg:flex-row flex-col items-center gap-6 mb-4">
                <Autocomplete
                  value={formik.values.language}
                  fullWidth
                  multiple
                  onChange={(e, value) =>
                    formik.setFieldValue("language", value)
                  }
                  options={data.languages}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <InputCustom
                      name={"language"}
                      title="Lingue parlate"
                      {...params}
                    />
                  )}
                />

                {props.type === "new" && (
                  <div className={"flex flex-col w-full"}>
                    <Typography component={"div"}>Immagine Profilo</Typography>
                    <label className="block mt-2">
                      <input
                        multiple={false}
                        value={formik.values.profile_image}
                        onChange={(e) => {
                          setFiles(e.target.files);
                          formik.setFieldValue("profile_image", e.target.value);
                        }}
                        name={"profile_image"}
                        type={"file"}
                        className="block w-full text-sm text-slate-500
      file:mr-4 file:py-2 file:px-4
      file:rounded-lg file:border-0
      file:text-sm file:font-semibold
      file:bg-[#dc3d22] file:text-white file:uppercase
      hover:file:opacity-25 file:cursor-pointer"
                      />
                    </label>
                  </div>
                )}
              </div>
              {props.type === "new" && (
                <div className="flex flex-row items-center justify-between gap-6 mb-4 lg:w-1/2 w-full">
                  <div className={"flex flex-col w-full"}>
                    <div className={"flex flex-row items-center"}>
                      <Typography component={"div"}>
                        Documento d'identità
                      </Typography>
                      <div className={"text-red-500"}>*</div>
                    </div>
                    <label className="block mt-2">
                      <input
                        value={formik.values.document_image}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "document_image",
                            e.target.value
                          );
                          setFiles2(e.target.files);
                        }}
                        name={"document_image"}
                        type={"file"}
                        className="block w-full text-sm text-slate-500
      file:mr-4 file:py-2 file:px-4
      file:rounded-lg file:border-0
      file:text-sm file:font-semibold
      file:bg-[#dc3d22] file:text-white file:uppercase
      hover:file:opacity-25 file:cursor-pointer"
                      />
                    </label>
                  </div>
                </div>
              )}
              {props.type === "new" && (
                <>
                  <div className="flex flex-col gap-6 mt-8 mb-4">
                    <div className={"flex flex-row items-center "}>
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        control={
                          <Checkbox
                            checked={formik.values.accept_privacy_partner}
                            onChange={(event) =>
                              formik.setFieldValue(
                                "accept_privacy_partner",
                                event.target.checked
                              )
                            }
                          />
                        }
                        label="Trattamento dati Personali"
                      />
                      <div className={"text-red-500 "}>*</div>
                    </div>
                    <div className={"flex flex-row items-center"}>
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        control={
                          <Checkbox
                            checked={formik.values.accept_rule_partner}
                            onChange={(event) =>
                              formik.setFieldValue(
                                "accept_rule_partner",
                                event.target.checked
                              )
                            }
                          />
                        }
                        label="Visione del Regolamento"
                      />
                      <div className={"text-red-500 "}>*</div>
                    </div>
                    <div className={"flex flex-row items-center"}>
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        control={
                          <Checkbox
                            checked={formik.values.accept_statute_partner}
                            onChange={(event) =>
                              formik.setFieldValue(
                                "accept_statute_partner",
                                event.target.checked
                              )
                            }
                          />
                        }
                        label="Visione dello Statuto"
                      />
                      <div className={"text-red-500 "}>*</div>
                    </div>
                  </div>
                </>
              )} */}
                        </>
                    )}
                    {errorForm &&
                        Object.values(errorForm).map((res, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Alert className={"mb-2"} severity="error">
                                        {res}
                                    </Alert>
                                </React.Fragment>
                            );
                        })}
                    {formik.values.user_partner && (
                        <>
                            <div className="border-t-[1px] border-solid border-black w-[85vw] max-w-[1632px] relative mt-10"></div>

                            <div className="max-w-[1600px] w-full flex lg:flex-row flex-col items-center gap-6 mt-4 mb-4">
                                <InputCustom
                                    name={"iban"}
                                    value={formik.values.iban}
                                    onChange={formik.handleChange}
                                    title={"IBAN (Per eventuali rimborsi)"}
                                    fullWidth
                                />
                                <InputCustom
                                    name={"cell_intestatario_iban"}
                                    value={formik.values.cell_intestatario_iban}
                                    onChange={formik.handleChange}
                                    title={"Intestatario IBAN"}
                                    fullWidth
                                />
                            </div>
                            <div className="border-t-[1px] border-solid border-black w-[85vw] max-w-[1632px] relative mt-10"></div>
                        </>
                    )}
                    <div>
                        <FormControl sx={{ m: 3, width: '100%' }} component="fieldset" variant="standard">
                            <FormLabel component="legend">NewsLetter</FormLabel>
                            <FormControlLabel
                                sx={{ margin: 0 }}
                                control={
                                    <Switch
                                        checked={formik.values.accept_newsletter}
                                        onChange={(event) =>
                                            formik.setFieldValue(
                                                "accept_newsletter",
                                                event.target.checked
                                            )
                                        }
                                    />
                                }
                                label={"Iscrizione alla Newsletter"}
                            />
                            <FormGroup className="!grid grid-cols-2 min-[430px]:grid-cols-3 sm:grid-cols-4 gap-2">
                                {!loading || !formik.values.accept_newsletter?
                                    (
                                        formik.values.accept_newsletter ?
                                            formik.values.newsletter.map((newsletter, index) => {
                                                return <FormControlLabel key={index}
                                                    control={
                                                        <Checkbox checked={newsletter.checked} onChange={(e, value) => {
                                                            formik.setFieldValue('newsletter', formik.values.newsletter.map((newsLett) => {
                                                                if (newsLett.name === e.target.name) {
                                                                    newsLett.checked = !newsLett.checked
                                                                }
                                                                return newsLett
                                                            }))
                                                        }} name={newsletter.name} />
                                                    }
                                                    label={newsletter.name}
                                                />
                                            })
                                            : ''
                                    )
                                    : <LoadingComponent />}
                            </FormGroup>
                        </FormControl>
                    </div>
                    <div
                        className={`${props.type === "edit" ? " w-full" : "w-full"
                            } mt-6 flex flex-row items-center gap-4 justify-start`}>
                        <Button
                            variant={"contained"}
                            disabled={disable}
                            className={
                                "shadow-md uppercase w-full max-w-[300px] !text-2xl italic !font-normal"
                            }
                            type={"submit"}>
                            {props.type === "edit" ? "Aggiorna" : "Salva"}
                        </Button>
                    </div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={openSuccess}
                        autoHideDuration={3000}
                        onClose={() => {
                            setOpenSuccess(false);
                            setDisable(false);
                        }}>
                        <Alert
                            variant={"filled"}
                            onClose={() => {
                                setOpenSuccess(false);
                                setDisable(false);
                            }}
                            severity="success"
                            sx={{ width: "100%" }}>
                            Operazione completata con successo!
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={openError.open}
                        autoHideDuration={3000}
                        onClose={() => {
                            setOpenError({
                                open: false,
                                error: ''
                            });
                            setDisable(false);
                        }}>
                        <Alert
                            variant={"filled"}
                            onClose={() => {
                                setOpenError({
                                    open: false,
                                    error: ''
                                });
                                setDisable(false);
                            }}
                            severity="error"
                            sx={{ width: "100%" }}>
                            {openError.error}
                        </Alert>
                    </Snackbar>
                </form>
            </div>
        </div>
    );
};

const mapState = (state) => ({
    count: state.count,
});

export default connect(mapState)(NuovoForm2);

import InputBilo from "../components/InputBilo";
import { Link, useNavigate } from "react-router-dom";
import noImage from "../assets/img/no_image.jpg";

const ItemEvento = ({ res }) => {

    const navigate = useNavigate()
    return (
        <>
            <div className="rounded-t-3xl rounded-b-3xl shadow-2xl min-w-[350px] max-h-[530px] min-h-[335px] w-[30vh] h-[40vh]">
                <div className="h-[50%] relative">
                    <div
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 300,
                            fontStyle: "normal",
                            maxWidth: 'fit-content'
                        }}
                        className="absolute top-4 left-1/2 translate-x-[-50%] text-2xl z-10 text-white whitespace-nowrap">{res.sezione ? res.sezione : ''}
                    </div>
                    <div style={{
                        fontFamily: "bilo",
                        fontWeight: 600,
                        fontStyle: "normal",
                        width: '90%',
                    }}
                        className="relative top-14 left-1/2 translate-x-[-50%] text-sm md:text-2xl z-10 w-[260px] text-white pl-2 !leading-5">{res.title ? res.title.toUpperCase() : ''}
                    </div>
                    <div className="absolute top-0 w-full h-full bg-[#000000] rounded-t-3xl">
                        <img className="w-full h-full object-cover opacity-40 z-5 rounded-t-3xl" src={res['preview_image_path'] ? res['preview_image_path'] : noImage} alt="" />
                    </div>
                </div>
                <div className="h-[50%] bg-white rounded-b-3xl bg-[#f0e9e3]">
                    <div style={{
                        fontFamily: "bilo",
                        fontWeight: 300,
                        fontStyle: "normal",
                    }} className="text-center text-xl pt-[10px] text-[#796557]">Data dell'evento</div>
                    <div style={{
                        fontFamily: "bilo",
                        fontWeight: 600,
                        fontStyle: "normal",
                    }} className="text-center text-2xl text-[#796557]">{res['date_event'] ? `${res['date_event'].split('-')[2]}/${res['date_event'].split('-')[1]}/${res['date_event'].split('-')[0]}` : ''}</div>
                    <div className="flex justify-center align-center mt-2">
                        <div className="mr-10">
                            <InputBilo
                                className={'text-center'}
                                title={'Inizio: '}

                            />
                            <InputBilo
                                className={'text-center text-lg'}
                                fontWeight={700}
                                title={res['hours_start'] ? (res['hours_start'].split(':')[0] + ':' + res['hours_start'].split(':')[1]) : ''}

                            />
                        </div>
                        <div>
                            <InputBilo
                                className={'text-center'}
                                title={'Fine: '}

                            />
                            <InputBilo
                                className={'text-center text-lg'}
                                fontWeight={700}
                                title={res['hours_end'] ? (res['hours_end'].split(':')[0] + ':' + res['hours_end'].split(':')[1]) : ''}

                            />
                        </div>
                    </div>
                    <Link to={`/eventi/${res.internal_id ? res.internal_id : ''}`}
                        className={"flex w-full p-2 rounded-b-3xl min-h-[46px] hover:opacity-80 cursor-pointer bg-[#34291F]"}>
                        <InputBilo className={'w-full text-center text-white text-xl'} title={'GUARDA EVENTO'} />
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ItemEvento;

import React from 'react'
import { Outlet } from 'react-router-dom'
import CookiePolicy from '../Cookie/CookiePolicy'
import { CookieConsent } from '../App'

function BaseTemplate() {
    return (
        <>
            <CookieConsent />
            <Outlet />
        </>
    )
}

export default BaseTemplate
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#dc3d22",
    },
    secondary: {
      main: "#a7a492",
    },
    orangeCustom: {
        main: '#df5b23'
    }
  },
  greenCustom: "#19C35D",
  // typography: {
  //   fontFamily: "'Titillium Web', sans-serif",
  //   fontWeightRegular: 400,
  //   fontWeightBold: 700,
  // },
  
});

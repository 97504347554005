import React from "react";
import TopBarIndex from "../components/TopBarIndex";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { Paper } from "@mui/material";

const PaymentFailed = () => {
  return (
    <div>
      <TopBarIndex />
      <div className="h-screen w-full">
        <div className="container mx-auto text-center h-full justify-center items-center flex flex-col  text-3xl p-4">
          <Paper elevation={6}>
            <div className="p-4 border ronded shadow-orange-100 rounded">
              Qualcosa è andato storto, riprovare
            </div>
          </Paper>
        </div>
      </div>
      <FooterTop />
      <FooterDown />
    </div>
  );
};

export default PaymentFailed;

import React, { useState } from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import FooterTop from '../Sezioni/FooterTop'
import FooterDown from '../Sezioni/FooterDown'
import img1 from "../assets/img/accompagnatori.jpg";
import InputGimlet from '../components/InputGimlet'
import env from '../conf/variables'
import { Box, Button, Modal, Typography } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import { Visibility } from '@mui/icons-material'

let bilanci = [
    {
        year: 2019,
        document: [
            {
                id: 1,
                title: '2019 Bilancio con nota integrativa',
                url: `${env.url_api_without_api}document/bilancio/documenti_bilanci/2019/bilancio.pdf`
            },
            {
                id: 2,
                title: '2019 Relazione di missione',
                url: `${env.url_api_without_api}document/bilancio/documenti_bilanci/2019/relazione_di_missione.pdf`
            },
            {
                id: 3,
                title: '2019 Relazione Organo di Controllo',
                url: `${env.url_api_without_api}document/bilancio/documenti_bilanci/2019/relazione_organo_di_controllo.pdf`
            }
        ]
    },
    {
        year: 2020,
        document: [
            {
                id: 1,
                title: '2020 Bilancio con nota integrativa',
                url: `${env.url_api_without_api}document/bilancio/documenti_bilanci/2020/bilancio.pdf`
            },
            {
                id: 2,
                title: '2020 Relazione di missione',
                url: `${env.url_api_without_api}document/bilancio/documenti_bilanci/2020/relazione_di_missione.pdf`
            },
            {
                id: 3,
                title: '2020 Relazione Organo di Controllo',
                url: `${env.url_api_without_api}document/bilancio/documenti_bilanci/2020/relazione_organo_di_controllo.pdf`
            }
        ]
    },
    {
        year: 2021,
        document: [
            {
                id: 1,
                title: '2021 Bilancio con nota integrativa',
                url: `${env.url_api_without_api}document/bilancio/documenti_bilanci/2021/bilancio.pdf`
            },
            {
                id: 2,
                title: '2021 Relazione di missione',
                url: `${env.url_api_without_api}document/bilancio/documenti_bilanci/2021/relazione_di_missione.pdf`
            },
            {
                id: 3,
                title: '2021 Relazione Organo di Controllo',
                url: `${env.url_api_without_api}document/bilancio/documenti_bilanci/2021/relazione_organo_di_controllo.pdf`
            },
            {
                id: 4,
                title: '2021 Rendiconto attività ed economico',
                url: `${env.url_api_without_api}document/bilancio/documenti_bilanci/2021/rendiconto_attivita_ed_economico.pdf`
            }
        ]
    },
    {
        year: 2022,
        document: [
            {
                id: 1,
                title: '2022 Bilancio con nota integrativa',
                url: `${env.url_api_without_api}document/bilancio/documenti_bilanci/2022/bilancio.pdf`
            },
            {
                id: 2,
                title: '2022 Relazione di missione',
                url: `${env.url_api_without_api}document/bilancio/documenti_bilanci/2022/relazione_di_missione.pdf`
            },
            {
                id: 3,
                title: '2022 Rendiconto attività ed economico',
                url: `${env.url_api_without_api}document/bilancio/documenti_bilanci/2021/rendiconto_attivita_ed_economico.pdf`
            }
        ]
    },
]


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
};

function BilanciAnnuali() {

    const [openModal, setOpenModal] = useState(false)
    const [bilancio, setBilancio] = useState(null)


    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'azioni',
            headerName: 'Azioni',
            type: 'actions',
            width: 110,
            getActions: (url) => [
                <GridActionsCellItem
                    icon={<Visibility />}
                    onClick={() => { window.open(url.row.url, 'blank') }}
                    label={'Visualizza'}
                />
            ]
        },
    ];

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Bilanci annuali"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
                <div
                    className={"flex flex-row items-center gap-1"}
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    <div>Associazione  {">"}</div>
                    <div>Trekking Italia  {">"}</div>
                    <div className={"font-bold"}>Bilanci annuali</div>
                </div>
                <div>

                    <div className={"flex flex-col "}>
                        <InputGimlet
                            title={'Bilanci annuali'}
                            fontWeight={500}
                            fontStyle="italic"
                            className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                        />
                        <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 md:gap-10'>
                            {
                                bilanci.map((bilancio, index) => {
                                    return (
                                        <div onClick={() => {
                                            setBilancio(bilancio)
                                            setOpenModal(true)
                                        }} key={index} className='text-center text-white bg-[#df5b23] py-4 text-xl md:text-2xl rounded-2xl hover:bg-[#df5b23dd] hover:scale-95 hover:cursor-pointer'>
                                            {index + 2019}
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='w-full max-w-[1000px]'>
                        <InputGimlet
                            title={`Bilancio ${bilancio && bilancio.year}`}
                            className={'text-2xl text-[#df5b23]'}
                        />
                    </div>
                    <DataGrid
                        rows={bilancio && bilancio.document}
                        columns={columns}
                        sx={[{ width: '100%', maxWidth: '1000px' }]}
                        disableColumnMenu
                    />
                    <div
                        className={`w-full mt-6 flex flex-row items-center gap-4 justify-center`}>
                        <Button
                            variant={"contained"}
                            className={
                                "shadow-md uppercase w-full max-w-[300px] !text-2xl italic !font-normal"
                            }
                            onClick={() => {
                                setOpenModal(false)
                            }}>
                            Chiudi
                        </Button>
                    </div>
                </Box>
            </Modal>
            <FooterTop />
            <FooterDown />
        </>
    )
}

export default BilanciAnnuali
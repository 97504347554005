import React, { useEffect, useState } from 'react'
import InputBilo from '../components/InputBilo'
import { Checkbox, Dialog, DialogContent, DialogTitle, Divider, Drawer, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Rating, TextField } from '@mui/material'
import mappa from "../assets/img/icona_mappa.png";
import rettangolo from "../assets/img/rettangolo.png";
import InputGimlet from '../components/InputGimlet';
import Login from '../Auth/Login';
import DialogCustom from '../components/DialogCustom';
import { api } from '../conf/apiConf';
import InputCustom from '../components/InputCustom';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LandscapeIcon from "@mui/icons-material/Landscape";
import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";
import { useSelector } from 'react-redux';
import select from "../assets/img/freccina-drop-down-home.png";
import { useNavigate } from 'react-router-dom';
import { sub } from 'date-fns';
import moment from "moment";
import "moment/locale/it";
import { it } from "date-fns/locale";
import RatingCustom from '../components/RatingCustom';
import legendDifficulty from "../assets/img/scheda_valutazione.61de7991.jpg";
import LoadingComponent from '../components/LoadingComponent';
import filterIcon from "../assets/img/filtri_icon.png"
import { makeStyles } from '@material-ui/core';


function FormTrek({ formik, pos, regionsShown, statesShown, departments, filter, label, allTreks }) {
    moment.locale('it')

    const [infoFilters, setInfoFilters] = useState({
        position: {
            codeName: 'position',
            name: 'Posizione',
            hidden: true,
            initialState: formik?.initialState?.position,
            type: 'string',
            value: formik?.values?.position
        },
        regions: {
            codeName: 'regions',
            name: 'Destinazione',
            hidden: false,
            initialState: formik?.initialState?.regions,
            type: 'string',
            value: formik?.values?.regions
        },
        destination: {
            codeName: 'destination',
            name: 'Destinazione',
            hidden: false,
            initialState: formik?.initialState?.destination,
            type: 'string',
            value: formik?.values?.destination
        },
        typology: {
            codeName: 'typology',
            name: 'Tipologia',
            hidden: false,
            initialState: formik?.initialState?.typology,
            type: '[]',
            value: formik?.values?.typology
        },
        date: {
            codeName: 'date',
            name: 'Data di inizio',
            hidden: false,
            initialState: formik?.initialState?.date,
            type: 'date',
            value: formik?.values?.date
        },
        dateEnd: {
            codeName: 'dateEnd',
            name: 'Data di fine',
            hidden: false,
            initialState: formik?.initialState?.dateEnd,
            type: 'date',
            value: formik?.values?.dateEnd
        },
        label: {
            codeName: 'label',
            name: 'Classificazione',
            hidden: false,
            initialState: formik?.initialState?.label,
            type: 'string',
            value: formik?.values?.label
        },
        departments: {
            codeName: 'departments',
            name: 'Sezione organizzatrice',
            hidden: false,
            initialState: formik?.initialState?.departments,
            type: '[]',
            value: formik?.values?.departments
        },
        difficulty: {
            codeName: 'difficulty',
            name: 'Difficoltà',
            hidden: false,
            initialState: formik?.initialState?.difficulty,
            type: '[]',
            value: formik?.values?.difficulty
        },
        maps: {
            codeName: 'maps',
            name: 'Mappa',
            hidden: true,
            initialState: formik?.initialState?.maps,
            type: 'bool',
            value: formik?.values?.maps
        },
        title: {
            codeName: 'title',
            name: 'Titolo',
            hidden: false,
            initialState: formik?.initialState?.title,
            type: 'string',
            value: formik?.values?.title
        }
    })
    const [infoDifficolta, setInfoDifficolta] = useState(false);
    const [isOpenClass, setIsOpenClass] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [openSalvaRicerca, setOpenSalvaRicerca] = useState(false)
    const [titleSalvaRicerca, setTitleSalvaRicerca] = useState('')

    const navigate = useNavigate()
    const count = useSelector(state => state.count)

    const fomrSalvaRicerca = () => {
        return (
            <InputCustom
                title={"Nome ricerca*"}
                value={titleSalvaRicerca}
                fullWidth
                type="text"
                onChange={(e) => setTitleSalvaRicerca(e.target.value)}
            />
        )
    }

    function submit() {
        formik.submitForm()
    }

    useEffect(() => {
        setInfoFilters((prev) => {
            let temp = prev
            for (const key in temp) {
                if (temp.hasOwnProperty(key)) {
                    temp[key].value = formik.values[key]
                }
            }
            return temp
        })
    }, [JSON.stringify(formik.values)])

    if (!formik || !formik.values) {
        return <LoadingComponent />
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="shadow-2xl flex flex-col lg:mt-[14px] self-start">
                {/* <h1>{t("title")}</h1>
<p>{t("description")}</p> */}

                <div className="rounded-r-xl lg:rounded-t-xl bg-[#DF5B23] text-white">
                    <div className="flex flex-row items-center w-full justify-end gap-2 px-6">
                        <p className="uppercase font-light text-lg mr-4">
                            Visualizza come
                        </p>
                        <button
                            className={`min-w-[30px] cursor-pointer mr-2 hover:opacity-75 ${!formik.values.maps && "bg-black rounded-b-xl"
                                } p-2`}
                            onClick={() => {

                                localStorage.setItem('mapIsActive', false)
                                formik.setFieldValue('maps', false)
                                submit()
                            }}>
                            <img src={rettangolo} alt={"img"} className="w-8" />
                        </button>
                        <button
                            className={`min-w-[30px] cursor-pointer mr-2 hover:opacity-75 ${formik.values.maps && "bg-black rounded-b-xl"
                                } p-2`}
                            onClick={() => {
                                localStorage.setItem('mapIsActive', true)
                                formik.setFieldValue('maps', true)
                                submit()
                            }}>
                            <img src={mappa} alt={"img"} className="w-8" />
                        </button>
                    </div>
                    <div className="w-full flex flex-row items-center justify-center text-center">
                        <InputGimlet
                            className="text-2xl py-2"
                            title={"Trova il tuo prossimo trek"}
                        />
                    </div>
                </div>
                <div className="max-[1024px]:hidden grid grid-cols-1 min-[1060px]:grid-cols-2 w-full bg-[#F0E9E3] p-2">
                    {Object.values(infoFilters).map((filtro, index) => {
                        if (filtro?.hidden || filtro?.value === null || filtro?.value === '' || filtro?.value === undefined || filtro?.value?.length === 0) {
                            return ''
                        }
                        return (
                            <div
                                key={index}
                                className="relative rounded-md flex flex-col min-w-[115px] min-h-[70px] mr-[10px] mt-[10px] bg-[#df5b23cc] text-[#ffffff] p-1">
                                <p className='text-lg'>
                                    {filtro?.name}:
                                </p>
                                <div className='pl-2'>
                                    {
                                        filtro?.type === '[]' ?
                                            <div>
                                                {filtro?.value?.map((fil, index) => {
                                                    return (
                                                        <div key={index} className='my-1 flex items-center justify-between'>
                                                            <p className='inline'>
                                                                {
                                                                    filtro?.name === 'Difficoltà' ?
                                                                        fil === 0 ? 
                                                                         'Facile' : fil === 1 ? 
                                                                         'Facile / Medio' : fil === 2 ? 
                                                                         'Medio' : fil === 3 ? 
                                                                         'Medio / Impegnativo' : fil === 4 ? 
                                                                         'Impegnativo' : 'Impegnativo+'
                                                                        :
                                                                        (
                                                                            filtro?.name === 'Tipologia' ?
                                                                                filter?.data?.data?.filter(value => {
                                                                                    return fil === value.id
                                                                                })?.map(val => val.name)
                                                                                :
                                                                                departments?.data?.data?.filter(value => {
                                                                                    return fil === value.id
                                                                                })?.map(val => val.name)
                                                                        )
                                                                }
                                                            </p>
                                                            <button
                                                                onClick={
                                                                    () => {
                                                                        let newArray = formik?.values[filtro?.codeName]
                                                                        newArray.splice(index, 1)
                                                                        formik.setFieldValue(
                                                                            filtro?.codeName,
                                                                            newArray
                                                                        )
                                                                        submit()
                                                                    }
                                                                }
                                                                className={`inline ml-[5px] w-[25px] h-[25px] hover:opacity-[0.5] flex justify-center items-center rounded-full border-[1px] border-solid border-white`}>
                                                                x
                                                            </button>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            :
                                            (
                                                <div>
                                                    <p className='inline'>
                                                        {
                                                            filtro?.type === 'date' ?
                                                                moment(filtro?.value).format('DD/MM/yyyy')
                                                                :
                                                                (
                                                                    filtro?.name === 'Classificazione' ?
                                                                        label?.data?.data.find(lab => filtro?.value === lab.id)?.name
                                                                        : filtro?.value
                                                                )

                                                        }
                                                    </p>
                                                    {
                                                        filtro?.codeName === 'date' ?
                                                            ''
                                                            :

                                                            <button
                                                                onClick={() => {
                                                                    formik.setFieldValue(filtro?.codeName, filtro?.initialState)
                                                                    submit()
                                                                }}
                                                                className="absolute top-[2px] right-[2px] inline ml-[5px] w-[25px] h-[25px] hover:opacity-[0.5] justify-center items-center rounded-full border-[1px] border-solid border-white">
                                                                x
                                                            </button>
                                                    }
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="bg-[#F0E9E3] text-lg p-4 pt-12 flex">
                    <button
                        type={"button"}
                        onClick={() => {
                            setOpenSalvaRicerca(true)
                        }}
                        className={
                            "m-1 p-2 uppercase text-xl w-1/2 text-center font-bold bg-[#df5b23] text-white mt-2 cursor-pointer hover:opacity-50"
                        }

                    >SALVA RICERCA</button>
                    <button
                        onClick={() => {
                            formik.resetForm()
                            submit()
                            window.scrollTo({
                                top: 800,
                                left: 0,
                                behavior: "smooth",
                            });
                        }}
                        className={
                            "m-1 p-2 uppercase text-xl w-1/2 text-center font-bold bg-[#df5b23] text-white mt-2 cursor-pointer hover:opacity-50"
                        }
                    >
                        ELIMINA FILTRI
                    </button>
                </div>

                <div className="bg-[#F0E9E3] text-lg p-4 pt-12 flex items-end">
                    <InputCustom
                        classcontainer={"font-bold text-[#34291F]"}
                        title={'Ricerca per titolo'}
                        value={formik?.values?.title}
                        onChange={(e) => {
                            const { value } = e.target
                            formik.setFieldValue('title', value)
                            submit()
                        }}
                        search={'true'}
                    />
                </div>
                <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                    <FormControl sx={{ width: '100%' }}>
                        <FormLabel id="demo-controlled-radio-buttons-group">Posizione</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={pos}
                            onChange={(e) => {
                                formik.setFieldValue('position', e.target.value)
                                window.scrollTo({
                                    top: 800,
                                    left: 0,
                                    behavior: "smooth",
                                });
                                submit()
                                if (e.target.value === 'Estero') {
                                    navigate('/trek-estero-prova')
                                } else if (e.target.value === 'Italia') {
                                    navigate('/trek-italia-prova')
                                } else {
                                    navigate('/vicino-a-me')
                                }
                                // navigate(e.target.value === 'Estero' ? "/trek-estero" : "/trek-italia");
                            }}
                        >
                            <FormControlLabel sx={{ margin: 0, marginBottom: 1 }} className=" w-full flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg" value="Italia" control={<Radio />} label="Italia" />
                            <FormControlLabel sx={{ margin: 0, marginBottom: 1 }} className="m-0 w-full flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg" value="Estero" control={<Radio />} label="Estero" />
                            {/* <FormControlLabel sx={{ margin: 0 }} className="m-0 w-full flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg" value="Geolocalizzato" control={<Radio />} label="Geolocalizzato" /> */}
                        </RadioGroup>
                    </FormControl>
                </div>
                {pos === "Estero" ? (
                    <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                        <InputBilo
                            className="font-bold text-[#34291F]"
                            title={"Destinazione"}
                        />
                        <TextField
                            onClick={!isOpen ? () => {
                                setIsOpen(true)
                            } : () => { }}
                            select
                            name={"trek"}
                            className={"w-full"}
                            value={formik?.values?.destination ? formik?.values?.destination : "default"}
                            sx={{
                                backgroundColor: "#e6dbd1",
                                borderColor: "#e6dbd1",
                                borderRadius: 3,
                                fieldset: { borderColor: "#e6dbd1" },
                            }}
                            onChange={(e) => {
                                if (e.target.value === "default") {
                                    formik.setFieldValue('destination', null)
                                    window.scrollTo({
                                        top: 800,
                                        left: 0,
                                        behavior: "smooth",
                                    });
                                    submit()
                                }
                            }}
                            SelectProps={{ IconComponent: () => null, open: isOpen, onClose: () => { setIsOpen(false) } }}
                            InputProps={{
                                sx: {
                                    color: "#34291F",
                                    borderColor: "#F0E9E3",
                                    borderRadius: 3,
                                },
                                endAdornment: (
                                    <img
                                        alt={"img"}
                                        src={select}
                                        className={"object-contain hover:cursor-pointer"}
                                        onClick={() => {

                                        }}
                                    />
                                ),
                            }}>
                            <MenuItem disabled value={"default"}>Scegli il Paese</MenuItem>
                            {statesShown?.length > 0 &&
                                statesShown?.filter((state)=>{ return allTreks?.some(trek => trek.countries?.includes(state.name))}).map((res, index) => {
                                    if (res.name === "Italia") {
                                        return "";
                                    }
                                    return (
                                        <MenuItem
                                            onClick={() => {
                                                formik.setFieldValue('destination', res.name)
                                                submit()
                                            }}
                                            key={res.id}
                                            value={res?.name ? res?.name : 'default'}>
                                            {res.name}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </div>
                ) : (
                    ""
                )}
                {pos === "Italia" ? (
                    <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                        <InputBilo
                            className="font-bold text-[#34291F]"
                            title={"Destinazione"}
                        />
                        <TextField
                            onClick={!isOpen ? () => {
                                setIsOpen(true)
                            } : () => { }}
                            // ref={endAdornmentDest}
                            select
                            name={"trek"}
                            className={"w-full"}
                            value={formik?.values?.regions ? formik.values.regions : "default"}
                            sx={{
                                backgroundColor: "#e6dbd1",
                                borderColor: "#e6dbd1",
                                borderRadius: 3,
                                fieldset: { borderColor: "#e6dbd1" },
                            }}
                            onChange={(e) => {
                                if (e?.target?.value === "default") {
                                    formik.setFieldValue('regions', null)
                                    window.scrollTo({
                                        top: 800,
                                        left: 0,
                                        behavior: "smooth",
                                    });
                                    submit()
                                }
                            }}
                            SelectProps={{ IconComponent: () => null, open: isOpen, onClose: () => { setIsOpen(false) } }}
                            InputProps={{
                                sx: {
                                    color: "#34291F",
                                    borderColor: "#F0E9E3",
                                    backgroundColor: "transparent",
                                    borderRadius: 3,
                                },
                                endAdornment: (
                                    <img
                                        alt={"img"}
                                        src={select}
                                        className={"object-contain hover:cursor-pointer"}
                                        onClick={() => {
                                        }}
                                    />
                                ),
                            }}>
                            <MenuItem value={"default"}>Scegli la regione</MenuItem>
                            {regionsShown?.length > 0 &&
                                regionsShown?.filter((region)=>{ return allTreks?.some(trek => trek.regions?.includes(region.name))}).map((res, index) => {
                                    return (
                                        <MenuItem
                                            onClick={() => {
                                                formik.setFieldValue('regions', res.name)
                                                submit()
                                            }}
                                            key={res.id}
                                            value={res?.name}>
                                            {res.name}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </div>
                ) : (
                    ""
                )}
                <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                    <InputBilo
                        className="font-bold text-[#34291F]"
                        title={"Tipologia di trek"}
                    />
                    {filter?.data?.data
                        ? filter?.data?.data.map((res, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg">
                                        <Checkbox
                                            id={res.id.toString()}
                                            checked={formik && formik.values && formik.values.typology ? [...formik.values.typology]?.includes(res.id) : false} //typology
                                            onChange={(e) => {
                                                let typologyArray = formik.values.typology
                                                if(!Array.isArray(typologyArray)){
                                                    typologyArray = []
                                                }
                                                if (typologyArray.includes(res.id)) {
                                                    formik.setFieldValue('typology', typologyArray.filter(type => type !== res.id));
                                                } else {
                                                    formik.setFieldValue('typology', typologyArray.concat(res.id));
                                                }
                                                submit()
                                            }}
                                        />
                                        <InputBilo
                                            className="text-[#34291F]"
                                            title={res.name}
                                        />
                                    </div>
                                </React.Fragment>
                            );
                        })
                        : ""}
                </div>
                <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                    <InputBilo
                        className="font-bold text-[#34291F]"
                        title={"Data partenza trek"}
                    />
                    <div className="flex flex-col 2xl:flex-row items-center gap-4 mt-4">
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                            <DesktopDatePicker
                                label='da'
                                className="w-full"
                                inputFormat="dd/MM/yyyy"
                                value={formik.values.date !== null ? formik.values.date : moment()}
                                onChange={(newValue) => {
                                    let regex = /^\d\d\/\d\d\/\d\d\d\d/;
                                    if (newValue &&
                                        regex.test(`${newValue.getDate() > 9 ? newValue.getDate() : `0${newValue.getDate()}`}/${(newValue.getMonth() + 1) > 9 ? (newValue.getMonth() + 1) : `0${newValue.getMonth() + 1}`}/${newValue.getFullYear()}`)
                                    ) {
                                        formik.setFieldValue('date', moment(newValue).toString())
                                        submit()
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className={"w-full"}
                                        sx={{
                                            backgroundColor: "#e6dbd1",
                                            borderRadius: 3,
                                            "& .MuiOutlinedInput-root": {
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: "none",
                                                },
                                            },
                                            '& .MuiFormLabel-root': {
                                                fontSize: '24px',
                                                top: '-5px'
                                            }
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                            <DesktopDatePicker

                                label='fino al'
                                className="w-full"
                                inputFormat="dd/MM/yyyy"
                                minDate={formik.values.date ?? new Date()}
                                value={formik.values.dateEnd ?? null}
                                onChange={(newValue) => {
                                    let regex = /^\d\d\/\d\d\/\d\d\d\d/;
                                    if (newValue &&
                                        regex.test(`${newValue.getDate() > 9 ? newValue.getDate() : `0${newValue.getDate()}`}/${(newValue.getMonth() + 1) > 9 ? (newValue.getMonth() + 1) : `0${newValue.getMonth() + 1}`}/${newValue.getFullYear()}`) &&
                                        new Date(formik.values.date) <= newValue
                                    ) {
                                        formik.setFieldValue('dateEnd', newValue)
                                        submit()
                                    }

                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className={"w-full"}
                                        sx={{
                                            backgroundColor: "#e6dbd1",
                                            borderRadius: 3,
                                            "& .MuiOutlinedInput-root": {
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: "none",
                                                },
                                            },
                                            '& .MuiFormLabel-root': {
                                                fontSize: '24px',
                                                top: '-5px'
                                            }
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                    <InputBilo
                        className="font-bold text-[#34291F]"
                        title={"Classificazione"}
                    />
                    <TextField
                        onClick={isOpenClass ? () => { } : () => { setIsOpenClass(true) }}
                        select
                        name={"trek3"}
                        className={"w-full"}
                        value={formik?.values?.label ? formik.values.label : "default"}
                        sx={{
                            backgroundColor: "#e6dbd1",
                            borderColor: "#e6dbd1",
                            borderRadius: 3,
                            fieldset: { borderColor: "#e6dbd1" },
                        }}
                        onChange={(e) => {
                            if (e?.target?.value === "default") {
                                formik.setFieldValue('label', null)
                                window.scrollTo({
                                    top: 800,
                                    left: 0,
                                    behavior: "smooth",
                                });
                                submit()
                            }
                        }}
                        SelectProps={{ IconComponent: () => null, open: isOpenClass, onClose: () => { setIsOpenClass(false) } }}
                        InputProps={{
                            sx: {
                                color: "#34291F",
                                borderColor: "#F0E9E3",
                                borderRadius: 3,
                            },
                            endAdornment: (
                                <img alt={"img"} src={select} className={"object-contain hover:cursor-pointer"}
                                    onClick={() => {
                                        setIsOpenClass(true)
                                    }}
                                />

                            ),
                        }}>
                        <MenuItem value={"default"}>
                            -
                        </MenuItem>
                        {label?.data?.data?.length > 0 &&
                            label?.data?.data?.map((res, index) => {
                                return (
                                    <MenuItem
                                        onClick={() => {
                                            formik.setFieldValue('label', res.id)
                                            window.scrollTo({
                                                top: 800,
                                                left: 0,
                                                behavior: "smooth",
                                            });
                                            submit()
                                        }}
                                        key={index}
                                        value={res?.id ? res?.id : 'default'}>
                                        {res.name}
                                    </MenuItem>
                                );
                            })}
                    </TextField>
                </div>
                <div className="bg-[#F0E9E3] text-lg p-4 pt-12">
                    <InputBilo
                        className="font-bold text-[#34291F]"
                        title={"Sezione organizzatrice"}
                    />
                    {departments?.data?.data
                        ? departments?.data?.data?.filter(res => res.id !== 1).map((res, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="flex flex-row items-center mt-2 gap-2 bg-[#e6dbd1] p-2 rounded-lg">
                                        <Checkbox
                                            id={res.id.toString()}
                                            checked={formik.values.departments ? formik.values.departments.includes(res.id) : false}//departmentsArray
                                            onChange={() => {

                                                if (formik.values.departments.includes(res.id)) {
                                                    formik.setFieldValue('departments', formik.values.departments.filter(type => type !== res.id));
                                                } else {
                                                    formik.setFieldValue('departments', formik.values.departments.concat(res.id));
                                                }
                                                window.scrollTo({
                                                    top: 800,
                                                    left: 0,
                                                    behavior: "smooth",
                                                });
                                                submit()
                                            }}
                                        />
                                        <InputBilo
                                            className="text-[#34291F]"
                                            title={res.name}
                                        />
                                    </div>
                                </React.Fragment>
                            );
                        })
                        : ""}
                </div>
                <div className="bg-[#F0E9E3] text-lg p-4 pt-12 rounded-b-xl">
                    <div className="flex items-center">
                        <InputBilo
                            className="font-bold text-[#34291F]"
                            title={"Difficoltà"}
                        />
                        <button
                            className="w-[25px] h-[25px] rounded-full border-[1px] border-black flex justify-center items-center mx-[10px]"
                            onClick={() => {
                                setInfoDifficolta(true);
                                document.body.style.overflow = "hidden";
                            }}>
                            i
                        </button>
                    </div>
                    <RatingCustom
                        mobile={false}
                        max={6}
                        value={formik.values.difficulty}
                        onChange={(index) => {
                            if (formik.values.difficulty.includes(index)) {
                                formik.setFieldValue('difficulty', formik.values.difficulty.filter(diff => diff !== index))
                            } else {
                                formik.setFieldValue('difficulty', formik.values.difficulty.concat(index))
                            }
                            submit()
                        }}
                        bool={formik.values.maps}
                    />
                </div>
            </form >



            {/* MODALE SALVA RICERCA*/}
            {
                count && count.profile ?
                    <DialogCustom
                        title={"Salva ricerca"}
                        fullScreen={false}
                        open={openSalvaRicerca}
                        handleClose={() => {
                            setOpenSalvaRicerca(false)
                            api.post('/users/save-trek-search', {
                                name: titleSalvaRicerca,
                                url: `${pos === 'Italia' ? '/trek-italia' : (pos === 'Estero' ? '/trek-estero' : '/vicino-a-me')}${window.location.search}`
                            }).then(res => {

                            })

                        }}
                        closeModal={() => setOpenSalvaRicerca(false)}
                        content={fomrSalvaRicerca()}
                        button={"SALVA"}
                    />
                    :
                    <Dialog
                        open={openSalvaRicerca}
                        onClose={() => {
                            setOpenSalvaRicerca(false)
                        }}
                        maxWidth={"lg"}
                        fullWidth={true}>
                        <>
                            <DialogTitle>
                                Per salvare la ricerca devi effettuare l’accesso
                            </DialogTitle>
                            <DialogContent>
                                <Login loginTo={`/${pos === 'Italia' ? 'trek-italia' + window.location.search : (pos === 'Estero' ? 'trek-estero' + window.location.search : 'vicino-a-me')}`} />
                            </DialogContent>
                        </>

                    </Dialog>
            }
            {
                /* info difficoltà */
                infoDifficolta ? (
                    <div className="transition-shadow shadow-[0_0_6000px_6000px_rgba(33,33,33,0.3)] fixed z-[1] bg-white fixed top-[20px]  left-1/2 -translate-x-[50%] w-[80%] h-[90vh]">
                        <button
                            className="m-2 w-[25px] h-[25px] rounded-full border-[1px] border-black flex justify-center items-center mx-[10px]"
                            onClick={() => {
                                setInfoDifficolta(false);
                                document.body.style.overflow = "auto";
                            }}>
                            X
                        </button>
                        <img src={legendDifficulty} alt="legenda" />
                    </div>
                ) : (
                    ""
                )
            }
        </>
    )
}

const useStyles = makeStyles({
    drawer: {
      width: 'auto',      // Larghezza basata sul contenuto
      maxWidth: '75%',  // Larghezza massima di 700px
      borderTopRightRadius: 28,
      borderBottomRightRadius: 28,
    },
  });

function TrekFilter({ formik, pos, regionsShown, statesShown, departments, filter, label, allTreks }) {


    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024)
    const [listaFiltriOpen, setListaFiltriOpen] = useState(false)
    const classes = useStyles();


    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth < 1024);
        };

        window.addEventListener('resize', checkScreenSize);

        // Pulizia: rimuovere l'event listener quando il componente viene smontato
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    return (
        <>
            {
                !isMobile ?
                    <FormTrek formik={formik} pos={pos} regionsShown={regionsShown} statesShown={statesShown} departments={departments} filter={filter} label={label} allTreks={allTreks}/>
                    :
                    <>
                        <button
                            onClick={() => {
                                setListaFiltriOpen(true);
                            }}
                            className="w-[97px] h-[101px] rounded-tr-[35px] rounded-br-[35px] bg-[#F0E9E3] fixed left-0 bottom-[15px] shadow-[0_0_22px_0_rgba(12,12,12,0.7)] z-[999]">
                            <div className="w-[59px] h-[59px] rounded-full relative bg-[#796557] left-1/4">
                                <img src={filterIcon} alt="filtri_img" />
                            </div>
                        </button>
                        <Drawer classes={{ paper: classes.drawer }} open={listaFiltriOpen} onClose={() => {
                                setListaFiltriOpen(false);
                            }}>
                            <FormTrek formik={formik} pos={pos} regionsShown={regionsShown} statesShown={statesShown} departments={departments} filter={filter} label={label} allTreks={allTreks}/>
                        </Drawer>
                    </>

            }
        </>

    )
}

export default TrekFilter
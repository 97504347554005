import TopBarIndex from '../components/TopBarIndex';
import FooterDown from '../Sezioni/FooterDown';
import montagne1 from "../assets/img/footer-marrone.png";
import img1 from "../assets/img/accompagnatori.jpg";
import style from "./Privacy.module.css"
import BackgroundTitle from '../components/BackgroundTitle';
import InputBilo from '../components/InputBilo';
import { useSelector } from 'react-redux';
import { api } from '../conf/apiConf';
import useSWR from 'swr';
import { Autocomplete, Button, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import env from '../conf/variables';
import { useFormik } from 'formik';
import InputCustom from '../components/InputCustom';
import { useEffect, useState } from 'react';


function Privacy() {

    const count = useSelector(state => state.count)
    const [firstTime, setFirstTime] = useState(true)

    api.setHeaders({
        Authorization: "Bearer " + count.token,
        Accept: 'application/json'
    });

    const fetcher2 = (url) =>
        api.get(url).then((res) => {
            return res;
        });
    const { data: user, error: errorUser } = useSWR(
        [`users/get/` + count.profile.id],
        fetcher2
    );

    function formattedDate(date) {
        let value = new Date(date.split('T')[0])
        let year = value.getUTCFullYear();
        let month = ((value.getUTCMonth() + 1) < 10 ? ('0' + (value.getUTCMonth() + 1)) : (value.getUTCMonth() + 1));
        let day = (value.getUTCDate() < 10 ? ('0' + value.getUTCDate()) : value.getUTCDate());
        return `${day}/${month}/${year}`
    }

    const formik = useFormik({
        initialValues: {
            accept_partnerconsent_1: 'false',
            accept_partnerconsent_2: 'false',
            accept_partnerconsent_3: 'false',
            accept_partnerconsent_4: 'null',
            disability_type: [],
        },
        onSubmit: (values) => {
            if(values.accept_partnerconsent_4 === 'null'){
                values.accept_partnerconsent_4 = null
            }
            api.post(`/users/${user && user.data && user.data.data && user.data.data.id}/save-privacy-preferencies`, values)
                .then((res) => {
                    window.location.reload()
                })
        }
    })

    useEffect(() => {
        formik.setFieldValue('accept_partnerconsent_1', user && user.data && user.data.data && user.data.data.accept_partnerconsent_1 === '1' ? 'true' : 'false')
        formik.setFieldValue('accept_partnerconsent_2', user && user.data && user.data.data && user.data.data.accept_partnerconsent_2 === '1' ? 'true' : 'false')
        formik.setFieldValue('accept_partnerconsent_3', user && user.data && user.data.data && user.data.data.accept_partnerconsent_3 === '1' ? 'true' : 'false')
        formik.setFieldValue('accept_partnerconsent_4', user && user.data && user.data.data && user.data.data.accept_partnerconsent_4 === '1' ? 'true' : (user && user.data && user.data.data && user.data.data.accept_partnerconsent_4 === '0' ? 'false' : 'null'))
        formik.setFieldValue('disability_type', user && user.data && user.data.data && user.data.data.disability_type)
    }, [user])
    
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Privacy"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Area privata &gt; </div>
                        <div className={"font-bold"}>Privacy</div>
                    </div>
                    {/* Content */}
                    <div
                        className={"my-6 mb-[3.5rem] text-4xl text-[#DF5B23]"}
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}
                    >
                        Privacy
                    </div>
                    {/* <div className='my-12'>
                        <InputBilo
                            className={"text-xl font-bold"}
                            title="Privacy utente"
                        />
                        <div className="my-6 max-h-56 overflow-y-auto">
                            {Privacy()}
                        </div>
                        <InputBilo
                            className={"text-xl font-bold"}
                            title={`Accettata in data: ${user && user.data && user.data.data ? formattedDate(user.data.data.created_at) : ''} `}
                        />
                    </div>
                    {
                        user && user.data && user.data.data && user.data.data.user_partner === '1' &&
                        <div className='my-12'>
                            <InputBilo
                                className={"text-xl font-bold"}
                                title="Privacy socio"
                            />
                            <div className="my-6 max-h-56 overflow-y-auto">
                                {Privacy2()}
                            </div>
                            <InputBilo
                                className={"text-xl font-bold"}
                                title={`Accettata in data: ${user && user.data && user.data.data.associate ? formattedDate(user.data.data.associate.created_at) : ''} `}
                            />
                        </div>

                    } */}
                    <div className={"flex flex-col "}>
                        <Typography className={'!mt-4'} component={"h1"}>Informativa Privacy:</Typography>
                        <iframe
                            title="Informativa Privacy"
                            className="w-full"
                            height="800"
                            src={`${env.url_api_without_api}document/informativa_privacy.pdf`}
                            allowFullScreen
                        ></iframe>
                        <div className="flex items-center text-xl">
                            {
                                user && user.data && user.data.data && user.data.data.accept_privacy_date ?
                                    `Accettata in data ${user.data.data.accept_privacy_date.split('-')[2].split(' ')[0]}/${user.data.data.accept_privacy_date.split('-')[1]}/${user.data.data.accept_privacy_date.split('-')[0]} ${user.data.data.accept_privacy_date.split(' ')[1]}`
                                    : 'Non accettata'
                            }
                        </div>
                    </div>
                    {user && user.data && user.data.data && user.data.data.user_partner === '1' && (
                        <>
                            <div className="flex flex-col gap-6 mt-8 mb-4">
                                {/* <Divider />
                                <div>
                                    <Typography className={'!mt-4'} component={"h1"}>Statuto:</Typography>
                                    <iframe
                                        title="Statuto"
                                        className="w-full"
                                        height="400"
                                        src={`${env.url_api_without_api}/document/statuto.pdf`}
                                        allowFullScreen
                                    ></iframe>
                                    <div className={"flex flex-row items-center text-xl"}>
                                        {
                                            user && user.data && user.data.data && user.data.data.accept_statute_partner_date ?
                                                `Accettata in data ${user.data.data.accept_statute_partner_date.split('-')[2].split(' ')[0]}/${user.data.data.accept_statute_partner_date.split('-')[1]}/${user.data.data.accept_statute_partner_date.split('-')[0]} ${user.data.data.accept_statute_partner_date.split(' ')[1]}`
                                                : 'Non accettata'
                                        }
                                    </div>
                                </div>

                                <Divider />
                                <div>
                                    <Typography className={'!mt-4'} component={"h1"}>REGOLAMENTO DI ATTUAZIONE DELLO STATUTO:</Typography>
                                    <iframe
                                        title="REGOLAMENTO DI ATTUAZIONE DELLO STATUTO"
                                        className="w-full"
                                        height="400"
                                        src={`${env.url_api_without_api}/document/regolamento_statuto.pdf`}
                                        allowFullScreen
                                    ></iframe>
                                    <div className={"flex flex-row items-center text-xl"}>
                                        {
                                            user && user.data && user.data.data && user.data.data.accept_rule_partner_date ?
                                                `Accettata in data ${user.data.data.accept_rule_partner_date.split('-')[2].split(' ')[0]}/${user.data.data.accept_rule_partner_date.split('-')[1]}/${user.data.data.accept_rule_partner_date.split('-')[0]} ${user.data.data.accept_rule_partner_date.split(' ')[1]}`
                                                : 'Non accettata'
                                        }
                                    </div>
                                </div>
                                <Divider />

                                <div>
                                    <Typography className={'!mt-4'} component={"h1"}>REGOLAMENTO SUL FUNZIONAMENTO DELLE ASSEMBLEE:</Typography>
                                    <iframe
                                        title="REGOLAMENTO SUL FUNZIONAMENTO DELLE ASSEMBLEE"
                                        className="w-full"
                                        height="400"
                                        src={`${env.url_api_without_api}/document/regolamento_assemblee.pdf`}
                                        allowFullScreen
                                    ></iframe>
                                    <div className={"flex flex-row items-center text-xl"}>
                                        {
                                            user && user.data && user.data.data && user.data.data.accept_privacy_partner_date ?
                                                `Accettata in data ${user.data.data.accept_privacy_partner_date.split('-')[2].split(' ')[0]}/${user.data.data.accept_privacy_partner_date.split('-')[1]}/${user.data.data.accept_privacy_partner_date.split('-')[0]} ${user.data.data.accept_privacy_partner_date.split(' ')[1]}`
                                                : 'Non accettata'
                                        }
                                    </div>
                                </div> */}

                                <Divider />
                                <form onSubmit={formik.handleSubmit}>
                                    <Typography className={'!mt-4'} component={"h1"}>Consenso soci:</Typography>
                                    <iframe
                                        title="Consenso soci"
                                        className="w-full"
                                        height="800"
                                        src={`${env.url_api_without_api}document/consenso_soci.pdf`}
                                        allowFullScreen
                                    ></iframe>
                                    <Typography className={'!mt-4'} component={"h1"}>
                                        Invio di materiale informativo e/o pubblicitario anche mediante telefono o internet (Newsletter/cataloghi).
                                    </Typography>
                                    <div className={"flex flex-row items-center"}>
                                        <FormControlLabel
                                            sx={{ margin: 0 }}
                                            control={
                                                <Checkbox
                                                    checked={formik.values.accept_partnerconsent_1 === 'true'}
                                                    onChange={(
                                                        event
                                                    ) =>
                                                        formik.setFieldValue(
                                                            "accept_partnerconsent_1",
                                                            'true'
                                                        )
                                                    }
                                                />
                                            }
                                            label="CONSENTO"
                                        />
                                        <FormControlLabel
                                            sx={{ margin: 0, whiteSpace: 'nowrap' }}
                                            control={
                                                <Checkbox
                                                    checked={formik.values.accept_partnerconsent_1 === 'false'}
                                                    onChange={(
                                                        event
                                                    ) =>
                                                        formik.setFieldValue(
                                                            "accept_partnerconsent_1",
                                                            'false'
                                                        )
                                                    }
                                                />
                                            }
                                            label="NON CONSENTO"
                                        />
                                        <div className='w-full text-center max-w-[600px]'>
                                        {
                                            user && user.data && user.data.data && user.data.data.accept_partnerconsent_1_date ?
                                                `(Accettato in data ${user.data.data.accept_partnerconsent_1_date.split('-')[2].split(' ')[0]}/${user.data.data.accept_partnerconsent_1_date.split('-')[1]}/${user.data.data.accept_partnerconsent_1_date.split('-')[0]} ${user.data.data.accept_partnerconsent_1_date.split(' ')[1]})`
                                                : ''
                                        }
                                        </div>
                                    </div>
                                    <Typography className={'!mt-4'} component={"h1"}>
                                        Pubblicazione della mia immagine e/o videoripresa sul sito web e/o social network e/o brochure istituzionali
                                        in via gratuita.
                                    </Typography>
                                    <div className={"flex flex-row items-center"}>
                                        <FormControlLabel
                                            sx={{ margin: 0 }}
                                            control={
                                                <Checkbox
                                                    checked={formik.values.accept_partnerconsent_2 === 'true'}
                                                    onChange={(
                                                        event
                                                    ) =>
                                                        formik.setFieldValue(
                                                            "accept_partnerconsent_2",
                                                            'true'
                                                        )
                                                    }
                                                />
                                            }
                                            label="CONSENTO"
                                        />
                                        <FormControlLabel
                                            sx={{ margin: 0, whiteSpace: 'nowrap' }}
                                            control={
                                                <Checkbox
                                                    checked={formik.values.accept_partnerconsent_2 === 'false'}
                                                    onChange={(
                                                        event
                                                    ) =>
                                                        formik.setFieldValue(
                                                            "accept_partnerconsent_2",
                                                            'false'
                                                        )
                                                    }
                                                />
                                            }
                                            label="NON CONSENTO"
                                        />
                                        <div className='w-full text-center max-w-[600px]'>
                                        {
                                            user && user.data && user.data.data && user.data.data.accept_partnerconsent_2_date ?
                                                `(Accettato in data ${user.data.data.accept_partnerconsent_2_date.split('-')[2].split(' ')[0]}/${user.data.data.accept_partnerconsent_2_date.split('-')[1]}/${user.data.data.accept_partnerconsent_2_date.split('-')[0]} ${user.data.data.accept_partnerconsent_2_date.split(' ')[1]})`
                                                : ''
                                        }
                                        </div>
                                    </div>
                                    <Typography className={'!mt-4'} component={"h1"}>
                                        Invio via SMS, mail, whatsApp, etc. di comunicazioni tra i soci partecipanti, utili per lo svolgimento e
                                        organizzazione delle escursioni.
                                    </Typography>
                                    <div className={"flex flex-row items-center"}>
                                        <FormControlLabel
                                            sx={{ margin: 0 }}
                                            control={
                                                <Checkbox
                                                    checked={formik.values.accept_partnerconsent_3 === 'true'}
                                                    onChange={(
                                                        event
                                                    ) =>
                                                        formik.setFieldValue(
                                                            "accept_partnerconsent_3",
                                                            'true'
                                                        )
                                                    }
                                                />
                                            }
                                            label="CONSENTO"
                                        />
                                        <FormControlLabel
                                            sx={{ margin: 0, whiteSpace: 'nowrap' }}
                                            control={
                                                <Checkbox
                                                    checked={formik.values.accept_partnerconsent_3 === 'false'}
                                                    onChange={(
                                                        event
                                                    ) =>
                                                        formik.setFieldValue(
                                                            "accept_partnerconsent_3",
                                                            'false'
                                                        )
                                                    }
                                                />
                                            }
                                            label="NON CONSENTO"
                                        />
                                        <div className='w-full text-center max-w-[600px]'>
                                        {
                                            user && user.data && user.data.data && user.data.data.accept_partnerconsent_3_date ?
                                                `(Accettato in data ${user.data.data.accept_partnerconsent_3_date.split('-')[2].split(' ')[0]}/${user.data.data.accept_partnerconsent_3_date.split('-')[1]}/${user.data.data.accept_partnerconsent_3_date.split('-')[0]} ${user.data.data.accept_partnerconsent_3_date.split(' ')[1]})`
                                                : ''
                                        }
                                        </div>
                                    </div>
                                    <Typography className={'!mt-4'} component={"h1"}>
                                        Trattamento dati sanitari in riferimento alle condizioni psicofisiche del partecipante alle escursioni itineranti.
                                    </Typography>
                                    <div className={"flex flex-row items-center"}>
                                        <FormControlLabel
                                            sx={{ margin: 0 }}
                                            control={
                                                <Checkbox
                                                    checked={formik.values.accept_partnerconsent_4 === 'true'}
                                                    onChange={(
                                                        event
                                                    ) =>
                                                        formik.setFieldValue(
                                                            "accept_partnerconsent_4",
                                                            'true'
                                                        )
                                                    }
                                                />
                                            }
                                            label="CONSENTO"
                                        />
                                        <FormControlLabel
                                            sx={{ margin: 0, whiteSpace: 'nowrap' }}
                                            control={
                                                <Checkbox
                                                    checked={formik.values.accept_partnerconsent_4 === 'false'}
                                                    onChange={(
                                                        event
                                                    ) =>
                                                        formik.setFieldValue(
                                                            "accept_partnerconsent_4",
                                                            'false'
                                                        )
                                                    }
                                                />
                                            }
                                            label="Non Consento"
                                        />
                                        <FormControlLabel
                                            sx={{ margin: 0, whiteSpace: 'nowrap' }}
                                            control={
                                                <Checkbox
                                                    checked={formik.values.accept_partnerconsent_4 === 'null'}
                                                    onChange={(
                                                        event
                                                    ) =>
                                                        formik.setFieldValue(
                                                            "accept_partnerconsent_4",
                                                            'null'
                                                        )
                                                    }
                                                />
                                            }
                                            label="NON APPLICABILE"
                                        />
                                        <div className='w-full text-center max-w-[422px]'>
                                        {
                                            user && user.data && user.data.data && user.data.data.accept_partnerconsent_4_date ?
                                                `(Accettato in data ${user.data.data.accept_partnerconsent_4_date.split('-')[2].split(' ')[0]}/${user.data.data.accept_partnerconsent_4_date.split('-')[1]}/${user.data.data.accept_partnerconsent_4_date.split('-')[0]} ${user.data.data.accept_partnerconsent_4_date.split(' ')[1]})`
                                                : ''
                                        }
                                        </div>
                                    </div>
                                    <Autocomplete
                                        defaultValue={user && user.data && user.data.data && JSON.parse(user.data.data.disability_type)}
                                        fullWidth
                                        multiple
                                        onChange={(e, value) => {formik.setFieldValue("disability_type", value)}}
                                        options={['fisica', 'psichica', 'sensoriale']}
                                        getOptionLabel={(option) => option}
                                        filterSelectedOptions
                                        value={formik.values.disability_type && formik.values.disability_type.length ? JSON.parse(formik.values.disability_type) : []}
                                        renderInput={(params) => {
                                            return <InputCustom
                                                name={"disability_type"}
                                                title="Selezionare tipologia di disabilità"
                                                {...params}
                                            />
                                        }}
                                    />
                                    <div
                                        className={`mt-6 flex flex-row items-center gap-4 justify-start`}>
                                        <Button
                                            variant={"contained"}
                                            className={
                                                "shadow-md uppercase w-full max-w-[300px] !text-2xl italic !font-normal"
                                            }
                                            type={"submit"}>
                                            Aggiorna
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={"2xl:hidden flex flex-col items-center gap-2 bg-[#f0e9e3] mt-8 py-8 relative"}>
                <img
                    src={montagne1}
                    alt={"img"}
                    className={"object-contain absolute z-30 -bottom-3"}
                />
            </div>
            <FooterDown />

        </>
    );
}

export default Privacy